import React, { FC, HtmlHTMLAttributes, useCallback, useState } from "react";
import { Theme } from "@periplus/ui-library";
import { Dialog, Typography } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import useUpdateDeclarationRequest from "domain/declaration/useUpdateDeclarationRequest";
import { CustomsIcon, WarehouseIcon } from "components/Icons";
import { ArrivalType } from "graphql/generated";
import { DeclarationDashboardEntity } from "../hooks/useGetDeclarationsDashboard";
import { makeStyles } from 'tss-react/mui';

export type PlaceOfArrivalType = "ac-place" | "border";

interface ConfirmArrivalDialogProps extends HtmlHTMLAttributes<HTMLElement> {
  onClose: () => void;
  declaration: DeclarationDashboardEntity;
  arrivalType: ArrivalType;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  arrivalTimeContainer: {
    display: "grid",
    justifyItems: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 32px",
      gridAutoFlow: "column",
      gap: 8,
    },
  },
}));

const ConfirmArrivalDialog: FC<ConfirmArrivalDialogProps> = ({
  onClose,
  declaration,
  arrivalType,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation("declaration");

  const updateDeclaration = useUpdateDeclarationRequest();

  const [arrivalTime] = useState(dayjs());
  const [isConfirming, setIsConfirming] = useState(false);

  const handleConfirm = useCallback(async () => {
    setIsConfirming(true);
    try {
      await updateDeclaration({
        variables: {
          file_id: declaration.fileId,
          declaration_status: "in_progress",
          arrival_time: arrivalTime,
          arrival_type: arrivalType,
        },
      });
      onClose();
    } catch (error) {
      setIsConfirming(false);
    }
  }, [onClose, arrivalTime, declaration, updateDeclaration, arrivalType]);

  return (
    <Dialog
      TitleIcon={
        arrivalType === ArrivalType.AllowedConsignee
          ? WarehouseIcon
          : CustomsIcon
      }
      mainTitle={t(
        arrivalType === ArrivalType.AllowedConsignee
          ? "Arrival at Place of Authorised Consignee"
          : "Arrival At The Border"
      )}
      subTitle={t("Please confirm the arrival time.")}
      onClose={onClose}
      onConfirm={handleConfirm}
      ConfirmButtonProps={{
        loading: isConfirming,
      }}
    >
      <div className={classes.arrivalTimeContainer}>
        <Typography variant="h1" color="primary">
          {arrivalTime.format("DD.MM.YY HH:mm")}
        </Typography>
        <Typography variant="h1" color="primary">
          {arrivalTime.format("([GMT]Z)")}
        </Typography>
      </div>
    </Dialog>
  );
};

export default ConfirmArrivalDialog;
