import React, { FC, HtmlHTMLAttributes, useCallback, useMemo } from "react";
import { Button, Theme } from "@periplus/ui-library";
import {
  FormTextField,
  Panel,
  Typography,
  FormSubmitButton,
  PageContainer,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { useAuth } from "keycloak";
import { Form, Formik } from "formik";
import SaveIcon from "@mui/icons-material/Save";
import * as Yup from "yup";
import useUpdateMyProfile from "domain/user/useUpdateMyProfile";
import { useSnackbar } from "notistack";
import { makeStyles } from 'tss-react/mui';

interface MyProfileProps extends HtmlHTMLAttributes<HTMLElement> {}

const useStyles = makeStyles()((theme: Theme) => ({
  form: {
    display: "grid",
    gap: 8,
    [theme.breakpoints.up("sm")]: {
      gap: 12,
      gridTemplateColumns: "1fr 2fr",
    },
  },
  inputsTitle: {
    "&:not(:first-child)": {
      marginTop: 4,
    },
  },
  namesInputsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  contactsInputsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  addressInputsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    gridAutoFlow: "row",
    [theme.breakpoints.up("sm")]: {
      display: "grid",
      gridTemplate: `
      "country  country country       city          city      city"
      "street   street  street_number street_number postcode  postcode"
    `,
    },
  },
}));

const MyProfile: FC<MyProfileProps> = () => {
  const { classes } = useStyles();
  const { t } = useTranslation("myProfile");
  const { user, refetch, updateUiSettings } = useAuth();
  const updateMyProfile = useUpdateMyProfile();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useMemo(
    () => ({
      firstName: user?.first_name || "",
      lastName: user?.last_name || "",
      email: user?.email || "",
      phone: user?.phone_number || "",
      company_name: user?.tenant?.tenant_name,
      country: user?.tenant?.address.country,
      city: user?.tenant?.address.city,
      street: user?.tenant?.address.street,
      street_number: user?.tenant?.address.street_number,
    }),
    [user]
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      firstName: Yup.string().trim().required(),
      lastName: Yup.string().trim().required(),
      email: Yup.string().trim().required(),
      phone: Yup.string().trim(),
    });
  }, []);
  const handleSubmit = useCallback(
    async (values) => {
      const castedValues = validationSchema.cast(values);
      await updateMyProfile({
        variables: {
          id: user?.userId,
          firstName: castedValues.firstName,
          lastName: castedValues.lastName,
          email: castedValues.email,
          phoneNumber: castedValues.phone,
        },
      });
      await refetch();
      enqueueSnackbar(t("Successfully"), { variant: "success" });
    },
    [user, updateMyProfile, enqueueSnackbar, t, refetch, validationSchema]
  );

  return (
    <PageContainer title={t("navigation:my-profile")}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ dirty }) => (
          <Panel
            title={
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  updateUiSettings({
                    pages: {},
                  });
                }}
              >
                {t("Reset Settings")}
              </Button>
            }
            controls={
              <FormSubmitButton
                endIcon={<SaveIcon />}
                minimizeForMobiles
                disabled={!dirty}
              />
            }
          >
            <Form className={classes.form}>
              <Typography
                className={classes.inputsTitle}
                variant="subtitle1"
                color="textSecondary"
              >
                {t("Name and Surname")}
              </Typography>
              <div className={classes.namesInputsContainer}>
                <FormTextField
                  name="firstName"
                  label={t("First Name")}
                  fullWidth
                />
                <FormTextField
                  name="lastName"
                  label={t("Last Name")}
                  fullWidth
                />
              </div>
              <Typography
                className={classes.inputsTitle}
                variant="subtitle1"
                color="textSecondary"
              >
                {t("Contact details")}
              </Typography>
              <div className={classes.contactsInputsContainer}>
                <FormTextField
                  name="email"
                  label={t("E-Mail")}
                  fullWidth
                  disabled
                />
                <FormTextField name="phone" label={t("Phone")} fullWidth />
              </div>
              <Typography
                className={classes.inputsTitle}
                variant="subtitle1"
                color="textSecondary"
              >
                {t("Company")}
              </Typography>
              <FormTextField
                name="company_name"
                label={t("Company Name")}
                disabled
                fullWidth
              />
              <Typography
                className={classes.inputsTitle}
                variant="subtitle1"
                color="textSecondary"
              >
                {t("Address")}
              </Typography>
              <div className={classes.addressInputsContainer}>
                <FormTextField
                  name="country"
                  label={t("Country")}
                  disabled
                  style={{ gridArea: "country" }}
                />
                <FormTextField
                  name="city"
                  label={t("City")}
                  disabled
                  style={{ gridArea: "city" }}
                />
                <FormTextField
                  name="street"
                  label={t("Street")}
                  disabled
                  style={{ gridArea: "street" }}
                />
                <FormTextField
                  name="street_number"
                  label={t("Street Number")}
                  disabled
                  style={{ gridArea: "street_number" }}
                />
                <FormTextField
                  name="postcode"
                  label={t("Postcode")}
                  disabled
                  style={{ gridArea: "postcode" }}
                />
              </div>
            </Form>
          </Panel>
        )}
      </Formik>
    </PageContainer>
  );
};

export default MyProfile;
