
export interface Country {
  id: string;
  name: string;
}

export const ALL_COUNTRIES = [
  {id: "AF", name: "Afghanistan"},
  {id: "AX", name: "Aland Islands"},
  {id: "AL", name: "Albania"},
  {id: "DZ", name: "Algeria"},
  {id: "AS", name: "American Samoa"},
  {id: "AD", name: "Andorra"},
  {id: "AO", name: "Angola"},
  {id: "AI", name: "Anguilla"},
  {id: "AQ", name: "Antarctica"},
  {id: "AG", name: "Antigua And Barbuda"},
  {id: "AR", name: "Argentina"},
  {id: "AM", name: "Armenia"},
  {id: "AW", name: "Aruba"},
  {id: "AU", name: "Australia"},
  {id: "AT", name: "Austria"},
  {id: "AZ", name: "Azerbaijan"},
  {id: "BS", name: "Bahamas"},
  {id: "BH", name: "Bahrain"},
  {id: "BD", name: "Bangladesh"},
  {id: "BB", name: "Barbados"},
  {id: "BY", name: "Belarus"},
  {id: "BE", name: "Belgium"},
  {id: "BZ", name: "Belize"},
  {id: "BJ", name: "Benin"},
  {id: "BM", name: "Bermuda"},
  {id: "BT", name: "Bhutan"},
  {id: "BO", name: "Bolivia"},
  {id: "BA", name: "Bosnia And Herzegovina"},
  {id: "BW", name: "Botswana"},
  {id: "BV", name: "Bouvet Island"},
  {id: "BR", name: "Brazil"},
  {id: "IO", name: "British Indian Ocean Territory"},
  {id: "BN", name: "Brunei Darussalam"},
  {id: "BG", name: "Bulgaria"},
  {id: "BF", name: "Burkina Faso"},
  {id: "BI", name: "Burundi"},
  {id: "KH", name: "Cambodia"},
  {id: "CM", name: "Cameroon"},
  {id: "CA", name: "Canada"},
  {id: "CV", name: "Cape Verde"},
  {id: "KY", name: "Cayman Islands"},
  {id: "CF", name: "Central African Republic"},
  {id: "TD", name: "Chad"},
  {id: "CL", name: "Chile"},
  {id: "CN", name: "China"},
  {id: "CX", name: "Christmas Island"},
  {id: "CC", name: "Cocos (Keeling) Islands"},
  {id: "CO", name: "Colombia"},
  {id: "KM", name: "Comoros"},
  {id: "CG", name: "Congo"},
  {id: "CD", name: "Congo, Democratic Republic"},
  {id: "CK", name: "Cook Islands"},
  {id: "CR", name: "Costa Rica"},
  {id: "CI", name: "Cote D\"Ivoire"},
  {id: "HR", name: "Croatia"},
  {id: "CU", name: "Cuba"},
  {id: "CY", name: "Cyprus"},
  {id: "CZ", name: "Czech Republic"},
  {id: "DK", name: "Denmark"},
  {id: "DJ", name: "Djibouti"},
  {id: "DM", name: "Dominica"},
  {id: "DO", name: "Dominican Republic"},
  {id: "EC", name: "Ecuador"},
  {id: "EG", name: "Egypt"},
  {id: "SV", name: "El Salvador"},
  {id: "GQ", name: "Equatorial Guinea"},
  {id: "ER", name: "Eritrea"},
  {id: "EE", name: "Estonia"},
  {id: "ET", name: "Ethiopia"},
  {id: "FK", name: "Falkland Islands (Malvinas)"},
  {id: "FO", name: "Faroe Islands"},
  {id: "FJ", name: "Fiji"},
  {id: "FI", name: "Finland"},
  {id: "FR", name: "France"},
  {id: "GF", name: "French Guiana"},
  {id: "PF", name: "French Polynesia"},
  {id: "TF", name: "French Southern Territories"},
  {id: "GA", name: "Gabon"},
  {id: "GM", name: "Gambia"},
  {id: "GE", name: "Georgia"},
  {id: "DE", name: "Germany"},
  {id: "GH", name: "Ghana"},
  {id: "GI", name: "Gibraltar"},
  {id: "GR", name: "Greece"},
  {id: "GL", name: "Greenland"},
  {id: "GD", name: "Grenada"},
  {id: "GP", name: "Guadeloupe"},
  {id: "GU", name: "Guam"},
  {id: "GT", name: "Guatemala"},
  {id: "GG", name: "Guernsey"},
  {id: "GN", name: "Guinea"},
  {id: "GW", name: "Guinea-Bissau"},
  {id: "GY", name: "Guyana"},
  {id: "HT", name: "Haiti"},
  {id: "HM", name: "Heard Island & Mcdonald Islands"},
  {id: "VA", name: "Holy See (Vatican City State)"},
  {id: "HN", name: "Honduras"},
  {id: "HK", name: "Hong Kong"},
  {id: "HU", name: "Hungary"},
  {id: "IS", name: "Iceland"},
  {id: "IN", name: "India"},
  {id: "ID", name: "Indonesia"},
  {id: "IR", name: "Iran, Islamic Republic Of"},
  {id: "IQ", name: "Iraq"},
  {id: "IE", name: "Ireland"},
  {id: "IM", name: "Isle Of Man"},
  {id: "IL", name: "Israel"},
  {id: "IT", name: "Italy"},
  {id: "JM", name: "Jamaica"},
  {id: "JP", name: "Japan"},
  {id: "JE", name: "Jersey"},
  {id: "JO", name: "Jordan"},
  {id: "KZ", name: "Kazakhstan"},
  {id: "KE", name: "Kenya"},
  {id: "KI", name: "Kiribati"},
  {id: "KR", name: "Korea"},
  {id: "KP", name: "North Korea"},
  {id: "KW", name: "Kuwait"},
  {id: "KG", name: "Kyrgyzstan"},
  {id: "LA", name: "Lao People\"s Democratic Republic"},
  {id: "LV", name: "Latvia"},
  {id: "LB", name: "Lebanon"},
  {id: "LS", name: "Lesotho"},
  {id: "LR", name: "Liberia"},
  {id: "LY", name: "Libyan Arab Jamahiriya"},
  {id: "LI", name: "Liechtenstein"},
  {id: "LT", name: "Lithuania"},
  {id: "LU", name: "Luxembourg"},
  {id: "MO", name: "Macao"},
  {id: "MK", name: "Macedonia"},
  {id: "MG", name: "Madagascar"},
  {id: "MW", name: "Malawi"},
  {id: "MY", name: "Malaysia"},
  {id: "MV", name: "Maldives"},
  {id: "ML", name: "Mali"},
  {id: "MT", name: "Malta"},
  {id: "MH", name: "Marshall Islands"},
  {id: "MQ", name: "Martinique"},
  {id: "MR", name: "Mauritania"},
  {id: "MU", name: "Mauritius"},
  {id: "YT", name: "Mayotte"},
  {id: "MX", name: "Mexico"},
  {id: "FM", name: "Micronesia, Federated States Of"},
  {id: "MD", name: "Moldova"},
  {id: "MC", name: "Monaco"},
  {id: "MN", name: "Mongolia"},
  {id: "ME", name: "Montenegro"},
  {id: "MS", name: "Montserrat"},
  {id: "MA", name: "Morocco"},
  {id: "MZ", name: "Mozambique"},
  {id: "MM", name: "Myanmar"},
  {id: "NA", name: "Namibia"},
  {id: "NR", name: "Nauru"},
  {id: "NP", name: "Nepal"},
  {id: "NL", name: "Netherlands"},
  {id: "AN", name: "Netherlands Antilles"},
  {id: "NC", name: "New Caledonia"},
  {id: "NZ", name: "New Zealand"},
  {id: "NI", name: "Nicaragua"},
  {id: "NE", name: "Niger"},
  {id: "NG", name: "Nigeria"},
  {id: "NU", name: "Niue"},
  {id: "NF", name: "Norfolk Island"},
  {id: "MP", name: "Northern Mariana Islands"},
  {id: "NO", name: "Norway"},
  {id: "OM", name: "Oman"},
  {id: "PK", name: "Pakistan"},
  {id: "PW", name: "Palau"},
  {id: "PS", name: "Palestinian Territory, Occupied"},
  {id: "PA", name: "Panama"},
  {id: "PG", name: "Papua New Guinea"},
  {id: "PY", name: "Paraguay"},
  {id: "PE", name: "Peru"},
  {id: "PH", name: "Philippines"},
  {id: "PN", name: "Pitcairn"},
  {id: "PL", name: "Poland"},
  {id: "PT", name: "Portugal"},
  {id: "PR", name: "Puerto Rico"},
  {id: "QA", name: "Qatar"},
  {id: "RE", name: "Reunion"},
  {id: "RO", name: "Romania"},
  {id: "RU", name: "Russian Federation"},
  {id: "RW", name: "Rwanda"},
  {id: "BL", name: "Saint Barthelemy"},
  {id: "SH", name: "Saint Helena"},
  {id: "KN", name: "Saint Kitts And Nevis"},
  {id: "LC", name: "Saint Lucia"},
  {id: "MF", name: "Saint Martin"},
  {id: "PM", name: "Saint Pierre And Miquelon"},
  {id: "VC", name: "Saint Vincent And Grenadines"},
  {id: "WS", name: "Samoa"},
  {id: "SM", name: "San Marino"},
  {id: "ST", name: "Sao Tome And Principe"},
  {id: "SA", name: "Saudi Arabia"},
  {id: "SN", name: "Senegal"},
  {id: "RS", name: "Serbia"},
  {id: "SC", name: "Seychelles"},
  {id: "SL", name: "Sierra Leone"},
  {id: "SG", name: "Singapore"},
  {id: "SK", name: "Slovakia"},
  {id: "SI", name: "Slovenia"},
  {id: "SB", name: "Solomon Islands"},
  {id: "SO", name: "Somalia"},
  {id: "ZA", name: "South Africa"},
  {id: "GS", name: "South Georgia And Sandwich Isl."},
  {id: "ES", name: "Spain"},
  {id: "LK", name: "Sri Lanka"},
  {id: "SD", name: "Sudan"},
  {id: "SR", name: "Suriname"},
  {id: "SJ", name: "Svalbard And Jan Mayen"},
  {id: "SZ", name: "Swaziland"},
  {id: "SE", name: "Sweden"},
  {id: "CH", name: "Switzerland"},
  {id: "SY", name: "Syrian Arab Republic"},
  {id: "TW", name: "Taiwan"},
  {id: "TJ", name: "Tajikistan"},
  {id: "TZ", name: "Tanzania"},
  {id: "TH", name: "Thailand"},
  {id: "TL", name: "Timor-Leste"},
  {id: "TG", name: "Togo"},
  {id: "TK", name: "Tokelau"},
  {id: "TO", name: "Tonga"},
  {id: "TT", name: "Trinidad And Tobago"},
  {id: "TN", name: "Tunisia"},
  {id: "TR", name: "Turkey"},
  {id: "TM", name: "Turkmenistan"},
  {id: "TC", name: "Turks And Caicos Islands"},
  {id: "TV", name: "Tuvalu"},
  {id: "UG", name: "Uganda"},
  {id: "UA", name: "Ukraine"},
  {id: "AE", name: "United Arab Emirates"},
  {id: "GB", name: "United Kingdom"},
  {id: "US", name: "United States"},
  {id: "UM", name: "United States Outlying Islands"},
  {id: "UY", name: "Uruguay"},
  {id: "UZ", name: "Uzbekistan"},
  {id: "VU", name: "Vanuatu"},
  {id: "VE", name: "Venezuela"},
  {id: "VN", name: "Vietnam"},
  {id: "VG", name: "Virgin Islands, British"},
  {id: "VI", name: "Virgin Islands, U.S."},
  {id: "WF", name: "Wallis And Futuna"},
  {id: "EH", name: "Western Sahara"},
  {id: "YE", name: "Yemen"},
  {id: "ZM", name: "Zambia"},
  {id: "ZW", name: "Zimbabwe"},
];

const EU_CHF_CODES = new Set([
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
]);
const EU_FTA_CODES = new Set([
  "NO",
  "LI",
  "IS"
]);

export const FILTER_EU_CHF = (countries: Country[]) => countries.filter(c => EU_CHF_CODES.has(c.id));
export const FILTER_EU_FTA = (countries: Country[]) => countries.filter(c => EU_FTA_CODES.has(c.id));
export const FILTER_REST = (countries: Country[]) => countries.filter(c => !EU_CHF_CODES.has(c.id) && !EU_FTA_CODES.has(c.id));

const eu_chf_value = FILTER_EU_CHF(ALL_COUNTRIES);
const eu_fta_value = FILTER_EU_FTA(ALL_COUNTRIES);
const rest_value = FILTER_REST(ALL_COUNTRIES);

export const FTA_COUNTRIES_DATA = {
  EU_CHF: eu_chf_value,
  EU_FTA: eu_fta_value,
  rest: rest_value
};
