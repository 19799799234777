import React, { useState, useEffect, useContext, useCallback } from "react";
import Token from "./IToken";
import config from "config";
import { useKeycloak } from "@react-keycloak/web";
import { SasStore } from "utils/blockBlobClient";
import client from "graphql/client";
import gql from "graphql-tag";
import { Tenant } from "graphql/generated";
import { changeAppLanguage } from "i18n";
import useUpdateMyUiSettings from "domain/user/useUpdateMyUiSettings";
import { MNR_TENANT_ID } from "domain/tenant/types";
import useUpdateMyUiSettingsTemplates from "domain/user/useUpdateMyUiSettingsTemplates";

export enum Role {
  DEFAULT = "default",
  ADMINISTRATOR = "administrator",
  EDITOR = "editor",
  MANAGER = "manager",
  VIEWER = "viewer",
  Supplier = "supplier",
  SupplierExtended = "supplier_extended",
  FreightForwarder = "freight_forwarder",
  CustomsBroker = "customs_broker",
  Importer = "importer",
}

export enum Permissions {
  LOGIN = "login",
  DOC_UPLOAD = "doc_upload",
  DASH_FLT = "dash_flt",
  DASH_EXC_CUST = "dash_exc_cust",
  DASH_VIA_CUST = "dash_via_cust",
  DMS_WD = "dms_wd",
  DMS_ALL = "dms_all",
  CHAT = "chat",
  USER_PROFILE = "user_profile",
  DASHBOARD = "dashboard",
  CREATE_DEC = "create_dec",
  ADDRESS_MGT = "address_mgt",
  CUSTOMS_ACCOUNT_MGT = "customs_account_mgt",
  DASH_CONFIRM_ARRIVAL_BORDER = "dash_confirm_arrival_border",
  DASH_CONFIRM_ARRIVAL_AC = "dash_confirm_arrival_ac",
  ZOLL_BORDEREAU = "zoll_bordereau",
  REPORTING = "reporting",
  ALERT_MANAGER = "alert_manager",
  ADDRESS_DETAILS = "address_details",
  SUPPLIER_ADDRESS = "supplier_address",
}
const namespace = "https://hasura.io/jwt/claims";

const delay = async (timeout: number) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};
/**
 * Properties from the IdToken + the custom defined properties (in the Auth0 Rules!)
 */

interface UserUiSettings {
  language?: string;
  sidebarOpen?: boolean;
  lastCheckedReleaseNotes?: string;
  pages: { [key: string]: any };
  [key: string]: any;
}

export interface UiSettingsTemplate {
  name: string;
  lastAppliedDate: string | null;
  settings: any;
}
interface UserUiSettingsTemplates {
  [key: string]: UiSettingsTemplate[];
}

export interface User extends Token {
  userId: string;
  allowedOrganizations: string[];
  currentOrganization: string;
  defaultRole: Role;
  currentRole: Role;
  allowedRoles: Role[];
  tenant_id: string;
  hasAllowedPermissions: (permissions: Permissions[]) => boolean;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  userPermissions?: Permissions[];
  tenant?: Tenant;
  isMnrUser: boolean;
  uiSettings: UserUiSettings;
  uiSettingsTemplates: UserUiSettingsTemplates;
  [key: string]: any;
}

interface OwnIdToken extends Token {
  [key: string]: any;
}

/**
 * Translates decoded Auth0-Id-token into a custom, flat user object
 * @param idToken
 */
function getUserFromIdToken(idToken: OwnIdToken) {
  // Strip the namespace, since we want to have a flat user object
  const { [namespace]: customProperties, ...authProperties } = idToken;
  const tenant_id = customProperties["x-hasura-tenant-id"];

  return {
    userId: customProperties["x-hasura-user-id"],
    allowedOrganizations: customProperties["x-hasura-allowed-organizations"],
    currentOrganization: customProperties["x-hasura-current-organization"],
    defaultRole: customProperties["x-hasura-default-role"],
    currentRole: customProperties["x-hasura-current-role"],
    allowedRoles: customProperties["x-hasura-allowed-roles"],
    tenant_id,
    ...authProperties,
  };
}

const updateSasTokens = () => {
  const sasStore = new SasStore();
  sasStore.updateSASForContainer(config.blobStorage.documentsContainerName);
  sasStore.updateSASForContainer(
    config.blobStorage.documentsContainerName,
    "application/pdf"
  );
  sasStore.updateSASForContainer(config.blobStorage.thumbsContainerName);
};

export interface IAuthContext {
  isAuthenticated: boolean;
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  updateUiSettings: (partialNewUiSettings: Partial<UserUiSettings>) => void;
  updateUiSettingsTemplates: (
    settingsTemplates: UserUiSettingsTemplates
  ) => void;
  idToken: string | undefined;
  loading: boolean;
  loginWithRedirect: CallableFunction;
  logout: CallableFunction;
  refetch: CallableFunction;
}

const initialAuthContext: IAuthContext = {
  isAuthenticated: false,
  user: undefined,
  setUser: () => undefined,
  updateUiSettings: () => undefined,
  updateUiSettingsTemplates: () => undefined,
  idToken: undefined,
  loading: true,
  loginWithRedirect: () => console.info("Initializing..."),
  logout: () => console.info("Initializing..."),
  refetch: () => console.info("Initializing..."),
};

// /**
//  * A function that routes the user to the right place after login
//  * @param appState
//  */
// export const onRedirectCallback = (appState: any) => {
//   window.history.replaceState(
//     {},
//     document.title,
//     appState && appState.targetUrl
//       ? appState.targetUrl
//       : window.location.pathname
//   );
// };

export const AuthContext =
  React.createContext<IAuthContext>(initialAuthContext);
export const useAuth = () => useContext(AuthContext);

// TODO(df): Improve typing...
export const AuthProvider = ({ children }: any) => {
  const updateMyUiSettings = useUpdateMyUiSettings();
  const [updateMyUiSettingsTemplates] = useUpdateMyUiSettingsTemplates();

  const [user, setUserOrig] = useState<User>();
  const setUser = (
    newStateDispatch:
      | Omit<User, "isMnrUser">
      | ((
          prev: Omit<User, "isMnrUser"> | undefined
        ) => Omit<User, "isMnrUser"> | undefined)
      | undefined
  ) => {
    setUserOrig((prevOrig) => {
      const newState: Omit<User, "isMnrUser"> | undefined =
        typeof newStateDispatch === "function"
          ? newStateDispatch(prevOrig)
          : newStateDispatch;
      return newState
        ? ({
            ...newState,
            isMnrUser: newState.tenant_id === MNR_TENANT_ID,
          } as User)
        : undefined;
    });
  };
  useEffect(() => {
    if (user?.uiSettings.language) {
      changeAppLanguage(user.uiSettings.language);
    }
  }, [user?.uiSettings.language]);
  const userBasePart = React.useMemo(
    () => ({
      userPermissions: [],
      hasAllowedPermissions(permissions: Permissions[]) {
        return !!this.userPermissions?.some((userPermission) => {
          return permissions.includes(userPermission);
        });
      },
      uiSettings: { pages: {} },
      uiSettingsTemplates: {},
    }),
    []
  );
  const [idToken, setIdToken] = useState();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  //@ts-ignore
  keycloak.onReady = useCallback(() => {}, []);
  //@ts-ignore
  keycloak.onAuthSuccess = () => {
    if (!keycloak) return null;
    const user = keycloak.idTokenParsed || {};

    setUser({ ...userBasePart, ...getUserFromIdToken(user) });
    updateSasTokens();
  };

  //@ts-ignore
  keycloak.onTokenExpired = () => {
    //@ts-ignore
    keycloak.updateToken(25).then((refreshed) => {
      if (refreshed) {
        updateSasTokens();
      }
    });
  };

  //@ts-ignore
  keycloak.onAuthRefreshError = () => {
    console.log("onAuthRefreshError");
    setAuthenticated(false);
    //@ts-ignore
    keycloak.login({
      redirectUri: config.keycloak.redirectUrl,
    });
  };

  const getDBUser = useCallback(async () => {
    const { data } = await client.query({
      query: gql`
        query WD_GET_USER {
          pp_users {
            permissions_desk
            user {
              first_name
              last_name
              email
              phone_number
              ui_settings
              ui_settings_templates
              tenant {
                id
                tenant_name
                tenantDocumentTypes {
                  document_type
                  default_flags
                  meta
                  code
                }
                declaration_labels(where: { is_deleted: { _eq: false } }) {
                  id
                  title
                  description
                  color
                  is_deleted
                }
                address {
                  id
                  country
                  city
                  street
                  street_number
                  zipcode
                  address_type
                  company_name
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "no-cache",
    });
    const dbUser = data?.pp_users[0].user;
    const userPermissions = data?.pp_users[0].permissions_desk;
    const uiSettings = {
      pages: {},
      ...data?.pp_users[0].user.ui_settings.wd,
    };

    return {
      ...dbUser,
      userPermissions,
      uiSettings,
      uiSettingsTemplates: {
        ...data?.pp_users[0].user.ui_settings_templates.wd,
      },
    };
  }, []);

  let initAuthClient = useCallback(async () => {
    while (!initialized) {
      await delay(10);
    }

    //@ts-ignore
    if (keycloak.authenticated || false) {
      //@ts-ignore
      const user = keycloak.idTokenParsed || {};
      const userFromIdToken = getUserFromIdToken(user);
      if (
        !userFromIdToken.allowedRoles.every((role) =>
          [Role.Supplier].includes(role)
        )
      ) {
        const dbUser = await getDBUser();
        const updatedUser: User = {
          ...userBasePart,
          ...userFromIdToken,
          ...dbUser,
        };

        setUser(updatedUser);
      } else {
        setUser({ ...userBasePart, ...userFromIdToken });
      }

      setAuthenticated(true);
      setIdToken(idToken);
      updateSasTokens();
    }

    setLoading(false);
  }, [
    idToken,
    //@ts-ignore
    keycloak.authenticated,
    //@ts-ignore
    keycloak.idTokenParsed,
    initialized,
    getDBUser,
    userBasePart,
  ]);

  //@ts-ignore
  if (window.Cypress && config.isProdMode === false) {
    initAuthClient = async () => Promise.resolve();
  }

  useEffect(() => {
    initAuthClient();
  }, [initAuthClient]);

  //@ts-ignore
  if (window.Cypress && config.isProdMode === false) {
    //todo add NODE_ENV check
    return (
      <AuthContext.Provider
        value={{
          ...initialAuthContext,
          isAuthenticated: true,
          loading: false,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        user,
        setUser,
        idToken,
        loading,
        loginWithRedirect: () => {
          //@ts-ignore
          keycloak.login({
            redirectUri: config.keycloak.redirectUrl,
            scope: "adit-api:call",
          });
        },
        //@ts-ignore
        logout: () => keycloak.logout(),
        refetch: async () => {
          if (!user) return;
          if (user.allowedRoles.every((role) => [Role.Supplier].includes(role)))
            return;

          const dbUser = await getDBUser();

          setUser((prev) => {
            return (
              prev && {
                ...prev,
                ...dbUser,
              }
            );
          });
        },
        updateUiSettings: (partialNewUiSettings: Partial<UserUiSettings>) => {
          const newUiSettings = {
            ...user!.uiSettings,
            ...partialNewUiSettings,
          };
          setUser((prev) => prev && { ...prev, uiSettings: newUiSettings });
          updateMyUiSettings({
            variables: {
              id: user!.userId,
              ui_settings: { wd: newUiSettings },
            },
          });
        },
        updateUiSettingsTemplates: (
          newSettingsTemplates: UserUiSettingsTemplates
        ) => {
          setUser(
            (prev) =>
              prev && { ...prev, uiSettingsTemplates: newSettingsTemplates }
          );
          updateMyUiSettingsTemplates({
            variables: {
              id: user!.userId,
              ui_settings_templates: { wd: newSettingsTemplates },
            },
          });
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
