import * as React from "react";
import { Popover, Typography, IconButton } from "@periplus/ui-library";
import {  ConfirmationNumberOutlined } from "@mui/icons-material";

const AdditionalRef = ({ children, ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        {...rest}
      >
        <ConfirmationNumberOutlined />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 1, maxWidth: "200px" }}>{children}</Typography>
      </Popover>
    </div>
  );
};

export default AdditionalRef;
