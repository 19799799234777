import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Box, Grid, Typography, colors } from "@periplus/ui-library";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

const ErrorScreen = memo(() => {
  const { t } = useTranslation();
  return (
    <Box
      position="absolute"
      left="50%"
      top="50%"
      style={{ transform: "translate(-50%, -50%)" }}
    >
      <Grid container direction="column">
        <Box width="100%" textAlign="center">
          <ErrorOutline color="error" style={{ fontSize: "6rem" }} />
        </Box>
        <Typography variant="h3">{t("error:crash")}</Typography>
        <Grid container direction="row" alignItems="baseline">
          <Typography>{t("error:try")}</Typography>
          &nbsp;
          <Button
            onClick={() => window.location.reload()}
            style={{
              textTransform: "lowercase",
              padding: 0,
              color: colors.blue[400],
            }}
          >
            <Typography>{t("common:refreshing")}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
});

export default ErrorScreen;
