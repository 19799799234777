import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Group_Members } from "graphql/generated";

const WD_GET_ADDRESS_GROUP_MEMBERS = gql`
  query WD_GET_ADDRESS_GROUP_MEMBERS {
    pp_group_members {
      payer {
        id
        company_name
        address_type
        tenant {
          id
          address {
            addressType {
              name
            }
          }
        }
      }
      clearance_types
      declaration_types
      permissions_js
    }
  }
`;

const useGetAddressGroupMembers = () => {
  const { data, ...rest } = useQuery(WD_GET_ADDRESS_GROUP_MEMBERS);

  return {
    addressGroupMembers: (data?.pp_group_members || []) as Group_Members[],
    ...rest,
  };
};

export default useGetAddressGroupMembers;
