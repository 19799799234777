import React, { FC, HtmlHTMLAttributes, useState } from "react";
import { IconButton, Menu } from "@periplus/ui-library";
import { MoreVert } from "@mui/icons-material";
import { TransformedDocument } from "../DocumentsTab";
import ClassificationButton from "./ClassificationButton";
import DownloadButton from "./DownloadButton";
import PrintButton from "./PrintButton";
import ShareButton from "./ShareButton";
import DeleteButton from "./DeleteButton";
import { DeclarationStatus } from "graphql/generated";

interface ActionsProps extends HtmlHTMLAttributes<HTMLElement> {
  document: TransformedDocument;
  file_id: string;
  declarationStatus: DeclarationStatus;
  onDeleted: () => Promise<any>;
  onClassificated: () => Promise<any>;
}

const Actions: FC<ActionsProps> = ({
  document,
  file_id,
  declarationStatus,
  onDeleted,
  onClassificated,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        size="small"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
        sx={{
          "& .MuiMenuItem-root": {
            "&:not(:last-child)": {
              borderBottom: "1px solid #D9D5E9",
            },
          },
        }}
      >
        <ClassificationButton
          document={document}
          declarationStatus={declarationStatus}
          onCancel={handleCloseMenu}
          onClassificated={async () => {
            await onClassificated();
            handleCloseMenu();
          }}
        />
        <DownloadButton document={document} onClick={handleCloseMenu} />
        <ShareButton document={document} onClick={handleCloseMenu} />
        {(!document.customRelevant ||
          document.document_extension === "pdf") && (
          <PrintButton document={document} onClick={handleCloseMenu} />
        )}
        <DeleteButton
          document={document}
          onCancel={handleCloseMenu}
          onDeleted={async () => {
            await onDeleted();
            handleCloseMenu();
          }}
          file_id={file_id}
        />
      </Menu>
    </>
  );
};

export default Actions;
