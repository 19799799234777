import React, { useMemo, useState, useEffect } from "react";
import {
  Box,
  BoxProps,
  MRTable,
  Paper,
  Typography,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import ReactEcharts from "echarts-for-react";
import { TableLocalStorage } from "hooks/usePageLocalStorage";
import usePageSettings from "domain/user/usePageSettings";
import { KPICustomAgencyEntity, KPIEntity } from "../hooks/useGetKPI";

interface Props extends BoxProps {
  kpis: KPIEntity;
}

export default ({ kpis }: Props) => {
  const { t } = useTranslation();

  const { pageSettings, setPageSettings } = usePageSettings<
    TableLocalStorage<{}, true>
  >({ itemsPerPage: undefined });

  const chartOption = {
    tooltip: {
      formatter: "{b} : {c} ({d}%)",
    },
    series: [
      {
        type: "pie",
        radius: [20, 140],
        roseType: "area",
        itemStyle: {
          borderRadius: 5,
        },
        data: kpis.customsAgencies.map((el) => ({
          name: el.label,
          value: el.count,
        })),
      },
    ],
  };

  const [columns, tableData] = useMemo<
    [MRTable.MRT_ColumnDef<KPICustomAgencyEntity>[], KPICustomAgencyEntity[]]
  >(
    () => [
      [
        {
          header: t("Customs Agency"),
          id: "customsAgency",
          accessorFn: (originalRow) => originalRow.label,
        },
        {
          header: t("Declarations"),
          id: "declarations",
          accessorFn: (originalRow) => originalRow.count,
        },
        {
          header: t("Percentage"),
          id: "percentage",
          accessorFn: (originalRow) => originalRow.percentage,
        },
      ],
      [...kpis.customsAgencies],
    ],
    [t, kpis.customsAgencies]
  );

  const [columnSizing, setColumnSizing] =
    useState<MRTable.MRT_ColumnSizingState>(pageSettings.columnSizing ?? {});
  const [columnOrder, setColumnOrder] = useState<MRTable.MRT_ColumnOrderState>(
    pageSettings.columnOrder ?? columns.map((c) => c.id!)
  );

  useEffect(
    () => {
      setPageSettings({
        columnSizing,
        columnOrder,
      });
    },
    // eslint-disable-next-line
    [columnSizing, columnOrder]
  );

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t("Customs Agencies")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
        }}
      >
        <Box sx={{ flexGrow: 1, flexBasis: "50%" }}>
          <Paper variant="outlined">
            <ReactEcharts
              option={chartOption}
              style={{
                height: "350px",
              }}
            />
          </Paper>
        </Box>
        <Box sx={{ flexGrow: 1, flexBasis: "50%" }}>
          <MRTable.Table
            columns={columns}
            data={tableData}
            state={{
              columnSizing,
              columnOrder,
            }}
            enablePagination={false}
            enableTopToolbar={false}
            onColumnSizingChange={setColumnSizing}
            onColumnOrderChange={setColumnOrder}
            muiTableContainerProps={{
              sx: {
                maxHeight: "350px",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
