import {
  Dialog,
  Grid,
  Theme,
  Typography,
  Box,
  Autocomplete,
} from "@periplus/ui-library";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useLinkAddresses } from "domain/address";
import AddressInfo from "./AddressInfo";
import AddressFormAutocomplete from "domain/address/components/AddressFormAutocomplete";
import { AddressListEntity } from "domain/address/useGetAddresses";
import { AddressManagementEntity } from "../../hooks/useGetAddressesManagement";
import { AddressType } from "domain/declaration/types";
import { getEnumKeys } from "utils/enums";
import { useState } from "react";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: 40,
  },
  linkContainer: {
    padding: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  inputContainer: {
    marginTop: theme.spacing(0.5),
    flex: 1,
  },
  infoContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey2.main,
    gap: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));

type LinkAddressDialogProps = {
  address: AddressManagementEntity;
  tenantId: string | undefined;
  onClose: () => void;
  onLinkSuccess: () => void;
};

const LinkAddressDialog = ({
  address,
  onClose,
  tenantId,
  onLinkSuccess,
}: LinkAddressDialogProps) => {
  const { classes } = useStyles();
  const { linkAddresses } = useLinkAddresses();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("addresses");
  const [addressTypes, setAddressTypes] = useState([AddressType.supplier]);

  const validationSchema = Yup.object({
    destinationAddress: Yup.object()
      .nullable()
      .required(t("validation:isRequired"))
      .test({
        message: t("Address cannot be linked to itself"),
        test: (value) => {
          return value?.id !== address.id;
        },
      }),
  });

  return (
    <Formik<{
      destinationAddress: AddressListEntity | null;
    }>
      initialValues={{
        destinationAddress: null,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          await linkAddresses({
            linkAddressId: address.id,
            linkedToAddressId: values.destinationAddress?.id,
            tenantId: tenantId,
            sourceLink: address.source,
            sourceLinkedTo: values.destinationAddress?.source,
            linkAoId: address.aoId,
            linkedToAoId: values.destinationAddress?.aoId,
          });
          enqueueSnackbar(t("Address successfully linked"), {
            variant: "success",
          });
          onClose();
          onLinkSuccess();
        } catch (error) {
          enqueueSnackbar(t("Address linking unsuccessful"), {
            variant: "error",
          });
        }
      }}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => {
        return (
          <Dialog
            onClose={onClose}
            fullWidth
            onConfirm={handleSubmit}
            maxWidth="sm"
            mainTitle={t("Link Address")}
            subTitle={t("Choose the address you want to link")}
            TitleIcon={LinkOutlinedIcon}
            CancelButtonProps={{ label: t("common:abort") }}
            ConfirmButtonProps={{
              label: t("Link"),
              disabled: isSubmitting || !isValid,
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid
                container
                direction="column"
                className={classes.dialogContent}
              >
                <Autocomplete<{ id: number; label: string }, true>
                  value={
                    addressTypes?.map((atId) => ({
                      id: atId,
                      label: t(`addressTypes:${AddressType[atId]}`),
                    })) ?? []
                  }
                  options={getEnumKeys(AddressType).map((atName) => ({
                    id: AddressType[atName],
                    label: t(`addressTypes:${atName}`),
                  }))}
                  onChange={(e, newAddressTypes) => {
                    setAddressTypes(newAddressTypes.map((item) => item.id));
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  multiple
                  InputProps={{
                    label: t("Type"),
                  }}
                />
                <Grid item className={classes.inputContainer}>
                  <AddressFormAutocomplete
                    name="destinationAddress"
                    addressTypes={addressTypes}
                    InputProps={{
                      label: t("Destination Address"),
                    }}
                    excludeAddresses={[address.id]}
                  />
                  <AddressInfo address={values.destinationAddress} />
                </Grid>
                <Box className={classes.infoContainer}>
                  <Box>
                    <InfoOutlinedIcon color="primary" />
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      {t(
                        "Note that when linking addresses, the source address will appear as the destination address. The source address information will no longer be visible."
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default LinkAddressDialog;
