import React, { FC } from "react";
import { FormTextField, TextFieldProps } from "@periplus/ui-library";
import { DeclarationRequestFormPaths } from "../useDeclarationFormik";

interface DeclarationFormTextFieldProps extends TextFieldProps {
  name: DeclarationRequestFormPaths;
}

const DeclarationFormTextField: FC<DeclarationFormTextFieldProps> = (props) => {
  return <FormTextField {...props} />;
};

export default DeclarationFormTextField;
