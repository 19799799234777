import gql from "graphql-tag";
import client from "graphql/client";

const DELETE_DOCUMENT = gql`
  mutation DELETE_DOCUMENT($id: uuid!) {
    update_document(where: { id: { _eq: $id } }, _set: { is_deleted: true }) {
      affected_rows
    }
  }
`;

const DELETE_DOCUMENT_AND_UPDATE_DEC_STATUS = gql`
  mutation DELETE_DOCUMENT_AND_UPDATE_DEC_STATUS($id: uuid!, $fileId: uuid!) {
    update_document(where: { id: { _eq: $id } }, _set: { is_deleted: true }) {
      affected_rows
    }
    update_declaration_request(
      _set: { declaration_status: "information_missing" }
      where: { file_id: { _eq: $fileId } }
    ) {
      affected_rows
    }
  }
`;

export default () => {
  return async (id: string, declarationId: string) => {
    const {
      data: {
        declaration_request: [
          {
            file: { documents: declarationDocuments },
          },
        ],
      },
    } = await client.query({
      query: gql`
        query GET_DECLARATION_REQUEST_DOCUMENTS($declarationId: uuid!) {
          declaration_request(where: { file_id: { _eq: $declarationId } }) {
            file {
              documents {
                id
                document_type
                is_generated
                is_deleted
              }
            }
          }
        }
      `,
      fetchPolicy: "no-cache",
      variables: {
        declarationId,
      },
    });
    const isInformationMissing = !declarationDocuments.some(
      (declarationDocument) =>
        !declarationDocument.is_deleted && declarationDocument.id !== id
    );
    return client.mutate(
      isInformationMissing
        ? {
            mutation: DELETE_DOCUMENT_AND_UPDATE_DEC_STATUS,
            variables: {
              id,
              fileId: declarationId,
            },
          }
        : {
            mutation: DELETE_DOCUMENT,
            variables: {
              id,
            },
          }
    );
  };
};
