import React, { FC, HtmlHTMLAttributes } from "react";
import { Theme } from "@periplus/ui-library";
import { Dialog } from "@periplus/ui-library";
import { ReactComponent as SuccessDocs } from "assets/SuccessDocs.svg";
import { Typography } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { makeStyles } from 'tss-react/mui';

interface SuccessDialogProps extends HtmlHTMLAttributes<HTMLElement> {
  onClose: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  successWrapper: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    padding: 32,
  },
  successImageHolder: {
    display: "flex",
    backgroundColor: "#604DFF",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "200px",
    width: "162px",
    height: "162px",
  },
}));

const SuccessDialog: FC<SuccessDialogProps> = ({ onClose }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={true} onClose={onClose}>
      <div className={classes.successWrapper}>
        <div className={classes.successImageHolder}>
          <SuccessDocs />
        </div>
        <Typography variant="h4" color="textSecondary">
          {t("newDeclaration:successTitle")}
        </Typography>
        <Typography variant="h6">
          {t("newDeclaration:successSubTitle")}
        </Typography>
      </div>
    </Dialog>
  );
};

export default SuccessDialog;
