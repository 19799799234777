import React, { FC, useState, useEffect } from "react";
import {
  Box,
  Typography,
  MRTable,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import useGetGoodsItems, {
  DeclarationDetailsGoodsItemsEntity,
} from "pages/Declaration/hooks/useGetDeclarationDetailsGoodsItems";
import { convertPrimitive } from "utils/common";
import { DeclarationDetailsEntity } from "pages/Declaration/hooks/useGetDeclarationDetails";
import usePageSettings from "domain/user/usePageSettings";
import { TableLocalStorage } from "hooks/usePageLocalStorage";
import {
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ColumnSizingState,
  MRT_DensityState,
  MRT_PaginationState,
  MRT_VisibilityState,
} from "@periplus/ui-library/lib/components/MRTable";
import { useAppState } from "App/AppContext";

interface GoodsItemsPanelProps {
  declaration: DeclarationDetailsEntity;
}

const GoodsItemsPanel: FC<GoodsItemsPanelProps> = ({ declaration }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("declaration");
  const { edecData } = useAppState();
  const {
    data: { goodsItems },
    loading: goodsItemsLoading,
  } = useGetGoodsItems({
    file_id: declaration.fileId,
    dr_no: declaration.drNo!,
  });

  const [columns, tableData] = React.useMemo<
    [
      MRTable.MRT_ColumnDef<DeclarationDetailsGoodsItemsEntity>[],
      DeclarationDetailsGoodsItemsEntity[]
    ]
  >(
    () => [
      [
        {
          header: t("Position Nr."),
          accessorKey: "customs_item_number",
        },
        {
          header: t("Tariff Nr."),
          accessorKey: "commodity_code",
        },
        {
          header: t("Statistical code"),
          accessorKey: "statistical_code",
        },
        {
          header: t("Description"),
          accessorKey: "description",
          Cell: ({
            row: {
              original: { description },
            },
          }) => <Typography sx={{ minWidth: 300 }}>{description}</Typography>,
        },
        {
          header: t("Commercial good"),
          id: "commercial_good",
          accessorFn: ({ commercial_good }) =>
            edecData.edec_domains.find(
              (el) =>
                el.domain_name === "commercialGood" &&
                el.value === { true: "1", false: "2" }[String(commercial_good)]
            )?.[`meaning_${language}`],
        },
        {
          header: t("Preference"),
          id: "origin_preference",
          accessorFn: ({ origin_preference }) =>
            edecData.edec_domains.find(
              (el) =>
                el.domain_name === "yesNoCode" &&
                el.value ===
                  convertPrimitive(origin_preference, "number")?.toString()
            )?.[`meaning_${language}`],
        },
        {
          header: t("Country of origin"),
          accessorKey: "origin_country",
        },
        {
          header: t("Clearance type"),
          id: "clearance_type",
          accessorFn: ({ clearance_type }) =>
            edecData.edec_domains.find(
              (el) =>
                el.domain_name === "customsClearanceType" &&
                el.value === clearance_type?.toString()
            )?.[`meaning_${language}`],
        },
        {
          header: t("Repair"),
          id: "repair",
          accessorFn: ({ repair }) =>
            edecData.edec_domains.find(
              (el) =>
                el.domain_name === "yesNoCode" &&
                el.value === convertPrimitive(repair, "number")?.toString()
            )?.[`meaning_${language}`],
        },
        {
          header: t("Repair type"),
          accessorKey: "repair_reason",
        },
        {
          header: t("Net weight"),
          accessorKey: "net_mass",
        },
        {
          header: t("Gross weight"),
          accessorKey: "gross_mass",
        },
        {
          header: t("Statistical value"),
          accessorKey: "statistical_value",
        },
        {
          header: t("VAT-Value"),
          accessorKey: "vat_value",
        },
        {
          header: t("VAT %"),
          accessorKey: "vat_rate",
        },
        {
          header: t("VAT"),
          accessorKey: "vat_total",
        },
        {
          header: t("Duty rate"),
          accessorKey: "duty_rate",
        },
        {
          header: t("Duty"),
          accessorKey: "duty_total",
        },
        {
          header: t("Duties (incl. additional taxes)"),
          accessorKey: "duty_and_other_taxes_total",
        },
        {
          header: t("Additional unit"),
          accessorKey: "additional_unit",
        },
        {
          header: t("Packages (type, quantity, number)"),
          id: "packagings",
          accessorFn: ({ packagings }) =>
            packagings
              .map(
                (
                  {
                    packaging_type,
                    packaging_quantity,
                    packaging_reference_number,
                  },
                  i
                ) =>
                  packaging_type &&
                  `${
                    packaging_type
                      ? edecData.edec_domains.find(
                          (el) =>
                            el.domain_name === "packagingType" &&
                            el.value === packaging_type?.toString()
                        )?.[`meaning_${language}`]
                      : "---"
                  }, ${packaging_quantity ? packaging_quantity : "---"}, ${
                    packaging_reference_number
                      ? packaging_reference_number
                      : "---"
                  }`
              )
              .join("\n"),
          muiTableBodyCellProps: {
            sx: {
              whiteSpace: "pre",
              fontSize: "14px",
            },
          },
        },
        {
          header: t("Produced documents (type, number, date)"),
          Cell: ({ row: { original: originalRow } }) =>
            originalRow.produced_documents?.map(
              (
                {
                  produced_document_type,
                  produced_document_reference_number,
                  produced_document_issue_date,
                },
                i
              ) => (
                <Box key={i}>
                  {[
                    edecData.edec_domains.find(
                      (el) =>
                        el.domain_name === "ProducedDocuments" &&
                        el.value === produced_document_type
                    )?.[`meaning_${language}`],
                    produced_document_reference_number,
                    produced_document_issue_date,
                  ]
                    .filter((el) => el)
                    .join(", ")}
                </Box>
              )
            ),
          id: "produced_documents",
        },
        {
          header: t("Authorization code"),
          id: "permit_obligation_code",
          accessorFn: ({ permit_obligation_code }) =>
            edecData.edec_domains.find(
              (el) =>
                el.domain_name === "permitObligation" &&
                el.value === permit_obligation_code?.toString()
            )?.[`meaning_${language}`],
        },
        {
          header: t("Authorization (type, authority, number)"),
          id: "permits",
          accessorFn: ({ permits }) =>
            permits
              .map(({ permit_type, permit_authority, permit_number }, i) =>
                [
                  edecData.edec_domains.find(
                    (el) =>
                      el.domain_name === "permitType" &&
                      el.value === permit_type?.toString()
                  )?.[`meaning_${language}`],
                  edecData.edec_domains.find(
                    (el) =>
                      el.domain_name === "permitAuthority" &&
                      el.value === permit_authority?.toString()
                  )?.[`meaning_${language}`],
                  permit_number,
                ]
                  .filter((el) => el)
                  .join(", ")
              )
              .join("\n"),
          muiTableBodyCellProps: {
            sx: {
              whiteSpace: "pre",
              fontSize: "14px",
            },
          },
        },
        {
          header: t("Selection result"),
          id: "selection_result",
          accessorFn: ({ selection_result }) =>
            edecData.edec_domains.find(
              (el) =>
                el.domain_name === "selectionResult" &&
                el.value === selection_result?.toString()
            )?.[`meaning_${language}`],
        },
        {
          header: t("Special mention"),
          id: "special_mention",
          accessorFn: ({ goods_item_special_mentions }) =>
            goods_item_special_mentions.map((el, i) => el.text).join("\n"),
          muiTableBodyCellProps: {
            sx: {
              whiteSpace: "pre",
              fontSize: "14px",
            },
          },
        },
      ],
      [...goodsItems],
    ],
    [t, language, edecData.edec_domains, goodsItems]
  );

  const { pageSettings, setPageSettingsForMRTable } = usePageSettings<{
    goodsItemsTable: TableLocalStorage;
  }>({
    goodsItemsTable: {
      itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
      density: "compact",
      columnSizing: {},
      columnPinning: {},
      columnVisibility: columns.reduce((acc, column) => {
        const columnId: any = column.accessorKey ?? column.id;
        return {
          ...acc,
          [columnId]: [
            "commodity_code",
            "description",
            "packagings",
            "additional_unit",
            "net_mass",
            "gross_mass",
            "vat_value",
            "vat_total",
            "duty_total",
          ].some((initialVisibleColumn) => initialVisibleColumn === columnId),
        };
      }, {} as MRT_VisibilityState),
      columnOrder: columns.map((c) => (c.accessorKey ?? c.id) as string),
    },
  });

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: pageSettings.goodsItemsTable.itemsPerPage,
  });
  const [density, setDensity] = useState<MRT_DensityState>(
    pageSettings.goodsItemsTable.density!
  );
  const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>(
    pageSettings.goodsItemsTable.columnSizing!
  );
  const [columnPinning, setColumnPinning] = useState<MRT_ColumnPinningState>(
    pageSettings.goodsItemsTable.columnPinning!
  );
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    pageSettings.goodsItemsTable.columnVisibility!
  );
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(
    pageSettings.goodsItemsTable.columnOrder!
  );

  useEffect(
    () => {
      setPageSettingsForMRTable({
        goodsItemsTable: {
          itemsPerPage: pagination.pageSize,
          density,
          columnSizing,
          columnPinning,
          columnVisibility,
          columnOrder,
        },
      });
    },
    // eslint-disable-next-line
    [
      pagination.pageSize,
      density,
      columnSizing,
      columnPinning,
      columnVisibility,
      columnOrder,
    ]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <MRTable.Table<DeclarationDetailsGoodsItemsEntity>
        columns={columns}
        data={tableData}
        initialState={{
          sorting: [{ id: "customs_item_number", desc: false }],
        }}
        state={{
          pagination,
          density,
          columnSizing,
          columnPinning,
          columnVisibility,
          columnOrder,
          isLoading: goodsItemsLoading,
        }}
        onPaginationChange={setPagination}
        onDensityChange={setDensity}
        onColumnSizingChange={setColumnSizing}
        onColumnPinningChange={setColumnPinning}
        onColumnVisibilityChange={setColumnVisibility}
        onColumnOrderChange={setColumnOrder}
        renderTopToolbarCustomActions={() => (
          <Typography variant="h6" sx={{ alignSelf: "center" }}>
            {t("Goods Items")}
          </Typography>
        )}
        muiTableHeadCellProps={{
          sx: {
            "& .Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
            },
          },
        }}
      />
    </Box>
  );
};

export default GoodsItemsPanel;
