import React, { FC } from "react";
import { Box, BoxProps, Typography } from "@periplus/ui-library";

export interface GroupProps extends BoxProps {
  title: string;
}

const Group: FC<GroupProps> = ({ title, children }) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        gap: 1,
        "&:not(:first-of-type) .groupTitle": {
          pt: 1,
        },
        "&:not(:first-of-type) .itemsContainer": {
          borderTop: 1,
          borderColor: theme.palette.grey3.main,
          pt: 1,
        },
      })}
    >
      <Typography
        className="groupTitle"
        variant="subtitle1"
        color="textSecondary"
        sx={{
          maxWidth: 150,
          minWidth: 150,
        }}
      >
        {title}
      </Typography>
      <Box
        className="itemsContainer"
        sx={(theme) => ({
          display: "flex",
          gap: 4,
          flexGrow: 1,
          flexWrap: "wrap",
        })}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Group;
