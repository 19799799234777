import React, { useState } from "react";
import { IconButton, MRTable, Menu } from "@periplus/ui-library";
import { MoreVert } from "@mui/icons-material";
import Download from "./Download";
import { Bordereau } from "../hooks/useGetBordereaus";

interface ActionsProps {
  cell: MRTable.MRT_Cell<Bordereau>;
  row: MRTable.MRT_Row<Bordereau>;
  table: MRTable.MRT_TableInstance<Bordereau>;
}

export default ({ row }: ActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        size="small"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
        sx={{
          "& .MuiMenuItem-root": {
            "&:not(:last-child)": {
              borderBottom: "1px solid #D9D5E9",
            },
          },
        }}
      >
        <Download
          extension="pdf"
          borderau={row.original}
          onClick={handleCloseMenu}
        />
        <Download
          extension="xml"
          borderau={row.original}
          onClick={handleCloseMenu}
        />
      </Menu>
    </>
  );
};
