import { useMutation } from "@apollo/client";
import { useDebounceCallback } from "@react-hook/debounce";
import gql from "graphql-tag";

export default () => {
  const [updateMyUiSettings] = useMutation(gql`
    mutation WdUpdateMyUiSettings($id: uuid!, $ui_settings: jsonb!) {
      update_user_pp_by_pk(
        pk_columns: { id: $id }
        _append: { ui_settings: $ui_settings }
      ) {
        id
      }
    }
  `);

  const debouncedMuttation = useDebounceCallback(updateMyUiSettings, 300);

  return debouncedMuttation;
};
