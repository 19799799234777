import React, { PropsWithChildren } from "react"; //useState
import { PageContainer } from "@periplus/ui-library"; //Button,
import { useTranslation } from "react-i18next";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import AddressDialog from "./AddressDialog";
// import { useAuth } from "keycloak";
import { useHistory, useRouteMatch } from "react-router-dom";

interface Props extends PropsWithChildren<{}> {
  //onAddressCreated: () => void;
}

const TABS = [
  {
    id: "general",
    label: "General",
  },
  {
    id: "linked",
    label: "Linked",
  },
] as const;

export default ({ children }: Props) => {
  //, onAddressCreated
  const { t } = useTranslation();
  //const { user } = useAuth();
  const history = useHistory();
  const { url } = useRouteMatch();
  //const [createAddressDialogOpen, setCreateAddressDialogOpen] = useState(false);

  return (
    <PageContainer
      title={t("navigation:address")}
      tabs={TABS.map(({ label }) => t(label))}
      selectedTab={TABS.findIndex(({ id }) => id === url.split("/").pop()!)}
      onTabChange={(newTabIndex) =>
        history.push(
          `${url.split("/").slice(0, -1).join("/")}/${TABS[newTabIndex].id}`
        )
      }
      // controls={
      //   <Button
      //     variant="contained"
      //     color="primary"
      //     endIcon={<ArrowRightAltIcon />}
      //     onClick={() => setCreateAddressDialogOpen(true)}
      //   >
      //     {t("common:createNew")}
      //   </Button>
      // }
      sx={{
        "& .LuiPageContainer-content": {
          display: "flex",
          flexDirection: "row",
          gap: 0,
          p: 0,
        },
      }}
    >
      {children}
      {/* {createAddressDialogOpen && (
        <AddressDialog
          tenantId={user?.tenant_id}
          onClose={() => setCreateAddressDialogOpen(false)}
          onConfirm={() => {
            setCreateAddressDialogOpen(false);
            onAddressCreated();
          }}
        />
      )} */}
    </PageContainer>
  );
};
