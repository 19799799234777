import { useMemo, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { MRTable } from "@periplus/ui-library";

export interface TableUrlSearchParams<T = any> {
  page: number;
  itemsPerPage: number;
  search?: string;
  search_field?: string;
  sorting?: MRTable.MRT_SortingState;
  filters?: T;
}

const parse = (query: string) => {
  const queryStringParse = queryString.parse(query, {
    arrayFormat: "bracket-separator",
  });
  const jsonParse = Object.entries(queryStringParse).reduce(
    (acc, [key, val]) => {
      acc[key] = JSON.parse(val as string);
      return acc;
    },
    {} as any
  );
  return jsonParse;
};

const stringify = (object: Record<string, any>) => {
  const jsonStringify = Object.entries(object).reduce((acc, [key, val]) => {
    acc[key] = JSON.stringify(val);
    return acc;
  }, {} as any);
  const queryStringStringify = queryString.stringify(jsonStringify, {
    arrayFormat: "bracket-separator",
  });
  return queryStringStringify;
};

//If usePageLocalStorage and useUrlSearchParams called together, then usePageLocalStorage should be called firstly
function useUrlSearchParams<T = any>(initialParams?: T) {
  const history = useHistory();
  const { search } = useLocation();

  useEffect(
    () =>
      initialParams &&
      history.replace({
        search: stringify({ ...initialParams, ...parse(search) }),
      }),
    // eslint-disable-next-line
    []
  );

  const urlSearchParams: T = useMemo<any>(
    () => {
      const parsedParams = parse(search);
      return Object.keys(parsedParams).length ? parsedParams : initialParams;
    },
    // eslint-disable-next-line
    [search]
  );

  const setUrlSearchParams = useCallback(
    (newParams: Partial<T>) => {
      history.push({
        search: stringify({ ...urlSearchParams, ...newParams }),
      });
    },
    [urlSearchParams, history]
  );

  return { urlSearchParams, setUrlSearchParams };
}

export default useUrlSearchParams;
