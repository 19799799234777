import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  FiltersSidebar as LuiFiltersSidebar,
  Autocomplete,
  DatePicker,
  Typography,
  Box,
} from "@periplus/ui-library";
import dayjs from "dayjs";
import StatusFilter from "./StatusFilter";
import { useTranslation } from "react-i18next";
import { AddressType } from "domain/declaration/types";
import { useAuth } from "keycloak";
import DeclarationLabelChip from "../DeclarationLabel/DeclarationLabelChip";
import { Declaration_Labels } from "graphql/generated";
import { FALL_BACK_LANGUAGE } from "i18n";
import { Permissions } from "keycloak/context/AuthContext";
import AddressAutocomplete from "domain/address/components/AddressAutocomplete";
import {
  useGetAddressesLazy,
  AddressListEntity,
  getAddressesQueryVariables,
} from "domain/address/useGetAddresses";
import useGetTenants from "domain/tenant/useGetTenants";
import { useAppState } from "App/AppContext";

interface AddressesFilters {
  consignor?: number[];
  consignee?: number[];
  importer?: number[];
  freight_forwarder?: number[];
  supplier?: number[];
}

type RelativeDate =
  | "today"
  | "last2weeks"
  | "last4weeks"
  | "last1month"
  | "last3month"
  | "last6month";

export const DECLARATION_FILTER_RELATIVE_DATES = {
  today: {
    label: "Today",
    min_date: dayjs().startOf("day"),
    max_date: dayjs().endOf("day"),
  },
  last2weeks: {
    label: "Last 2 weeks",
    min_date: dayjs().subtract(2, "weeks").startOf("day"),
    max_date: dayjs().endOf("day"),
  },
  last4weeks: {
    label: "Last 4 weeks",
    min_date: dayjs().subtract(4, "weeks").startOf("day"),
    max_date: dayjs().endOf("day"),
  },
  last1month: {
    label: "Previous Month",
    min_date: dayjs().subtract(1, "month").startOf("month").startOf("day"),
    max_date: dayjs().subtract(1, "month").endOf("month").endOf("day"),
  },
  last3month: {
    label: "Last 3 Month",
    min_date: dayjs().subtract(3, "month").startOf("month").startOf("day"),
    max_date: dayjs().subtract(1, "month").endOf("month").endOf("day"),
  },
  last6month: {
    label: "Last 6 Month",
    min_date: dayjs().subtract(6, "month").startOf("month").startOf("day"),
    max_date: dayjs().subtract(1, "month").endOf("month").endOf("day"),
  },
};

export interface DeclarationFilters extends AddressesFilters {
  declaration_statuses?: string[];
  relative_date?: RelativeDate;
  min_date?: string;
  max_date?: string;
  declaration_type?: string;
  clearance_type?: string;
  arrival_type?: string;
  payer?: string;
  evv_status?: string;
  declaration_labels?: number[];
}

interface DeclarationFiltersSidebarProps {
  filters?: DeclarationFilters;
  onChange: (newFilters?: DeclarationFilters) => void;
  defaultFilters: DeclarationFilters;
}

const DeclarationFiltersSidebar: FC<DeclarationFiltersSidebarProps> = ({
  defaultFilters,
  filters,
  onChange,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("declaration");
  const { edecData } = useAppState();
  const { user } = useAuth();
  const showAddressesFilters = user?.hasAllowedPermissions([
    Permissions.ADDRESS_DETAILS,
  ]);
  const isSupplierAvailable = user?.hasAllowedPermissions([
    Permissions.SUPPLIER_ADDRESS,
  ]);

  const addressTypesFilters = useMemo(
    () =>
      [
        "consignor",
        "consignee",
        "importer",
        "freight_forwarder",
        ...(isSupplierAvailable ? ["supplier"] : []),
      ] as (keyof AddressesFilters)[],
    [isSupplierAvailable]
  );

  const mergedFilters = useMemo(
    () => ({ ...defaultFilters, ...filters }),
    [defaultFilters, filters]
  );

  const [addressTypesFiltersAddresses, setAddressTypesFiltersAddresses] =
    useState(
      addressTypesFilters.reduce((acc, at) => {
        acc[at] = [];
        return acc;
      }, {} as Record<keyof AddressesFilters, AddressListEntity[]>)
    );

  const [getInitialAddresses, { loading: initialAddressesLoading }] =
    useGetAddressesLazy();
  useEffect(() => {
    const addressIds = addressTypesFilters.reduce((acc, addressType) => {
      acc.push(...(mergedFilters[addressType] ?? []));
      return acc;
    }, [] as number[]);
    if (!addressIds.length) {
      return;
    }

    getInitialAddresses({
      variables: getAddressesQueryVariables({
        addressIds,
      }),
    }).then(({ data }) => {
      setAddressTypesFiltersAddresses(
        addressTypesFilters.reduce((acc, addressType) => {
          acc[addressType] =
            data?.addresses.filter((address) =>
              mergedFilters[addressType]?.some(
                (filterAddressTypeAddress) =>
                  filterAddressTypeAddress === address.id
              )
            ) ?? [];
          return acc;
        }, {} as Record<keyof AddressesFilters, AddressListEntity[]>)
      );
    });
    // eslint-disable-next-line
  }, []);

  const {
    data: { tenants },
    loading: tenantsLoading,
  } = useGetTenants();

  const values = useMemo(
    () => ({
      ...addressTypesFiltersAddresses,
      declaration_statuses: mergedFilters?.declaration_statuses || [],
      relative_date: mergedFilters?.relative_date || null,
      ...(mergedFilters?.relative_date
        ? {
            min_date:
              DECLARATION_FILTER_RELATIVE_DATES[mergedFilters.relative_date]
                .min_date,
            max_date:
              DECLARATION_FILTER_RELATIVE_DATES[mergedFilters.relative_date]
                .max_date,
          }
        : {
            min_date: mergedFilters?.min_date
              ? dayjs(mergedFilters?.min_date)
              : null,
            max_date: mergedFilters?.max_date
              ? dayjs(mergedFilters?.max_date).endOf("day")
              : null,
          }),
      declaration_type: mergedFilters?.declaration_type || null,
      clearance_type: mergedFilters?.clearance_type || null,
      arrival_type: mergedFilters?.arrival_type || null,
      payer:
        (mergedFilters.payer &&
          tenants.find((tenant) => tenant.id === mergedFilters.payer)) ||
        null,
      evv_status: mergedFilters?.evv_status || null,
      declaration_labels:
        user?.tenant?.declaration_labels.filter((tenantDeclarationLabel) =>
          mergedFilters.declaration_labels?.includes(tenantDeclarationLabel.id)
        ) || [],
    }),
    [mergedFilters, tenants, user, addressTypesFiltersAddresses]
  );

  const handleChange = useCallback(
    (newValues: typeof values) => {
      const newFilters = {
        ...addressTypesFilters.reduce((acc, addressType) => {
          const addressTypeAddresses = newValues[addressType];
          if (addressTypeAddresses?.length) {
            acc[addressType] = addressTypeAddresses.map((a) => a.id);
          }
          return acc;
        }, {} as Record<keyof AddressesFilters, number[]>),
        declaration_statuses: newValues.declaration_statuses?.length
          ? newValues.declaration_statuses
          : undefined,
        relative_date: newValues.relative_date, //this filter should be null if it empty, because it has default value
        ...(!newValues.relative_date && {
          min_date: newValues.min_date?.startOf("day").format(),
          max_date: newValues.max_date?.endOf("day").format(),
        }),
        declaration_type: newValues.declaration_type || undefined,
        clearance_type: newValues.clearance_type || undefined,
        arrival_type: newValues.arrival_type || undefined,
        payer: newValues.payer?.id,
        evv_status: newValues.evv_status || undefined,
        ...(!!newValues.declaration_labels.length && {
          declaration_labels: newValues.declaration_labels.map(
            (declaration_label) => declaration_label.id
          ),
        }),
      };
      const filteredNewFilters = Object.keys(newFilters).reduce(
        (acc, newFilterKey) => {
          if (newFilters[newFilterKey] !== defaultFilters[newFilterKey]) {
            acc[newFilterKey] = newFilters[newFilterKey];
          }
          return acc;
        },
        {} as DeclarationFilters
      );
      onChange(
        Object.values(filteredNewFilters).some((filter) => filter !== undefined)
          ? filteredNewFilters
          : undefined
      );
    },
    [defaultFilters, onChange, addressTypesFilters]
  );

  return (
    <Box
      sx={(theme) => ({
        minWidth: 240,
        borderRight: `1px solid ${theme.palette.grey3.main}`,
      })}
    >
      <LuiFiltersSidebar
        sx={(theme) => ({
          maxHeight: "calc(100vh - var(--appbar-height))",
          overflow: "auto",
          borderRight: `1px solid ${theme.palette.grey3.main}`,
          position: "fixed",
          top: "var(--appbar-height)",
        })}
        groups={[
          {
            title: t("General"),
            active: Boolean(
              filters?.arrival_type ||
                filters?.clearance_type ||
                filters?.declaration_type
            ),
            content: (
              <>
                <Autocomplete
                  value={values.arrival_type}
                  onChange={(e, newValue) =>
                    handleChange({ ...values, arrival_type: newValue })
                  }
                  getOptionLabel={(option) => t(`arrival_type:${option}`)}
                  options={["border", "allowed_consignee", "unknown"]}
                  InputProps={{
                    label: t("Arrival Type"),
                  }}
                />
                <Autocomplete
                  value={values.declaration_type}
                  onChange={(e, newValue) =>
                    handleChange({ ...values, declaration_type: newValue })
                  }
                  getOptionLabel={(option) =>
                    edecData.edec_domains.find(
                      (el) =>
                        el.domain_name === "declarationType" &&
                        el.value === option
                    )?.[`meaning_${language}`]
                  }
                  options={edecData.edec_domains
                    .filter((el) => el.domain_name === "declarationType")
                    .map((el) => el.value)}
                  InputProps={{
                    label: t("Declaration Type"),
                  }}
                />
                {/* <Autocomplete
                  value={values.declaration_type}
                  onChange={(e, newValue) =>
                    handleChange({ ...values, declaration_type: newValue })
                  }
                  getOptionLabel={(option) => t(`declaration_type:${option}`)}
                  options={["import", "export"]}
                  InputProps={{
                    label: t("Declaration Type"),
                  }}
                /> */}
              </>
            ),
          },
          {
            title: t("Timeframe"),
            active: Boolean(
              filters?.relative_date !== undefined ||
                filters?.min_date ||
                filters?.max_date
            ),
            content: (
              <>
                {(() => {
                  return (
                    <Autocomplete<RelativeDate>
                      //@ts-ignore
                      value={values.relative_date}
                      options={[
                        "today",
                        "last2weeks",
                        "last4weeks",
                        "last1month",
                        "last3month",
                        "last6month",
                      ]}
                      onChange={(e, newValue) =>
                        handleChange({
                          ...values,
                          relative_date: newValue,
                          min_date: newValue
                            ? DECLARATION_FILTER_RELATIVE_DATES[newValue]
                                .min_date
                            : null,
                          max_date: newValue
                            ? DECLARATION_FILTER_RELATIVE_DATES[newValue]
                                .max_date
                            : null,
                        })
                      }
                      getOptionLabel={(option) =>
                        t(DECLARATION_FILTER_RELATIVE_DATES[option].label)
                      }
                      InputProps={{
                        label: t("Recent Days"),
                      }}
                    />
                  );
                })()}
                <Typography variant="overline" color="textSecondary">
                  {t("or date range")}
                </Typography>
                <DatePicker
                  value={values.min_date}
                  onChange={(newValue) =>
                    handleChange({
                      ...values,
                      relative_date: null,
                      min_date: newValue,
                    })
                  }
                  label={t("From")}
                  slotProps={{
                    field: {
                      clearable: true,
                    },
                  }}
                />
                <DatePicker
                  value={values.max_date}
                  onChange={(newValue) =>
                    handleChange({
                      ...values,
                      relative_date: null,
                      max_date: newValue,
                    })
                  }
                  label={t("To")}
                  slotProps={{
                    field: {
                      clearable: true,
                    },
                  }}
                />
              </>
            ),
          },
          ...(showAddressesFilters
            ? [
                {
                  title: t("Addresses"),
                  active: Boolean(
                    (user?.isMnrUser && filters?.payer) ||
                      (!user?.isMnrUser &&
                        addressTypesFilters.some(
                          (addressType) => filters?.[addressType]?.length
                        ))
                  ),
                  content: (
                    <>
                      {user?.isMnrUser && (
                        <Autocomplete
                          value={values.payer}
                          getOptionLabel={(option) =>
                            option.address.company_name
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          options={tenants}
                          onChange={(event, newPayer) => {
                            handleChange({
                              ...values,
                              payer: newPayer,
                            });
                          }}
                          loading={tenantsLoading}
                          InputProps={{
                            label: t("Client"),
                          }}
                        />
                      )}
                      {!user?.isMnrUser &&
                        addressTypesFilters.map((addressType) => (
                          <AddressAutocomplete
                            addressTypes={[AddressType[addressType]]}
                            key={addressType}
                            value={values[addressType]}
                            onChange={(e, newValue) => {
                              setAddressTypesFiltersAddresses((prev) => ({
                                ...prev,
                                [addressType]: newValue,
                              }));
                              handleChange({
                                ...values,
                                [addressType]: newValue,
                              });
                            }}
                            InputProps={{
                              label: t(`addressTypes:${addressType}`),
                            }}
                            multiple
                            loading={initialAddressesLoading}
                          />
                        ))}
                    </>
                  ),
                },
              ]
            : []),
          {
            title: t("Status"),
            active: Boolean(
              filters?.declaration_statuses ||
                filters?.evv_status ||
                !!filters?.declaration_labels?.length
            ),
            content: (
              <>
                <StatusFilter
                  value={values.declaration_statuses}
                  onChange={(newValue) =>
                    handleChange({ ...values, declaration_statuses: newValue })
                  }
                />
                <Autocomplete
                  value={values.evv_status}
                  options={["received", "confirmed"]}
                  getOptionLabel={(option) => t(`evv_status:${option}`)}
                  onChange={(e, newValue) =>
                    handleChange({
                      ...values,
                      evv_status: newValue,
                    })
                  }
                  InputProps={{
                    label: t("EVV Status"),
                  }}
                />
                {!!user?.tenant?.declaration_labels.length && (
                  <Autocomplete<Declaration_Labels, true>
                    multiple
                    value={values.declaration_labels}
                    options={user.tenant.declaration_labels}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, newValue) =>
                      handleChange({ ...values, declaration_labels: newValue })
                    }
                    getOptionLabel={(option) =>
                      option.title[language] || option.title[FALL_BACK_LANGUAGE]
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          <DeclarationLabelChip declaration_label={option} />
                        </li>
                      );
                    }}
                    renderTags={(values) => {
                      return values.map((value) => (
                        <DeclarationLabelChip declaration_label={value} />
                      ));
                    }}
                    InputProps={{
                      label: t("Labels"),
                    }}
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        gap: 1,
                      },
                    }}
                  />
                )}
              </>
            ),
          },
        ]}
        onClear={() => {
          setAddressTypesFiltersAddresses((prev) => {
            const newState = { ...prev };
            Object.keys(prev).forEach((key: unknown) => {
              newState[key as keyof AddressesFilters] = [];
            });
            return newState;
          });
          onChange(undefined);
        }}
      />
    </Box>
  );
};

export default DeclarationFiltersSidebar;
