import gql from "graphql-tag";
import {
  WdGetDeclarationDetailsGoodsItemsQuery,
  useWdGetDeclarationDetailsGoodsItemsQuery,
} from "graphql/generated";
import { useMemo } from "react";

export type DeclarationDetailsGoodsItemsEntity = NonNullable<
  WdGetDeclarationDetailsGoodsItemsQuery["goodsItems"]
>[number];

gql`
  query WdGetDeclarationDetailsGoodsItems($where: goods_item_bool_exp) {
    goodsItems: goods_item(where: $where) {
      customs_item_number
      commodity_code
      description
      packagings {
        packaging_type
        packaging_quantity
        packaging_reference_number
      }
      additional_unit
      net_mass
      gross_mass
      vat_value
      vat_total
      duty_total
      commercial_good
      clearance_type
      origin_preference
      origin_country
      repair
      repair_reason
      produced_documents {
        produced_document_type
        produced_document_reference_number
        produced_document_issue_date
      }
      statistical_code
      statistical_value
      vat_rate
      duty_rate
      goods_item_special_mentions(order_by: { sequence_number: asc }) {
        text
      }
      permit_obligation_code
      permits {
        permit_type
        permit_authority
        permit_number
      }
      selection_result
      duty_and_other_taxes_total
    }
  }
`;

export default ({ file_id, dr_no }: { file_id: string; dr_no: number }) => {
  const result = useWdGetDeclarationDetailsGoodsItemsQuery({
    variables: {
      where: {
        file_id: { _eq: file_id },
        dr_no: { _eq: dr_no },
      },
    },
  });
  return useMemo(
    () => ({
      ...result,
      data: {
        ...result.data,
        goodsItems: (result.data ?? result.previousData)?.goodsItems ?? [],
      },
    }),
    [result]
  );
};
