import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@periplus/ui-library";
import { AddressType } from "domain/declaration/types";
import Group from "./Group";
import Field from "./Field";
import { Permissions, useAuth } from "keycloak/context/AuthContext";
import { DeclarationDetailsEntity } from "pages/Declaration/hooks/useGetDeclarationDetails";

interface AddressesGroupProps {
  declaration: DeclarationDetailsEntity;
}

const AddressesGroup: FC<AddressesGroupProps> = ({ declaration }) => {
  const { user } = useAuth();
  const { t } = useTranslation("declaration");
  const showAddressesDetails = user?.hasAllowedPermissions([
    Permissions.ADDRESS_DETAILS,
  ]);
  const isSupplierAvailable = user?.hasAllowedPermissions([
    Permissions.SUPPLIER_ADDRESS,
  ]);

  const addressesOrder = [
    AddressType.consignee,
    AddressType.consignor,
    AddressType.freight_forwarder,
    AddressType.carrier,
    AddressType.importer,
    ...(isSupplierAvailable ? [AddressType.supplier] : []),
    AddressType.consignee_transit,
    AddressType.consignor_transit,
  ];

  return (
    <Group title={t("Stakeholders")}>
      {addressesOrder.reduce((acc, addressType) => {
        const address = declaration.addresses?.find(
          (address) => address.addressTypeId === addressType && address.id
        );

        if (address) {
          acc.push(
            <Field
              key={addressType}
              label={t(`addressTypes:${AddressType[addressType]}`)}
              value={
                <Box key={addressType}>
                  <Typography>{address.companyName}</Typography>
                  {showAddressesDetails && (
                    <>
                      <Typography>{address.street}</Typography>
                      <Typography>
                        {address.zipcode}
                        {" " + address.city}
                      </Typography>
                      <Typography>{address.country}</Typography>
                    </>
                  )}
                </Box>
              }
            />
          );
        }

        return acc;
      }, [] as ReactNode[])}
    </Group>
  );
};

export default AddressesGroup;
