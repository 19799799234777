import {
  FC,
  useState,
  HtmlHTMLAttributes,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Panel,
  CancelButton,
  PageContainer,
  Loader,
  Button,
  PageContainerSkeleton,
} from "@periplus/ui-library";
import usePageLocalStorage from "hooks/usePageLocalStorage";
import SuccessDialog from "./SuccessDialog";
import { DeclarationForm } from "domain/declaration/components/DeclarationForm";
import { AddressType } from "domain/declaration/types";
import useGetAddressGroup, {
  AddressGroupListEntity,
} from "domain/addressGroup/useGetAddressGroup";
import useGetAddressGroupMembers from "domain/addressGroup/useGetAddressGroupMembers";
import { FormikProvider } from "formik";
import useDeclarationFormik, {
  DECLARATION_REQUEST_FORM_ADDRESSES_TYPES,
  DeclarationRequestFormAddresses,
  DeclarationRequestForm,
} from "domain/declaration/components/DeclarationForm/useDeclarationFormik";
import { Group_Members } from "graphql/generated";
import { useAppState } from "App/AppContext";
import { useCustomDropzone } from "pages/Declaration/useCustomDropzone";
import dayjs from "dayjs";

interface NewDeclarationProps extends HtmlHTMLAttributes<HTMLElement> {
  addressGroupMembers: Group_Members[];
  addressGroups: AddressGroupListEntity[];
  refetchAddressGroups: ReturnType<typeof useGetAddressGroup>["refetch"];
}

const NewDeclaration: FC<NewDeclarationProps> = ({
  addressGroupMembers,
  addressGroups,
  refetchAddressGroups,
}) => {
  const { t } = useTranslation("declaration");
  const {
    edecData: { edec_customs_offices },
  } = useAppState();
  const { pageLocalStorage, setPageLocalStorage } = usePageLocalStorage<{
    form?: Partial<DeclarationRequestForm>;
  }>();
  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);

  const initialValues = useMemo<DeclarationRequestForm>(() => {
    const sortedAddressGroupMembers = [...addressGroupMembers].sort((a, b) =>
      a.payer!.company_name.localeCompare(b.payer!.company_name)
    );

    return {
      payer: sortedAddressGroupMembers[0]!.payer!,
      declaration_type: sortedAddressGroupMembers[0]?.declaration_types[0],
      clearance_type: sortedAddressGroupMembers[0]?.clearance_types[0],
      docs: [],
      ...DECLARATION_REQUEST_FORM_ADDRESSES_TYPES.reduce((acc, addressType) => {
        acc[addressType] = {
          address: null,
          ref: "",
        };

        return acc;
      }, {} as DeclarationRequestFormAddresses),
      tr_no: "",
      ch_exp_decl: null,
      imp_cust_clr: null,
      transitdoc_type: null,
      dest_tolldept_code: "",
      dest_tolldept_name: "",
      customs_place: {
        is_allowed_consignee: false,
        wa_number: "",
      },
      toll_dep: null,
      eta: null,
      is_express: false,
      special_remarks: "",
      arrival_time: null,
      declaration_status: "documents_recieved",
    };
  }, [addressGroupMembers]);

  const handleCloseSuccessDialog = () => {
    setIsOpenSuccessDialog(false);
  };

  const handleSubmit = useCallback(
    ({ payer, declaration_type, clearance_type }, { resetForm }) => {
      refetchAddressGroups();
      setPageLocalStorage({
        form: {
          payer,
          declaration_type,
          clearance_type,
        },
      });
      resetForm({
        values: {
          ...initialValues,
          payer,
          declaration_type,
          clearance_type,
        },
      });
      setIsOpenSuccessDialog(true);
      setTimeout(() => {
        setIsOpenSuccessDialog(false);
      }, 5000);
    },
    [refetchAddressGroups, initialValues, setPageLocalStorage]
  );
  const initialValuesWithPreviouseValues = useMemo(
    () => {
      return {
        ...initialValues,
        ...(() => {
          const pageLocalStorageFormFiltered: Partial<DeclarationRequestForm> =
            {
              ...pageLocalStorage.form,
              ...(pageLocalStorage.form?.eta && {
                eta: dayjs(pageLocalStorage.form?.eta),
              }),
              ...(pageLocalStorage.form?.arrival_time && {
                arrival_time: dayjs(pageLocalStorage.form?.arrival_time),
              }),
            };
          if (
            !addressGroupMembers.some(
              (agm) => agm.payer?.id === pageLocalStorage.form?.payer?.id
            )
          ) {
            delete pageLocalStorageFormFiltered.payer;
          }
          if (
            !addressGroupMembers.some(
              (agm) =>
                agm.payer?.id === pageLocalStorage.form?.payer?.id &&
                agm.declaration_types.some(
                  (dt) => dt === pageLocalStorage.form?.declaration_type
                )
            )
          ) {
            delete pageLocalStorageFormFiltered.declaration_type;
          }
          if (
            !addressGroupMembers.some(
              (agm) =>
                agm.payer?.id === pageLocalStorage.form?.payer?.id &&
                agm.clearance_types.some(
                  (ct) => ct === pageLocalStorage.form?.clearance_type
                )
            )
          ) {
            delete pageLocalStorageFormFiltered.clearance_type;
          }
          DECLARATION_REQUEST_FORM_ADDRESSES_TYPES.forEach((addressType) => {
            const address = pageLocalStorage.form?.[addressType]?.address;
            if (
              address &&
              !addressGroups.some(
                (ag) =>
                  ag.payerAddress?.id === pageLocalStorage.form?.payer?.id &&
                  ag.groupMembers.some(
                    (gm) =>
                      gm.address?.id === address.id &&
                      gm.address_type === AddressType[addressType]
                  )
              )
            ) {
              delete pageLocalStorageFormFiltered[addressType];
            }
          });
          if (
            !edec_customs_offices
              .filter(
                (el) => new Date(el.valid_to).getTime() > new Date().getTime()
              )
              .some(
                (el) => el.number === pageLocalStorage.form?.toll_dep?.number
              )
          )
            delete pageLocalStorageFormFiltered.toll_dep;
          return pageLocalStorageFormFiltered;
        })(),
      };
    },
    // eslint-disable-next-line
    [initialValues, addressGroupMembers, addressGroups, edec_customs_offices]
  );
  const formik = useDeclarationFormik({
    initialValues: initialValuesWithPreviouseValues,
    addressGroupMembers: addressGroupMembers,
    onSuccess: handleSubmit,
  });

  useEffect(() => {
    const { docs, ...valuesForStoringInLocalStorage } = formik.values;
    setPageLocalStorage({ form: valuesForStoringInLocalStorage });
  }, [formik.values, setPageLocalStorage]);

  const { getRootProps: getDropzoneRootProps } = useCustomDropzone({
    onDrop: (newFiles: File[]) => {
      const files = formik.values.docs ?? [];
      formik.setFieldValue("docs", [...files, ...newFiles]);
    },
    noClick: true,
    noKeyboard: true,
    noDrag: false,
  });

  return (
    <PageContainer
      {...getDropzoneRootProps()}
      title={t("navigation:new-declaration")}
      sx={{
        "& .LuiPageContainer-appBar-controls": {
          display: "flex",
          gap: 1,
        },
      }}
      controls={
        formik.values.payer && (
          <>
            <CancelButton
              //@ts-ignore
              onClick={() => {
                setPageLocalStorage({
                  form: {
                    payer: formik.values.payer,
                    declaration_type: formik.values.declaration_type,
                    clearance_type: formik.values.clearance_type,
                  },
                });
                formik.resetForm({
                  values: initialValues,
                });
              }}
              label={t("Clear form")}
            />
            <Button
              color="primary"
              variant="contained"
              startIcon={<ArrowForwardIcon />}
              disabled={(() => {
                const permissions_js = addressGroupMembers.find(
                  (agm) =>
                    agm.payer?.id === formik.values.payer?.id &&
                    agm.clearance_types.some(
                      (el) => el === formik.values.clearance_type
                    ) &&
                    agm.declaration_types.some(
                      (el) => el === formik.values.declaration_type
                    )
                )?.permissions_js;
                return (
                  !permissions_js ||
                  !Object.keys(permissions_js).length ||
                  Object.values(permissions_js).every((el: any) => !el.visible)
                );
              })()}
              loading={formik.isSubmitting}
              onClick={() => formik.submitForm()}
              minimizeForMobiles
            >
              {t("Start customs clearance")}
            </Button>
          </>
        )
      }
    >
      <FormikProvider value={formik}>
        {!formik.values.payer ? (
          <Loader />
        ) : (
          <Panel>
            <DeclarationForm
              formType="create"
              addressGroups={addressGroups}
              addressGroupMembers={addressGroupMembers}
            />
          </Panel>
        )}
        {isOpenSuccessDialog && (
          <SuccessDialog onClose={handleCloseSuccessDialog} />
        )}
      </FormikProvider>
    </PageContainer>
  );
};

export default () => {
  const { addressGroupMembers, loading: addressGroupMembersLoading } =
    useGetAddressGroupMembers();

  const {
    addressGroups,
    loading: addressGroupsLoading,
    refetch: refetchAddressGroups,
  } = useGetAddressGroup();

  if (addressGroupMembersLoading || addressGroupsLoading)
    return <PageContainerSkeleton />;

  return (
    <NewDeclaration
      addressGroupMembers={addressGroupMembers}
      addressGroups={addressGroups}
      refetchAddressGroups={refetchAddressGroups}
    />
  );
};
