import React, { FC, HtmlHTMLAttributes, useEffect } from "react";
import {
  Layout as LuiLayout,
  useLayoutContext,
  LayoutProvider,
  useMediaQuery,
  Theme,
} from "@periplus/ui-library";
import { useAuth } from "keycloak"; //Role,
import { Permissions } from "keycloak/context/AuthContext";
//import NotificationsWidget from "./NotificationsWidget";
import UserMenuContent from "./UserMenuContent";
import SidebarContent from "./SidebarContent";

export interface NavigationItem {
  Icon: React.ElementType;
  text: string;
  redirecting: string;
  permission?: Permissions;
}

interface LayoutProps extends HtmlHTMLAttributes<HTMLElement> {
  navigation: NavigationItem[];
}

const Layout: FC<LayoutProps> = ({ navigation, children }) => {
  const { user, updateUiSettings } = useAuth();
  const isUpLaptop = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up("laptop"),
    { noSsr: true }
  );

  const { layoutState } = useLayoutContext();

  useEffect(() => {
    if (
      isUpLaptop &&
      user?.uiSettings.sidebarOpen !== layoutState.sidebarOpen
    ) {
      updateUiSettings({ sidebarOpen: layoutState.sidebarOpen });
    }
    // eslint-disable-next-line
  }, [isUpLaptop, layoutState.sidebarOpen]);

  return (
    <LuiLayout
      user={user!}
      renderUserMenuContent={(close) => <UserMenuContent close={close} />}
      renderSidebarContent={(close) => (
        <SidebarContent navigation={navigation} close={close} />
      )}
      sidebarProps={{
        sx: {
          "& .MuiDrawer-paper": {
            backgroundColor: "#1D1D4B",
          },
          "& .LuiUserMenu-icon": {
            backgroundColor: "white",
          },
          "& .LuiUserMenu-fullName": {
            color: "white",
          },
          "& .LuiUserMenu-popupIndicator": {
            color: "white",
          },
          "& .LuiDrawer-closeIcon": {
            color: "white",
          },
        },
      }}
    >
      {children}
      {/* {!user?.allowedRoles.every((role) => [Role.Supplier].includes(role)) && (
        <NotificationsWidget />
      )} */}
    </LuiLayout>
  );
};

export default (props: LayoutProps) => {
  const { user } = useAuth();

  return (
    <LayoutProvider
      initialState={{
        sidebarOpen: user?.uiSettings.sidebarOpen,
      }}
    >
      <Layout {...props} />
    </LayoutProvider>
  );
};
