import React, { FC, ReactNode, useState } from "react";
import { Box } from "@periplus/ui-library";
import DeclarationFieldLabel from "./DeclarationFieldLabel";
import DeclarationFieldValue from "./DeclarationFieldValue";

export interface DeclarationFieldProps {
  label: string;
  value: ReactNode;
  onEdit?: () => void;
  noWrap?: boolean;
  view: "card" | "table";
}

const DeclarationField: FC<DeclarationFieldProps> = ({
  label,
  value,
  onEdit,
  noWrap,
  view,
}) => {
  const [hovered, setHovered] = useState(false);

  switch (view) {
    case "table":
      return (
        <DeclarationFieldValue
          noWrap={noWrap}
          onEdit={onEdit}
          sx={{ height: "100%", width: "100%" }}
        >
          {value}
        </DeclarationFieldValue>
      );
    case "card":
      return (
        <Box
          {...(onEdit && {
            onMouseEnter: () => setHovered(true),
            onMouseLeave: () => setHovered(false),
          })}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            [theme.breakpoints.up("sm")]: {
              flexDirection: "column",
              gap: 1,
            },
          })}
        >
          <DeclarationFieldLabel onEdit={onEdit} hovered={hovered}>
            {label}
          </DeclarationFieldLabel>
          <DeclarationFieldValue noWrap={noWrap}>{value}</DeclarationFieldValue>
        </Box>
      );
  }
};

export default DeclarationField;
