import React, { FC } from "react";
import { combineSX, Chip, ChipProps } from "@periplus/ui-library";
import { Declaration_Labels } from "graphql/generated";
import { useTranslation } from "react-i18next";
import { FALL_BACK_LANGUAGE } from "i18n";

interface DeclarationLabelChipProps extends ChipProps {
  declaration_label: Declaration_Labels;
}

const DeclarationLabelChip: FC<DeclarationLabelChipProps> = ({
  declaration_label,
  sx,
  ...rest
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Chip
      label={
        declaration_label.title[language] ||
        declaration_label.title[FALL_BACK_LANGUAGE]
      }
      size="small"
      sx={combineSX(
        {
          backgroundColor: `${declaration_label.color!}40`,
          color: declaration_label.color!,
          borderRadius: "4px",
          overflow: "hidden",
        },
        sx
      )}
      {...rest}
    />
  );
};

export default DeclarationLabelChip;
