import React, { FC } from "react";
import { combineSX, Chip, ChipProps } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { DECLARATION_STATUS_COLORS } from "domain/declaration/types";
import { DeclarationStatus } from "graphql/generated";

interface DeclarationStatusChipProps extends ChipProps {
  declarationStatus: DeclarationStatus;
}

const DeclarationStatusChip: FC<DeclarationStatusChipProps> = ({
  declarationStatus,
  sx,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Chip
      label={t(`declaration_status:${declarationStatus}`)}
      size="small"
      sx={combineSX(
        {
          display: "inline-flex",
          padding: "4px 6px",
          backgroundColor: `${DECLARATION_STATUS_COLORS[declarationStatus]?.color}40`,
          color: DECLARATION_STATUS_COLORS[declarationStatus]?.color,
          borderRadius: "4px",
          overflow: "hidden",
          "& .MuiChip-label": {
            display: "inline-block",
            maxWidth: "100%",
          },
        },
        sx
      )}
      {...rest}
    />
  );
};

export default DeclarationStatusChip;
