import gql from "graphql-tag";
import { TableUrlSearchParams } from "hooks/useUrlSearchParams";
import {
  BORDERAUX_FILTER_RELATIVE_DATES,
  BorderauxFilters,
} from "pages/Bordereaux/FiltersSidebar";
import {
  WdGetBordereausQuery,
  useWdGetBordereausQuery,
} from "graphql/generated";
import { useMemo } from "react";

interface Variables extends TableUrlSearchParams<BorderauxFilters> {}

export type Bordereau = Omit<
  NonNullable<
    NonNullable<
      NonNullable<WdGetBordereausQuery["getBordereaus"]>["bordereaus"]
    >[number]
  >,
  "bordereauItems"
> & {
  bordereauItems: NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<WdGetBordereausQuery["getBordereaus"]>["bordereaus"]
        >[number]
      >["bordereauItems"]
    >[number]
  >[];
};

gql`
  query WdGetBordereaus($args: BordereauArgs) {
    getBordereaus(args: $args) {
      bordereausCount: count
      bordereaus: data {
        documentNumber
        documentTitle
        documentDate
        documentTime
        accountNumber
        status
        duties
        vat
        numberOfDocuments
        amount
        consignee
        bordereauXmlDoc {
          document_blobs
        }
        bordereauPdfDoc {
          document_blobs
        }
        bordereauItems {
          id
          customsReference
          customOffice {
            name
            officeNumber
          }
          documentTypeNameTranslations {
            de
            en
          }
          documentTypeAbbreviation
          traderReference
          customsDeclarationVersion
          status
          amount
          drNo
          file {
            id
          }
        }
      }
    }
  }
`;

const useGetBordereaus = ({
  page,
  itemsPerPage,
  search,
  filters: { relative_date, minDate, maxDate, status, ...restFilters } = {},
}: Variables) => {
  const result = useWdGetBordereausQuery({
    variables: {
      args: {
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        search: search?.trim() || undefined,
        minDate: relative_date
          ? BORDERAUX_FILTER_RELATIVE_DATES[relative_date].minDate
          : minDate,
        maxDate: relative_date
          ? BORDERAUX_FILTER_RELATIVE_DATES[relative_date].maxDate
          : maxDate,
        status: (status ?? "All") as any,
        orderby: "documentDate DESC",
        ...restFilters,
      },
    },
    fetchPolicy: "no-cache",
  });

  return useMemo(
    () => ({
      ...result,
      data: {
        bordereaus:
          ((result.data?.getBordereaus?.bordereaus ??
            result.previousData?.getBordereaus?.bordereaus) as Bordereau[]) ??
          [],
        bordereausCount:
          (result.data ?? result.previousData)?.getBordereaus
            ?.bordereausCount || 0,
      },
    }),
    [result]
  );
};

export default useGetBordereaus;
