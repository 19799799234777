import React, { FC } from "react";
import { Box, BoxProps, Tooltip } from "@periplus/ui-library";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { useTranslation } from "react-i18next";
import { DeclarationDashboardEntity } from "pages/Dashboard/hooks/useGetDeclarationsDashboard";

interface DeclarationEVVProps extends BoxProps {
  declaration: DeclarationDashboardEntity;
}

const DeclarationEVV: FC<DeclarationEVVProps> = ({ declaration, ...rest }) => {
  const { t } = useTranslation("declaration");
  return (
    <Box {...rest}>
      {(() => {
        const isEVVReceived = declaration.declaration.evvReceived;
        if (!isEVVReceived) return null;

        const isEVVConfirmed = declaration.declarationRequest.meta.evvConfirmed;

        const { Icon, label } = isEVVConfirmed
          ? {
              Icon: DraftsOutlinedIcon,
              label: "Confirmed",
            }
          : { Icon: MailOutlineOutlinedIcon, label: "Received" };

        return (
          <Tooltip title={t(label)}>
            <div style={{ position: "relative" }}>
              <Icon
                style={{
                  color: "#4F4F4F",
                  marginTop: 2,
                  width: 24,
                  height: 24,
                }}
              />
              {!isEVVConfirmed && (
                <div
                  style={{
                    width: 10,
                    height: 10,
                    position: "absolute",
                    left: 0,
                    top: 0,
                    backgroundColor: "#EC008C",
                    borderRadius: "100%",
                  }}
                />
              )}
            </div>
          </Tooltip>
        );
      })()}
    </Box>
  );
};

export default DeclarationEVV;
