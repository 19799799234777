import React, { FC } from "react";
import { Box, BoxProps, IconButton } from "@periplus/ui-library";
import { Link } from "react-router-dom";
import { Launch } from "@mui/icons-material";
import DeclarationActions from "../DeclarationActions";
import { DeclarationDashboardEntity } from "../hooks/useGetDeclarationsDashboard";

interface RowActionsProps extends BoxProps {
  declaration: DeclarationDashboardEntity;
}

const RowActions: FC<RowActionsProps> = ({ declaration }) => {
  if (declaration.declarationStatus === "declaration_canceled") return null;

  return (
    <Box sx={{ display: "flex", gap: 0.5 }}>
      <Link
        to={`/declaration/${declaration.fileId}/${declaration.drNo}`}
        style={{
          textDecoration: "none",
        }}
      >
        <IconButton color="primary">
          <Launch />
        </IconButton>
      </Link>
      <DeclarationActions declaration={declaration} />
    </Box>
  );
};

export default React.memo(RowActions);
