import { useMemo, useState } from "react";
import { Box, BoxProps, Paper, Search, Typography } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import Summary from "../components/Summary";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { KPIEntity } from "../hooks/useGetKPI";
import { TenantListEntity } from "domain/tenant/useGetTenants";
import { Dayjs } from "dayjs";
import useGetTariffTabKPI from "../hooks/useGetTariffTabKPI";

interface Props extends BoxProps {
  kpis: KPIEntity;
  payer: TenantListEntity;
  dateFrom: Dayjs;
  dateTo: Dayjs;
  traderIdentificationNumber?: string;
}

interface Node {
  path: string | null;
  name: string | null;
  value: number;
  declarationCount: number;
  children?: Node[];
}

function getLevelOption() {
  return [
    {
      itemStyle: {
        borderWidth: 0,
        gapWidth: 5,
      },
    },
    {
      itemStyle: {
        gapWidth: 1,
      },
    },
    {
      colorSaturation: [0.35, 0.5],
      itemStyle: {
        gapWidth: 1,
        borderColorSaturation: 0.6,
      },
    },
  ];
}

export default ({
  kpis,
  payer,
  dateFrom,
  dateTo,
  traderIdentificationNumber,
}: Props) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");

  const {
    data: { commodityCodesKpi },
  } = useGetTariffTabKPI({
    variables: {
      tenant_id: payer.id,
      date_from: dateFrom.format(),
      date_to: dateTo.format(),
      commodity_code: search,
      trader_identification_number: traderIdentificationNumber,
    },
  });

  const filteredCommodityCodesChapters = useMemo(
    () =>
      kpis.commodityCodesChapters.filter((el) =>
        el.tariffNumber.startsWith(search)
      ),
    [kpis.commodityCodesChapters, search]
  );

  const chartOption = useMemo(
    () => ({
      tooltip: {
        formatter: function (info: any) {
          var value = info.value;
          var treePathInfo = info.treePathInfo;
          var treePath = [] as any[];
          for (var i = 1; i < treePathInfo.length; i++) {
            treePath.push(treePathInfo[i].name);
          }
          return [
            '<div class="tooltip-title">' +
              echarts.format.encodeHTML(treePath.join("/")) +
              "</div>",
            `${t("Clearances")}: ${info.data.declarationCount}`,
            `</br>${t("Tariff positions")}: ${value}`,
          ].join("");
        },
      },
      series: [
        {
          name: t("Tariff Numbers"),
          type: "treemap",
          visibleMin: 400,
          width: "100%",
          height: "100%",
          label: {
            show: true,
            formatter: "{b}",
          },
          itemStyle: {
            borderColor: "#fff",
          },
          levels: getLevelOption(),
          data: filteredCommodityCodesChapters.reduce((hierarchy, item) => {
            const chapter = item.chapter;
            const heading = item.heading;
            const tariffNumber = item.tariffNumber;
            const value = item.goodsItemCount ?? 0;
            const declarationCount = item.declarationCount ?? 0;

            let chapterNode = hierarchy.find((node) => node.path === chapter);
            if (!chapterNode) {
              chapterNode = {
                path: chapter,
                name: chapter,
                value: 0,
                declarationCount: 0,
                children: [],
              };
              hierarchy.push(chapterNode);
            }
            chapterNode.value += value;
            chapterNode.declarationCount += declarationCount;

            let headingNode = chapterNode.children?.find(
              (node) => node.path === `${chapter}/${heading}`
            );
            if (!headingNode) {
              headingNode = {
                path: `${chapter}/${heading}`,
                name: heading,
                value: 0,
                declarationCount: 0,
                children: [],
              };
              chapterNode.children?.push(headingNode);
            }
            headingNode.value += value;
            headingNode.declarationCount += declarationCount;

            let subheadingNode = headingNode.children?.find(
              (node) => node.path === `${chapter}/${heading}/${tariffNumber}`
            );
            if (!subheadingNode) {
              subheadingNode = {
                path: `${chapter}/${heading}/${tariffNumber}`,
                name: tariffNumber,
                value: value,
                declarationCount: declarationCount,
              };
              headingNode.children?.push(subheadingNode);
            } else {
              subheadingNode.value += value;
              subheadingNode.declarationCount += declarationCount;
            }

            return hierarchy;
          }, [] as Node[]),
        },
      ],
    }),
    [filteredCommodityCodesChapters, t]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{t("Specific Tariff Details")}</Typography>
          <Search
            onChange={setSearch}
            label={t("Search Tariff Number")}
            sx={{
              minWidth: 230,
            }}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Summary
            title={t("Clearances")}
            value={commodityCodesKpi?.declarationCount ?? 0}
            primary
          />
          <Summary
            title={t("Tariff positions")}
            value={commodityCodesKpi?.goodsItemCount ?? 0}
          />
          <Summary
            title={t("Net Weight")}
            value={`${(commodityCodesKpi?.totalNetMass ?? 0).toFixed(2)} kg`}
          />
          <Summary
            title={t("Gross Weight")}
            value={`${(commodityCodesKpi?.totalGrossMass ?? 0).toFixed(2)} kg`}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h6">{t("Tariff Numbers Used")}</Typography>
        <Paper
          variant="outlined"
          sx={{
            p: 1,
          }}
        >
          <ReactEcharts option={chartOption} style={{ height: 400 }} />
        </Paper>
      </Box>
    </Box>
  );
};
