import React, { FC, HtmlHTMLAttributes } from "react";
import { Theme } from "@periplus/ui-library";
import { FormTextField } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { makeStyles } from 'tss-react/mui';

interface ACPlaceFormProps extends HtmlHTMLAttributes<HTMLElement> {
  disabled: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
}));

const ACPlaceForm: FC<ACPlaceFormProps> = ({ disabled }) => {
  const { classes } = useStyles();
  const { t } = useTranslation("declaration");

  return (
    <div className={classes.root}>
      <FormTextField
        name="customs_place.wa_number"
        label={t("WA Number")}
        fullWidth
        disabled={disabled}
      />
    </div>
  );
};

export default ACPlaceForm;
