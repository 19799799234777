import { useEffect, useMemo, useState } from "react";
import gql from "graphql-tag";
import {
  DECLARATION_FILTER_RELATIVE_DATES,
  DeclarationFilters,
} from "domain/declaration/components/DeclarationFiltersSidebar";
import { TableUrlSearchParams } from "hooks/useUrlSearchParams";
import {
  AddressFilter,
  WdGetDeclarationsDashboardQuery,
  useWdGetDeclarationsDashboardQuery,
} from "graphql/generated";

export type DeclarationDashboardEntity = NonNullable<
  WdGetDeclarationsDashboardQuery["pp_declaration_requests"]
>[number];

interface Variables extends TableUrlSearchParams<DeclarationFilters> {}

gql`
  query WdGetDeclarationsDashboard(
    $limit: Int
    $offset: Int
    $min_date: LocalDateTime
    $max_date: LocalDateTime
    $search: String
    $declaration_statuses: String
    $addresses: [AddressFilter!]
    $declaration_type: String
    $clearance_type: String
    $arrival_type: String
    $orderby: [JSONObject!]
    $payer: UUID
    $evv_status: String
    $declaration_labels: String
  ) {
    pp_declaration_requests(
      args: {
        _limit: $limit
        _offset: $offset
        _date_min: $min_date
        _date_max: $max_date
        _search: $search
        _declaration_statuses: $declaration_statuses
        _orderby: $orderby
        _addresses: $addresses
        _decl_type: $declaration_type
        _clr_type: $clearance_type
        _arrival_type: $arrival_type
        _payer: $payer
        _evv_status: $evv_status
        _declaration_labels: $declaration_labels
      }
    ) {
      addressReferences
      fileId
      drNo
      declarationStatus
      searchReferences
      declarationRequest {
        arrivalTime
        arrivalType
        clearanceType
        creationDate
        acceptanceDate
        creationUser
        declarationType
        drNo
        eta
        fileId
        meta
        waNumber
      }
      declarationLabels {
        id
        title
        description
        color
        isDeleted
      }
      file {
        identification
        isDeleted
      }
      customsOffice {
        name
        number
      }
      declaration {
        acceptanceDate
        customsDeclarationNumber
        customsDeclarationVersion
        evvReceived
        customsOfficeNumber
        declarationType
        borderEauNumber
      }
      addresses {
        addressTypeId
        companyName
      }
      payer {
        address {
          id
          companyName
        }
      }
      userPp {
        displayName
        email
      }
      declarationDiscussion {
        unreadMessagesCount
      }
    }
  }
`;

const useGetDeclarationsDashboard = ({
  page,
  itemsPerPage,
  sorting,
  search,
  filters: {
    consignor,
    consignee,
    importer,
    freight_forwarder,
    supplier,
    declaration_statuses,
    evv_status,
    declaration_labels,
    relative_date,
    min_date,
    max_date,
    ...restFilters
  } = {},
}: Variables) => {
  const result = useWdGetDeclarationsDashboardQuery({
    variables: {
      limit: itemsPerPage + 1,
      offset: (page - 1) * itemsPerPage,
      orderby: sorting?.length ? sorting : undefined,
      declaration_statuses: declaration_statuses
        ? `{${declaration_statuses?.join(",")}}`
        : undefined,
      search: search ? `%${search.trim()}%` : undefined,
      addresses: (() => {
        const addresses = [
          { addressType: "consignor", addressIds: consignor },
          { addressType: "consignee", addressIds: consignee },
          { addressType: "importer", addressIds: importer },
          {
            addressType: "freight_forwarder",
            addressIds: freight_forwarder,
          },
          { addressType: "supplier", addressIds: supplier },
        ].filter((el) => el.addressIds?.length) as AddressFilter[];
        return addresses.length ? addresses : undefined;
      })(),
      evv_status: evv_status || undefined,
      ...(!!declaration_labels?.length && {
        declaration_labels: `{${declaration_labels.join(",")}}`,
      }),
      min_date: relative_date
        ? DECLARATION_FILTER_RELATIVE_DATES[relative_date].min_date
        : min_date,
      max_date: relative_date
        ? DECLARATION_FILTER_RELATIVE_DATES[relative_date].max_date
        : max_date,
      ...restFilters,
    },
    fetchPolicy: "no-cache",
  });

  const [data, setData] = useState<{
    declarationRequests: DeclarationDashboardEntity[];
    isLastPage: boolean;
  }>({
    declarationRequests: [],
    isLastPage: true,
  });
  useEffect(() => {
    setData((prev) =>
      result.data
        ? {
            declarationRequests: result.data.pp_declaration_requests!.slice(
              0,
              itemsPerPage
            ),
            isLastPage:
              result.data.pp_declaration_requests!.length <= itemsPerPage,
          }
        : {
            declarationRequests: prev.declarationRequests,
            isLastPage: true,
          }
    );
  }, [result.data, itemsPerPage]);

  return useMemo(
    () => ({
      ...result,
      data,
    }),
    [result, data]
  );
};

export default useGetDeclarationsDashboard;
