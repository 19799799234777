export enum DefaultFlag {
  EdecRelevant = 524288,
  custom_relevant = 4,
  wedeclare_relevant = 8,
}

export interface DocumentType {
  display_name: string;
  lastModifiedDate: string;
  name: string;
  translations: { de: string; en: string; ru?: string };
  document_type_tenant_settings: {
    code: string;
    default_flags: number;
    meta: {
      color: string;
    };
  };
}
