import { FC, HtmlHTMLAttributes } from "react";
import { Theme } from "@periplus/ui-library";
import { Typography } from "@periplus/ui-library";
import { useField } from "formik";
import {
  DeclarationRequestFormAddress,
  DeclarationRequestFormAddressesTypes,
} from "./useDeclarationFormik";
import AddressFormAutocomplete from "domain/address/components/AddressFormAutocomplete";
import { AddressType } from "domain/declaration/types";
import { makeStyles } from 'tss-react/mui';

interface AddressSelectProps extends HtmlHTMLAttributes<HTMLElement> {
  addressType: DeclarationRequestFormAddressesTypes;
  disabled?: boolean;
  detailed?: boolean;
  addresses?: DeclarationRequestFormAddress[];
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  selectedAddressInfoContainer: {
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
}));

const AddressSelect: FC<AddressSelectProps> = ({
  className,
  addressType,
  disabled,
  detailed,
  addresses,
}) => {
  const { classes, cx } = useStyles();
  const [, { value }] = useField<DeclarationRequestFormAddress | null>(
    `${addressType}.address`
  );
  return (
    <div className={cx(className, classes.root)}>
      <AddressFormAutocomplete
        name={`${addressType}.address`}
        addressTypes={[AddressType[addressType]]}
        options={addresses}
        fullWidth
        disabled={disabled}
      />
      {detailed && Boolean(value) && (
        <div className={classes.selectedAddressInfoContainer}>
          <Typography>{value?.companyName}</Typography>
          {(() => {
            const streetInfo = [value?.street, value?.streetNumber].filter(
              (el) => el
            );
            return streetInfo.length ? (
              <Typography>{streetInfo.join(" ")}</Typography>
            ) : null;
          })()}
          <Typography>
            {`${value?.country} - ${value?.zipcode} ${value?.city}`}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default AddressSelect;
