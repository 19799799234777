import gql from "graphql-tag";
import { useWdUpdateDeclarationEvvConfirmationMutation } from "graphql/generated";

gql`
  mutation WdUpdateDeclarationEVVConfirmation(
    $file_id: uuid!
    $dr_no: smallint!
    $evv_confirmed: Boolean!
  ) {
    update_declaration_request_by_pk(
      pk_columns: { file_id: $file_id, dr_no: $dr_no }
      _append: { meta: { evv_confirmed: $evv_confirmed } }
    ) {
      file_id
    }
  }
`;

export default useWdUpdateDeclarationEvvConfirmationMutation;
