import React, { FC, HtmlHTMLAttributes, useState } from "react";
import { Box, Theme, Typography } from "@periplus/ui-library";
import { Popover, MenuItem } from "@periplus/ui-library";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Button, FormSelect } from "@periplus/ui-library";
import { useUpdateDocumentType } from "domain/documentType";
import * as yup from "yup";
import { DocumentType } from "domain/documentType/types";
import { TransformedDocument } from "../../DocumentsTab";
import { makeStyles } from 'tss-react/mui';

interface ClassificationPopoverProps extends HtmlHTMLAttributes<HTMLElement> {
  anchorEl: any;
  document: TransformedDocument;
  documentTypes: DocumentType[];
  onClose: () => void;
  onClassificated: () => Promise<any>;
}

const useStyles = makeStyles()((theme: Theme) => ({
  documentTypeSelect: {
    width: 250,
  },
}));

const ClassificationPopover: FC<ClassificationPopoverProps> = ({
  anchorEl,
  document,
  documentTypes,
  onClose,
  onClassificated,
}) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const assignDocumentType = useUpdateDocumentType();

  const initialValues = {
    documentType:
      document.document_type !== "unknown"
        ? document.document_type
        : documentTypes?.some(
            (documentType) => documentType.name === "commercial_invoice"
          )
        ? "commercial_invoice"
        : "",
  };

  const validationSchema = yup.object().shape({
    documentType: yup.string().required(),
  });

  const handleSubmit = async (values, formikHelpers) => {
    setIsLoading(true);
    await assignDocumentType({
      variables: {
        doc_id: document.id,
        document_type: values.documentType,
      },
    });
    await onClassificated();
    setIsLoading(false);
  };

  return (
    <Popover
      open={true}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <>
            <Box
              sx={{
                p: 1.5,
                borderBottom: 1,
                borderColor: "grey3.main",
              }}
            >
              <Typography variant="h6">
                {t("declaration:classifyPopOverTitle")}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{
                  mt: 1,
                }}
              >
                {t("declaration:classifyPopOverSubTitle")}
              </Typography>
            </Box>
            <Box
              sx={{
                p: 1.5,
              }}
            >
              <FormSelect
                className={classes.documentTypeSelect}
                name="documentType"
              >
                {documentTypes?.map((documentType) => (
                  <MenuItem key={documentType.name} value={documentType.name}>
                    {documentType.translations[i18n.language.substring(0, 2)]}
                  </MenuItem>
                ))}
              </FormSelect>
            </Box>
            <Box
              sx={{
                p: 1.5,
                display: "flex",
                gap: 1,
                backgroundColor: "#fafafe",
                justifyContent: "flex-end",
              }}
            >
              <Button variant="text" onClick={onClose}>
                {t("common:cancel")}
              </Button>
              <Button
                color="primary"
                onClick={submitForm}
                variant="contained"
                loading={isLoading}
                disabled={!isValid}
              >
                {t("common:assign")}
              </Button>
            </Box>
          </>
        )}
      </Formik>
    </Popover>
  );
};

export default ClassificationPopover;
