import { gql } from "@apollo/client";
import { useWdMarkDeclarationMessagesAsReadMutation } from "graphql/generated";

gql`
  mutation WdMarkDeclarationMessagesAsRead(
    $disc_id: bigint
    $read_date: timestamptz!
    $tenant_id: uuid!
  ) {
    update_declaration_message(
      _set: { read_date: $read_date }
      where: {
        discussion_id: { _eq: $disc_id }
        read_date: { _is_null: true }
        user: { tenant_id: { _neq: $tenant_id } }
      }
    ) {
      returning {
        discussion_id
      }
    }
  }
`;

export default useWdMarkDeclarationMessagesAsReadMutation;
