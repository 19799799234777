// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState } from "react";
import uuid from "uuid";
import { SasUpdatePolicyFactory, SasStore } from "utils/blockBlobClient";
import {
  BlockBlobClient,
  AnonymousCredential,
  newPipeline,
} from "@azure/storage-blob";
import config from "config";

const prepareData = (
  fileUrl: string,
  contentType?: string
): { sasContentType?: string; containerNameExtracted?: string } => {
  const containerNameExtracted = fileUrl.split("/")[3];

  /*
   * doc  application/msword
   * xls  application/vnd.ms-excel
   * docx application/vnd.openxmlformats-officedocument.wordprocessingml.document
   * xlsx application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
   * */

  const extension = fileUrl.split(".").pop();
  /* as browsers can't open non-PDF's ignoring this setting */
  const sasContentType = extension === "pdf" ? contentType : undefined;

  return {
    sasContentType,
    containerNameExtracted,
  };
};

const defaultBlobClientSettings = {
  blobHTTPHeaders: {
    blobCacheControl: "max-age=300, must-revalidate",
  },
  maxSingleShotSize: 4 * 1024 * 1024,
};

export const uploadFile = async (file, options) => {
  const { name } = file;
  const { blobClientSettings = defaultBlobClientSettings } = options;
  const sasStore = new SasStore();
  const pipeline = newPipeline(new AnonymousCredential());
  const { storageAccountName, documentsContainerName } = config.blobStorage;

  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      // blob client
      try {
        const sasToken = await sasStore.getOrFetchValidSASForContainer(
          config.blobStorage.documentsContainerName
        );

        // Inject SAS update policy factory into current pipeline
        pipeline.factories.unshift(new SasUpdatePolicyFactory(sasStore));
        const blobServer = `https://${storageAccountName}.blob.core.windows.net`;
        const fileName = `${uuid()}.${name.split(".").pop()}`;

        // @ts-ignore
        const blobUri = `${blobServer}/${documentsContainerName}/${fileName}`;

        const blockBlobClient = new BlockBlobClient(
          `${blobUri}?${sasToken.value}`,
          pipeline
        );

        await blockBlobClient.uploadBrowserData(file, blobClientSettings);

        resolve({ name, blobUri });
      } catch (error) {
        reject({ name });
      }
    }, 500);
  });
};

export const useGetDownloadUrlFunction = () => {
  const [url, setUrl] = useState<string>();

  const getDownloadUrl = (
    fileUrl: string,
    contentType?: string,
    contentDisposition?: string
  ): {
    url?: string;
    loading: boolean;
  } => {
    if (!fileUrl) {
      return {
        url: fileUrl,
        loading: false,
      };
    }

    const { sasContentType, containerNameExtracted } = prepareData(
      fileUrl,
      contentType
    );

    if (!containerNameExtracted) {
      return {
        url: fileUrl,
        loading: false,
      };
    }

    const sasStore = new SasStore();

    const sasToken = sasStore.getValidSASForContainer(
      containerNameExtracted,
      sasContentType,
      contentDisposition
    );

    if (sasToken) {
      return {
        url: `${fileUrl}?${sasToken.value}`,
        loading: false,
      };
    }

    (function fetchSasToken() {
      sasStore
        .updateSASForContainer(
          containerNameExtracted,
          sasContentType,
          contentDisposition
        )
        .then(({ value, expiryDate }) => {
          setUrl(`${fileUrl}?${value}`);
          // FIXME: this setTimeout is no needed, check and remove it
          setTimeout(
            fetchSasToken,
            new Date(expiryDate).getTime() - new Date().getTime()
          );
        });
    })();

    return {
      url,
      loading: !url,
    };
  };

  return getDownloadUrl;
};

export const useGetDownloadUrl = (
  fileUrl: string,
  contentType?: string,
  contentDisposition?: string
): {
  url?: string;
  loading: boolean;
} => {
  const [url, setUrl] = useState<string>();

  if (!fileUrl) {
    return {
      url: fileUrl,
      loading: false,
    };
  }

  const { sasContentType, containerNameExtracted } = prepareData(
    fileUrl,
    contentType
  );

  if (!containerNameExtracted) {
    return {
      url: fileUrl,
      loading: false,
    };
  }

  const sasStore = new SasStore();

  const sasToken = sasStore.getValidSASForContainer(
    containerNameExtracted,
    sasContentType,
    contentDisposition
  );

  if (sasToken) {
    return {
      url: `${fileUrl}?${sasToken.value}`,
      loading: false,
    };
  }

  (function fetchSasToken() {
    sasStore
      .updateSASForContainer(
        containerNameExtracted,
        sasContentType,
        contentDisposition
      )
      .then(({ value }) => {
        setUrl(`${fileUrl}?${value}`);
      });
  })();

  return {
    url,
    loading: !url,
  };
};

export const getDownloadUrl = async (
  fileUrl: string,
  contentType?: string,
  contentDisposition?: string
) => {
  const { sasContentType, containerNameExtracted } = prepareData(
    fileUrl,
    contentType
  );

  if (!containerNameExtracted) {
    return fileUrl;
  }

  const sasStore = new SasStore();

  const sasToken = await sasStore.getOrFetchValidSASForContainer(
    config.blobStorage.documentsContainerName,
    sasContentType,
    contentDisposition
  );

  return `${fileUrl}?${sasToken?.value || ""}`;
};
