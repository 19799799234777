import { AddressType } from "domain/declaration/types";
import {
  WdGetAddressesQuery,
  WdGetAddressesQueryVariables,
  useWdGetAddressesLazyQuery,
  useWdGetAddressesQuery,
} from "graphql/generated";
import { useMemo } from "react";
import { LazyQueryHookOptions, QueryHookOptions } from "@apollo/client";
import { Undefinable } from "utils/utilityTypes";

export type AddressListEntity = Undefinable<
  NonNullable<WdGetAddressesQuery["addresses"]>[number],
  "source" | "aoId"
>;

export const getAddressesQueryVariables = ({
  addressIds,
  search,
  addressTypes,
  emptyAddressType,
  ...rest
}: {
  addressIds?: number[];
  addressTypes?: AddressType[];
} & Pick<
  WdGetAddressesQueryVariables,
  "search" | "emptyAddressType" | "limit"
>) => ({
  ...(addressIds?.length && {
    addressIds: `{${addressIds.join(",")}}`,
  }),
  ...(search && { search }),
  ...(addressTypes?.length && {
    addressTypes: `{${addressTypes.join(",")}}`,
  }),
  ...(emptyAddressType !== undefined && { emptyAddressType }),
  ...rest,
});

export const useGetAddressesLazy = (
  baseOptions?: LazyQueryHookOptions<
    WdGetAddressesQuery,
    WdGetAddressesQueryVariables
  >
) => {
  const [query, result] = useWdGetAddressesLazyQuery({
    fetchPolicy: "no-cache",
    ...baseOptions,
  });

  return useMemo(() => {
    return [
      query,
      {
        ...result,
        data: {
          addresses: result.data?.addresses ?? [],
        },
      },
    ] as const;
  }, [result, query]);
};

export default (
  baseOptions?: QueryHookOptions<
    WdGetAddressesQuery,
    WdGetAddressesQueryVariables
  >
) => {
  const result = useWdGetAddressesQuery({
    fetchPolicy: "no-cache",
    ...baseOptions,
  });

  return useMemo(() => {
    return {
      ...result,
      data: {
        addresses: result.data?.addresses ?? [],
      },
    };
  }, [result]);
};
