import React from "react";
import { Box, BoxProps } from "@periplus/ui-library";
import CustomsClearancesPanel from "./CustomsClearancesPanel";
import CustomsAgenciesPanel from "./CustomsAgenciesPanel";
import { KPIEntity } from "../hooks/useGetKPI";
import { Dayjs } from "dayjs";

interface Props extends BoxProps {
  kpis: KPIEntity;
  dateFrom: Dayjs;
  dateTo: Dayjs;
}

export default ({ kpis, dateFrom, dateTo }: Props) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <CustomsClearancesPanel kpis={kpis} dateFrom={dateFrom} dateTo={dateTo} />
      <CustomsAgenciesPanel kpis={kpis} />
    </Box>
  );
};
