import React, { FC } from "react";
import { useGetDownloadUrl } from "utils/azureBlobStorageUtils";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import printJS from "print-js";
import { useTranslation } from "react-i18next";
import { ListItemIcon, ListItemText, MenuItem } from "@periplus/ui-library";
import { TransformedDocument } from "../DocumentsTab";

interface PrintButtonProps {
  document: TransformedDocument;
  onClick: () => void;
}

const PrintButton: FC<PrintButtonProps> = ({ document, onClick }) => {
  const { t } = useTranslation();
  const printUrl = useGetDownloadUrl(
    document.document_blobs?.find(
      (blob: any) => blob.extension === document.document_extension
    )?.url
  );

  return (
    <MenuItem
      onClick={() => {
        printJS({
          printable: printUrl.url,
        });
        onClick();
      }}
    >
      <ListItemIcon>
        <PrintOutlinedIcon sx={{ color: "text.secondary" }} />
      </ListItemIcon>
      <ListItemText
        primary={t("tooltips:print")}
        primaryTypographyProps={{ color: "text.secondary" }}
      />
    </MenuItem>
  );
};

export default PrintButton;
