import gql from "graphql-tag";
import {
  DECLARATION_FILTER_RELATIVE_DATES,
  DeclarationFilters,
} from "domain/declaration/components/DeclarationFiltersSidebar";
import { TableUrlSearchParams } from "hooks/useUrlSearchParams";
import {
  AddressFilter,
  useWdGetDeclarationsGoodsItemsStatisticsCountQuery,
} from "graphql/generated";

interface Variables extends TableUrlSearchParams<DeclarationFilters> {}

gql`
  query WDGetDeclarationsGoodsItemsStatisticsCount(
    $limit: Int
    $offset: Int
    $min_date: LocalDateTime
    $max_date: LocalDateTime
    $search: String
    $search_field: String
    $declaration_statuses: String
    $addresses: [AddressFilter!]
    $declaration_type: String
    $clearance_type: String
    $arrival_type: String
    $payer: UUID
    $evv_status: String
    $declaration_labels: String
    $file: UUID
  ) {
    acs_goods_items_for_drs_count(
      args: {
        _limit: $limit
        _offset: $offset
        _date_min: $min_date
        _date_max: $max_date
        _search: $search
        _search_field: $search_field
        _declaration_statuses: $declaration_statuses
        _addresses: $addresses
        _decl_type: $declaration_type
        _clr_type: $clearance_type
        _arrival_type: $arrival_type
        _payer: $payer
        _evv_status: $evv_status
        _declaration_labels: $declaration_labels
        _file_id: $file
      }
    ) {
      resultSetCount
    }
  }
`;

const useGetDeclarationsGoodsItemsStatisticsCount = ({
  search,
  search_field,
  filters: {
    consignor,
    consignee,
    importer,
    supplier,
    freight_forwarder,
    declaration_statuses,
    evv_status,
    declaration_labels,
    relative_date,
    min_date,
    max_date,
    declaration_type,
    clearance_type,
    arrival_type,
    payer,
  } = {},
}: Variables) => {
  const filters = {
    declaration_statuses: declaration_statuses
      ? `{${declaration_statuses?.join(",")}}`
      : undefined,
    search: (() => {
      if (!search) {
        return undefined;
      }
      if (search_field) {
        return search;
      }

      return `%${search}%`;
    })(),
    addresses: (() => {
      const addresses = [
        { addressType: "consignor", addressIds: consignor },
        { addressType: "consignee", addressIds: consignee },
        { addressType: "importer", addressIds: importer },
        {
          addressType: "freight_forwarder",
          addressIds: freight_forwarder,
        },
        { addressType: "supplier", addressIds: supplier },
      ].filter((el) => el.addressIds?.length) as AddressFilter[];
      return addresses.length ? addresses : undefined;
    })(),
    evv_status: evv_status || undefined,
    search_field: search_field || undefined,
    ...(!!declaration_labels?.length && {
      declaration_labels: `{${declaration_labels.join(",")}}`,
    }),
    min_date: relative_date
      ? DECLARATION_FILTER_RELATIVE_DATES[relative_date].min_date
      : min_date,
    max_date: relative_date
      ? DECLARATION_FILTER_RELATIVE_DATES[relative_date].max_date
      : max_date,
    declaration_type,
    clearance_type,
    arrival_type,
    payer,
  };

  const result = useWdGetDeclarationsGoodsItemsStatisticsCountQuery({
    fetchPolicy: "network-only",
    variables: filters,
    skip: [filters.min_date, filters.max_date].some(
      (el) => el === "Invalid Date"
    ),
  });

  return {
    ...result,
    data: result.data ?? result.previousData,
  };
};

export default useGetDeclarationsGoodsItemsStatisticsCount;
