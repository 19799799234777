import React, { useMemo } from "react";
import { useAuth } from "keycloak";
import { useLocation } from "react-router-dom";
import { UiSettingsTemplate } from "keycloak/context/AuthContext";

export default function usePageSettings() {
  const { user, updateUiSettingsTemplates } = useAuth();
  const {
    state: { path },
  } = useLocation<{ path: string }>();

  const pageSettingsTemplates = useMemo(
    () => user!.uiSettingsTemplates[path] ?? [],
    [user, path]
  );

  const setPageSettingsTemplates = React.useCallback(
    (
      value:
        | UiSettingsTemplate[]
        | ((prevState: UiSettingsTemplate[]) => UiSettingsTemplate[])
    ) => {
      let updatedPageSettingsTemplates: UiSettingsTemplate[];
      if (typeof value === "function") {
        const func = value as (
          prevState: UiSettingsTemplate[]
        ) => UiSettingsTemplate[];
        updatedPageSettingsTemplates = func(pageSettingsTemplates);
      } else {
        updatedPageSettingsTemplates = value;
      }
      updateUiSettingsTemplates({
        ...user!.uiSettingsTemplates,
        [path]: updatedPageSettingsTemplates,
      });
    },
    [user, path, pageSettingsTemplates, updateUiSettingsTemplates]
  );

  return { pageSettingsTemplates, setPageSettingsTemplates };
}
