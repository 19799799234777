import React, { FC, useCallback, useMemo } from "react";
import {
  //Box,
  BoxProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Theme,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { Germany, GreatBritain } from "components/Icons";
import LanguageIcon from "@mui/icons-material/Language";
//import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import { useHistory } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useAuth } from "keycloak";
import NestedList from "./NestedList";
import { Permissions } from "keycloak/context/AuthContext";
import PersonIcon from "@mui/icons-material/Person";
//import { useAppContext } from "App/AppContext";

export type LanguageItem = {
  key?: string;
  element: string;
  abbreviation: string;
  Icon?: React.ElementType;
  action: () => void;
};

interface UserMenuContentProps extends BoxProps {
  close: () => void;
}

const UserMenuContent: FC<UserMenuContentProps> = ({ close }) => {
  const { t } = useTranslation();
  //const [appState, appAction] = useAppContext();
  const { logout, user, updateUiSettings } = useAuth();

  const history = useHistory();
  const isUpLaptop = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up("laptop"),
    { noSsr: true }
  );

  const changeLanguage = useCallback(
    (lng: string) => () => {
      updateUiSettings({ language: lng });
    },
    [updateUiSettings]
  );

  const languages = useMemo<LanguageItem[]>(
    () => [
      {
        abbreviation: "en",
        element: "English",
        Icon: GreatBritain,
        action: changeLanguage("en"),
      },
      {
        abbreviation: "de",
        element: "Deutsch",
        Icon: Germany,
        action: changeLanguage("de"),
      },
    ],
    [changeLanguage]
  );

  const menuItems = [
    {
      id: 0,
      text: t("common:chooseLanguage"),
      subElements: languages,
      icon: <LanguageIcon />,
      permission: Permissions.LOGIN,
    },
    // {
    //   id: 1,
    //   text: t("notifications:notifications"),
    //   action: () => appAction.toggleNotificationsShown(),
    //   icon: (
    //     <Box
    //       sx={{
    //         position: "relative",
    //       }}
    //     >
    //       {appState.isNewMessages && (
    //         <Box
    //           sx={{
    //             width: 10,
    //             height: 10,
    //             position: "absolute",
    //             right: 13,
    //             top: 13,
    //             backgroundColor: "#EC008C",
    //             borderRadius: "100%",
    //           }}
    //         />
    //       )}
    //       <NotificationsIcon />
    //     </Box>
    //   ),
    //   permission: Permissions.LOGIN,
    // },
    {
      id: 2,
      text: t("common:My Profile"),
      action: () => history.push("/my-profile"),
      icon: <PersonIcon />,
      permission: Permissions.USER_PROFILE,
    },
    {
      id: 3,
      text: t("common:logout"),
      action: () => logout(),
      permission: Permissions.LOGIN,
      icon: <ExitToAppIcon />,
      color: "#D73333",
    },
  ];

  return (
    <List sx={{ p: 0 }}>
      {menuItems
        .filter(({ permission }) => user?.hasAllowedPermissions([permission]))
        .map(
          ({ id, text, icon, action, subElements, mobileOnly, color }: any) => (
            <React.Fragment key={id}>
              {subElements ? (
                mobileOnly ? (
                  !isUpLaptop && (
                    <NestedList
                      label={text}
                      sectionIcon={icon}
                      items={subElements}
                    />
                  )
                ) : (
                  <NestedList
                    label={text}
                    sectionIcon={icon}
                    items={subElements}
                  />
                )
              ) : (
                <ListItem
                  button
                  onClick={() => {
                    action();
                    close();
                  }}
                  sx={{
                    borderTop: 1,
                    borderColor: "grey3.main",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: color || "text.secondary",
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    sx={{
                      color: color || "text.secondary",
                      ml: 1,
                    }}
                  />
                </ListItem>
              )}
            </React.Fragment>
          )
        )}
    </List>
  );
};

export default UserMenuContent;
