import React from "react";
import { UiLibraryProvider, theme, CssBaseline } from "@periplus/ui-library";
import { ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import Routes from "./Routes";
import { useTranslation } from "react-i18next";
import { LanguageType } from "i18n";
import { AppProvider } from "App/AppContext";

function App() {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UiLibraryProvider language={language as LanguageType}>
          <Routes />
        </UiLibraryProvider>
      </ThemeProvider>
    </AppProvider>
  );
}

export default Sentry.withProfiler(App);
