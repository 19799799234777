import { useState } from "react";
import { Button } from "@periplus/ui-library";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SettingsTemplatesDialog from "./TemplatesManagerDialog";
import { StatisticPageSettings } from "pages/Statistics/Statistics";
import { useTranslation } from "react-i18next";

interface Props {
  onTemplateApply: (settings: Partial<StatisticPageSettings>) => void;
}

export default ({ onTemplateApply }: Props) => {
  const { t } = useTranslation();

  const [settingsTemplatesDialogOpen, setSettingsTemplatesDialogOpen] =
    useState(false);

  return (
    <>
      <Button
        variant="outlined"
        endIcon={<LibraryBooksIcon />}
        onClick={() => {
          setSettingsTemplatesDialogOpen(true);
        }}
      >
        {t("Templates")}
      </Button>
      {settingsTemplatesDialogOpen && (
        <SettingsTemplatesDialog
          onTemplateApply={(value) => {
            setSettingsTemplatesDialogOpen(false);
            onTemplateApply(value);
          }}
          onClose={() => setSettingsTemplatesDialogOpen(false)}
        />
      )}
    </>
  );
};
