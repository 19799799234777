import { FC, Fragment, HtmlHTMLAttributes } from "react";
import { Form, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, Theme } from "@periplus/ui-library";
import {
  FormDateTimePicker,
  FormAutocomplete,
  Radio,
} from "@periplus/ui-library";
import DeclarationFileField from "./DeclarationFileField";
import CustomsPlace from "./CustomsPlace";
import { AddressType } from "domain/declaration/types";
import FieldLabel from "./FieldLabel";
import {
  DECLARATION_REQUEST_FORM_ADDRESSES_TYPES,
  DeclarationRequestForm,
  DeclarationRequestFormAddress,
  DeclarationRequestFormAddresses,
  DeclarationRequestFormPayer,
} from "./useDeclarationFormik";
import AddressSelect from "./AddressSelect";
import { Group_Members } from "graphql/generated";
import DeclarationFormAutocomplete from "./components/DeclarationFormAutocomplete";
import DeclarationFormTextField from "./components/DeclarationFormTextField";
import { AddressGroupListEntity } from "domain/addressGroup/useGetAddressGroup";
import { useAppState } from "App/AppContext";
import { makeStyles } from "tss-react/mui";

interface DeclarationFormProps extends HtmlHTMLAttributes<HTMLElement> {
  formType: "edit" | "create";
  addressGroups?: AddressGroupListEntity[];
  addressGroupMembers: Group_Members[];
}

const useStyles = makeStyles()((theme: Theme) => ({
  form: {
    display: "grid",
    "& > *:nth-of-type(odd):not(:last-child)": {
      marginBottom: 12,
    },
    "& > *:nth-of-type(even):not(:last-child)": {
      marginBottom: 24,
    },
    [theme.breakpoints.up("sm")]: {
      gap: "24px 48px",
      gridTemplateColumns: "minmax(auto, 205px) minmax(auto, 517px)",
      "& > *:nth-of-type(odd):not(:last-child)": {
        marginBottom: "unset",
      },
      "& > *:nth-of-type(even):not(:last-child)": {
        marginBottom: "unset",
      },
    },
    [theme.breakpoints.up("md")]: {
      gap: "24px 96px",
    },
  },
  purchaseOrderNumberInputAdornedStart: {
    fontSize: 14,
    lineHeight: "18px",
  },
  waNumberInput: {
    maxWidth: 179,
  },
  waNumberInputContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  notAvailableCheckboxContainer: {
    display: "flex",
    alignItems: "center",
  },
  notAvailableCheckboxLabel: {
    marginLeft: 7,
  },
  waNumberInputsDivider: {
    margin: "0px 8px",
  },
  doublInputsContainer: {
    display: "flex",
    gap: 8,
  },
  checkbox: {
    justifySelf: "baseline",
    padding: 0,
  },
}));

const DeclarationForm: FC<DeclarationFormProps> = ({
  formType,
  addressGroups,
  addressGroupMembers,
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation("declaration");
  const {
    edecData: { edec_customs_offices },
  } = useAppState();

  const { values, setFieldValue, setValues } =
    useFormikContext<DeclarationRequestForm>();
  const isDone =
    values.declaration_status === "declaration_exported" ||
    values.declaration_status === "declaration_exported_reference_missing";
  const permissions_js = addressGroupMembers.find(
    (agm) =>
      agm.payer?.id === values.payer.id &&
      agm.clearance_types.some((el) => el === values.clearance_type) &&
      agm.declaration_types.some((el) => el === values.declaration_type)
  )?.permissions_js as Group_Members["permissions_js"];

  const handlePermissionsParamsChange = ({
    payer,
    declaration_type,
    clearance_type,
  }) => {
    const newValues: DeclarationRequestForm = {
      ...values,
      payer,
      declaration_type,
      clearance_type,
      ...DECLARATION_REQUEST_FORM_ADDRESSES_TYPES.reduce((acc, addressType) => {
        acc[addressType] = {
          ...values[addressType],
          address: null,
        };
        return acc;
      }, {} as DeclarationRequestFormAddresses),
    };
    setValues(newValues);
  };

  return (
    <Form className={classes.form}>
      {!isDone && (
        <>
          <FieldLabel>{t("Payer")}</FieldLabel>
          <DeclarationFormAutocomplete
            name="payer"
            options={addressGroupMembers
              .reduce((acc, agm) => {
                if (!acc.some((accEl) => accEl.id === agm.payer?.id))
                  acc.push(agm.payer!);
                return acc;
              }, [] as DeclarationRequestFormPayer[])
              .sort((a, b) => a.company_name.localeCompare(b.company_name))}
            getOptionLabel={(option) => option.company_name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fullWidth
            disableClearable
            onChange={(e, newPayer) => {
              const newBusinessCase = addressGroupMembers.find(
                (agm) => agm.payer?.id === newPayer.id
              );
              handlePermissionsParamsChange({
                payer: newPayer,
                declaration_type: newBusinessCase?.declaration_types[0],
                clearance_type: newBusinessCase?.clearance_types[0],
              });
            }}
          />
        </>
      )}

      {!isDone && (
        <>
          <FieldLabel>{t("Type/Clearance")}</FieldLabel>
          <div className={cx(classes.doublInputsContainer)}>
            <FormAutocomplete
              name="declaration_type"
              options={addressGroupMembers
                .filter((bc) => bc.payer?.id === values.payer.id)
                .reduce((acc, { declaration_types }) => {
                  declaration_types.forEach((dt) => {
                    if (!acc.includes(dt)) acc.push(dt);
                  });
                  return acc;
                }, [] as string[])}
              getOptionLabel={(option) => t(option)}
              onChange={(e, newDeclarationType) => {
                handlePermissionsParamsChange({
                  payer: values.payer,
                  declaration_type: newDeclarationType,
                  clearance_type: values.clearance_type,
                });
              }}
              fullWidth
              InputProps={{
                label: t("Type"),
              }}
              disableClearable
            />
            <FormAutocomplete
              name="clearance_type"
              options={addressGroupMembers
                .filter((bc) => bc.payer?.id === values.payer.id)
                .reduce((acc, { clearance_types }) => {
                  clearance_types.forEach((ct) => {
                    if (!acc.includes(ct)) acc.push(ct);
                  });
                  return acc;
                }, [] as string[])}
              getOptionLabel={(option) => t(option)}
              onChange={(e, newClearanceType) => {
                handlePermissionsParamsChange({
                  payer: values.payer,
                  declaration_type: values.declaration_type,
                  clearance_type: newClearanceType,
                });
              }}
              fullWidth
              InputProps={{
                label: t("Clearance"),
              }}
              disableClearable
            />
          </div>
        </>
      )}
      {permissions_js && !!Object.keys(permissions_js).length && (
        <>
          {!isDone && formType === "create" && permissions_js.docs.visible && (
            <>
              <FieldLabel required={permissions_js.docs.mandatory}>
                {t("documents")}
              </FieldLabel>
              <DeclarationFileField
                name="docs"
                label={t("newDeclaration:downloadAllAvailable")}
                fullWidth
              />
            </>
          )}
          {permissions_js.carrier_ref.visible && (
            <>
              <FieldLabel required={permissions_js.carrier_ref.mandatory}>
                {t("Carrierreference")}
              </FieldLabel>
              <DeclarationFormTextField name="carrier.ref" />
            </>
          )}
          {(!isDone || permissions_js.tr_no.editable_in_archive) &&
            permissions_js.tr_no.visible && (
              <>
                <FieldLabel required={permissions_js.tr_no.mandatory}>
                  {t("Truck and Trailer Plate No.")}
                </FieldLabel>
                <DeclarationFormTextField name="tr_no" />
              </>
            )}
          {(!isDone || permissions_js.ch_exp_decl.editable_in_archive) &&
            permissions_js.ch_exp_decl.visible && (
              <>
                <FieldLabel required={permissions_js.ch_exp_decl.mandatory}>
                  {t("CH-Exportdeclaration")}
                </FieldLabel>
                <Checkbox
                  className={classes.checkbox}
                  checked={!!values.ch_exp_decl}
                  onChange={(event) =>
                    setFieldValue("ch_exp_decl", event.target.checked)
                  }
                />
              </>
            )}
          {(!isDone || permissions_js.imp_cust_clr.editable_in_archive) &&
            permissions_js.imp_cust_clr.visible && (
              <>
                <FieldLabel required={permissions_js.imp_cust_clr.mandatory}>
                  {t("Import customs clearance in the destination Country")}
                </FieldLabel>
                <Checkbox
                  className={classes.checkbox}
                  checked={!!values.imp_cust_clr}
                  onChange={(event) =>
                    setFieldValue("imp_cust_clr", event.target.checked)
                  }
                />
              </>
            )}
          {(!isDone || permissions_js.transitdoc_type.editable_in_archive) &&
            permissions_js.transitdoc_type.visible && (
              <>
                <FieldLabel required={permissions_js.transitdoc_type.mandatory}>
                  {t("Type Transitdocument")}
                </FieldLabel>
                <FormAutocomplete
                  name="transitdoc_type"
                  options={["T1", "T2", "Geleitschein"]}
                />
              </>
            )}
          {DECLARATION_REQUEST_FORM_ADDRESSES_TYPES.filter(
            (addressType) =>
              (!isDone ||
                permissions_js[`${addressType}_addr`]?.editable_in_archive) &&
              permissions_js[`${addressType}_addr`]?.visible
          ).map((addressType) => (
            <Fragment key={addressType}>
              <FieldLabel
                required={permissions_js[`${addressType}_addr`]?.mandatory}
              >
                {t(`addressTypes:${addressType}`)}
              </FieldLabel>
              <AddressSelect
                detailed
                addressType={addressType}
                addresses={addressGroups
                  ?.filter((ag) => ag.payerAddress?.id === values.payer.id)
                  .reduce((acc, ag) => {
                    ag.groupMembers.forEach((gm) => {
                      if (
                        gm.address_type === AddressType[addressType] &&
                        !acc.some((accEl) => accEl.id === gm.address?.id)
                      )
                        acc.push({
                          ...gm.address!,
                        });
                    });
                    return acc;
                  }, [] as DeclarationRequestFormAddress[])
                  .sort((a, b) => a.companyName.localeCompare(b.companyName))}
              />
            </Fragment>
          ))}
          {(!isDone || permissions_js.dest_tolldept_code.editable_in_archive) &&
            permissions_js.dest_tolldept_code.visible && (
              <>
                <FieldLabel
                  required={permissions_js.dest_tolldept_code.mandatory}
                >
                  {t("Destination Tolldept Code")}
                </FieldLabel>
                <DeclarationFormTextField name="dest_tolldept_code" />
              </>
            )}
          {(!isDone || permissions_js.dest_tolldept_name.editable_in_archive) &&
            permissions_js.dest_tolldept_name.visible && (
              <>
                <FieldLabel
                  required={permissions_js.dest_tolldept_name.mandatory}
                >
                  {t("Destination Tolldept Name")}
                </FieldLabel>
                <DeclarationFormTextField name="dest_tolldept_name" />
              </>
            )}
          {permissions_js.supplier_ref.visible && (
            <>
              <FieldLabel required={permissions_js.supplier_ref.mandatory}>
                {t("Exporterreference")}
              </FieldLabel>
              <DeclarationFormTextField name="supplier.ref" />
            </>
          )}
          {permissions_js.importer_ref.visible && (
            <>
              <FieldLabel required={permissions_js.importer_ref.mandatory}>
                {t("Importerreference")}
              </FieldLabel>
              <DeclarationFormTextField name="importer.ref" />
            </>
          )}
          {(!isDone || permissions_js.customsplace.editable_in_archive) &&
            permissions_js.customsplace.visible && (
              <>
                <FieldLabel>{t("Customs place")}</FieldLabel>
                <CustomsPlace />
              </>
            )}
          {(!isDone || permissions_js.toll_dep.editable_in_archive) &&
            permissions_js.toll_dep.visible && (
              <>
                <FieldLabel required={permissions_js.toll_dep.mandatory}>
                  {t("Toll Dept")}
                </FieldLabel>
                <DeclarationFormAutocomplete
                  name="toll_dep"
                  options={edec_customs_offices
                    .filter(
                      (el) =>
                        new Date(el.valid_to).getTime() > new Date().getTime()
                    )
                    .sort((a, b) => a.name!.localeCompare(b.name!))}
                  getOptionLabel={(option) => `${option.name} ${option.number}`}
                  isOptionEqualToValue={(option, value) =>
                    option.number === value.number
                  }
                  fullWidth
                  InputProps={{
                    label: t("Customs office"),
                  }}
                />
              </>
            )}
          {(!isDone || permissions_js.eta.editable_in_archive) &&
            permissions_js.eta.visible && (
              <>
                <FieldLabel required={permissions_js.eta.mandatory}>
                  {t("Estimated arrival the border")}
                </FieldLabel>
                <FormDateTimePicker
                  name="eta"
                  slotProps={{ field: { clearable: true } }}
                />
              </>
            )}
          {(!isDone || permissions_js.is_express.editable_in_archive) &&
            permissions_js.is_express.visible && (
              <>
                <FieldLabel required={permissions_js.is_express.mandatory}>
                  {t("Service")}
                </FieldLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={!values.is_express}
                        onChange={() => setFieldValue("is_express", false)}
                      />
                    }
                    label={t("Standard")}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={values.is_express}
                        onChange={() => setFieldValue("is_express", true)}
                      />
                    }
                    label={t("Express")}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                      },
                    }}
                  />
                </div>
              </>
            )}
          {!isDone &&
            formType === "create" &&
            permissions_js.special_remarks.visible && (
              <>
                <FieldLabel required={permissions_js.special_remarks.mandatory}>
                  {t("Special remarks")}
                </FieldLabel>
                <DeclarationFormTextField
                  name="special_remarks"
                  multiline
                  minRows={4}
                />
              </>
            )}

          {(!isDone || permissions_js.arrival_time?.editable_in_archive) &&
            formType === "edit" && (
              <>
                <FieldLabel>{t("ATA")}</FieldLabel>
                <FormDateTimePicker
                  name="arrival_time"
                  slotProps={{ field: { clearable: true } }}
                />
              </>
            )}
        </>
      )}
    </Form>
  );
};

export default DeclarationForm;
