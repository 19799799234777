import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  BoxProps,
  Checkbox,
  Typography,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  MRTable,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import ConfirmEVVButton from "./ConfirmEVVButton";
import { DefaultFlag } from "domain/documentType/types";
import { useAuth } from "keycloak";
import { Permissions } from "keycloak/context/AuthContext";
import dayjs from "dayjs";
import Actions from "./Actions";
import Pages from "./Pages";
import usePageSettings from "domain/user/usePageSettings";
import { DeclarationDetailsEntity } from "pages/Declaration/hooks/useGetDeclarationDetails";
import { DeclarationDetailsDocumentEntity } from "../hooks/useGetDeclarationDetailsDocuments";
import { TableLocalStorage } from "hooks/usePageLocalStorage";

interface DocumentsTabProps extends BoxProps {
  declaration: DeclarationDetailsEntity;
  documents: DeclarationDetailsDocumentEntity[];
  onEVVConfirmedChange: () => Promise<any>;
  onClassificated: () => Promise<any>;
  onDeleted: () => Promise<any>;
}

export interface TransformedDocument extends DeclarationDetailsDocumentEntity {
  customRelevant: boolean;
}

const DocumentsTab: FC<DocumentsTabProps> = ({
  declaration,
  documents,
  onEVVConfirmedChange,
  onClassificated,
  onDeleted,
}) => {
  const { user } = useAuth();
  const showZollBordereau = !!user?.hasAllowedPermissions([
    Permissions.ZOLL_BORDEREAU,
  ]);
  const relevantDocuments = useMemo(() => {
    const result: TransformedDocument[] = [];
    const customsDocuments = documents
      .filter(
        (document) =>
          DefaultFlag.EdecRelevant ===
            (user!.tenant!.tenantDocumentTypes.find(
              (tenantDocType) =>
                tenantDocType.document_type === document.document_type
            )?.default_flags &
              DefaultFlag.EdecRelevant) &&
          !document.is_deleted &&
          ((document.document_type.startsWith("bordereau_response") &&
            showZollBordereau) ||
            !document.document_type.startsWith("bordereau_response"))
      )
      .map((el) => ({ ...el, customRelevant: true }));
    result.push(...customsDocuments);

    const myDocuments = documents.filter(
      (document) =>
        !(
          DefaultFlag.EdecRelevant ===
          (user!.tenant!.tenantDocumentTypes.find(
            (tenantDocType) =>
              tenantDocType.document_type === document.document_type
          )?.default_flags &
            DefaultFlag.EdecRelevant)
        )
    );
    const classifiedMyDocuments = myDocuments
      .filter(
        (document) =>
          document.document_type !== "unknown" &&
          !document.is_deleted &&
          (DefaultFlag.custom_relevant ===
            (document.document_flags & DefaultFlag.custom_relevant) ||
            DefaultFlag.wedeclare_relevant ===
              (document.document_flags & DefaultFlag.wedeclare_relevant))
      )
      .sort(
        (a, b) =>
          a.document_version - b.document_version || a.sequence! - b.sequence!
      );
    const notClassifiedMyDocuments = myDocuments.filter(
      (document) =>
        document.document_type === "unknown" &&
        !document.is_deleted &&
        (document.pages_m2m?.some(
          ({ page }) => page?.pageStatus !== "classified"
        ) ||
          document.document_extension === "xml")
    );
    result.push(
      ...[...classifiedMyDocuments, ...notClassifiedMyDocuments].map((el) => ({
        ...el,
        customRelevant: false,
      }))
    );

    return result;
  }, [showZollBordereau, documents, user]);

  const { t, i18n } = useTranslation("declaration");
  const { pageSettings, setPageSettingsForMRTable } = usePageSettings<
    TableLocalStorage<TransformedDocument>
  >({ itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0] });

  const showCustomsDocs = !!user?.hasAllowedPermissions([
    Permissions.DASH_EXC_CUST,
  ]);
  const [filters, setFilters] = useState<{
    customs: boolean;
    myDocuments: boolean;
  }>({
    customs: showCustomsDocs,
    myDocuments: true,
  });
  const [columnFilters, setColumnFilters] =
    useState<MRTable.MRT_ColumnFiltersState>([]);

  const filteredDocuments = useMemo(() => {
    const result: TransformedDocument[] = [];
    if (filters.customs) {
      result.push(...relevantDocuments.filter((el) => el.customRelevant));
    }
    if (filters.myDocuments) {
      result.push(...relevantDocuments.filter((el) => !el.customRelevant));
    }
    const documentVersionFilter = columnFilters.find(
      (el) => el.id === "documentVersion"
    );
    if (documentVersionFilter) {
      return result.filter((el) =>
        (documentVersionFilter.value as string[]).includes(
          el.document_version.toString()
        )
      );
    }

    const maxVersionsByDocTypes = result.reduce<Record<string, number>>(
      (acc, el) => {
        if (!acc[el.document_type]) {
          acc[el.document_type] = el.document_version;
        } else if (acc[el.document_type] < el.document_version) {
          acc[el.document_type] = el.document_version;
        }

        return acc;
      },
      {}
    );

    return result.filter(
      (el) => el.document_version === maxVersionsByDocTypes[el.document_type]
    );
  }, [relevantDocuments, filters, columnFilters]);

  const [columns, tableData] = React.useMemo<
    [MRTable.MRT_ColumnDef<TransformedDocument>[], TransformedDocument[]]
  >(
    () => [
      [
        {
          header: t("Version"),
          id: "documentVersion",
          accessorFn: (originalRow) => originalRow.document_version.toString(),
          filterVariant: "multi-select",
          filterSelectOptions: [
            ...new Set(
              relevantDocuments.map((el) => el.document_version.toString())
            ),
          ],
          muiTableBodyCellProps: {
            sx: {
              justifyContent: "center",
            },
          },
        },
        {
          header: t("Preview"),
          id: "pages",
          Cell: ({ row: { original: originalRow } }) =>
            !!originalRow.pages_m2m.length && <Pages document={originalRow} />,
        },
        {
          header: t("Name"),
          id: "documentName",
          size: 450,
          accessorFn: (originalRow) => originalRow.document_name,
          Cell: ({ row: { original: originalRow } }) => {
            const numberOfPages = originalRow.pages_m2m.filter(
              ({ page }) =>
                originalRow.documentType.name !== "unknown" ||
                (originalRow.documentType.name === "unknown" &&
                  page?.pageStatus !== "classified")
            ).length;
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  noWrap
                  tooltip
                  sx={{ maxWidth: "50ch" }}
                >
                  {originalRow.document_name}
                </Typography>
                {!!numberOfPages && (
                  <Typography variant="body2" color="textSecondary">
                    {numberOfPages}{" "}
                    {t(`${numberOfPages > 1 ? "Pages" : "Page"}`)}
                  </Typography>
                )}
              </Box>
            );
          },
        },
        {
          header: t("Class"),
          id: "documentType",
          accessorFn: (originalRow) =>
            originalRow.document_type !== "unknown"
              ? originalRow.documentType.translations[i18n.language]
              : "",
          Cell: ({ row: { original: originalRow } }) => {
            if (originalRow.document_type === "unknown") {
              return " ";
            }

            const color =
              user!.tenant!.tenantDocumentTypes.find(
                (tenantDocType) =>
                  tenantDocType.document_type === originalRow.document_type
              )?.meta?.color || "grey";
            return (
              <Box
                sx={{
                  display: "inline-flex",
                  border: 1,
                  borderRadius: "4px",
                  borderColor: color,
                  position: "relative",
                  "&:before": {
                    content: '""',
                    position: "absolute",
                    left: -1,
                    top: 0,
                    width: 16,
                    height: "100%",
                    backgroundColor: color,
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  },
                }}
              >
                <Typography
                  variant="overline"
                  sx={{
                    ml: 2,
                    py: 0.5,
                    px: 1,
                  }}
                >
                  {`${originalRow.documentType.translations[i18n.language]}` +
                    `${
                      originalRow.sequence && originalRow.sequence > 1
                        ? ` ${originalRow.sequence}`
                        : ""
                    }`}
                </Typography>
              </Box>
            );
          },
        },
        {
          header: t("Updated"),
          id: "lastModifiedDate",
          accessorFn: (originalRow) =>
            originalRow.last_modified_date &&
            dayjs(originalRow.last_modified_date).format("DD.MM.YYYY hh:mm"),
        },
        {
          header: t("Format"),
          id: "documentExtension",
          accessorFn: (originalRow) => originalRow.document_extension,
          muiTableBodyCellProps: {
            sx: {
              textTransform: "uppercase",
            },
          },
        },
      ],
      [...filteredDocuments],
    ],
    [t, user, relevantDocuments, i18n.language, filteredDocuments]
  );

  const [pagination, setPagination] = useState<MRTable.MRT_PaginationState>({
    pageIndex: 0,
    pageSize: pageSettings.itemsPerPage,
  });
  const [density, setDensity] = useState<MRTable.MRT_DensityState>(
    pageSettings.density ?? "compact"
  );
  const [columnSizing, setColumnSizing] =
    useState<MRTable.MRT_ColumnSizingState>(pageSettings.columnSizing ?? {});
  const [columnPinning, setColumnPinning] =
    useState<MRTable.MRT_ColumnPinningState>(pageSettings.columnPinning ?? {});
  const [columnVisibility, setColumnVisibility] =
    useState<MRTable.MRT_VisibilityState>(pageSettings.columnVisibility ?? {});
  const [columnOrder, setColumnOrder] = useState<MRTable.MRT_ColumnOrderState>(
    pageSettings.columnOrder ?? columns.map((c) => c.id!)
  );
  const [showColumnFilters, setShowColumnFilters] = useState<boolean>(
    pageSettings.showColumnFilters ?? false
  );

  useEffect(
    () => {
      setPageSettingsForMRTable({
        itemsPerPage: pagination.pageSize,
        density,
        columnSizing,
        columnPinning,
        columnVisibility,
        columnOrder,
        showColumnFilters,
      });
    },
    // eslint-disable-next-line
    [
      pagination.pageSize,
      density,
      columnSizing,
      columnPinning,
      columnVisibility,
      columnOrder,
      showColumnFilters,
    ]
  );

  return (
    <MRTable.Table
      columns={columns}
      data={tableData}
      state={{
        pagination,
        density,
        columnSizing,
        columnPinning,
        columnVisibility,
        columnOrder,
        showColumnFilters,
        columnFilters,
      }}
      renderTopToolbarCustomActions={() => (
        <Box sx={{ display: "flex", gap: 1, alignSelf: "center" }}>
          {showCustomsDocs && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={filters.customs}
                onChange={(e, checked) => {
                  setFilters({ ...filters, customs: checked });
                  setPagination({ ...pagination, pageIndex: 0 });
                }}
              />
              <Typography sx={{ ml: 1 }}>{t("Customs")}</Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={filters.myDocuments}
              onChange={(e, checked) => {
                setFilters({ ...filters, myDocuments: checked });
                setPagination({ ...pagination, pageIndex: 0 });
              }}
            />
            <Typography
              sx={{
                ml: 1,
              }}
            >
              {t("My Documents")}
            </Typography>
          </Box>
        </Box>
      )}
      enableRowActions
      enableColumnFilters
      onPaginationChange={setPagination}
      onDensityChange={setDensity}
      onColumnSizingChange={setColumnSizing}
      onColumnPinningChange={setColumnPinning}
      onColumnVisibilityChange={setColumnVisibility}
      onColumnOrderChange={setColumnOrder}
      onShowColumnFiltersChange={setShowColumnFilters}
      onColumnFiltersChange={setColumnFilters}
      renderRowActions={({ row: { original: originalRow } }) => (
        <Actions
          document={originalRow}
          file_id={declaration.fileId}
          declarationStatus={declaration.declarationStatus}
          onDeleted={async () => {
            await onDeleted();
          }}
          onClassificated={onClassificated}
        />
      )}
      muiTableContainerProps={{
        sx: {
          maxHeight: "calc(100vh - var(--appbar-height) - 145px)",
        },
      }}
      muiTablePaperProps={({ table }) => ({
        sx: (theme) => ({
          minWidth: 0,
        }),
      })}
      muiTableHeadCellProps={{
        sx: {
          "& .Mui-TableHeadCell-Content-Wrapper": {
            whiteSpace: "nowrap",
          },
        },
      }}
      renderToolbarInternalActions={({ table }) => {
        return (
          <>
            <MRTable.MRT_ToggleFiltersButton table={table} />
            <MRTable.MRT_ShowHideColumnsButton table={table} />
            <MRTable.MRT_ToggleFullScreenButton table={table} />
            <ConfirmEVVButton
              declaration={declaration}
              onEVVConfirmedChange={onEVVConfirmedChange}
            />
          </>
        );
      }}
    />
  );
};

export default DocumentsTab;
