import { useMemo } from "react";
import gql from "graphql-tag";
import {
  DECLARATION_FILTER_RELATIVE_DATES,
  DeclarationFilters,
} from "domain/declaration/components/DeclarationFiltersSidebar";
import { TableUrlSearchParams } from "hooks/useUrlSearchParams";
import {
  AddressFilter,
  WdGetDeclarationsGoodsItemsStatisticsQuery,
  WdGetDeclarationsGoodsItemsStatisticsQueryVariables,
} from "graphql/generated";
import { NotNullable } from "utils/utilityTypes";
import { useQuery } from "@apollo/client";

type DeclarationGoodsItemStatisticOriginalEntity = NotNullable<
  NonNullable<
    NonNullable<
      WdGetDeclarationsGoodsItemsStatisticsQuery["acs_goods_items_for_drs"]
    >[number]
  >,
  "declarationLabels"
>;
export type DeclarationGoodsItemStatisticEntity = Omit<
  DeclarationGoodsItemStatisticOriginalEntity,
  "declarationLabels"
> & {
  declarationLabels: NonNullable<
    DeclarationGoodsItemStatisticOriginalEntity["declarationLabels"][number]
  >[];
};

interface Variables extends TableUrlSearchParams<DeclarationFilters> {}

const QUERY = gql`
  query WDGetDeclarationsGoodsItemsStatistics(
    $limit: Int
    $offset: Int
    $min_date: LocalDateTime
    $max_date: LocalDateTime
    $search: String
    $search_field: String
    $declaration_statuses: String
    $addresses: [AddressFilter!]
    $declaration_type: String
    $clearance_type: String
    $arrival_type: String
    $orderby: [JSONObject!]
    $payer: UUID
    $evv_status: String
    $declaration_labels: String
  ) {
    acs_goods_items_for_drs(
      args: {
        _limit: $limit
        _offset: $offset
        _date_min: $min_date
        _date_max: $max_date
        _search: $search
        _search_field: $search_field
        _declaration_statuses: $declaration_statuses
        _orderby: $orderby
        _addresses: $addresses
        _decl_type: $declaration_type
        _clr_type: $clearance_type
        _arrival_type: $arrival_type
        _payer: $payer
        _evv_status: $evv_status
        _declaration_labels: $declaration_labels
      }
    ) {
      refs
      fileId
      drNo
      myDuties
      identification
      customsItemNumber
      declarationStatus
      declarationLabels {
        id
      }
      addresses {
        addressTypeId
        country
        companyName
        city
        street
        vatNumber
        zipcode
      }
      declarationSpecialMentions {
        text
      }
      previousDocuments {
        id
        previousDocumentType
        previousDocumentReference
      }
      customsOffice {
        number
        name
      }
      declaration {
        customsDeclarationNumber
        customsDeclarationVersion
        incoterms
        invoiceCurrencyType
        declarationType
        dutyTotalAmount
        requestorTraderIdentificationNumber
        traderDeclarationNumber
        traderReference
        totalGoodsItems
        vatNumber
        vatSuffix
        placeOfUnloading
        dispatchCountry
        packagingQuantity
        grossMass
        totalVatValue
        customsAccountNumber
        customsAccountName
        vatAccountNumber
        vatAccountName
        transportMode
        transportType
        transportCountry
        transportNumber
        dutyAndOtherTaxesAmount
        status
        serviceType
        release
        statusDate
        statusTime
        materialCheck
        vatTotalAmount
      }
      userPp {
        firstName
        email
      }
      declarationRequest {
        fileId
        drNo
        creationDate
        eta
        arrivalTime
        meta
        creationUser
        acceptanceDate
        declarationType
        arrivalType
        clearanceType
      }
      producedDocuments {
        id
        producedDocumentType
        producedDocumentReferenceNumber
        producedDocumentIssueDate
      }
      permits {
        id
        permitType
        permitAuthority
        permitNumber
      }
      packagings {
        id
        packagingType
        packagingQuantity
        packagingReferenceNumber
      }
      goodsItemSpecialMentions {
        text
      }
      goodsItems {
        netMass
        grossMass
        description
        commodityCode
        fileId
        vatValue
        originCountry
        drNo
        dutyTotal
        customsItemNumber
        commercialGood
        clearanceType
        originPreference
        repair
        repairReason
        additionalUnit
        statisticalCode
        statisticalValue
        vatRate
        vatTotal
        dutyRate
        permitObligationCode
        selectionResult
        dutyAndOtherTaxesTotal
      }
    }
  }
`;

const useGetDeclarationsGoodsItemsStatistics = ({
  page,
  itemsPerPage,
  sorting,
  search,
  search_field,
  filters: {
    consignor,
    consignee,
    importer,
    freight_forwarder,
    supplier,
    declaration_statuses,
    evv_status,
    declaration_labels,
    relative_date,
    min_date,
    max_date,
    declaration_type,
    clearance_type,
    arrival_type,
    payer,
  } = {},
}: Variables) => {
  const filter: WdGetDeclarationsGoodsItemsStatisticsQueryVariables = {
    limit: itemsPerPage,
    offset: (page - 1) * itemsPerPage,
    orderby: sorting?.length ? sorting : undefined,
    declaration_statuses: declaration_statuses
      ? `{${declaration_statuses?.join(",")}}`
      : undefined,
    search: (() => {
      if (!search) {
        return undefined;
      }
      if (search_field) {
        return search;
      }

      return `%${search}%`;
    })(),
    addresses: (() => {
      const addresses = [
        { addressType: "consignor", addressIds: consignor },
        { addressType: "consignee", addressIds: consignee },
        { addressType: "importer", addressIds: importer },
        {
          addressType: "freight_forwarder",
          addressIds: freight_forwarder,
        },
        { addressType: "supplier", addressIds: supplier },
      ].filter((el) => el.addressIds?.length) as AddressFilter[];
      return addresses.length ? addresses : undefined;
    })(),
    evv_status: evv_status || undefined,
    search_field: search_field || undefined,
    ...(!!declaration_labels?.length && {
      declaration_labels: `{${declaration_labels.join(",")}}`,
    }),
    min_date: relative_date
      ? DECLARATION_FILTER_RELATIVE_DATES[relative_date].min_date
      : min_date,
    max_date: relative_date
      ? DECLARATION_FILTER_RELATIVE_DATES[relative_date].max_date
      : max_date,
    declaration_type,
    clearance_type,
    arrival_type,
    payer,
  };

  const result = useQuery<
    { acs_goods_items_for_drs: DeclarationGoodsItemStatisticEntity[] },
    WdGetDeclarationsGoodsItemsStatisticsQueryVariables
  >(QUERY, {
    fetchPolicy: "network-only",
    variables: filter,
    skip: [filter.min_date, filter.max_date].some(
      (el) => el === "Invalid Date"
    ),
  });

  return useMemo(
    () => ({
      ...result,
      data: {
        declarations_goods_items_statistics:
          (result.data ?? result.previousData)?.acs_goods_items_for_drs ?? [],
      },
    }),
    [result]
  );
};

export default useGetDeclarationsGoodsItemsStatistics;
