import gql from "graphql-tag";
import { WdAssignDeclarationLabelsMutationVariables } from "graphql/generated";
import { useMutation } from "@apollo/client";

const useAssignDeclarationLabels = () => {
  const [muttation] = useMutation<
    void,
    WdAssignDeclarationLabelsMutationVariables
  >(
    gql`
      mutation WdAssignDeclarationLabels(
        $drNo: Int!
        $fileId: UUID!
        $labelIds: [Int!]!
      ) {
        assignDeclarationLabels(
          labels: { drNo: $drNo, fileId: $fileId, labelIds: $labelIds }
        ) {
          fileId
          drNo
          labelId
        }
      }
    `
  );

  return muttation;
};

export default useAssignDeclarationLabels;
