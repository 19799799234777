import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Document } from "./types";
import client from "graphql/client";

const WD_GET_DOCUMENTS = gql`
  query WD_GET_DOCUMENTS($file_id: uuid) {
    documents: document(
      where: { is_deleted: { _eq: false }, file: { _eq: $file_id } }
    ) {
      id
      document_type
      creation_date
      document_blobs(path: "blobs")
      document_extension
      documentType {
        translations
      }
    }
  }
`;

const useGetDocuments = ({ file_id }: { file_id: string }) => {
  const { data, ...rest } = useQuery<{
    document: Document[];
  }>(WD_GET_DOCUMENTS, {
    variables: {
      file_id,
    },
    fetchPolicy: "no-cache",
  });

  return {
    documents: data?.document || [],
    ...rest,
  };
};

export default useGetDocuments;

export const useGetLazyDocuments = () => {
  const result = ({ file_id }: { file_id: string }) =>
    client.query<{
      documents: Document[];
    }>({
      query: WD_GET_DOCUMENTS,
      variables: {
        file_id,
      },
      fetchPolicy: "no-cache",
    });

  return result;
};
