import gql from "graphql-tag";
import useErrorHandling from "hooks/useErrorHandling";

import {
  Address_Insert_Input,
  useWdInsertAddressMutation,
} from "graphql/generated";

gql`
  mutation WdInsertAddress($objects: [address_insert_input!]!) {
    insert_address(objects: $objects) {
      affected_rows
    }
  }
`;

const useInsertAddress = () => {
  const withErrorHandling = useErrorHandling({ propagate: true });
  const [insertAddress] = useWdInsertAddressMutation();

  const handleInsert = (objects: Address_Insert_Input) => {
    return withErrorHandling(insertAddress, {
      variables: { objects },
    });
  };

  return handleInsert;
};

export default useInsertAddress;
