import React, { FC, useState } from "react";
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { Button } from "@periplus/ui-library";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { DeclarationDetailsEntity } from "pages/Declaration/hooks/useGetDeclarationDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useUpdateDeclarationEVVConfirmation from "../../../domain/declaration/useUpdateDeclarationEVVConfirmation";
import { makeStyles } from 'tss-react/mui';

interface ConfirmEVVButtonProps {
  declaration: DeclarationDetailsEntity;
  onEVVConfirmedChange: () => Promise<any>;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
}));

const ConfirmEVVButton: FC<ConfirmEVVButtonProps> = ({
  declaration,
  onEVVConfirmedChange,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation("declaration");
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const [updateEVVConfirmation] = useUpdateDeclarationEVVConfirmation();

  if (!declaration.declaration.evvReceived) return null;

  return (
    <>
      <ButtonGroup variant="contained" aria-label="split button">
        <Button
          className={classes.root}
          variant="contained"
          color="primary"
          onClick={async () => {
            setIsLoading(true);
            await updateEVVConfirmation({
              variables: {
                file_id: declaration.fileId,
                dr_no: declaration.drNo!,
                evv_confirmed: true,
              },
            });
            await onEVVConfirmedChange();
            setIsLoading(false);
          }}
          minimizeForMobiles
          endIcon={
            declaration.declarationRequest.meta.evvConfirmed ? (
              <DraftsOutlinedIcon />
            ) : (
              <MailOutlineOutlinedIcon />
            )
          }
          loading={isLoading}
          disabled={declaration.declarationRequest.meta.evvConfirmed}
        >
          {t("Confirm EVVs")}
        </Button>
        <Button
          onClick={handleToggle}
          ref={anchorRef}
          color="primary"
          variant="contained"
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 2,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  <MenuItem
                    disabled={!declaration.declarationRequest.meta.evvConfirmed}
                    onClick={async () => {
                      setOpen(false);
                      setIsLoading(true);
                      await updateEVVConfirmation({
                        variables: {
                          file_id: declaration.fileId,
                          dr_no: declaration.drNo!,
                          evv_confirmed: false,
                        },
                      });
                      await onEVVConfirmedChange();
                      setIsLoading(false);
                    }}
                  >
                    {t("Revoke Confirmation")}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ConfirmEVVButton;
