import React, { FC, HtmlHTMLAttributes } from "react";
import {
  Box,
  ToggleButtonGroup,
  Search,
  ColumnsCustomizationMenu,
  Columns,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { DeclarationDashboardEntity } from "./hooks/useGetDeclarationsDashboard";

interface ActionBarProps extends HtmlHTMLAttributes<HTMLElement> {
  view: "cards" | "table";
  onViewChange: (value: "cards" | "table") => void;
  search?: string;
  onSearchChange: (value?: string) => void;
  columns?: Columns<DeclarationDashboardEntity>;
  onColumnsSettingsChange?: (
    value: Columns<DeclarationDashboardEntity>
  ) => void;
}

const ActionBar: FC<ActionBarProps> = ({
  view,
  onViewChange,
  search,
  onSearchChange,
  columns,
  onColumnsSettingsChange,
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <ToggleButtonGroup
        exclusive
        value={view}
        onChange={(e, newView) => {
          newView && onViewChange(newView);
        }}
        options={[
          {
            label: t("Overview"),
            value: "cards",
          },
          {
            label: t("Table"),
            value: "table",
          },
        ]}
      />
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        {view === "table" && (
          <ColumnsCustomizationMenu
            columns={columns!}
            onChange={onColumnsSettingsChange!}
          />
        )}
        <Search value={search} onChange={onSearchChange} />
      </Box>
    </Box>
  );
};

export default ActionBar;
