import { FormAutocomplete } from "@periplus/ui-library";
import useAddressAutocomplete, {
  AddressAutocompleteBaseProps,
} from "./useAddressAutocomplete";

interface AddressFormAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends Omit<
    AddressAutocompleteBaseProps<Multiple, DisableClearable>,
    "value" | "onChange"
  > {
  name: string;
}

export default function <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
>({ name, ...rest }: AddressFormAutocompleteProps<Multiple, DisableClearable>) {
  const props = useAddressAutocomplete<Multiple, DisableClearable>(rest);

  return <FormAutocomplete name={name} {...props} />;
}
