import React, { useMemo, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Grid, Theme, Typography } from "@periplus/ui-library";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { useTranslation } from "react-i18next";
import Countries from "i18n-iso-countries";
import { Dialog, FormAutocomplete, FormTextField } from "@periplus/ui-library";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import { useInsertAddress } from "domain/address";
import { useEditAddress } from "domain/address/";
import { useAppState } from "App/AppContext";
import { makeStyles } from "tss-react/mui";
import { AddressManagementEntity } from "../GeneralTab/hooks/useGetAddressesManagement";

Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const useStyles = makeStyles()((theme: Theme) => ({
  contentContainer: {
    padding: `0 ${theme.spacing(3.25)} ${theme.spacing(1.5)}`,
  },
  groupTitle: {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)",
    padding: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
  },
  checkboxText: {
    display: "flex",
    gap: theme.spacing(),
  },
}));

type AddressDialogProps = {
  tenantId: string | undefined;
  address?: AddressManagementEntity | null;
  onClose?: () => void;
  onConfirm?: () => void;
};

enum AddressFields {
  Company = "company_name",
  Street = "street",
  Zip = "zipcode",
  City = "city",
  Country = "country",
  Reference = "reference",
}

const defaultAddress = {
  [AddressFields.Company]: "",
  [AddressFields.Street]: "",
  [AddressFields.Zip]: "",
  [AddressFields.City]: "",
  [AddressFields.Country]: "",
  [AddressFields.Reference]: "",
};

type FieldsGroupProps = {
  title: string;
  children: React.ReactNode | React.ReactNode[];
};

const FieldsGroup = ({ title, children }: FieldsGroupProps) => {
  const { classes } = useStyles();
  return (
    <Grid item container direction="column">
      <Typography className={classes.groupTitle}>{title}</Typography>
      <Grid item container spacing={2}>
        {children}
      </Grid>
    </Grid>
  );
};

const AddressDialog = ({
  address,
  tenantId,
  onClose = () => {},
  onConfirm = () => {},
}: AddressDialogProps) => {
  const { classes } = useStyles();
  const {
    edecData: { edec_countries },
  } = useAppState();

  const isMountedRef = useRef<boolean | null>(null);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const { editAddress } = useEditAddress();
  const createAddress = useInsertAddress();

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    company_name: address?.companyName ?? defaultAddress.company_name,
    street: address?.street ?? defaultAddress.street,
    zipcode: address?.zipcode ?? defaultAddress.zipcode,
    city: address?.city ?? defaultAddress.city,
    country: address?.country ?? defaultAddress.country,
    reference: address?.reference ?? defaultAddress.reference,
  };

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [AddressFields.Company]: Yup.string()
        .trim()
        .nullable()
        .required(t("validation:isRequired")),
      [AddressFields.Street]: Yup.string()
        .trim()
        .nullable()
        .required(t("validation:isRequired")),
      [AddressFields.Zip]: Yup.string()
        .trim()
        .nullable()
        .required(t("validation:isRequired")),
      [AddressFields.City]: Yup.string()
        .trim()
        .nullable()
        .required(t("validation:isRequired")),
      [AddressFields.Country]: Yup.string()
        .trim()
        .nullable()
        .required(t("validation:isRequired")),
    });
  }, [t]);

  // const handleFormikSubmit = async (
  //   values: typeof initialValues,
  //   { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  // ) => {
  //   try {
  //     let message = "";
  //     if (address) {
  //       const additionalParams = {
  //         tenantId: tenantId,
  //         source: address.source,
  //       };
  //       await editAddress(address.id, { ...values }, additionalParams);
  //       message = t("common:addressUpdated");
  //     } else {
  //       await createAddress(values);
  //       message = t("common:addressAdded");
  //     }

  //     enqueueSnackbar(message, { variant: "success" });
  //     if (isMountedRef.current) {
  //       onConfirm();
  //     }
  //   } catch (_) {}

  //   if (isMountedRef.current) {
  //     setSubmitting(false);
  //   }
  // };

  const handleFormikSubmit = async (
    values: typeof initialValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      let message = "";
      if (address) {
        const additionalParams = {
          tenantId: tenantId,
          source: address.source,
        };
        await editAddress(
          address.id,
          address.ao_id,
          { ...values },
          additionalParams
        );
        message = t("common:addressUpdated");
      } else {
        await createAddress(values);
        message = t("common:addressAdded");
      }

      enqueueSnackbar(message, { variant: "success" });
      setSubmitting(false);
      onConfirm();
    } catch (_) {
      enqueueSnackbar(t("common:addressError"), { variant: "error" });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Dialog
          fullWidth
          maxWidth="xs"
          mainTitle={t("common:company")}
          subTitle={t("common:companyInformation")}
          TitleIcon={PlaylistAddCheckIcon}
          onClose={onClose}
          onConfirm={props.submitForm}
          CancelButtonProps={{ label: t("common:abort") }}
          ConfirmButtonProps={{
            label: t("common:save"),
            disabled: props.isSubmitting || !props.dirty || !props.isValid,
          }}
        >
          <form onSubmit={props.handleSubmit}>
            <Grid
              container
              direction="column"
              spacing={3}
              className={classes.contentContainer}
            >
              <FieldsGroup title={t("common:company")}>
                <Grid item container xs={12}>
                  <FormTextField
                    required
                    name={AddressFields.Company}
                    variant="outlined"
                    fullWidth
                    label={t("common:name")}
                  />
                </Grid>
                <Grid item container xs={12}>
                  <FormTextField
                    name={AddressFields.Reference}
                    variant="outlined"
                    fullWidth
                    label={t("common:reference")}
                    disabled
                  />
                </Grid>
              </FieldsGroup>
              <FieldsGroup title={t("common:address")}>
                <Grid item xs={12}>
                  <FormTextField
                    required
                    name={AddressFields.Street}
                    variant="outlined"
                    fullWidth
                    label={t("common:street")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    required
                    name={AddressFields.Zip}
                    variant="outlined"
                    fullWidth
                    label={t("common:zip")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    required
                    name={AddressFields.City}
                    variant="outlined"
                    fullWidth
                    label={t("common:city")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormAutocomplete
                    name={AddressFields.Country}
                    options={edec_countries.map<string>((ec) => ec.iso_code)}
                    getOptionLabel={(option) =>
                      edec_countries.find((ec) => ec.iso_code === option)?.[
                        `name_${language}`
                      ] ?? option
                    }
                    InputProps={{
                      variant: "outlined",
                      label: t("common:location"),
                    }}
                  />
                </Grid>
              </FieldsGroup>
            </Grid>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default AddressDialog;
