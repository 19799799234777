import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const LINK_ADDRESSES_MUTATION = gql`
  mutation LinkAddresses($input: LinkAddressesInputACS!) {
    linkAddressesACS(input: $input) {
      address_id
      address_override_id
    }
  }
`;

const useLinkAddresses = () => {
  const [linkAddressesMutation, { data, loading, error }] = useMutation(
    LINK_ADDRESSES_MUTATION
  );

  const linkAddresses = async (input) => {
    await linkAddressesMutation({
      variables: {
        input,
      },
    });
  };

  return { linkAddresses, data, loading, error };
};

export default useLinkAddresses;
