import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useMemo } from "react";
import { ImportListTrigger } from "./types";

const WD_GET_IMPORT_LIST_TRIGGERS = gql`
  query WD_GET_IMPORT_LIST_TRIGGERS($limit: Int, $offset: Int) {
    import_list_trigger: import_list_trigger(
      limit: $limit
      offset: $offset
      order_by: { creation_date: desc_nulls_first }
    ) {
      id
      display_name
      type
      config
      creation_date
      creation_user
      last_modified_date
      last_modified_user
      creationUser {
        display_name
      }
      lastModifiedUser {
        display_name
      }
      meta
      label_id
      label {
        id
        title
        description
        color
        is_deleted
      }
    }
    import_list_trigger_aggregate {
      aggregate {
        totalCount: count
      }
    }
  }
`;

const useGetImportListTriggers = (variables: {
  limit?: number;
  offset?: number;
}) => {
  const { data, ...rest } = useQuery<{
    import_list_trigger: ImportListTrigger[];
    import_list_trigger_aggregate: any;
  }>(WD_GET_IMPORT_LIST_TRIGGERS, {
    variables,
    fetchPolicy: "no-cache",
  });

  const { import_list_triggers, totalCount } = useMemo(() => {
    const import_list_triggers = data?.import_list_trigger || [];
    const totalCount = data?.import_list_trigger_aggregate.aggregate.totalCount;
    return {
      import_list_triggers,
      totalCount,
    };
  }, [data]);

  return {
    import_list_triggers,
    totalCount,
    ...rest,
  };
};

export default useGetImportListTriggers;
