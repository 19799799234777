import gql from "graphql-tag";
import { useAuth } from "keycloak";
import client from "graphql/client";
import {
  DECLARATION_FILTER_RELATIVE_DATES,
  DeclarationFilters,
} from "./components/DeclarationFiltersSidebar";
import * as filesUtils from "utils/files";
import { getDownloadUrl } from "utils/azureBlobStorageUtils";
import dayjs from "dayjs";
import { useCallback } from "react";

type Variables = {
  filters?: DeclarationFilters;
  search?: string;
  search_field?: string;
  columns?: string[];
  total_items: number;
};

const useDownloadDeclarationGoodItemsStatistics = () => {
  const { user } = useAuth();

  return useCallback(
    async ({
      filters: {
        consignor,
        consignee,
        importer,
        supplier,
        freight_forwarder,
        declaration_statuses,
        declaration_labels,
        relative_date,
        min_date,
        max_date,
        ...restFilters
      } = {},
      ...restVariables
    }: Variables) => {
      const result = await client.query<{
        getDeclarationGoodItemsStatistics: {
          url: string;
          md5: string;
        };
      }>({
        query: gql`
          query WDDownloadGoodItemsStatistics(
            $addresses_qi: String
            $arrival_type: String
            $clearance_type: String
            $declaration_statuses: String
            $declaration_type: String
            $max_date: timestamptz
            $min_date: timestamptz
            $payer: ID
            $tenantId: ID!
            $evv_status: String
            $search: String
            $search_field: String
            $columns: [String]
            $total_items: Int
            $declaration_labels: String
          ) {
            getDeclarationGoodItemsStatistics(
              tenantId: $tenantId
              addresses_qi: $addresses_qi
              arrival_type: $arrival_type
              clearance_type: $clearance_type
              declaration_statuses: $declaration_statuses
              declaration_type: $declaration_type
              max_date: $max_date
              min_date: $min_date
              payer: $payer
              evv_status: $evv_status
              search: $search
              search_field: $search_field
              columns: $columns
              total_items: $total_items
              declaration_labels: $declaration_labels
            )
          }
        `,
        variables: {
          tenantId: user?.tenant_id,
          declaration_statuses: declaration_statuses
            ? `{${declaration_statuses?.join(",")}}`
            : undefined,
          addresses_qi:
            [
              ...(consignor?.length ? [`(${consignor.join(" | ")})`] : []),
              ...(consignee?.length ? [`(${consignee.join(" | ")})`] : []),
              ...(importer?.length ? [`(${importer.join(" | ")})`] : []),
              ...(freight_forwarder?.length
                ? [`(${freight_forwarder.join(" | ")})`]
                : []),
              ...(supplier?.length ? [`(${supplier.join(" | ")})`] : []),
            ].join(" & ") || undefined,
          ...(!!declaration_labels?.length && {
            declaration_labels: `{${declaration_labels.join(",")}}`,
          }),
          min_date: relative_date
            ? DECLARATION_FILTER_RELATIVE_DATES[relative_date].min_date
            : min_date,
          max_date: relative_date
            ? DECLARATION_FILTER_RELATIVE_DATES[relative_date].max_date
            : max_date,
          ...restFilters,
          ...restVariables,
        },
        fetchPolicy: "no-cache",
      });
      const url =
        result &&
        result.data &&
        result.data.getDeclarationGoodItemsStatistics &&
        result.data.getDeclarationGoodItemsStatistics.url;
      if (url) {
        const downloadUrl = await getDownloadUrl(
          url,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          `attachment; filename="Import-${dayjs().format("DD/MM/YYYY")}.xlsx"`
        );
        filesUtils.download([downloadUrl]);
      }
    },
    [user]
  );
};

export default useDownloadDeclarationGoodItemsStatistics;
