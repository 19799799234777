import gql from "graphql-tag";
import { useWdUpdateMyUiSettingsTemplatesMutation } from "graphql/generated";

gql`
  mutation WdUpdateMyUiSettingsTemplates(
    $id: uuid!
    $ui_settings_templates: jsonb!
  ) {
    update_user_pp_by_pk(
      pk_columns: { id: $id }
      _append: { ui_settings_templates: $ui_settings_templates }
    ) {
      id
    }
  }
`;

export default useWdUpdateMyUiSettingsTemplatesMutation;
