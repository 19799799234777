import { useCallback } from "react";
import useUpdateAddress from "./useUpdateAddress";
import useUpdateAddressOverride from "./useUpdateAddressOverride";
import { Addresses } from "graphql/generated";

const useDeleteAddress = () => {
  const handleUpdateAddress = useUpdateAddress();
  const handleUpdateAddressOverride = useUpdateAddressOverride();

  const handleDelete = useCallback(
    (id: number, source: Addresses["source"]) => {
      const handleUpdate =
        source === "ao" ? handleUpdateAddressOverride : handleUpdateAddress;
      return handleUpdate(id, { is_deleted: true });
    },
    [handleUpdateAddress, handleUpdateAddressOverride]
  );

  return handleDelete;
};

export default useDeleteAddress;
