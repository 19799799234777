import gql from "graphql-tag";
import useErrorHandling from "hooks/useErrorHandling";
import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { PartialAddress } from "./types";

export const UPDATE_ADDRESS_OVERRIDE = gql`
  mutation UPDATE_ADDRESS_OVERRIDE($id: Int, $values: address_override_set_input) {
    update_address_override(where: { id: { _eq: $id } }, _set: $values) {
      affected_rows
    }
  }
`;

const useUpdateAddressOverride = () => {
  const withErrorHandling = useErrorHandling({ propagate: true });
  const [updateAddressOverride] = useMutation(UPDATE_ADDRESS_OVERRIDE);

  const handleUpdate = useCallback(
    (id: number, values: Partial<PartialAddress>) => {
      return withErrorHandling(updateAddressOverride, {
        variables: { id, values },
      });
    },
    [withErrorHandling, updateAddressOverride]
  );

  return handleUpdate;
};

export default useUpdateAddressOverride;
