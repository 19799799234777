import { DeclarationStatus } from "graphql/generated";

export type DeclarationType = "import" | "export";
export type ClearanceType = "transit" | "temporary" | "definitive";

export type ArrivalType = "unknown" | "allowed_consignee" | "border";

export const DECLARATION_STATUS_COLORS = {
  [DeclarationStatus.DocumentsRecieved]: {
    color: "#FFB800",
  },
  [DeclarationStatus.InProgress]: {
    color: "#604DFF",
  },
  [DeclarationStatus.DeclarationPending]: {
    color: "#22AEF8",
  },
  [DeclarationStatus.InformationMissing]: {
    color: "#EB5757",
  },
  [DeclarationStatus.DeclarationExported]: {
    color: "#32D376",
  },
  [DeclarationStatus.DeclarationExportedReferenceMissing]: {
    color: "#c7cc58",
  },
  [DeclarationStatus.DeclarationCanceled]: {
    color: "#D73333",
  },
  [DeclarationStatus.DeclarationRevoked]: {
    color: "#ff65c0",
  },
  [DeclarationStatus.ArrivalConfirmed]: {
    color: "grey",
  },
};

export enum AddressType {
  consignor = 1,
  consignee = 2,
  supplier = 3,
  importer = 4,
  freight_forwarder = 5,
  carrier = 6,
  consignee_transit = 7,
  consignor_transit = 8,
  payer = 9,
  authorized_consignee = 10,
}
