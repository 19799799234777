import { useCallback } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import useErrorHandling from "hooks/useErrorHandling";
import { useAuth } from "keycloak/context/AuthContext";

export const START_CHAT = gql`
  mutation WE_DECLARE_START_DISCUSSION(
    $fileId: uuid!
    $theme: String!
#    $message: String!
    $user: uuid!
  ) {
    insert_declaration_discussion_one(
      object: {
        file_id: $fileId
        discussion_theme: $theme
        creation_user: $user
#        declaration_messages: {
#          data: { creation_user: $user, message: $message }
#        }
      }
    ) {
      discussion_id
      discussion_theme
      file_id
      #      declaration_request {
      #        declaration_request_addresses(where: { address_type: { _eq: 4 }}) {
      #            refs
      #        }
      #      }
    }
  }
`;

const useStartChat = () => {
  const { user } = useAuth();
  const withErrorHandling = useErrorHandling();
  const [start] = useMutation(START_CHAT);

  const startChat = useCallback(
    async ({ fileId, theme }) => {
      return await withErrorHandling(start, {
        variables: { fileId, theme, user: user?.userId },
      });
    },
    [start, user, withErrorHandling]
  );

  return startChat;
};

export default useStartChat;
