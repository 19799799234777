import { useCallback } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import useErrorHandling from "hooks/useErrorHandling";
import { useAuth } from "keycloak";

export const SEND_MESSAGE = gql`
  mutation WE_DECLARE_CREATE_DECLARATION_MESSAGE(
    $discussionId: Int!
    $message: String!
    $user: ID!
    $source: String!
    $messageType: String!
  ) {
    createDeclarationMessage(
      discussionId: $discussionId
      message: $message
      user: $user
      source: $source
      messageType: $messageType
    ) {
      affected_rows
    }
  }
`;

const useSendMessage = () => {
  const { user } = useAuth();
  const withErrorHandling = useErrorHandling();
  const [send] = useMutation(SEND_MESSAGE);

  const sendMessage = useCallback(
    async ({
      discussionId,
      message,
      messageType,
    }: {
      discussionId: number;
      message: string;
      messageType: "message" | "remark";
    }) => {
      return await withErrorHandling(send, {
        variables: {
          discussionId,
          message,
          user: user?.userId,
          messageType,
          source: "we_declare",
        },
      });
    },
    [send, user, withErrorHandling]
  );

  return sendMessage;
};

export default useSendMessage;
