import { Box, Typography } from "@periplus/ui-library";
import { AddressListEntity } from "domain/address/useGetAddresses";

interface AddressInfoProps {
  address: AddressListEntity | null;
}

const AddressInfo = ({ address }: AddressInfoProps) => {
  if (!address) {
    return null;
  }

  return (
    <Box
      sx={{
        pt: 1.5,
        pl: 1.5,
        pr: 1.5,
      }}
    >
      <Typography variant="subtitle1">{address.companyName}</Typography>
      <Typography>{address.street}</Typography>
      <Typography>
        {address.zipcode} {address.city}
      </Typography>
      <Typography>{address.country}</Typography>
    </Box>
  );
};

export default AddressInfo;
