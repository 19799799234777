import gql from "graphql-tag";
import { useWdUnlinkAddressMutation } from "graphql/generated";

gql`
  mutation WdUnlinkAddress($sourceAddressId: Int!) {
    unlinkAddressACS(sourceAddressId: $sourceAddressId)
  }
`;

export default useWdUnlinkAddressMutation;
