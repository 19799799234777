import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  PageContainer,
  Button,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  TableProps,
  MRTable,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import useUrlSearchParams, {
  TableUrlSearchParams,
} from "hooks/useUrlSearchParams";
import { TableLocalStorage } from "hooks/usePageLocalStorage";
import { DeclarationFilters } from "domain/declaration/components/DeclarationFiltersSidebar";
import DeclarationFiltersSidebar from "domain/declaration/components/DeclarationFiltersSidebar";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import { useHistory } from "react-router-dom";
import Cards from "./Cards";
import Table from "./Table";
import usePageSettings from "domain/user/usePageSettings";
import useGetDeclarationsDashboard, {
  DeclarationDashboardEntity,
} from "./hooks/useGetDeclarationsDashboard";

const defaultFilters = {
  relative_date: "last4weeks",
} as DeclarationFilters;

const Dashboard: FC = () => {
  const { t } = useTranslation("declaration");
  const history = useHistory();

  const { pageSettings, setPageSettings, setPageSettingsForMRTable } =
    usePageSettings<
      Pick<
        TableLocalStorage<DeclarationFilters>,
        "itemsPerPage" | "filters"
      > & {
        view: "cards" | "table";
        table?: Pick<
          MRTable.MRT_TableState<DeclarationDashboardEntity>,
          "sorting"
        >;
      }
    >({
      itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
      view: "cards",
    });

  const [view, setView] = useState<"cards" | "table">(pageSettings.view);
  const [tableViewSorting, setTableViewSorting] =
    useState<MRTable.MRT_SortingState>(pageSettings.table?.sorting ?? []);
  useEffect(
    () => {
      setPageSettingsForMRTable((prev) => ({
        ...prev,
        table: {
          ...prev.table,
          sorting: tableViewSorting,
        },
      }));
    },
    // eslint-disable-next-line
    [tableViewSorting]
  );

  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<
    TableUrlSearchParams<DeclarationFilters>
  >({
    page: 1,
    itemsPerPage: pageSettings.itemsPerPage,
    ...(pageSettings.filters && {
      filters: {
        ...pageSettings.filters,
      },
    }),
  });
  const getDeclarationRequestsVariables = useMemo(() => {
    return {
      ...urlSearchParams,
      sorting: tableViewSorting,
      filters: {
        ...defaultFilters,
        ...urlSearchParams.filters,
      },
    };
  }, [urlSearchParams, tableViewSorting]);

  const {
    data: { declarationRequests, isLastPage },
    loading: declarationsLoading,
    refetch,
  } = useGetDeclarationsDashboard(getDeclarationRequestsVariables);

  const handlePageChange = useCallback<
    TableProps<DeclarationDashboardEntity>["onChangePage"]
  >(
    (newPage) => {
      setUrlSearchParams({ page: newPage });
    },
    [setUrlSearchParams]
  );

  const handleItemsPerPageChange = useCallback<
    TableProps<DeclarationDashboardEntity>["onChangeItemsPerPage"]
  >(
    (newItemsPerPage) => {
      setPageSettings({
        itemsPerPage: newItemsPerPage,
      });
      setUrlSearchParams({ page: 1, itemsPerPage: newItemsPerPage });
    },
    [setUrlSearchParams, setPageSettings]
  );

  const handleViewChange = (newView: "cards" | "table") => {
    setView(newView);
    setPageSettings({
      view: newView,
    });
  };

  return (
    <PageContainer
      title={t("navigation:dashboard")}
      sx={{
        flexDirection: "row",
        gap: 0,
        "& .LuiPageContainer-content": {
          p: 0,
          display: "flex",
        },
      }}
      controls={
        <Button
          color="primary"
          endIcon={<CreateNewFolderOutlinedIcon />}
          variant="contained"
          onClick={() => history.push("/new-declaration")}
          sx={(theme) => ({
            [theme.breakpoints.up("sm")]: {
              minWidth: 155,
            },
          })}
          minimizeForMobiles
        >
          {t("Create Request")}
        </Button>
      }
    >
      <DeclarationFiltersSidebar
        filters={urlSearchParams.filters}
        onChange={(newFilters) => {
          setUrlSearchParams({ filters: newFilters, page: 1 });
          setPageSettings({
            filters: newFilters,
          });
        }}
        defaultFilters={defaultFilters}
      />
      <Box
        sx={{
          padding: 2,
          width: "100%",
          minWidth: 0,
        }}
      >
        {view === "cards" ? (
          <Cards
            view={view}
            onViewChange={handleViewChange}
            search={urlSearchParams.search}
            onSearchChange={(newSearch) =>
              setUrlSearchParams({ search: newSearch || undefined, page: 1 })
            }
            declarations={declarationRequests}
            page={urlSearchParams.page}
            itemsPerPage={urlSearchParams.itemsPerPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
            loading={declarationsLoading}
            onLabelsAssigned={() => refetch()}
            isLastPage={isLastPage}
          />
        ) : (
          <Table
            view={view}
            onViewChange={handleViewChange}
            search={urlSearchParams.search}
            onSearchChange={(newSearch) =>
              setUrlSearchParams({ search: newSearch || undefined, page: 1 })
            }
            declarations={declarationRequests}
            page={urlSearchParams.page}
            itemsPerPage={urlSearchParams.itemsPerPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
            loading={declarationsLoading}
            onLabelsAssigned={() => refetch()}
            isLastPage={isLastPage}
            sorting={tableViewSorting}
            onSortingChange={setTableViewSorting}
            onRefresh={() => refetch()}
          />
        )}
      </Box>
    </PageContainer>
  );
};

export default Dashboard;
