import React, { FC, HtmlHTMLAttributes } from "react";
import { Theme } from "@periplus/ui-library";
import { Typography } from "@periplus/ui-library";
import { CustomsIcon, WarehouseIcon } from "components/Icons";
import { useTranslation } from "react-i18next";
import { ArrivalType } from "graphql/generated";
import { makeStyles } from 'tss-react/mui';

interface DeclarationArrivalTypeBlockProps
  extends HtmlHTMLAttributes<HTMLElement> {
  arrival_type: ArrivalType | null;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    gap: theme.spacing(),
  },
  icon: {
    width: 18,
    height: 18,
    color: "#4F4F4F",
  },
  label: {},
}));

const DeclarationArrivalTypeBlock: FC<DeclarationArrivalTypeBlockProps> = ({
  arrival_type,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation("declaration");

  let Icon, label;

  switch (arrival_type) {
    case ArrivalType.AllowedConsignee:
      Icon = WarehouseIcon;
      label = "AC";
      break;
    case ArrivalType.Border:
      Icon = CustomsIcon;
      label = "Border";
      break;
    default:
      //console.log("Unexpected arrival_type value: ", arrival_type);
      return null;
  }

  return (
    <div className={classes.root}>
      <Icon className={classes.icon} />
      <Typography className={classes.label}>{t(`${label}`)}</Typography>
    </div>
  );
};

export default DeclarationArrivalTypeBlock;
