import React, { FC, useReducer, HtmlHTMLAttributes } from "react";
import { CircularProgress } from "@periplus/ui-library";
import { Clear } from "@mui/icons-material";
import { Typography, ThreeDotsMenu } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";

import CustomsDocumentsDialog from "./CustomsDocumentsDialog";
import CancelDeclarationDialog from "./CancelDeclarationDialog";
import ConfirmArrivalDialog from "./ConfirmArrivalDialog";
import { CustomsIcon, WarehouseIcon } from "components/Icons";
import { useAuth } from "keycloak";
import { Permissions } from "keycloak/context/AuthContext";
import { useGetLazyDocuments } from "domain/document/useGetDocuments";
import { Document } from "domain/document/types";
import { ArrivalType } from "graphql/generated";
import { DeclarationDashboardEntity } from "../hooks/useGetDeclarationsDashboard";
import { makeStyles } from "tss-react/mui";

interface DeclarationActionsProps extends HtmlHTMLAttributes<HTMLElement> {
  declaration: DeclarationDashboardEntity;
}

const useStyles = makeStyles()((theme) => ({
  listItemIcon: {
    color: "rgb(115, 122, 150)",
    marginRight: 12,
  },
}));

type State = {
  confirmArrivalDialog: {
    isOpen: boolean;
    selectedArrivalType?: ArrivalType;
  };
  cancelDeclarationDialog: {
    isOpen: boolean;
  };
  downloadEVVDialog: {
    isOpen: boolean;
    loading?: boolean;
    documents?: Document[];
  };
};

type Action =
  | { type: "open_confirm_arrival_dialog"; payload: ArrivalType }
  | { type: "close_confirm_arrival_dialog" }
  | { type: "open_cancel_declaration_dialog" }
  | { type: "close_cancel_declaration_dialog" }
  | { type: "load_download_EVV_dialog" }
  | { type: "open_download_EVV_dialog"; payload: Document[] }
  | { type: "close_download_EVV_dialog" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "open_confirm_arrival_dialog":
      return {
        ...state,
        confirmArrivalDialog: {
          isOpen: true,
          selectedArrivalType: action.payload,
        },
      };
    case "close_confirm_arrival_dialog":
      return {
        ...state,
        confirmArrivalDialog: {
          isOpen: false,
        },
      };
    case "open_cancel_declaration_dialog":
      return {
        ...state,
        cancelDeclarationDialog: {
          isOpen: true,
        },
      };
    case "close_cancel_declaration_dialog":
      return {
        ...state,
        cancelDeclarationDialog: {
          isOpen: false,
        },
      };
    case "load_download_EVV_dialog":
      return {
        ...state,
        downloadEVVDialog: {
          ...state.downloadEVVDialog,
          loading: true,
        },
      };
    case "open_download_EVV_dialog":
      return {
        ...state,
        downloadEVVDialog: {
          ...state.downloadEVVDialog,
          loading: false,
          isOpen: true,
          documents: action.payload,
        },
      };
    case "close_download_EVV_dialog":
      return {
        ...state,
        downloadEVVDialog: {
          ...state.downloadEVVDialog,
          isOpen: false,
        },
      };
  }
};

const DeclarationActions: FC<DeclarationActionsProps> = ({ declaration }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth();

  const showACArrivalButton = user?.hasAllowedPermissions([
    Permissions.DASH_CONFIRM_ARRIVAL_AC,
  ]);

  const showBorderButton = user?.hasAllowedPermissions([
    Permissions.DASH_CONFIRM_ARRIVAL_BORDER,
  ]);

  const getDocuments = useGetLazyDocuments();

  const [state, dispatch] = useReducer(reducer, {
    confirmArrivalDialog: {
      isOpen: false,
    },
    cancelDeclarationDialog: {
      isOpen: false,
    },
    downloadEVVDialog: {
      isOpen: false,
    },
  });

  return (
    <>
      <ThreeDotsMenu
        options={[
          ...(declaration.declaration.acceptanceDate
            ? [
                {
                  content: (
                    <>
                      {state.downloadEVVDialog.loading ? (
                        <CircularProgress
                          className={classes.listItemIcon}
                          style={{ width: 24, height: 24 }}
                        />
                      ) : (
                        <GetAppOutlinedIcon className={classes.listItemIcon} />
                      )}
                      <Typography color="textSecondary" noWrap>
                        {t("common:customsDocuments")}
                      </Typography>
                    </>
                  ),
                  action: (close) => {
                    dispatch({
                      type: "load_download_EVV_dialog",
                    });
                    getDocuments({ file_id: declaration.fileId }).then(
                      ({ data }) => {
                        dispatch({
                          type: "open_download_EVV_dialog",
                          payload: data.documents,
                        });
                        close();
                      }
                    );
                  },
                },
              ]
            : []),

          ...(!declaration.declarationRequest.arrivalTime &&
          showACArrivalButton &&
          declaration.declarationRequest.arrivalType ===
            ArrivalType.AllowedConsignee
            ? [
                {
                  content: (
                    <>
                      <WarehouseIcon
                        className={classes.listItemIcon}
                        style={{ width: 24, height: 24 }}
                      />
                      <Typography color="textSecondary" noWrap>
                        {t(`declaration:Arrival AC-Place`)}
                      </Typography>
                    </>
                  ),
                  action: (close) => {
                    dispatch({
                      type: "open_confirm_arrival_dialog",
                      payload: ArrivalType.AllowedConsignee,
                    });
                    close();
                  },
                },
              ]
            : []),
          ...(!declaration.declarationRequest.arrivalTime &&
          declaration.declarationRequest.arrivalType === ArrivalType.Border &&
          showBorderButton
            ? [
                {
                  content: (
                    <>
                      <CustomsIcon
                        className={classes.listItemIcon}
                        style={{ width: 24, height: 24 }}
                      />
                      <Typography color="textSecondary" noWrap>
                        {t(`declaration:Arrival At The Border`)}
                      </Typography>
                    </>
                  ),
                  action: (close) => {
                    dispatch({
                      type: "open_confirm_arrival_dialog",
                      payload: ArrivalType.Border,
                    });
                    close();
                  },
                },
              ]
            : []),
          {
            content: (
              <>
                <Clear
                  className={classes.listItemIcon}
                  style={{ color: "#D73333", width: 24, height: 24 }}
                />
                <Typography color="error" noWrap>
                  {t("declaration:cancelDeclaration")}
                </Typography>
              </>
            ),
            action: (close) => {
              dispatch({ type: "open_cancel_declaration_dialog" });
              close();
            },
          },
        ]}
      />
      {state.downloadEVVDialog.isOpen && (
        <CustomsDocumentsDialog
          reference={declaration.addressReferences!}
          documents={state.downloadEVVDialog.documents as Document[]}
          onClose={() => dispatch({ type: "close_download_EVV_dialog" })}
        />
      )}
      {state.cancelDeclarationDialog.isOpen && (
        <CancelDeclarationDialog
          declaration={declaration}
          onClose={() => dispatch({ type: "close_cancel_declaration_dialog" })}
        />
      )}
      {state.confirmArrivalDialog.isOpen &&
        state.confirmArrivalDialog.selectedArrivalType && (
          <ConfirmArrivalDialog
            declaration={declaration}
            arrivalType={state.confirmArrivalDialog.selectedArrivalType}
            onClose={() => dispatch({ type: "close_confirm_arrival_dialog" })}
          />
        )}
    </>
  );
};

export default DeclarationActions;
