import React, { FC } from "react";
import { Autocomplete } from "@periplus/ui-library";
import DeclarationStatusChip from "domain/declaration/components/DeclarationStatusChip";
import { DeclarationStatus } from "graphql/generated";
import { useTranslation } from "react-i18next";

interface StatusFilterProps {
  value: string[];
  onChange(value: string[]): void;
  label?: string;
}

const StatusFilter: FC<StatusFilterProps> = ({ value, onChange, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Autocomplete<DeclarationStatus, true>
      value={value as DeclarationStatus[]}
      options={Object.keys(DeclarationStatus)
        .filter((key) => key !== DeclarationStatus.InformationMissing) // Filter out "InformationMissing" key
        .map((key) => {
          const declarationStatus =
            DeclarationStatus[key as keyof typeof DeclarationStatus]; // Get the enum value
          return declarationStatus;
        })}
      multiple
      onChange={(e, newValue) => onChange(newValue)}
      getOptionLabel={(option) => t(`declaration_status:${option}`)}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option}>
            <DeclarationStatusChip declarationStatus={option} />
          </li>
        );
      }}
      renderTags={(values) => {
        return values.map((value) => (
          <DeclarationStatusChip declarationStatus={value} />
        ));
      }}
      InputProps={{
        label: t("declaration:Declaration State"),
      }}
      sx={{
        "& .MuiAutocomplete-inputRoot": {
          gap: 1,
        },
      }}
    />
  );
};

export default StatusFilter;
