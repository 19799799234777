import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Panel,
  Theme,
  Typography,
  RadioGroup,
  Radio,
  Box,
  FormControlLabel,
} from "@periplus/ui-library";
import { ChatBox } from "@periplus/ui-library";
import useSubscribeDeclarationDiscussions from "domain/declarationDiscussion/useSubscribeDeclarationDiscussions";
import { useAuth } from "keycloak";
import useSendMessage from "domain/declarationDiscussion/useSendMessage";
import useStartChat from "domain/declarationDiscussion/useStartChat";
import dayjs from "dayjs";
import camelCase from "lodash/camelCase";
import useMarkDeclarationMessagesAsRead from "domain/declarationDiscussion/useMarkDeclarationMessagesAsRead";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  blue: {
    color: "#FFFFFF",
    backgroundColor: "#716EDE",
  },
  topicsTitle: {},
  topicsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  topicRadioGroup: {
    gap: theme.spacing(2),
  },
}));

interface CommunicationPanelProps {
  file_id: string;
}

type UserMessage = {
  author?: string;
  message: string;
  inner: boolean;
  date: string;
  remark?: boolean;
};

const TOPICS = ["Missing Documents", "Missing Information", "Other"];
const CommunicationPanel: FC<CommunicationPanelProps> = ({ file_id }) => {
  const { classes } = useStyles();
  const { t } = useTranslation("declaration");
  const { user } = useAuth();
  const [userMessages, setUserMessages] = useState<UserMessage[] | undefined>();
  const [selectedTopic, selectTopic] = useState(TOPICS[2]);

  const startChat = useStartChat();
  const sendMessage = useSendMessage();

  const { data } = useSubscribeDeclarationDiscussions(file_id);

  const discussion = data?.declaration_discussion[0];

  const [markDeclarationMessagesAsRead] = useMarkDeclarationMessagesAsRead();
  useEffect(() => {
    if (
      discussion?.discussion_id &&
      discussion.declaration_messages.some(
        (message) =>
          !message.read_date && message.user?.tenant_id !== user?.tenant_id
      )
    ) {
      markDeclarationMessagesAsRead({
        variables: {
          disc_id: discussion.discussion_id,
          read_date: new Date(),
          tenant_id: user?.tenant_id,
        },
      });
    }
  }, [discussion, markDeclarationMessagesAsRead, user]);

  useEffect(() => {
    if (!data) return;

    const serverMessages =
      data.declaration_discussion[0]?.declaration_messages.map(
        (declaration_message, index) => ({
          author:
            declaration_message.creation_user === user?.userId
              ? undefined
              : declaration_message.user?.display_name ||
                [
                  declaration_message.user?.first_name,
                  declaration_message.user?.last_name,
                ]
                  .filter(Boolean)
                  .join(" ") ||
                declaration_message.user?.email ||
                declaration_message.user?.tenant.tenant_name,
          message: declaration_message.message,
          inner: declaration_message.user?.tenant_id === user?.tenant_id,
          date: declaration_message.msg_date,
          remark: declaration_message.type === "remark",
        })
      ) ?? [];

    setUserMessages(serverMessages);
  }, [data, t, user]);

  const handleNewMessage = useCallback(
    (message: string, remark?: boolean) => {
      const messageType = remark ? "remark" : "message";
      if (discussion) {
        sendMessage({
          discussionId: discussion.discussion_id,
          message,
          messageType: remark ? "remark" : "message",
        });
      } else {
        startChat({
          fileId: file_id,
          theme: selectedTopic,
        }).then(({ data }) => {
          sendMessage({
            discussionId:
              data?.insert_declaration_discussion_one?.discussion_id,
            message,
            messageType,
          });
        });
      }

      setUserMessages((prevMessages) =>
        prevMessages?.concat({
          message,
          inner: true,
          date: dayjs().format(),
          remark,
        })
      );
    },
    [discussion, file_id, selectedTopic, sendMessage, startChat]
  );

  const handleChangeTopic = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      selectTopic(value);
    },
    []
  );

  return (
    <Box
      sx={{
        maxWidth: 300,
        minWidth: 300,
        maxHeight: "calc(100vh - var(--appbar-height) - 32px)",
      }}
    >
      <Panel
        title={t("Live-Chat")}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: 300,
          maxHeight: "calc(100vh - var(--appbar-height) - 32px)",
          position: "fixed",
          "& .LuiPanel-content": {
            p: 0,
            mt: 0,
            height: "100%",
            overflow: "hidden",
          },
        }}
      >
        <ChatBox
          placeholder={t("common:message")}
          messages={userMessages ?? []}
          onSend={handleNewMessage}
          messageInputProps={{
            autoFocus: false,
          }}
        >
          {userMessages &&
            (userMessages.length === 0 ? (
              <div className={classes.topicsContainer}>
                <Typography className={classes.topicsTitle}>
                  {t("notifications:selectSubject")}
                </Typography>
                <RadioGroup
                  className={classes.topicRadioGroup}
                  value={selectedTopic}
                  onChange={handleChangeTopic}
                >
                  {TOPICS.map((subject) => (
                    <FormControlLabel
                      key={subject}
                      control={<Radio value={subject} />}
                      label={t(`notifications:${camelCase(subject)}`)}
                      sx={(theme) => ({
                        "& .MuiFormControlLabel-label": {
                          ...theme.typography.body1,
                          color: theme.palette.text.secondary,
                        },
                      })}
                    />
                  ))}
                </RadioGroup>
              </div>
            ) : (
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "center", mb: 1 }}
              >
                {t(
                  `notifications:${camelCase(
                    discussion?.discussion_theme ?? selectedTopic
                  )}`
                )}
              </Typography>
            ))}
        </ChatBox>
      </Panel>
    </Box>
  );
};

export default CommunicationPanel;
