const common = {
  maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE || 1024 * 1024 * 100,
};

const development = {
  ...common,
  keycloak: {
    url: "https://authentication-staging.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId: "liber",
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "http://localhost:1010/v1/graphql",
  blobStorage: {
    documentsContainerName: "documents",
    thumbsContainerName: "thumbs",
    storageAccountName: "aditdocuments",
  },
};

const test = {
  ...common,
  keycloak: {
    url: "https://authentication-staging.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId: "liber",
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "http://localhost:8080/v1/graphql",
  blobStorage: {
    documentsContainerName: "documents",
    thumbsContainerName: "thumbs",
    storageAccountName: "aditdocuments",
  },
};

const staging = {
  ...common,
  keycloak: {
    url: "https://authentication-staging.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId: "liber",
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "https://staging.api.adit.periplus.ch/v1/graphql",
  blobStorage: {
    documentsContainerName: "documents",
    thumbsContainerName: "thumbs",
    storageAccountName: "aditdocuments",
  },
};

const demo = {
  ...common,
  keycloak: {
    url: "https://authentication-demo.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId: "liber",
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "https://demo.api.adit.periplus.ch/v1/graphql",
  blobStorage: {
    documentsContainerName: "documents",
    thumbsContainerName: "thumbs",
    storageAccountName: "aditdocumentsdemo",
  },
};

const production = {
  ...common,
  keycloak: {
    url: "https://authentication.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId: "liber",
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "https://production.api.adit.periplus.ch/v1/graphql",
  blobStorage: {
    documentsContainerName: "documents",
    thumbsContainerName: "thumbs",
    storageAccountName: "aditdocumentsproduction",
  },
};

// Default to development if not set
function getConfig() {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "development":
      return development;
    case "test":
      return test;
    case "staging":
      return staging;
    case "production":
      return production;
    case "demo":
      return demo;
    default:
      return development;
  }
}

const isProdMode = process.env.REACT_APP_ENVIRONMENT === "production";

// Default to development if not set
const config = getConfig();

export default {
  // All "common", general config or initial values belong here...
  START_MESSAGE: "Hello World",
  graphQl: {
    url: config.hasura || "http://localhost:5000",
  },
  isProdMode,
  ...config,
};
