import React, { FC, useState } from "react";
import { Checkbox, Dialog, FormControlLabel } from "@periplus/ui-library";
import { DeclarationLabel } from "graphql/generated";
import DeclarationLabelChip from "./DeclarationLabelChip";
import { useAuth } from "keycloak";
import { useTranslation } from "react-i18next";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import useAssignDeclarationLabels from "domain/declaration/useAssignDeclarationLabels";

interface DeclarationLabelsAssignmentDialogProps {
  file_id: string;
  dr_no: number;
  declaration_labels: Pick<DeclarationLabel, "id">[];
  onClose: () => void;
  onAssigned: () => void;
}

const DeclarationLabelsAssignmentDialog: FC<
  DeclarationLabelsAssignmentDialogProps
> = ({ file_id, dr_no, declaration_labels, onClose, onAssigned }) => {
  const { user } = useAuth();
  const { t } = useTranslation("declaration");
  const [state, setState] = useState(
    declaration_labels.reduce<Record<number, boolean>>(
      (acc, el) => ({ ...acc, [el.id]: true }),
      {}
    )
  );
  const [isAssignLoading, setIsAssignLoading] = useState(false);

  const assign = useAssignDeclarationLabels();

  return (
    <Dialog
      mainTitle={t("Manage Labels")}
      onClose={onClose}
      onConfirm={async () => {
        setIsAssignLoading(true);
        await assign({
          variables: {
            fileId: file_id,
            drNo: dr_no,
            labelIds: Object.entries(state)
              .filter(([, checked]) => checked)
              .map(([label_id]) => Number(label_id)),
          },
        });
        setIsAssignLoading(false);
        onAssigned();
      }}
      ConfirmButtonProps={{
        label: t("common:Save"),
        endIcon: <SaveOutlinedIcon />,
        loading: isAssignLoading,
      }}
      sx={{
        "& .MuiDialog-paper": {
          minWidth: 300,
        },
        "& .MuiDialogContent-root": {
          display: "flex",
          flexDirection: "column",
          gap: 3,
        },
      }}
    >
      {user!.tenant!.declaration_labels.map((declaration_label) => (
        <FormControlLabel
          key={declaration_label.id}
          control={
            <Checkbox
              checked={state[declaration_label.id] || false}
              onChange={(e, checked) =>
                setState((prev) => ({
                  ...prev,
                  [declaration_label.id]: checked,
                }))
              }
            />
          }
          label={<DeclarationLabelChip declaration_label={declaration_label} />}
          sx={{
            "& .MuiFormControlLabel-label": {
              ml: 2,
            },
          }}
        />
      ))}
    </Dialog>
  );
};

export default DeclarationLabelsAssignmentDialog;
