import React, {
  Dispatch,
  FC,
  HtmlHTMLAttributes,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  MRTable,
  Badge,
  IconButton,
  ToggleButtonGroup,
} from "@periplus/ui-library";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import DeclarationEVV from "domain/declaration/components/DeclarationEVV";
import DeclarationStatusChip from "domain/declaration/components/DeclarationStatusChip";
import DeclarationArrivalTypeBlock from "domain/declaration/components/DeclarationArrivalTypeBlock";
import DeclarationLabelsField from "domain/declaration/components/DeclarationLabel/DeclarationLabelsField";
import { useAuth } from "keycloak";
import { Permissions } from "keycloak/context/AuthContext";
import AdditionalRef from "../AdditionalRef";
import { DeclarationDashboardEntity } from "../hooks/useGetDeclarationsDashboard";
import {
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ColumnSizingState,
  MRT_DensityState,
  MRT_PaginationState,
  MRT_TableState,
  MRT_VisibilityState,
} from "material-react-table";
import RowActions from "./RowActions";
import usePageSettings from "domain/user/usePageSettings";
import { useAppState } from "App/AppContext";
import ChatOutlined from "@mui/icons-material/ChatOutlined";
import { useHistory } from "react-router-dom";
import { DeclarationStatus } from "graphql/generated";

interface TableProps extends HtmlHTMLAttributes<HTMLElement> {
  declarations: DeclarationDashboardEntity[];
  loading: boolean;
  page: number;
  itemsPerPage: number;
  onPageChange: (value: number) => void;
  onItemsPerPageChange: (value: number) => void;
  view: "cards" | "table";
  onViewChange: (value: "cards" | "table") => void;
  search?: string;
  onSearchChange: (value?: string) => void;
  onLabelsAssigned: () => void;
  isLastPage: boolean;
  sorting: MRTable.MRT_SortingState;
  onSortingChange: Dispatch<SetStateAction<MRTable.MRT_SortingState>>;
  onRefresh: () => void;
}

const Table: FC<TableProps> = ({
  declarations,
  loading,
  view,
  onViewChange,
  page,
  onPageChange,
  itemsPerPage,
  onItemsPerPageChange,
  search,
  onSearchChange,
  onLabelsAssigned,
  isLastPage,
  sorting,
  onSortingChange,
  onRefresh,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("declaration");
  const history = useHistory();
  const { edecData } = useAppState();
  const { user } = useAuth();

  const isSupplierAvailable = user?.hasAllowedPermissions([
    Permissions.SUPPLIER_ADDRESS,
  ]);

  const { pageSettings, setPageSettingsForMRTable } = usePageSettings<{
    table?: Pick<
      MRT_TableState<DeclarationDashboardEntity>,
      | "density"
      | "columnSizing"
      | "columnPinning"
      | "columnVisibility"
      | "columnOrder"
    >;
  }>();

  const [columns, tableData] = React.useMemo<
    [
      MRTable.MRT_ColumnDef<DeclarationDashboardEntity>[],
      DeclarationDashboardEntity[]
    ]
  >(
    () => [
      [
        {
          header: t("Reference"),
          id: "reference",
          accessorFn: (originalRow) => originalRow.addressReferences,
          Cell: ({
            row: {
              original: { addressReferences, searchReferences },
            },
          }) => (
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              {addressReferences}
              {searchReferences && (
                <AdditionalRef sx={{ marginLeft: "6px" }}>
                  {searchReferences}
                </AdditionalRef>
              )}
            </Box>
          ),
        },
        {
          header: t("Status"),
          id: "declarationStatus",
          accessorFn: (originalRow) => originalRow.declarationStatus,
          Cell: ({
            row: {
              original: { declarationStatus },
            },
          }) => <DeclarationStatusChip declarationStatus={declarationStatus} />,
        },
        {
          header: t("Creation date"),
          id: "creationDate",
          accessorFn: (originalRow) =>
            originalRow.declarationRequest?.creationDate &&
            dayjs(originalRow.declarationRequest.creationDate).format(
              "DD.MM.YYYY HH:mm"
            ),
        },
        {
          header: t("Acceptance date"),
          id: "acceptanceDate",
          accessorFn: (originalRow) =>
            originalRow.declarationRequest!.acceptanceDate &&
            dayjs(originalRow.declarationRequest!.acceptanceDate).format(
              "DD.MM.YYYY HH:mm"
            ),
        },
        ...(user?.tenant?.declaration_labels.length
          ? [
            {
              header: t("Labels"),
              id: "declarationLabels",
              enableSorting: false,
              Cell: ({
                row: {
                  original: { fileId, drNo, declarationLabels },
                },
              }) => (
                <DeclarationLabelsField
                  view="table"
                  file_id={fileId}
                  dr_no={drNo}
                  declaration_labels={declarationLabels}
                  onLabelsAssigned={onLabelsAssigned}
                />
              ),
            },
          ]
          : []),
        {
          header: t("Arrival"),
          id: "arrivalType",
          accessorFn: (originalRow) =>
            originalRow.declarationRequest.arrivalType,
          Cell: ({
            row: {
              original: {
                declarationRequest: { arrivalType },
              },
            },
          }) => <DeclarationArrivalTypeBlock arrival_type={arrivalType} />,
        },
        {
          header: t("Client"),
          id: "payer",
          accessorFn: ({
            payer: {
              address: { companyName },
            },
          }) => companyName,
        },
        {
          header: t("Importer"),
          id: "importer",
          enableSorting: false,
          accessorFn: ({ addresses }) =>
            addresses?.find((address) => address.addressTypeId === 4)
              ?.companyName,
        },
        {
          header: t("Consignor"),
          id: "consignor",
          enableSorting: false,
          accessorFn: ({ addresses }) =>
            addresses?.find((address) => address.addressTypeId === 1)
              ?.companyName,
        },
        ...(isSupplierAvailable
          ? [
            {
              header: t("Supplier"),
              id: "supplier",
              enableSorting: false,
              accessorFn: ({ addresses }) =>
                addresses?.find((address) => address.addressTypeId === 3)
                  ?.companyName,
            },
          ]
          : []),
        {
          header: t("Declaration type"),
          id: "declarationType",
          accessorFn: (originalRow) => {
            return edecData.edec_domains.find(
              (el) =>
                el.domain_name === "declarationType" &&
                el.value === originalRow.declaration.declarationType
            )?.[`meaning_${language}`];
          },
        },
        {
          header: t("Toll Dept."),
          id: "customsOffice",
          accessorFn: ({
            declaration: { customsOfficeNumber },
            customsOffice,
          }) =>
            [customsOfficeNumber, customsOffice?.name]
              .filter(Boolean)
              .join(" "),
        },
        {
          header: t("Declaration Nr."),
          id: "customsDeclarationNumber",
          accessorFn: (originalRow) =>
            originalRow.declaration.customsDeclarationNumber,
        },
        {
          header: t("Declaration version"),
          id: "customsDeclarationVersion",
          accessorFn: (originalRow) =>
            originalRow.declaration.customsDeclarationVersion,
        },
        {
          header: t("Bordereau"),
          id: "borderEauNumber",
          accessorFn: ({ declaration }) => declaration.borderEauNumber,
        },
        {
          header: t("Jobreference"),
          id: "identification",
          accessorFn: ({ file: { identification } }) => identification || "...",
        },
        {
          header: t("ETA"),
          id: "eta",
          accessorFn: ({ declarationRequest: { eta } }) =>
            eta && dayjs(eta).format("DD.MM.YYYY HH:mm"),
        },
        {
          header: t("ATA"),
          id: "arrivalTime",
          accessorFn: ({ declarationRequest: { arrivalTime } }) =>
            arrivalTime && dayjs(arrivalTime).format("DD.MM.YYYY HH:mm"),
        },
        {
          header: t("EVVs"),
          id: "evv",
          accessorFn: (originalRow) =>
            originalRow.declarationRequest.meta.evvConfirmed,
          Cell: ({ row: { original } }) => (
            <DeclarationEVV declaration={original} />
          ),
        },
        {
          header: t("WA-Number"),
          id: "waNumber",
          accessorFn: ({ declarationRequest: { waNumber } }) => waNumber,
        },
        {
          header: t("Email"),
          id: "email",
          accessorFn: ({ userPp }) => userPp?.email,
        },
        {
          header: "",
          id: "message",
          Cell: ({ row: { original: originalRow } }) =>
            !!originalRow.declarationDiscussion?.unreadMessagesCount && (
              <IconButton
                disabled={
                  originalRow.declarationStatus ===
                  DeclarationStatus.DeclarationCanceled
                }
                onClick={() => {
                  history.push(
                    `/declaration/${originalRow.fileId}/${originalRow.drNo}`
                  );
                }}
              >
                <Badge
                  badgeContent={
                    originalRow.declarationDiscussion?.unreadMessagesCount
                  }
                  color={"primary"}
                  invisible={
                    originalRow.declarationStatus ===
                    DeclarationStatus.DeclarationCanceled
                  }
                  slotProps={{
                    badge: {
                      style: {
                        top: "2px",
                      },
                    },
                  }}
                >
                  <ChatOutlined />
                </Badge>
              </IconButton>
            ),
          enableSorting: false,
          enableResizing: false,
          enableColumnActions: false,
          size: 50,
          maxSize: 50,
          muiTableHeadCellProps: {},
          muiTableBodyCellProps: {
            align: "center",
          },
        },
      ],
      [...declarations],
    ],
    [
      t,
      isSupplierAvailable,
      user?.tenant?.declaration_labels.length,
      onLabelsAssigned,
      declarations,
      language,
      edecData,
      history,
    ]
  );

  const pagination = useMemo<MRT_PaginationState>(
    () => ({
      pageIndex: page - 1,
      pageSize: itemsPerPage,
    }),
    [page, itemsPerPage]
  );
  const [density, setDensity] = useState<MRT_DensityState>(
    pageSettings.table?.density ?? "compact"
  );
  const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>(
    pageSettings.table?.columnSizing ?? {}
  );
  const [columnPinning, setColumnPinning] = useState<MRT_ColumnPinningState>(
    pageSettings.table?.columnPinning ?? {
      right: ["message"],
    }
  );
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    pageSettings.table?.columnVisibility ?? {}
  );
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(
    pageSettings.table?.columnOrder ?? columns.map((c) => c.id!)
  );

  useEffect(
    () => {
      setPageSettingsForMRTable((prev) => ({
        ...prev,
        table: {
          ...prev.table,
          density,
          columnSizing,
          columnPinning,
          columnVisibility,
          columnOrder,
        },
      }));
    },
    // eslint-disable-next-line
    [density, columnSizing, columnPinning, columnVisibility, columnOrder]
  );

  return (
    <MRTable.Table
      columns={columns}
      data={tableData}
      state={{
        globalFilter: search,
        pagination,
        density,
        columnSizing,
        columnPinning,
        columnVisibility,
        columnOrder,
        sorting,
        isLoading: loading,
      }}
      manualSorting
      enableRowActions
      onGlobalFilterChange={(updater) => {
        const newState =
          updater instanceof Function ? updater(search) : updater;
        onSearchChange(newState);
      }}
      manualPagination
      manualFiltering
      rowCount={Number.MAX_SAFE_INTEGER}
      onPaginationChange={(updater) => {
        const newState =
          updater instanceof Function ? updater(pagination) : updater;
        const newPage = newState.pageIndex + 1;
        if (page !== newPage) onPageChange(newPage);

        const newItemsPerPage = newState.pageSize;
        if (itemsPerPage !== newState.pageSize)
          onItemsPerPageChange(newItemsPerPage);
      }}
      onDensityChange={setDensity}
      onColumnSizingChange={setColumnSizing}
      onColumnPinningChange={setColumnPinning}
      onColumnVisibilityChange={setColumnVisibility}
      onColumnOrderChange={setColumnOrder}
      onSortingChange={onSortingChange}
      renderRowActions={({ row }) => <RowActions declaration={row.original} />}
      onRefresh={onRefresh}
      muiTableContainerProps={{
        sx: {
          maxHeight: "calc(100vh - var(--appbar-height) - 145px)",
        },
      }}
      renderTopToolbarCustomActions={() => (
        <ToggleButtonGroup
          exclusive
          value={view}
          onChange={(e, newView) => {
            newView && onViewChange(newView);
          }}
          options={[
            {
              label: t("Overview"),
              value: "cards",
            },
            {
              label: t("Table"),
              value: "table",
            },
          ]}
        />
      )}
      muiPaginationProps={{
        showFirstButton: true,
        showLastButton: false,
        hideNextButton: isLastPage,
      }}
      muiTableBodyRowProps={({ row }) => ({
        onDoubleClick: (e) =>
          history.push(
            `/declaration/${row.original.fileId}/${row.original.drNo}`
          ),
      })}
    />
  );
};

export default Table;
