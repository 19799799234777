import React, { memo, useMemo } from "react";
import { Switch, Redirect } from "react-router-dom";
import NewDeclaration from "pages/NewDeclaration";
import NotFoundPage from "../NotFoundPage";
import { useAuth, Permissions } from "keycloak/context/AuthContext";
import Declaration from "pages/Declaration";
import ImportListTriggers from "pages/ImportListTriggers/ImportListTriggersList";
import Layout from "components/Layout";
import AddressManagement from "pages/AddressManagement";
import MyProfile from "pages/MyProfile";
import Statistics from "pages/Statistics";
import KPI from "pages/KPI";
import Dashboard from "pages/Dashboard";
import AuthRoute from "./AuthRoute";
import { useTranslation } from "react-i18next";
import BusinessIcon from "@mui/icons-material/Business";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AlarmIcon from "@mui/icons-material/Alarm";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Bordereaux from "pages/Bordereaux";
import DescriptionIcon from "@mui/icons-material/Description";
import { MNR_TENANT_ID } from "domain/tenant/types";

const AuthRoutes = memo(() => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const routes = useMemo(
    () => [
      {
        path: "/dashboard",
        component: Dashboard,
        exact: true,
        permission: Permissions.DASH_FLT,
      },
      {
        path: "/bordereaux",
        component: Bordereaux,
        exact: true,
      },
      {
        path: "/new-declaration",
        component: NewDeclaration,
        exact: true,
        permission: Permissions.DOC_UPLOAD,
      },
      {
        path: "/declaration/:id/:dr_no",
        component: Declaration,
        permission: Permissions.DOC_UPLOAD,
      },
      {
        path: "/alerts",
        exact: true,
        component: ImportListTriggers,
        permission: Permissions.ALERT_MANAGER,
      },
      ...(user?.tenant_id !== MNR_TENANT_ID
        ? [
            {
              path: "/address",
              component: AddressManagement,
              permission: Permissions.ADDRESS_MGT,
            },
          ]
        : []),
      {
        path: "/my-profile",
        exact: true,
        component: MyProfile,
        permission: Permissions.USER_PROFILE,
      },
      {
        path: "/statistics",
        component: Statistics,
        permission: Permissions.REPORTING,
      },
      {
        path: "/kpi",
        component: KPI,
        exact: true,
        permission: Permissions.REPORTING,
      },
    ],
    [user]
  );

  const filteredRoutes = routes.filter(({ permission }) =>
    permission ? user?.hasAllowedPermissions([permission]) : true
  );

  const navigation = useMemo(() => {
    return [
      {
        Icon: DashboardOutlinedIcon,
        text: "Dashboard",
        redirecting: "/dashboard",
        permission: Permissions.DASH_FLT,
      },
      {
        Icon: NoteAddOutlinedIcon,
        text: t("navigation:declarationRequest"),
        permission: Permissions.DOC_UPLOAD,
        redirecting: "/new-declaration",
      },
      {
        Icon: ListAltIcon,
        text: t("navigation:bordereaux"),
        redirecting: "/bordereaux",
      },
      ...(user?.tenant_id !== MNR_TENANT_ID
        ? [
            {
              Icon: BusinessIcon,
              text: t("navigation:address"),
              redirecting: "/address",
              permission: Permissions.ADDRESS_MGT,
            },
          ]
        : []),
      {
        Icon: AssessmentOutlinedIcon,
        text: t("navigation:statistics"),
        redirecting: "/statistics",
        permission: Permissions.REPORTING,
      },
      {
        Icon: AlarmIcon,
        text: t("navigation:alerts"),
        redirecting: "/alerts",
        permission: Permissions.ALERT_MANAGER,
      },
      {
        Icon: PieChartOutlinedIcon,
        text: t("navigation:kpi"),
        redirecting: "/kpi",
        permission: Permissions.REPORTING,
      },
      {
        Icon: DescriptionIcon,
        text: t("navigation:documentation"),
        redirecting: "https://dokumentation.wedeclare.ch/",
      },
    ];
  }, [t, user]);

  return (
    <Layout navigation={navigation}>
      <Switch>
        <Redirect exact from="/" to={filteredRoutes[0].path} />
        <Redirect exact from="/login" to={filteredRoutes[0].path} />
        {filteredRoutes.map((navigationItem) => {
          const Component = navigationItem.component;

          return (
            <AuthRoute
              key={navigationItem.path}
              path={navigationItem.path}
              exact={navigationItem.exact}
            >
              {React.createElement(Component)}
            </AuthRoute>
          );
        })}
        <AuthRoute component={NotFoundPage} />
      </Switch>
    </Layout>
  );
});

export default AuthRoutes;
