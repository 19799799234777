import { useMutation, MutationHookOptions } from "@apollo/client";
import gql from "graphql-tag";
import useErrorHandling from "hooks/useErrorHandling";

const UPDATE_MY_PROFILE = gql`
  mutation WD_UPDATE_MY_PROFILE(
    $id: uuid!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
  ) {
    update_user_pp_by_pk(
      pk_columns: { id: $id }
      _set: {
        first_name: $firstName
        last_name: $lastName
        email: $email
        phone_number: $phoneNumber
      }
    ) {
      id
    }
  }
`;

const useUpdateMyProfile = () => {
  const withErrorHandling = useErrorHandling();

  const [updateMyProfile] = useMutation(UPDATE_MY_PROFILE);

  return (options?: MutationHookOptions) =>
    withErrorHandling(updateMyProfile, options);
};

export default useUpdateMyProfile;
