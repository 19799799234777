import React, { FC } from "react";
import { Theme } from "@periplus/ui-library";
import { Typography, TypographyProps } from "@periplus/ui-library";
import { makeStyles } from 'tss-react/mui';

interface FieldLabelProps extends TypographyProps {
  required?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
}));

const FieldLabel: FC<FieldLabelProps> = ({ children, required }) => {
  const { classes } = useStyles();

  return (
    <Typography
      className={classes.root}
      variant="subtitle1"
      color="textSecondary"
    >
      {required ? `${children} *` : children}
    </Typography>
  );
};

export default FieldLabel;
