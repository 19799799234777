import React from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import AuthRoute from "App/Routes/Auth/AuthRoute";
import GeneralTab from "./GeneralTab";
import LinkedTab from "./LinkedTab";

interface Props {}

export default (props: Props) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/general`} />
      <Switch>
        <AuthRoute path={`${path}/general`}>
          <GeneralTab />
        </AuthRoute>
        <AuthRoute path={`${path}/linked`}>
          <LinkedTab />
        </AuthRoute>
      </Switch>
    </Switch>
  );
};
