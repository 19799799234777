import React, { FC } from "react";
import { Box, BoxProps, IconButton, combineSX } from "@periplus/ui-library";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";

interface DeclarationFieldEditControlsProps extends BoxProps {
  onEdit: () => void;
  editMode?: boolean;
  onSave?: () => void;
  submiting?: boolean;
  onCancel?: () => void;
}

const DeclarationFieldEditControls: FC<DeclarationFieldEditControlsProps> = ({
  onEdit,
  editMode,
  onSave,
  submiting,
  onCancel,
  sx,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={combineSX(
        {
          display: "inline-flex",
        },
        sx
      )}
    >
      {editMode ? (
        <>
          <IconButton
            tooltip={t("Save")}
            onClick={onSave}
            color="primary"
            loading={submiting}
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            tooltip={t("cancel")}
            onClick={onCancel}
            disabled={submiting}
            sx={{
              ml: 0.5,
            }}
          >
            <ClearIcon />
          </IconButton>
        </>
      ) : (
        <IconButton
          onClick={onEdit}
          tooltip={t("Edit")}
          size="small"
          color="primary"
        >
          <EditIcon sx={{ fontSize: 20 }} />
        </IconButton>
      )}
    </Box>
  );
};

export default DeclarationFieldEditControls;
