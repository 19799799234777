import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import useErrorHandling from "hooks/useErrorHandling";

import { PartialAddress } from "./types";

export const UPDATE_ADDRESS = gql`
  mutation UPDATE_ADDRESS($id: Int, $values: address_set_input) {
    update_address(where: { id: { _eq: $id } }, _set: $values) {
      affected_rows
    }
  }
`;

const useUpdateAddress = () => {
  const withErrorHandling = useErrorHandling({ propagate: true });
  const [updateAddress] = useMutation(UPDATE_ADDRESS);

  const handleUpdate = useCallback(
    (id: number, values: Partial<PartialAddress>) => {
      return withErrorHandling(updateAddress, {
        variables: { id, values },
      });
    },
    [withErrorHandling, updateAddress]
  );

  return handleUpdate;
};

export default useUpdateAddress;
