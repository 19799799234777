import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import useErrorHandling from "hooks/useErrorHandling";

const CHANGE_DECLARATION_STATUS = gql`
  mutation CHANGE_DECLARATION_STATUS($fileId: uuid!, $status: String) {
    update_declaration_request(
      _set: { declaration_status: $status }
      where: { file_id: { _eq: $fileId } }
    ) {
      affected_rows
    }
  }
`;

const useChangeDeclarationStatus = () => {
  const withErrorHandling = useErrorHandling();

  const [changeStatus] = useMutation(CHANGE_DECLARATION_STATUS);

  return (id: string, status: string) =>
    withErrorHandling(changeStatus, {
      variables: { fileId: id, status },
    });
};

export default useChangeDeclarationStatus;
