import { useEffect, useState, useMemo } from "react";
import {
  PageContainer,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  MRTable,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import useUrlSearchParams, {
  TableUrlSearchParams,
} from "hooks/useUrlSearchParams";
import { TableLocalStorage } from "hooks/usePageLocalStorage";
import FiltersSidebar, { BorderauxFilters } from "./FiltersSidebar";
import usePageSettings from "domain/user/usePageSettings";
import useGetBordereaus, {
  Bordereau,
} from "pages/Bordereaux/hooks/useGetBordereaus";
import SubTable from "./SubTable";
import Actions from "./Actions";

const Bordereaux = () => {
  const { t } = useTranslation("bordereaux");

  const { pageSettings, setPageSettings, setPageSettingsForMRTable } =
    usePageSettings<TableLocalStorage<BorderauxFilters>>({
      itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
    });

  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<
    TableUrlSearchParams<BorderauxFilters>
  >({
    page: 1,
    itemsPerPage: pageSettings.itemsPerPage,
    ...(pageSettings.filters && {
      filters: {
        ...pageSettings.filters,
      },
    }),
  });

  const {
    data: { bordereaus, bordereausCount },
    loading,
  } = useGetBordereaus({
    ...urlSearchParams,
    filters: urlSearchParams.filters,
  });

  const [columns, tableData] = useMemo<
    [MRTable.MRT_ColumnDef<Bordereau>[], Bordereau[]]
  >(
    () => [
      [
        {
          header: t("Bordereau Nr."),
          id: "documentNumber",
          accessorFn: (originalRow) => originalRow.documentNumber,
        },
        {
          header: t("Account"),
          id: "accountNumber",
          accessorFn: (originalRow) => originalRow.accountNumber,
        },
        {
          header: t("Files"),
          id: "status",
          accessorFn: (originalRow) =>
            originalRow.status ? t("Complete") : t("Incomplete"),
        },
        {
          header: t("Duties"),
          id: "duties",
          accessorFn: (originalRow) => originalRow.duties,
        },
        {
          header: t("VAT"),
          id: "vat",
          accessorFn: (originalRow) => originalRow.vat,
        },
        {
          header: t("Address"),
          id: "consignee",
          accessorFn: (originalRow) => originalRow.consignee,
        },
        {
          header: t("Date"),
          id: "documentDate",
          accessorFn: (originalRow) =>
            originalRow.documentDate &&
            `${dayjs(originalRow.documentDate).format(
              "DD.MM.YYYY"
            )} ${originalRow.documentTime.split(":").splice(0, 2).join(":")}`,
        },
      ],
      [...bordereaus],
    ],
    [t, bordereaus]
  );

  const pagination = useMemo<MRTable.MRT_PaginationState>(
    () => ({
      pageIndex: urlSearchParams.page - 1,
      pageSize: urlSearchParams.itemsPerPage,
    }),
    [urlSearchParams.page, urlSearchParams.itemsPerPage]
  );
  const [density, setDensity] = useState<MRTable.MRT_DensityState>(
    pageSettings.density ?? "compact"
  );
  const [columnSizing, setColumnSizing] =
    useState<MRTable.MRT_ColumnSizingState>(pageSettings.columnSizing ?? {});
  const [columnPinning, setColumnPinning] =
    useState<MRTable.MRT_ColumnPinningState>(pageSettings.columnPinning ?? {});
  const [columnVisibility, setColumnVisibility] =
    useState<MRTable.MRT_VisibilityState>(pageSettings.columnVisibility ?? {});
  const [columnOrder, setColumnOrder] = useState<MRTable.MRT_ColumnOrderState>(
    pageSettings.columnOrder ?? ["mrt-row-expand", ...columns.map((c) => c.id!)]
  );

  useEffect(
    () => {
      setPageSettingsForMRTable({
        itemsPerPage: urlSearchParams.itemsPerPage,
        density,
        columnSizing,
        columnPinning,
        columnVisibility,
        columnOrder,
      });
    },
    // eslint-disable-next-line
    [
      urlSearchParams.itemsPerPage,
      density,
      columnSizing,
      columnPinning,
      columnVisibility,
      columnOrder,
    ]
  );

  return (
    <PageContainer
      title={t("navigation:bordereaux")}
      sx={{
        "& .LuiPageContainer-content": {
          display: "flex",
          flexDirection: "row",
          gap: 0,
          p: 0,
        },
      }}
    >
      <FiltersSidebar
        filters={urlSearchParams.filters}
        onChange={(newFilters) => {
          setPageSettings({
            filters: newFilters,
          });
          setUrlSearchParams({ filters: newFilters, page: 1 });
        }}
      />
      <MRTable.Table
        columns={columns}
        data={tableData}
        state={{
          globalFilter: urlSearchParams.search,
          pagination,
          density,
          columnSizing,
          columnPinning,
          columnVisibility,
          columnOrder,
          isLoading: loading,
        }}
        enableSorting={false}
        onGlobalFilterChange={(updater) => {
          const newState =
            updater instanceof Function
              ? updater(urlSearchParams.search)
              : updater;
          setUrlSearchParams({ search: newState || undefined, page: 1 });
        }}
        manualPagination
        manualFiltering
        enableRowActions
        rowCount={bordereausCount}
        onPaginationChange={(updater) => {
          const newState =
            updater instanceof Function ? updater(pagination) : updater;
          const newPage = newState.pageIndex + 1;
          if (urlSearchParams.page !== newPage)
            setUrlSearchParams({ page: newPage });

          const newItemsPerPage = newState.pageSize;
          if (urlSearchParams.itemsPerPage !== newState.pageSize)
            setUrlSearchParams({ itemsPerPage: newItemsPerPage, page: 1 });
        }}
        onDensityChange={setDensity}
        onColumnSizingChange={setColumnSizing}
        onColumnPinningChange={setColumnPinning}
        onColumnVisibilityChange={setColumnVisibility}
        onColumnOrderChange={setColumnOrder}
        renderRowActions={Actions}
        muiTableContainerProps={{
          sx: {
            maxHeight: "calc(100vh - var(--appbar-height) - 145px)",
          },
        }}
        muiTablePaperProps={({ table }) => ({
          sx: (theme) => ({
            minWidth: 0,
            width: "100%",
            padding: `${theme.spacing(
              table.getState().isFullScreen ? 1 : 2
            )}!important`,
          }),
        })}
        muiTableHeadCellProps={{
          sx: {
            "& .Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
            },
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              sx: {
                "& .MuiSvgIcon-root": {
                  marginTop: "0px",
                },
              },
            },
            muiTableBodyCellProps: {
              sx: {
                textAlign: "center",
              },
            },
          },
        }}
        renderDetailPanel={({ row: { original } }) => (
          <SubTable bordereauItems={original.bordereauItems} />
        )}
        muiDetailPanelProps={{
          sx: {
            padding: "0px!important",
            width: "100%",
            "& .MuiCollapse-root": {
              width: "100%",
            },
          },
        }}
      />
    </PageContainer>
  );
};

export default Bordereaux;
