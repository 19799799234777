import React, { useMemo, useState } from "react";
import {
  Loader,
  TablePagination,
  PageContainer,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
} from "@periplus/ui-library";
import ImportListTriggerCard from "./ImportListTriggerCard";
import ActionBar from "./ActionBar";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import { useTranslation } from "react-i18next";
import CreateImportListTriggerDialog from "./CreateImportListTriggerDialog";
import useGetImportListTriggers from "domain/importListTrigger/useGetImportListTriggers";
import { ImportListTrigger } from "../../../domain/importListTrigger/types";
import { AlertDialog } from "@periplus/ui-library";
import useDeleteNotificationTrigger from "../../../domain/importListTrigger/useDeleteNotificationTrigger";
import { useSnackbar } from "notistack";
import usePageSettings from "domain/user/usePageSettings";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const ImportListTriggersList = () => {
  const { pageSettings, setPageSettings } = usePageSettings<{
    itemsPerPage: number;
  }>({
    itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
  });

  const { classes } = useStyles();
  const { t } = useTranslation();
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<{
    page: number;
    itemsPerPage: number;
  }>({
    page: 1,
    itemsPerPage: pageSettings.itemsPerPage,
  });
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [currentVal, setCurrentVal] = useState<ImportListTrigger | null>(null);

  const deleteNotificationTrigger = useDeleteNotificationTrigger();
  const { enqueueSnackbar } = useSnackbar();

  const { page, itemsPerPage } = useMemo(
    () => ({
      page: urlSearchParams.page,
      itemsPerPage: urlSearchParams.itemsPerPage,
    }),
    [urlSearchParams]
  );

  const getImportListTriggersVariables = useMemo(
    () => ({
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
    }),
    [page, itemsPerPage]
  );

  const { import_list_triggers, totalCount, loading, refetch } =
    useGetImportListTriggers(getImportListTriggersVariables);

  const handlePageChange = (value: number) => {
    setUrlSearchParams({ page: value });
  };
  const handleChangeItemsPerPage = (value: number) => {
    setUrlSearchParams({ page: 1, itemsPerPage: value });
    setPageSettings({
      itemsPerPage: value,
    });
  };

  const handleCreateClick = () => {
    setCurrentVal(null);
    setIsCreateDialogOpen(true);
  };
  const handleEditClick = (importListTrigger: ImportListTrigger) => {
    setTimeout(() => {
      setCurrentVal(importListTrigger);
      setIsCreateDialogOpen(true);
    });
  };

  const handleCreateDialogClose = () => {
    setIsCreateDialogOpen(false);
  };

  const handleCreateDialogConfirm = async () => {
    await refetch();
    setIsCreateDialogOpen(false);
  };

  const handleDeleteClick = (importListTrigger: ImportListTrigger) => {
    setTimeout(() => {
      setCurrentVal(importListTrigger);
      setIsDeleteDialogOpen(true);
    });
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = async () => {
    await deleteNotificationTrigger({
      variables: {
        id: currentVal!.id,
      },
    });
    enqueueSnackbar(t("importListTrigger:deleted"), { variant: "success" });
    await refetch();
    setIsDeleteDialogOpen(false);
  };

  return (
    <PageContainer title={t(`navigation:alerts`)}>
      {!import_list_triggers.length && loading ? (
        <Loader />
      ) : (
        <>
          <ActionBar onCreateClick={handleCreateClick} />
          {!import_list_triggers.length ? (
            <div className={classes.noData}>{t("noData")}</div>
          ) : (
            <>
              {import_list_triggers.map((trigger, index) => (
                <ImportListTriggerCard
                  key={trigger.id}
                  trigger={trigger}
                  index={index}
                  onEditClick={handleEditClick}
                  onDeleteClick={handleDeleteClick}
                />
              ))}
              {totalCount > TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0] && (
                <div className={classes.paginationContainer}>
                  <TablePagination
                    page={+urlSearchParams.page}
                    totalItems={totalCount}
                    itemsPerPage={+urlSearchParams.itemsPerPage}
                    onChangePage={handlePageChange}
                    onChangeItemsPerPage={handleChangeItemsPerPage}
                  />
                </div>
              )}
            </>
          )}
          {isCreateDialogOpen && (
            <CreateImportListTriggerDialog
              onClose={handleCreateDialogClose}
              onConfirm={handleCreateDialogConfirm}
              importListTrigger={currentVal}
            />
          )}
          {isDeleteDialogOpen && (
            <AlertDialog
              variant={"notice"}
              onClose={handleDeleteDialogClose}
              onConfirm={handleDeleteDialogConfirm}
            >
              {`${t("importListTrigger:deletePrompt")} "${
                currentVal?.display_name
              }"?`}
            </AlertDialog>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default ImportListTriggersList;
