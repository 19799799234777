import React, { FC, HtmlHTMLAttributes, useState } from "react";
import { Theme } from "@periplus/ui-library";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Button } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { makeStyles } from 'tss-react/mui';

interface ExportToExcelButtonProps extends HtmlHTMLAttributes<HTMLElement> {
  onClick: () => Promise<void>;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
}));

const ExportToExcelButton: FC<ExportToExcelButtonProps> = ({ onClick }) => {
  const { classes } = useStyles();
  const { t } = useTranslation("statistics");
  const [loading, setLoading] = useState(false);

  return (
    <Button
      className={classes.root}
      variant="contained"
      color="primary"
      onClick={async () => {
        setLoading(true);
        await onClick();
        setLoading(false);
      }}
      minimizeForMobiles
      endIcon={<InsertDriveFileOutlinedIcon />}
      loading={loading}
    >
      {t("Export to Excel")}
    </Button>
  );
};

export default ExportToExcelButton;
