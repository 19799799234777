import React, { FC, HtmlHTMLAttributes } from "react";
import ACPlaceForm from "./ACPlaceForm";
import { useFormikContext } from "formik";
import { Checkbox, Theme } from "@periplus/ui-library";
import { makeStyles } from 'tss-react/mui';

interface CustomsPlaceProps extends HtmlHTMLAttributes<HTMLElement> {}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  checkbox: {
    alignSelf: "baseline",
    padding: 0,
  },
}));

const CustomsPlace: FC<CustomsPlaceProps> = () => {
  const { classes } = useStyles();
  const { setValues, values } = useFormikContext<any>();

  return (
    <div className={classes.root}>
      <Checkbox
        className={classes.checkbox}
        checked={values.customs_place.is_allowed_consignee}
        onChange={(event, checked) => {
          setValues({
            ...values,
            customs_place: {
              is_allowed_consignee: checked,
              wa_number: "",
            },
          });
        }}
      />
      <ACPlaceForm disabled={!values.customs_place.is_allowed_consignee} />
    </div>
  );
};

export default CustomsPlace;
