import React, { FC } from "react";
import { useGetDownloadUrl } from "utils/azureBlobStorageUtils";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { ListItemIcon, ListItemText, MenuItem } from "@periplus/ui-library";
import { TransformedDocument } from "../DocumentsTab";

interface ShareButtonProps {
  document: TransformedDocument;
  onClick: () => void;
}

const ShareButton: FC<ShareButtonProps> = ({ document, onClick }) => {
  const { t } = useTranslation("declaration");

  const { url: downloadUrl } = useGetDownloadUrl(
    document.document_blobs?.find(
      (blob: any) => blob.extension === document.document_extension
    )?.url,
    "application/pdf",
    `inline; filename="${encodeURI(
      document.document_name?.includes(".")
        ? document.document_name
        : `${document.document_name}.${document.document_extension}`
    )}"`
  );

  return (
    <CopyToClipboard text={downloadUrl || ""}>
      <MenuItem
        onClick={() => {
          onClick();
        }}
      >
        <ListItemIcon>
          <ShareOutlinedIcon sx={{ color: "text.secondary" }} />
        </ListItemIcon>
        <ListItemText
          primary={t("Copy link")}
          primaryTypographyProps={{ color: "text.secondary" }}
        />
      </MenuItem>
    </CopyToClipboard>
  );
};

export default ShareButton;
