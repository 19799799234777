import React, { FC } from "react";
import DeclarationField, {
  DeclarationFieldProps,
} from "domain/declaration/components/DeclarationField";

interface FieldProps {
  label: DeclarationFieldProps["label"];
  value: DeclarationFieldProps["value"];
}

const Field: FC<FieldProps> = (props) => {
  return <DeclarationField {...props} noWrap view="card" />;
};

export default Field;
