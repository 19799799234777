import { useState, useMemo, useEffect } from "react";
import {
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  ThreeDotsMenu,
  ListItemIcon,
  ListItemText,
  MRTable,
} from "@periplus/ui-library";
import LinkOffOutlinedIcon from "@mui/icons-material/LinkOffOutlined";
import { useTranslation } from "react-i18next";

import { TableLocalStorage } from "hooks/usePageLocalStorage";
import useUrlSearchParams, {
  TableUrlSearchParams,
} from "hooks/useUrlSearchParams";
import usePageSettings from "domain/user/usePageSettings";
import PageContainer from "../components/PageContainer";
import useGetLinkedAddressesManagement, {
  LinkedAddressManagementEntity,
} from "./hooks/useGetLinkedAddressesManagement";
import UnlinkAddressDialog from "./components/UnlinkAddressDialog";

export default () => {
  const { t } = useTranslation();
  const [addressDialogState, setAddressDialogState] = useState<{
    dialog?: "unlink";
    address?: LinkedAddressManagementEntity;
  }>({});

  const { pageSettings, setPageSettingsForMRTable } =
    usePageSettings<TableLocalStorage>({
      itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
    });

  const { urlSearchParams, setUrlSearchParams } =
    useUrlSearchParams<TableUrlSearchParams>({
      page: 1,
      itemsPerPage: pageSettings.itemsPerPage,
    });

  const {
    data: { addresses, totalAddresses },
    loading,
    refetch,
  } = useGetLinkedAddressesManagement(urlSearchParams);

  const [columns, tableData] = useMemo<
    [
      MRTable.MRT_ColumnDef<LinkedAddressManagementEntity>[],
      LinkedAddressManagementEntity[]
    ]
  >(
    () => [
      [
        {
          id: "sourceAddress",
          header: `${t("Address")}:`,
          columns: [
            {
              header: t("company"),
              id: "sourceAddressCompanyName",
              accessorFn: (originalRow) =>
                originalRow.sourceAddress.companyName,
            },
            {
              header: t("address"),
              id: "sourceAddressStreet",
              accessorFn: (originalRow) =>
                [
                  originalRow.sourceAddress.street,
                  originalRow.sourceAddress.streetNumber,
                ]
                  .filter((el) => el)
                  .join(" "),
            },
            {
              header: t("zip"),
              id: "sourceAddressZipcode",
              accessorFn: (originalRow) => originalRow.sourceAddress.zipcode,
            },
            {
              header: t("city"),
              id: "sourceAddressCity",
              accessorFn: (originalRow) => originalRow.sourceAddress.city,
            },
            {
              header: t("country"),
              id: "sourceAddressCountry",
              accessorFn: (originalRow) => originalRow.sourceAddress.country,
            },
          ],
        },
        {
          id: "targetAddress",
          header: `${t("Linked to")}:`,
          columns: [
            {
              header: t("company"),
              id: "targetAddressCompanyName",
              accessorFn: (originalRow) =>
                originalRow.targetAddress.companyName,
            },
            {
              header: t("address"),
              id: "targetAddressStreet",
              accessorFn: (originalRow) =>
                [
                  originalRow.targetAddress.street,
                  originalRow.targetAddress.streetNumber,
                ]
                  .filter((el) => el)
                  .join(" "),
            },
            {
              header: t("zip"),
              id: "targetAddressZipcode",
              accessorFn: (originalRow) => originalRow.targetAddress.zipcode,
            },
            {
              header: t("city"),
              id: "targetAddressCity",
              accessorFn: (originalRow) => originalRow.targetAddress.city,
            },
            {
              header: t("country"),
              id: "targetAddressCountry",
              accessorFn: (originalRow) => originalRow.targetAddress.country,
            },
          ],
        },
      ],
      [...addresses],
    ],
    [t, addresses]
  );

  const pagination = useMemo<MRTable.MRT_PaginationState>(
    () => ({
      pageIndex: urlSearchParams.page - 1,
      pageSize: urlSearchParams.itemsPerPage,
    }),
    [urlSearchParams.page, urlSearchParams.itemsPerPage]
  );
  const [density, setDensity] = useState<MRTable.MRT_DensityState>(
    pageSettings.density ?? "compact"
  );
  const [columnSizing, setColumnSizing] =
    useState<MRTable.MRT_ColumnSizingState>(pageSettings.columnSizing ?? {});

  useEffect(
    () => {
      setPageSettingsForMRTable({
        itemsPerPage: urlSearchParams.itemsPerPage,
        density,
        columnSizing,
      });
    },
    // eslint-disable-next-line
    [urlSearchParams.itemsPerPage, density, columnSizing]
  );

  return (
    <PageContainer
    // onAddressCreated={() => {
    //   refetch();
    // }}
    >
      <MRTable.Table
        columns={columns}
        data={tableData}
        state={{
          globalFilter: urlSearchParams.search,
          pagination,
          density,
          columnSizing,
          isLoading: loading,
        }}
        enableRowActions
        enableSorting={false}
        enableColumnActions={false}
        enableColumnOrdering={false}
        renderToolbarInternalActions={({ table }) => {
          return (
            <>
              <MRTable.MRT_ToggleDensePaddingButton table={table} />
              <MRTable.MRT_ToggleFullScreenButton table={table} />
            </>
          );
        }}
        onGlobalFilterChange={(updater) => {
          const newState =
            updater instanceof Function
              ? updater(urlSearchParams.search)
              : updater;
          setUrlSearchParams({ search: newState || undefined, page: 1 });
        }}
        manualPagination
        manualFiltering
        rowCount={totalAddresses}
        onPaginationChange={(updater) => {
          const newState =
            updater instanceof Function ? updater(pagination) : updater;
          const newPage = newState.pageIndex + 1;
          if (urlSearchParams.page !== newPage)
            setUrlSearchParams({ page: newPage });

          const newItemsPerPage = newState.pageSize;
          if (urlSearchParams.itemsPerPage !== newState.pageSize)
            setUrlSearchParams({ itemsPerPage: newItemsPerPage, page: 1 });
        }}
        onDensityChange={setDensity}
        onColumnSizingChange={setColumnSizing}
        renderRowActions={({ row: { original } }) => (
          <ThreeDotsMenu
            options={[
              {
                content: (
                  <>
                    <ListItemIcon>
                      <LinkOffOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("Unlink", { ns: "addresses" })} />
                  </>
                ),
                action: (close) => {
                  setAddressDialogState({
                    dialog: "unlink",
                    address: original,
                  });
                  close();
                },
              },
            ]}
          />
        )}
        muiTableContainerProps={{
          sx: {
            maxHeight: "calc(100vh - var(--appbar-height) - 145px)",
          },
        }}
        muiTablePaperProps={({ table }) => ({
          sx: (theme) => ({
            minWidth: 0,
            width: "100%",
            padding: `${theme.spacing(
              table.getState().isFullScreen ? 1 : 2
            )}!important`,
          }),
        })}
        muiTableHeadCellProps={{
          sx: {
            "& .Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
            },
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableBodyCellProps: {
              sx: {
                justifyContent: "center",
              },
            },
          },
        }}
      />
      {addressDialogState?.dialog === "unlink" && (
        <UnlinkAddressDialog
          addressId={addressDialogState.address!.sourceAddress.id}
          onClose={() => setAddressDialogState({})}
          onConfirm={() => {
            refetch();
            setAddressDialogState({});
          }}
        />
      )}
    </PageContainer>
  );
};
