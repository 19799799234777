import { FC, useCallback, useState } from "react";
import { Box, PageContainer, Loader } from "@periplus/ui-library";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import useGetDeclarationDetails from "./hooks/useGetDeclarationDetails";
import useGetDeclarationDetailsDocuments from "pages/Declaration/hooks/useGetDeclarationDetailsDocuments";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import DocumentsTab from "./DocumentsTab/DocumentsTab";
import GeneralTab from "./GeneralTab";
import FileUpload from "./FileUpload";
import EditButton from "./EditButton";
import { uploadFile } from "utils/azureBlobStorageUtils";
import useCreateDeclaration from "domain/declaration/useCreateDeclaration";
import { useCustomDropzone } from "./useCustomDropzone";

const tabs = [
  {
    id: "general",
    label: "General",
  },
  {
    id: "documents",
    label: "Documents",
  },
];

const Declaration: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation("declaration");
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: { declaration },
    refetch: refetchDeclaration,
  } = useGetDeclarationDetails({
    file_id: id,
  });

  const {
    data: { documents },
    refetch: refetchDeclarationDocuments,
  } = useGetDeclarationDetailsDocuments({ file_id: id });

  const [isFilesUploads, setIsFilesUploads] = useState(false);
  const createDeclaration = useCreateDeclaration();

  const onDrop = useCallback(
    (files: File[]) => {
      if (files.length === 0) {
        return;
      }
      setIsFilesUploads(true);

      const filesUploadRequests = files.map(uploadFile);
      Promise.all(filesUploadRequests)
        .then(
          async (data: any[]) => {
            await createDeclaration({
              variables: {
                documents: data.map(({ name, blobUri }) => ({
                  fileName: name,
                  fileUrl: blobUri,
                })),
                declaration: {
                  declaration_status: "documents_recieved",
                },
                fileId: declaration?.fileId,
              },
            });
            refetchDeclaration();
            refetchDeclarationDocuments();
            history.push(`${url}/${tabs[1].id}`);
            setIsFilesUploads(false);
            enqueueSnackbar(t("Uploaded! Document will be available soon"), {
              variant: "success",
            });
          },
          (data) => {}
        )
        .catch(() => setIsFilesUploads(false));
    },
    [
      createDeclaration,
      declaration,
      refetchDeclaration,
      refetchDeclarationDocuments,
      history,
      url,
      enqueueSnackbar,
      t,
    ]
  );

  const { getRootProps: getScreenRootProps } = useCustomDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    noDrag: false,
  });

  return (
    <PageContainer
      {...getScreenRootProps()}
      title={
        declaration?.addressReferences &&
        `${t("navigation:declaration")}: ${declaration?.addressReferences}`
      }
      tabs={tabs.map(({ label }) => t(label))}
      selectedTab={tabs.findIndex(
        ({ id }) => id === pathname.split("/").pop()!
      )}
      onTabChange={(newTabIndex) =>
        history.push(`${url}/${tabs[newTabIndex].id}`)
      }
      controls={
        declaration && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <FileUpload onFileUpload={onDrop} isFilesUploads={isFilesUploads} />
            <EditButton
              declaration={declaration}
              onEdit={() => {
                refetchDeclaration();
              }}
            />
          </Box>
        )
      }
    >
      {!declaration || !documents ? (
        <Loader />
      ) : (
        <Switch>
          <Route path={`${path}/general`}>
            <GeneralTab
              declaration={declaration}
              onLabelsAssigned={() => refetchDeclaration()}
            />
          </Route>
          <Route path={`${path}/documents`}>
            <DocumentsTab
              declaration={declaration}
              documents={documents}
              onEVVConfirmedChange={async () => {
                await refetchDeclaration();
                await refetchDeclarationDocuments();
              }}
              onClassificated={refetchDeclarationDocuments}
              onDeleted={refetchDeclarationDocuments}
            />
          </Route>
        </Switch>
      )}
    </PageContainer>
  );
};

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/general`} />
      <Declaration />
    </Switch>
  );
};
