import React, { FC } from "react";
import { useGetDownloadUrl } from "utils/azureBlobStorageUtils";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import { useTranslation } from "react-i18next";
import { ListItemIcon, ListItemText, MenuItem } from "@periplus/ui-library";
import { TransformedDocument } from "../DocumentsTab";
import { download } from "utils/files";

interface DownloadButtonProps {
  document: TransformedDocument;
  onClick: () => void;
}

const DownloadButton: FC<DownloadButtonProps> = ({ document, onClick }) => {
  const { t } = useTranslation();

  const { url: downloadUrl } = useGetDownloadUrl(
    document.document_blobs?.find(
      (blob: any) => blob.extension === document.document_extension
    )?.url,
    "application/pdf",
    `attachment; filename="${encodeURI(
      document.document_name?.includes(".")
        ? document.document_name
        : `${document.document_name}.${document.document_extension}`
    )}"`
  );

  return (
    <MenuItem
      onClick={() => {
        download(downloadUrl!);
        onClick();
      }}
    >
      <ListItemIcon>
        <GetAppOutlinedIcon sx={{ color: "text.secondary" }} />
      </ListItemIcon>
      <ListItemText
        primary={t("tooltips:download")}
        primaryTypographyProps={{ color: "text.secondary" }}
      />
    </MenuItem>
  );
};

export default DownloadButton;
