import { BaseRequestPolicy, HttpOperationResponse } from "@azure/storage-blob";
import gql from "graphql-tag";
import client from "graphql/client";
import * as Cookies from "js-cookie";

class SasStore {
  public getValidSASForContainer(
    container: string,
    contentType?: string,
    contentDisposition?: string
  ) {
    let key = `${container}-sasToken${contentType ? `-${contentType}` : ""}`;
    const jsonSasToken = contentDisposition
      ? window.sessionStorage.getItem(`${key}-${contentDisposition}`)
      : Cookies.get(key);
    const sasToken: { value: string; expiryDate: string } | null =
      jsonSasToken && JSON.parse(jsonSasToken);

    if (sasToken) {
      const tokenExpiryTimeInMilliseconds =
        new Date(sasToken.expiryDate).getTime() - new Date().getTime();
      const tokenExpiryTimeInSeconds = tokenExpiryTimeInMilliseconds / 1000;

      if (tokenExpiryTimeInSeconds > 30) {
        return sasToken;
      }
    }

    if (contentDisposition) {
      window.sessionStorage.removeItem(key);
    }

    return undefined;
  }

  public async getOrFetchValidSASForContainer(
    container: string,
    contentType?: string,
    contentDisposition?: string
  ) {
    let key = `${container}-sasToken${contentType ? `-${contentType}` : ""}`;

    const jsonSasToken = contentDisposition
      ? window.sessionStorage.getItem(`${key}-${contentDisposition}`)
      : Cookies.get(key);

    const sasToken: { value: string; expiryDate: string } | undefined =
      jsonSasToken && JSON.parse(jsonSasToken);

    if (
      !sasToken ||
      new Date().getTime() >= new Date(sasToken.expiryDate).getTime()
    ) {
      return await this.updateSASForContainer(
        container,
        contentType,
        contentDisposition
      );
    }

    return sasToken;
  }

  public async updateSASForContainer(
    container: string,
    contentType?: string,
    contentDisposition?: string
  ): Promise<{ value: string; expiryDate: string }> {
    const { data } = await client.query({
      query: gql`
        query weDeclareGetSASToken(
          $container: String!
          $contentType: String
          $contentDisposition: String
        ) {
          getSASToken(
            container: $container
            contentType: $contentType
            contentDisposition: $contentDisposition
          ) {
            container
            sasToken
            expiryDate
          }
        }
      `,
      fetchPolicy: "no-cache",
      variables: {
        container,
        contentType,
        contentDisposition,
      },
    });

    if (data) {
      let key = `${container}-sasToken${contentType ? `-${contentType}` : ""}`;
      const value = data.getSASToken.sasToken;

      const stringifyValue = JSON.stringify({
        value,
        expiryDate: data.getSASToken.expiryDate,
      });

      if (contentDisposition) {
        key = key + "-" + contentDisposition;
        window.sessionStorage.setItem(key, stringifyValue);
      } else {
        Cookies.set(key, stringifyValue, {
          expires: new Date(data.getSASToken.expiryDate),
        });
      }
      return {
        value: data.getSASToken.sasToken,
        expiryDate: data.getSASToken.expiryDate,
      };
    }

    return {
      value: "",
      expiryDate: new Date().toDateString(),
    };
  }
}

class SasUpdatePolicyFactory {
  private readonly sasStore: any;

  constructor(sasStore: any) {
    this.sasStore = sasStore;
  }

  create(nextPolicy: any, options: any) {
    return new SasUpdatePolicy(nextPolicy, options, this.sasStore);
  }
}

class SasUpdatePolicy extends BaseRequestPolicy {
  private sasStore: any;

  constructor(nextPolicy: any, options: any, sasStore: any) {
    super(nextPolicy, options);
    this.sasStore = sasStore;
  }

  async sendRequest(request: any): Promise<HttpOperationResponse> {
    const urlObj = new URL(request.url);
    const sas = await this.sasStore.getValidSASForContainer(
      `${urlObj.origin}${urlObj.pathname}`
    );
    new URL(`http://hostname${sas}`).searchParams.forEach((value, key) => {
      urlObj.searchParams.set(key, value);
    });

    // Update request URL with latest SAS
    request.url = urlObj.toString();

    return this._nextPolicy.sendRequest(request);
  }
}

export { SasUpdatePolicy, SasUpdatePolicyFactory, SasStore };
