import { FC, useCallback, useState } from "react";
import {
  FiltersSidebar as LuiFiltersSidebar,
  Box,
  Autocomplete,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { useDebounceCallback } from "@react-hook/debounce";
import { AddressType } from "domain/declaration/types";
import { getEnumKeys } from "utils/enums";

export interface AddressesManagementFilters {
  address_types?: number[];
}

interface FiltersForm
  extends Omit<Required<AddressesManagementFilters>, "address_types"> {
  address_types: number[];
}

interface FiltersSidebarProps {
  filters?: AddressesManagementFilters;
  onChange: (newFilters?: AddressesManagementFilters) => void;
}

const defaultFilters: FiltersForm = {
  address_types: [],
};

const FiltersSidebar: FC<FiltersSidebarProps> = ({ filters, onChange }) => {
  const { t } = useTranslation();

  const [values, setValues] = useState<FiltersForm>({
    ...defaultFilters,
    ...filters,
    address_types: filters?.address_types ?? defaultFilters.address_types,
  });

  const debouncedOnChange = useDebounceCallback((newValues: typeof values) => {
    const newFilters = {
      address_types: newValues.address_types,
    };
    onChange(
      Object.values(newFilters).some((filter) => filter !== undefined)
        ? newFilters
        : undefined
    );
  }, 300);

  const handleChange = useCallback(
    (newValues: typeof values) => {
      setValues(newValues);
      debouncedOnChange(newValues);
    },
    [debouncedOnChange]
  );

  return (
    <Box
      sx={(theme) => ({
        minWidth: 240,
        borderRight: `1px solid ${theme.palette.grey3.main}`,
      })}
    >
      <LuiFiltersSidebar
        sx={(theme) => ({
          maxHeight: "calc(100vh - var(--appbar-height))",
          overflow: "auto",
          borderRight: `1px solid ${theme.palette.grey3.main}`,
          position: "fixed",
          top: "var(--appbar-height)",
        })}
        groups={[
          {
            title: t("common:General"),
            active: Boolean(values.address_types.length),
            content: (
              <>
                <Autocomplete<{ id: number; label: string }, true>
                  value={
                    values.address_types?.map((atId) => ({
                      id: atId,
                      label: t(`addressTypes:${AddressType[atId]}`),
                    })) ?? []
                  }
                  options={getEnumKeys(AddressType).map((atName) => ({
                    id: AddressType[atName],
                    label: t(`addressTypes:${atName}`),
                  }))}
                  onChange={(e, newAddressTypes) => {
                    handleChange({
                      ...values,
                      address_types: newAddressTypes.map((at) => at.id),
                    });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  multiple
                  InputProps={{
                    label: t("Type"),
                  }}
                />
              </>
            ),
          },
        ]}
        onClear={() => {
          setValues(defaultFilters);
          onChange(undefined);
        }}
      />
    </Box>
  );
};

export default FiltersSidebar;
