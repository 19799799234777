import React, { FC, useMemo, useState } from "react";
import { BoxProps } from "@periplus/ui-library";
import SmallThumbnail from "./SmallThumbnail";
import { TransformedDocument } from "../DocumentsTab";
import BigThumbnail from "./BigThumbnail";

interface PagesProps extends BoxProps {
  document: TransformedDocument;
}

const Pages: FC<PagesProps> = ({ document }) => {
  const [bigThumbnailOpen, setBbigThumbnailOpen] = useState(false);

  const pages = useMemo(
    () =>
      document.pages_m2m
        .filter(
          ({ page }) =>
            document.documentType.name !== "unknown" ||
            (document.documentType.name === "unknown" &&
              page?.pageStatus !== "classified")
        )
        .sort((a, b) => a.pageNumber - b.pageNumber),
    [document]
  );

  return (
    <>
      <SmallThumbnail
        pages={pages}
        extension={document.document_extension}
        onClick={() => setBbigThumbnailOpen(true)}
      />
      {bigThumbnailOpen && (
        <BigThumbnail
          pages={pages}
          onClose={() => setBbigThumbnailOpen(false)}
        />
      )}
    </>
  );
};

export default Pages;
