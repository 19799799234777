import React from "react";
import ErrorScreen from "pages/ErrorScreen";

/**
 * Prevents a catastrophic error from bubbling up the React tree
 * and displays a slightly better experience than the default
 * blank white screen.
 */
export default class ErrorBoundary extends React.Component<React.PropsWithChildren> {
  state = {
    crashed: false,
  };

  public static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { crashed: true };
  }

  componentDidCatch(error: Error) {
    console.error(error);
    this.setState({ crashed: true });
  }

  render() {
    const { crashed } = this.state;

    if (crashed) {
      return <ErrorScreen />;
    }

    return this.props.children;
  }
}
