export function convertPrimitive<
  T1 extends string | number | boolean | null | undefined,
  T2 extends "string" | "number" | "boolean"
>(
  variable: T1,
  type: T2
): T2 extends "string"
  ? string | Exclude<T1, number | boolean>
  : T2 extends "number"
  ? number | Exclude<T1, string | boolean>
  : T2 extends "boolean"
  ? boolean | Exclude<T1, number | string>
  : never;
export function convertPrimitive(val: any, type: string): unknown {
  if (val === null || val === undefined) return val;

  switch (type) {
    case "string":
      return String(val);
    case "number":
      return Number(val);
    case "boolean":
      return Boolean(val);
    default:
      return null;
  }
}
