import React, { FC, useCallback } from "react";
import { FormFileField, Typography } from "@periplus/ui-library";
import config from "config";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { formatBytes } from "utils/files";
import { FileRejection } from "react-dropzone";
import { ACCEPTED_FILE_EXTENSIONS } from "pages/Declaration/useCustomDropzone";
import { makeStyles } from 'tss-react/mui';

interface DeclarationFileFieldProps {
  name: string;
  label: string;
  classes?: any;
  fullWidth?: boolean;
  className?: string;
}

const useStyles = makeStyles()({
  helperText: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem",
    marginTop: 3,
    textAlign: "left",
    fontWeight: 400,
    lineHeight: 1.66,
  },
});

const DeclarationFileField: FC<DeclarationFileFieldProps> = ({
  name,
  label,
  classes,
  fullWidth,
  className,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { classes: innerClasses } = useStyles();

  const handleDropRejected = useCallback(
    (files: FileRejection[]) => {
      files.forEach(({ file: { name, size } }) => {
        const fileExtension = `.${name.split(".").pop()}`;
        if (!ACCEPTED_FILE_EXTENSIONS.includes(fileExtension)) {
          enqueueSnackbar(
            t("error:notSupportedFileExtension", {
              fileName: name,
              acceptedFileExtensions: ACCEPTED_FILE_EXTENSIONS.join(", "),
            }),
            { variant: "error" }
          );
          return;
        }

        if (size > +config.maxFileSize) {
          enqueueSnackbar(
            t("error:maxFileSize", {
              fileName: name,
              maxFileSize: formatBytes(+config.maxFileSize),
            }),
            { variant: "error" }
          );
          return;
        }
      });
    },
    [enqueueSnackbar, t]
  );

  return (
    <div className={className}>
      <FormFileField
        name={name}
        label={label}
        fullWidth={fullWidth}
        accept={ACCEPTED_FILE_EXTENSIONS}
        maxSize={+config.maxFileSize}
        onDropRejected={handleDropRejected}
        classes={classes}
        noClick={false}
        noKeyboard={false}
        noDrag={true}
      />
      <Typography className={innerClasses.helperText}>
        (
        {t(
          "newDeclaration:Commercial Invoice, Packing List, Certificate of Preferential Origin, Delivery Note, etc."
        )}{" "}
        )
      </Typography>
    </div>
  );
};

export default DeclarationFileField;
