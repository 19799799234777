import React, {
  useContext,
  useReducer,
  createContext,
  ReactNode,
  useMemo,
} from "react";
import useGetEdecMasterData, {
  EdecData,
} from "domain/edec/useGetEdecMasterData";

enum ReducerActions {
  toggleShowNotifications,
  toggleGotNewMessages,
}
type NotificationsPayload = { isNotificationsShown?: boolean };
type NewMessagePayload = { isNewMessages?: boolean };
type BreadcrumbParamsPayload = { [key: string]: any };

type Action = {
  type: ReducerActions;
  payload: NotificationsPayload | NewMessagePayload;
};
type Dispatchers = {
  toggleNotificationsShown: (state?: boolean) => void;
  toggleNewMessages: (state?: boolean) => void;
};
type State = NotificationsPayload &
  NewMessagePayload &
  BreadcrumbParamsPayload & { edecData: EdecData };

const AppStateContext = createContext<State | undefined>(undefined);
const AppDispatchContext = createContext<Dispatchers | undefined>(undefined);

const AppReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ReducerActions.toggleShowNotifications: {
      let { isNotificationsShown } = action.payload as NotificationsPayload;

      if (isNotificationsShown === undefined) {
        isNotificationsShown = !state.isNotificationsShown;
      }

      return {
        ...state,
        isNewMessages: false,
        isNotificationsShown,
      };
    }
    case ReducerActions.toggleGotNewMessages: {
      let { isNewMessages } = action.payload as NewMessagePayload;

      if (isNewMessages === undefined) {
        isNewMessages = !state.isNewMessages;
      }

      return {
        ...state,
        isNewMessages,
      };
    }
    default: {
      return state;
    }
  }
};

const useAppReducer = (): [State, Dispatchers] => {
  const edecData = useGetEdecMasterData();
  const [state, dispatch] = useReducer(AppReducer, {
    isNotificationsShown: false,
    isNewMessages: false,
    edecData,
  });

  const actions = useMemo(
    () => ({
      toggleNotificationsShown: (notificationsShownState?: boolean) => {
        dispatch({
          type: ReducerActions.toggleShowNotifications,
          payload: { isNotificationsShown: notificationsShownState },
        });
      },
      toggleNewMessages: (newMessagesState?: boolean) => {
        dispatch({
          type: ReducerActions.toggleGotNewMessages,
          payload: { isNewMessages: newMessagesState },
        });
      },
    }),
    []
  );

  return [state, actions];
};

const AppProvider = ({ children }: { children: ReactNode }) => {
  const [state, actions] = useAppReducer();

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={actions}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

const useAppState = () => {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error(
      "useNavigationState must be used within a NavigationProvider"
    );
  }
  return context;
};

const useAppAction = () => {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useNavigationAction must be used within a NavigationProvider"
    );
  }
  return context;
};

const useAppContext = (): [State, Dispatchers] => [
  useAppState(),
  useAppAction(),
];

export { AppProvider, useAppAction, useAppState, useAppContext };
