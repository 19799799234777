import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  WdUpdateNotificationTriggerMutation,
  WdUpdateNotificationTriggerMutationVariables,
} from "graphql/generated";

const useUpdateNotificationTrigger = () => {
  const [updateNotificationTrigger] = useMutation<
    WdUpdateNotificationTriggerMutation,
    WdUpdateNotificationTriggerMutationVariables
  >(
    gql`
      mutation WdUpdateNotificationTrigger(
        $id: Int!
        $data: NotificationTriggerInput!
      ) {
        updateNotificationTrigger(id: $id, data: $data) {
          id
          displayName
          type
          config
          creationUser{
            id
          }
          creationDate
          lastModifiedUser{
            id
          }
          lastModifiedDate
          meta
        }
      }
    `
  );

  return updateNotificationTrigger;
};

export default useUpdateNotificationTrigger;
