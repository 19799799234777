import { useMemo } from "react";
import { AutocompleteProps, Box, Chip, Tooltip } from "@periplus/ui-library";
import {
  useGetAddressesLazy,
  AddressListEntity,
  getAddressesQueryVariables,
} from "../useGetAddresses";
import { AddressType } from "domain/declaration/types";
import { useAuth } from "keycloak";
import { useDebounceCallback } from "@react-hook/debounce";
import { NotUndefinable, Undefinable } from "utils/utilityTypes";

export interface AddressAutocompleteBaseProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends Undefinable<
    AutocompleteProps<AddressListEntity, Multiple, DisableClearable, false>,
    "options"
  > {
  addressTypes?: AddressType[];
  excludeAddresses?: number[];
}

export default <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>({
  loading,
  options,
  addressTypes,
  excludeAddresses,
  ...rest
}: AddressAutocompleteBaseProps<Multiple, DisableClearable>): NotUndefinable<
  AddressAutocompleteBaseProps<Multiple, DisableClearable>,
  "options"
> => {
  const { user } = useAuth();

  const [
    searchAddresses,
    {
      data: { addresses },
      loading: searchAddressesLoading,
    },
  ] = useGetAddressesLazy();

  const debouncedSearch = useDebounceCallback((search?: string) => {
    searchAddresses({
      variables: getAddressesQueryVariables({
        search,
        addressTypes,
        limit: 10,
      }),
    });
  }, 300);

  const AddressDetails = ({ address }: { address: AddressListEntity }) => (
    <Box>
      <Box>{address.companyName}</Box>
      <Box>
        {address.country} {address.city} {address.street} {address.zipcode}
      </Box>
      {user?.isMnrUser && !!address.reference && <Box>{address.reference}</Box>}
    </Box>
  );

  const transformedOptions = useMemo(() => {
    const counts = {};
    return (options ?? addresses)
      .filter((address) => !excludeAddresses?.includes(address.id))
      .map((address) => {
        counts[address.companyName] =
          counts[address.companyName] === undefined
            ? 0
            : counts[address.companyName] + 1;

        return {
          ...address,
          companyName:
            address.companyName +
            (counts[address.companyName]
              ? ` (${counts[address.companyName]})`
              : ""),
        };
      });
  }, [options, addresses, excludeAddresses]);

  return {
    options: transformedOptions,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    loading: loading || searchAddressesLoading,
    onOpen: () => {
      debouncedSearch();
    },
    getOptionLabel: (option) => [option.companyName].join("\n"),
    renderOption: (props, option) => (
      <li {...props}>
        <AddressDetails address={option} />
      </li>
    ),
    renderTags: (value, getTagProps) =>
      value.map((option, index) => (
        <Tooltip
          title={<AddressDetails address={option} />}
          disableInteractive
          key={option.id}
        >
          <Chip label={option.companyName} {...getTagProps({ index })} />
        </Tooltip>
      )),
    autoComplete: true,
    filterSelectedOptions: true,
    onInputChange: (event, newInputValue) => {
      !options && debouncedSearch(newInputValue);
    },
    ...(!options && { filterOptions: (x) => x }),
    ...rest,
  };
};
