import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { AlertDialog } from "@periplus/ui-library";
import useChangeDeclarationStatus from "domain/declaration/useChangeDeclarationStatus";
import { DeclarationDashboardEntity } from "../hooks/useGetDeclarationsDashboard";

interface ICancelDeclaration {
  declaration: DeclarationDashboardEntity;
  onClose(): void;
}

const CancelDeclarationDialog: FC<ICancelDeclaration> = ({
  declaration,
  onClose,
}) => {
  const { t } = useTranslation();
  const changeDeclarationStatus = useChangeDeclarationStatus();

  const handleConfirmDialog = () => {
    onClose();
    changeDeclarationStatus(declaration.fileId, "declaration_canceled");
  };

  return (
    <AlertDialog
      variant="warning"
      CancelButtonProps={{ label: t("abort") }}
      ConfirmButtonProps={{ label: t("declaration:cancelDeclaration") }}
      onClose={onClose}
      onConfirm={handleConfirmDialog}
    >
      {`${t("newDeclaration:cancelRequest")} "${
        declaration.addressReferences
      }"?`}
    </AlertDialog>
  );
};

export default CancelDeclarationDialog;
