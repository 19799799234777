import React, { FC, useCallback, useState } from "react";
import {
  FiltersSidebar as LuiFiltersSidebar,
  Typography,
  Box,
  TextField,
  Autocomplete,
  DatePicker,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { useDebounceCallback } from "@react-hook/debounce";
import dayjs, { Dayjs } from "dayjs";

type RelativeDate =
  | "today"
  | "last2weeks"
  | "last4weeks"
  | "last1month"
  | "last3month"
  | "last6month";

export const BORDERAUX_FILTER_RELATIVE_DATES = {
  today: {
    label: "Today",
    minDate: dayjs().startOf("day"),
    maxDate: dayjs().endOf("day"),
  },
  last2weeks: {
    label: "Last 2 weeks",
    minDate: dayjs().subtract(2, "weeks").startOf("day"),
    maxDate: dayjs().endOf("day"),
  },
  last4weeks: {
    label: "Last 4 weeks",
    minDate: dayjs().subtract(4, "weeks").startOf("day"),
    maxDate: dayjs().endOf("day"),
  },
  last1month: {
    label: "Previous Month",
    minDate: dayjs().subtract(1, "month").startOf("month").startOf("day"),
    maxDate: dayjs().subtract(1, "month").endOf("month").endOf("day"),
  },
  last3month: {
    label: "Last 3 Month",
    minDate: dayjs().subtract(3, "month").startOf("month").startOf("day"),
    maxDate: dayjs().subtract(1, "month").endOf("month").endOf("day"),
  },
  last6month: {
    label: "Last 6 Month",
    minDate: dayjs().subtract(6, "month").startOf("month").startOf("day"),
    maxDate: dayjs().subtract(1, "month").endOf("month").endOf("day"),
  },
};

const BORDEREAU_STATUSES = ["Completed", "NotCompleted"] as const;
type BordereauStatuses = (typeof BORDEREAU_STATUSES)[number];

export interface BorderauxFilters {
  status?: BordereauStatuses;
  vatFrom?: number;
  vatTo?: number;
  dutiesFrom?: number;
  dutiesTo?: number;
  relative_date?: RelativeDate;
  minDate?: string;
  maxDate?: string;
}

interface BorderauxFiltersForm
  extends Omit<
    Required<BorderauxFilters>,
    | "status"
    | "vatFrom"
    | "vatTo"
    | "dutiesFrom"
    | "dutiesTo"
    | "minDate"
    | "maxDate"
    | "relative_date"
  > {
  status: BordereauStatuses | null;
  vatFrom: string;
  vatTo: string;
  dutiesFrom: string;
  dutiesTo: string;
  relative_date: RelativeDate | null;
  minDate: Dayjs | null;
  maxDate: Dayjs | null;
}

interface BorderauxFiltersSidebarProps {
  filters?: BorderauxFilters;
  onChange: (newFilters?: BorderauxFilters) => void;
}

const defaultFilters: BorderauxFiltersForm = {
  status: null,
  vatFrom: "",
  vatTo: "",
  dutiesFrom: "",
  dutiesTo: "",
  relative_date: null,
  minDate: null,
  maxDate: null,
};

const FiltersSidebar: FC<BorderauxFiltersSidebarProps> = ({
  filters,
  onChange,
}) => {
  const { t } = useTranslation();

  const [values, setValues] = useState<BorderauxFiltersForm>({
    ...defaultFilters,
    ...filters,
    vatFrom: filters?.vatFrom?.toString() ?? defaultFilters.vatFrom,
    vatTo: filters?.vatTo?.toString() ?? defaultFilters.vatTo,
    dutiesFrom: filters?.dutiesFrom?.toString() ?? defaultFilters.dutiesFrom,
    dutiesTo: filters?.dutiesTo?.toString() ?? defaultFilters.dutiesTo,
    relative_date: filters?.relative_date || null,
    ...(filters?.relative_date
      ? {
          minDate:
            BORDERAUX_FILTER_RELATIVE_DATES[filters.relative_date].minDate,
          maxDate:
            BORDERAUX_FILTER_RELATIVE_DATES[filters.relative_date].maxDate,
        }
      : {
          minDate: filters?.minDate ? dayjs(filters?.minDate) : null,
          maxDate: filters?.maxDate
            ? dayjs(filters?.maxDate).endOf("day")
            : null,
        }),
  });

  const debouncedOnChange = useDebounceCallback((newValues: typeof values) => {
    const castToNumber = (value: string) =>
      value === "" ? undefined : parseFloat(value);
    const newFilters = {
      status: newValues.status || undefined,
      vatFrom: castToNumber(newValues.vatFrom),
      vatTo: castToNumber(newValues.vatTo),
      dutiesFrom: castToNumber(newValues.dutiesFrom),
      dutiesTo: castToNumber(newValues.dutiesTo),
      relative_date: newValues.relative_date ?? undefined,
      ...(!newValues.relative_date && {
        minDate: newValues.minDate?.startOf("day").format(),
        maxDate: newValues.maxDate?.endOf("day").format(),
      }),
    };
    onChange(
      Object.values(newFilters).some((filter) => filter !== undefined)
        ? newFilters
        : undefined
    );
  }, 300);

  const handleChange = useCallback(
    (newValues: typeof values) => {
      setValues(newValues);
      debouncedOnChange(newValues);
    },
    [debouncedOnChange]
  );

  return (
    <Box
      sx={(theme) => ({
        minWidth: 240,
        borderRight: `1px solid ${theme.palette.grey3.main}`,
      })}
    >
      <LuiFiltersSidebar
        sx={(theme) => ({
          maxHeight: "calc(100vh - var(--appbar-height))",
          overflow: "auto",
          borderRight: `1px solid ${theme.palette.grey3.main}`,
          position: "fixed",
          top: "var(--appbar-height)",
        })}
        groups={[
          {
            title: t("common:General"),
            active: Boolean(
              values.status ||
                values.dutiesFrom ||
                values.dutiesTo ||
                values.vatFrom ||
                values.vatTo
            ),
            content: (
              <>
                <Typography variant="overline" color="textSecondary">
                  {t("Completed")}:
                </Typography>
                <Autocomplete
                  value={values.status}
                  options={BORDEREAU_STATUSES}
                  getOptionLabel={(option) => t(`bordereauStatus:${option}`)}
                  fullWidth
                  onChange={(e, newValue) =>
                    handleChange({
                      ...values,
                      status: newValue,
                    })
                  }
                />
                <Typography variant="overline" color="textSecondary">
                  {t("VAT")}
                </Typography>
                <TextField
                  label={t("common:From")}
                  value={values.vatFrom}
                  type="number"
                  onChange={({ target: { value: newValue } }) =>
                    handleChange({ ...values, vatFrom: newValue })
                  }
                />
                <TextField
                  label={t("common:To")}
                  value={values.vatTo}
                  type="number"
                  onChange={({ target: { value: newValue } }) =>
                    handleChange({ ...values, vatTo: newValue })
                  }
                />
                <Typography variant="overline" color="textSecondary">
                  {t("Duties")}
                </Typography>
                <TextField
                  label={t("common:From")}
                  value={values.dutiesFrom}
                  type="number"
                  onChange={({ target: { value: newValue } }) =>
                    handleChange({ ...values, dutiesFrom: newValue })
                  }
                />
                <TextField
                  label={t("common:To")}
                  value={values.dutiesTo}
                  type="number"
                  onChange={({ target: { value: newValue } }) =>
                    handleChange({ ...values, dutiesTo: newValue })
                  }
                />
              </>
            ),
          },
          {
            title: t("Timeframe"),
            active: Boolean(
              filters?.relative_date !== undefined ||
                filters?.minDate ||
                filters?.maxDate
            ),
            content: (
              <>
                {(() => {
                  return (
                    <Autocomplete<RelativeDate>
                      //@ts-ignore
                      value={values.relative_date}
                      options={[
                        "today",
                        "last2weeks",
                        "last4weeks",
                        "last1month",
                        "last3month",
                        "last6month",
                      ]}
                      onChange={(e, newValue) =>
                        handleChange({
                          ...values,
                          relative_date: newValue,
                          minDate: newValue
                            ? BORDERAUX_FILTER_RELATIVE_DATES[newValue].minDate
                            : null,
                          maxDate: newValue
                            ? BORDERAUX_FILTER_RELATIVE_DATES[newValue].maxDate
                            : null,
                        })
                      }
                      getOptionLabel={(option) =>
                        t(BORDERAUX_FILTER_RELATIVE_DATES[option].label)
                      }
                      InputProps={{
                        label: t("Recent Days"),
                      }}
                    />
                  );
                })()}
                <Typography variant="overline" color="textSecondary">
                  {t("or date range")}
                </Typography>
                <DatePicker
                  value={values.minDate}
                  onChange={(newValue) =>
                    handleChange({
                      ...values,
                      relative_date: null,
                      minDate: newValue,
                    })
                  }
                  label={t("From")}
                  slotProps={{
                    field: {
                      clearable: true,
                    },
                  }}
                />
                <DatePicker
                  value={values.maxDate}
                  onChange={(newValue) =>
                    handleChange({
                      ...values,
                      relative_date: null,
                      maxDate: newValue,
                    })
                  }
                  label={t("To")}
                  slotProps={{
                    field: {
                      clearable: true,
                    },
                  }}
                />
              </>
            ),
          },
        ]}
        onClear={() => {
          setValues(defaultFilters);
          onChange(undefined);
        }}
      />
    </Box>
  );
};

export default FiltersSidebar;
