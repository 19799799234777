import React, { FC } from "react";
import { Theme } from "@periplus/ui-library";
import { Typography, Button, Checkbox } from "@periplus/ui-library";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { useGetDownloadUrl } from "utils/azureBlobStorageUtils";
import { makeStyles } from 'tss-react/mui';

interface CustomDocumentProps {
  customDocument: any;
  reference: string;
  selected: boolean;
  onToggleSelect: (url: string) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    gap: 16,
    "&:not(:last-child)": {
      marginBottom: 16,
    },
  },
  titleContainer: {
    flexGrow: 1,
  },
  checkbox: {
    backgroundColor: "white!important",
  },
}));

const CustomDocument: FC<CustomDocumentProps> = ({
  customDocument,
  reference,
  selected,
  onToggleSelect,
}) => {
  const { classes } = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { url: downloadUrl } = useGetDownloadUrl(
    customDocument.document_blobs?.find(
      (blob: any) => blob.extension === customDocument.document_extension
    )?.url,
    "application/pdf",
    `attachment; filename="${encodeURI(
      `${reference}_${customDocument.documentType?.translations[language]}.${customDocument.document_extension}`
        .toLowerCase()

        .replaceAll(" ", "_")
    )}"`
  );

  const docName = customDocument.documentType?.translations[language]
    ?.toLowerCase()
    .replaceAll(" ", "_");

  return (
    <div className={classes.root}>
      <Checkbox
        variant="outlined"
        className={classes.checkbox}
        checked={selected}
        onChange={() => onToggleSelect(downloadUrl as string)}
      />
      <div className={classes.titleContainer}>
        <Typography
          variant="subtitle1"
          color={selected ? "primary" : "textSecondary"}
        >
          {t(`customsDocDialog:${docName}`)}
        </Typography>
        <Typography color="textSecondary">
          {dayjs(customDocument.creation_date).format("DD.MM.YYYY hh:mm:ss")}
        </Typography>
      </div>
      <a href={downloadUrl} download style={{ textDecoration: "none" }}>
        <Button
          variant="outlined"
          color="primary"
          endIcon={<GetAppOutlinedIcon />}
        >
          {customDocument.document_extension.toUpperCase()}
        </Button>
      </a>
    </div>
  );
};

export default CustomDocument;
