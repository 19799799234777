import React, { FC, useState } from "react";
import {
  BoxProps,
  List,
  ListItem,
  Theme,
  useLayoutContext,
  useMediaQuery,
  Typography,
  Box,
  Dialog,
} from "@periplus/ui-library";
import { NavigationItem } from "./Layout";
import { useAuth } from "keycloak";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface SidebarContentProps extends BoxProps {
  navigation: NavigationItem[];
  close: () => void;
}

const SidebarContent: FC<SidebarContentProps> = ({ navigation, close }) => {
  const { user, updateUiSettings } = useAuth();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isUpLaptop = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up("laptop"),
    { noSsr: true }
  );
  const location = useLocation();
  const history = useHistory();
  const { layoutState } = useLayoutContext();
  const [version, setVersion] = useState<string>();
  const [releaseNotes, setReleaseNotes] = useState<
    {
      version: string;
      date: string;
      notes: { en: string; de: string }[];
    }[]
  >();
  const [isReleaseNotesDialogOpen, setIsReleaseNotesDialogOpen] =
    useState(false);

  React.useEffect(() => {
    fetch("/version.txt")
      .then((response) => response.text())
      .then(setVersion);
    fetch("/release-notes.json")
      .then((response) => response.json())
      .then(setReleaseNotes);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <List sx={{ p: 0, px: 2, alignSelf: "stretch" }}>
        {navigation
          .filter(({ permission }) =>
            permission ? user?.hasAllowedPermissions([permission]) : true
          )
          .map(({ Icon, text, redirecting }, index) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                if (!isUpLaptop) {
                  close();
                }
                if (redirecting.includes("http")) {
                  window.open(redirecting, "_blank");
                } else {
                  history.push(redirecting);
                }
              }}
              selected={`/${location.pathname.split("/")[1]}` === redirecting}
              sx={(theme) => ({
                padding: "6px 8px",
                color: "white",
                "&:not(:last-child)": {
                  marginBottom: 1,
                },
                "&.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  borderRadius: "4px",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                  "& .MuiListItemIcon-root": {
                    color: "inherit",
                  },
                },
              })}
            >
              <Icon
                sx={{
                  display: "inline-flex",
                  flexShrink: 0,
                  color: "white",
                }}
              />
              {layoutState.sidebarOpen && (
                <Typography sx={{ color: "white", ml: 1 }}>{text}</Typography>
              )}
            </ListItem>
          ))}
      </List>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          pb: 3,
          position: "relative",
        }}
      >
        <Typography
          variant="body2"
          noWrap
          onClick={() => {
            setIsReleaseNotesDialogOpen(true);
            updateUiSettings({ lastCheckedReleaseNotes: version });
          }}
          sx={{
            color: "#9596AF",
            cursor: "pointer",
          }}
        >
          {layoutState.sidebarOpen && `WD `} {version}
        </Typography>
        {user?.uiSettings.lastCheckedReleaseNotes !== version && (
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              width: 8,
              height: 8,
              borderRadius: "50%",
              right: -10,
              top: 0,
              background: theme.palette.error.main,
              color: "white",
              fontSize: 11,
            })}
          />
        )}
        <Dialog
          open={isReleaseNotesDialogOpen}
          mainTitle={t("Release Notes")}
          onClose={() => setIsReleaseNotesDialogOpen(false)}
        >
          {releaseNotes?.map((releaseNote) => (
            <Box key={releaseNote.version}>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ fontWeight: 500 }}
              >
                {`${t("Version")} ${releaseNote.version}`}
              </Typography>
              <List>
                {releaseNote.notes.map((note, index) => (
                  <ListItem key={index} sx={{ pl: 2, color: "#9596AF" }}>
                    <Box
                      sx={{
                        width: 4,
                        height: 4,
                        borderRadius: "50%",
                        background: "#9596AF",
                        mr: 1,
                      }}
                    />
                    {note[language]}
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Dialog>
      </Box>
    </Box>
  );
};

export default SidebarContent;
