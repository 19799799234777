import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "@periplus/ui-library";

interface CustomsIconProps extends SvgIconProps {}

const CustomsIcon: FC<CustomsIconProps> = ({ style, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 485 485" style={{ fill: "none", ...style }} {...rest}>
      <rect
        x="13"
        y="25"
        width="194"
        height="435"
        rx="6"
        stroke="currentColor"
        strokeWidth="25"
      />
      <rect
        x="13"
        y="25"
        width="194"
        height="78"
        rx="6"
        stroke="currentColor"
        strokeWidth="25"
      />
      <rect
        x="65"
        y="145"
        width="91"
        height="143"
        rx="6"
        stroke="currentColor"
        strokeWidth="25"
      />
      <rect
        x="207"
        y="258"
        width="265"
        height="65"
        rx="6"
        stroke="currentColor"
        strokeWidth="25"
      />
      <path
        d="M279.054 323L317.159 257M279.054 323H342.274M279.054 323H218L256.105 257H317.159M317.159 257H380.379M342.274 323L380.379 257M342.274 323H402.895L441 257H380.379"
        stroke="currentColor"
        strokeWidth="20"
      />
      <path
        d="M460 326V465"
        stroke="currentColor"
        strokeWidth="15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CustomsIcon;
