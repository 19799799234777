import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  WdAddNotificationTriggerMutation,
  WdAddNotificationTriggerMutationVariables,
} from "graphql/generated";

const useAddNotificationTrigger = () => {
  const [addNotificationTrigger] = useMutation<
    WdAddNotificationTriggerMutation,
    WdAddNotificationTriggerMutationVariables
  >(
    gql`
      mutation WdAddNotificationTrigger($data: NotificationTriggerInput!) {
        addNotificationTrigger(data: $data) {
          id
          type
          config
          creationUser{
            id
          }
          creationDate
          lastModifiedUser{
            id
          }
          lastModifiedDate
          meta
        }
      }
    `
  );

  return addNotificationTrigger;
};

export default useAddNotificationTrigger;
