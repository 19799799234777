import { useMemo } from "react";
import _edecData from "./edecData.json";

type EdecDomain = {
  id: number;
  domain_name: string;
  meaning_de: string;
  meaning_en: string;
  valid_from: string;
  valid_to: string;
  value: string;
};

type EdecCountry = {
  id: number;
  iso_code: string;
  name_en: string;
  name_de: string;
  valid_from: string;
  valid_to: string;
};

export type EdecCustomsOffice = {
  id: number;
  number: string;
  name: string;
  valid_from: string;
  valid_to: string;
};

type EdecTariffCommodityCode = {
  id: number;
  valid_from: string;
  valid_to: string;
  value: string;
};

type EdecTariffStatisticalCode = {
  id: number;
  valid_from: string;
  valid_to: string;
  value: number;
};

export interface EdecData {
  edec_domains: EdecDomain[];
  edec_countries: EdecCountry[];
  edec_customs_offices: EdecCustomsOffice[];
  edec_tariff_commodity_code: EdecTariffCommodityCode[];
  edec_tariff_statistical_code: EdecTariffStatisticalCode[];
}

export const filterAndSortEdecData = <T extends { valid_from: any }>(
  edec?: T[]
) => {
  const result =
    edec &&
    [...edec].sort(
      (a, b) =>
        new Date(b.valid_from).getTime() - new Date(a.valid_from).getTime()
    );

  return result;
};

const edecData = _edecData as EdecData;

export default () => {
  return useMemo(() => {
    return {
      edec_domains:
        filterAndSortEdecData(edecData.edec_domains)?.reduce<EdecDomain[]>(
          (acc, curr) => {
            if (
              !acc.find(
                (item) =>
                  item.domain_name === curr.domain_name &&
                  item.value === curr.value
              )
            ) {
              acc.push(curr);
            }
            return acc;
          },
          []
        ) ?? [],
      edec_countries:
        filterAndSortEdecData(edecData.edec_countries)?.reduce<EdecCountry[]>(
          (acc, curr) => {
            if (!acc.find((item) => item.iso_code === curr.iso_code)) {
              acc.push(curr);
            }
            return acc;
          },
          []
        ) ?? [],
      edec_customs_offices:
        filterAndSortEdecData(edecData.edec_customs_offices)?.reduce<
          EdecCustomsOffice[]
        >((acc, curr) => {
          if (!acc.find((item) => item.number === curr.number)) {
            acc.push(curr);
          }
          return acc;
        }, []) ?? [],
      edec_tariff_commodity_code:
        filterAndSortEdecData(edecData.edec_tariff_commodity_code)?.reduce<
          EdecTariffCommodityCode[]
        >((acc, curr) => {
          if (!acc.find((item) => item.value === curr.value)) {
            acc.push(curr);
          }
          return acc;
        }, []) ?? [],
      edec_tariff_statistical_code:
        filterAndSortEdecData(edecData.edec_tariff_statistical_code)?.reduce<
          EdecTariffStatisticalCode[]
        >((acc, curr) => {
          if (!acc.find((item) => item.value === curr.value)) {
            acc.push(curr);
          }
          return acc;
        }, []) ?? [],
    } as EdecData;
  }, []);
};
