import { useMemo } from "react";
import gql from "graphql-tag";
import {
  WdGetKpiQuery,
  useWdGetKpiCommodityCodeQuery,
} from "graphql/generated";

export type KPIEntity = NonNullable<WdGetKpiQuery["kpis"]>;
export type KPICustomAgencyEntity = NonNullable<
  WdGetKpiQuery["kpis"]
>["customsAgencies"][number];

gql`
  query WdGetKpiCommodityCode(
    $date_from: String!
    $date_to: String!
    $commodity_code: String
    $tenant_id: String
    $trader_identification_number: String
  ) {
    pp_get_kpi_commodity_code(
      date_from: $date_from
      date_to: $date_to
      commodity_code: $commodity_code
      tenant_id: $tenant_id
      trader_identification_number: $trader_identification_number
    ) {
      declarationCount
      goodsItemCount
      totalNetMass
      totalGrossMass
    }
  }
`;

export default (...args: Parameters<typeof useWdGetKpiCommodityCodeQuery>) => {
  const result = useWdGetKpiCommodityCodeQuery(...args);

  return useMemo(
    () => ({
      ...result,
      data: {
        commodityCodesKpi: (result.data ?? result.previousData)
          ?.pp_get_kpi_commodity_code,
      },
    }),
    [result]
  );
};
