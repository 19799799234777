import React, { FC, HtmlHTMLAttributes } from "react";
import { Box, Loader, TablePagination } from "@periplus/ui-library";
import Card from "./Card";
import { useTranslation } from "react-i18next";
import ActionBar from "../ActionBar";
import { DeclarationDashboardEntity } from "../hooks/useGetDeclarationsDashboard";

interface CardsProps extends HtmlHTMLAttributes<HTMLElement> {
  loading: boolean;
  declarations: DeclarationDashboardEntity[];
  page: number;
  itemsPerPage: number;
  onPageChange: (value: number) => void;
  onItemsPerPageChange: (value: number) => void;
  view: "cards" | "table";
  onViewChange: (value: "cards" | "table") => void;
  search?: string;
  onSearchChange: (value?: string) => void;
  onLabelsAssigned: () => void;
  isLastPage: boolean;
}

const Cards: FC<CardsProps> = ({
  declarations,
  loading,
  page,
  itemsPerPage,
  onPageChange,
  onItemsPerPageChange,
  view,
  onViewChange,
  search,
  onSearchChange,
  onLabelsAssigned,
  isLastPage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ActionBar
        view={view}
        onViewChange={onViewChange}
        search={search}
        onSearchChange={onSearchChange}
      />
      <Box sx={{ marginTop: 2 }}>
        {loading ? (
          <Loader />
        ) : declarations.length ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                //   maxHeight: "calc(100vh - var(--appbar-height) - 145px)",
                //   overflow: "auto"
              }}
            >
              {declarations.map((declaration) => (
                <Card
                  declaration={declaration}
                  onLabelsAssigned={onLabelsAssigned}
                  key={JSON.stringify({
                    file_id: declaration.fileId,
                    dr_no: declaration.fileId,
                  })}
                />
              ))}
            </Box>
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <TablePagination
                page={page}
                isLastPage={isLastPage}
                itemsPerPage={itemsPerPage}
                onChangePage={onPageChange}
                onChangeItemsPerPage={onItemsPerPageChange}
              />
            </Box>
          </>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center">
            {t("noData")}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Cards;
