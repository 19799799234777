import { useMutation, MutationHookOptions } from "@apollo/client";
import gql from "graphql-tag";
import useErrorHandling from "hooks/useErrorHandling";

export const CLASSIFY_WE_DECLARE_DOCUMENT = gql`
  mutation CLASSIFY_WE_DECLARE_DOCUMENT($document_type: String!, $doc_id: ID!) {
    classifyWeDeclareDocument(
      doc_id: $doc_id
      document_type: $document_type
      is_customs_relevant: true
    )
  }
`;

const useUpdateDocumentType = () => {
  const withErrorHandling = useErrorHandling({ propagate: true });
  const [assignDocumentType] = useMutation(CLASSIFY_WE_DECLARE_DOCUMENT);

  const handleUpdate = (options?: MutationHookOptions) => {
    return withErrorHandling(assignDocumentType, options);
  };

  return handleUpdate;
};

export default useUpdateDocumentType;
