import { useCallback } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface UseErrorHandling {
  propagate?: boolean;
}

const useErrorHandling = (params?: UseErrorHandling) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation() as {
    t: any;
  };

  const { propagate } = params || {};
  const withErrorHandling = useCallback(
    async (callback, ...args) => {
      try {
        return await callback.apply(callback, args);
      } catch (e) {
        enqueueSnackbar(t("error:serverError"), { variant: "error" });
        if (propagate) throw e;
      }
    },
    [enqueueSnackbar, t, propagate]
  );

  return withErrorHandling;
};

export default useErrorHandling;
