import { Paper, PaperProps, Typography } from "@periplus/ui-library";

interface Props extends PaperProps {
  title: string;
  value: number | string;
  valuePrefix?: string;
  primary?: boolean;
}

export default ({ title, value, valuePrefix, primary }: Props) => {
  const formattedValue =
    typeof value === "number"
      ? (isFinite(value) ? value : 0).toFixed(2)
      : value;

  return (
    <Paper
      variant="outlined"
      sx={(theme) => ({
        p: 2,
        flexGrow: 1,
        ...(primary && {
          backgroundColor: theme.palette.primary.main,
          color: "white",
        }),
      })}
    >
      <Typography variant="h6" noWrap>
        {title}
      </Typography>
      <Typography variant="h1">
        {valuePrefix && valuePrefix + " "}
        {formattedValue.endsWith(".00")
          ? formattedValue.slice(0, -3)
          : formattedValue}
      </Typography>
    </Paper>
  );
};
