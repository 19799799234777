import {
  WdGetLinkedAddressesManagementQuery,
  useWdGetLinkedAddressesManagementQuery,
} from "graphql/generated";
import { TableUrlSearchParams } from "hooks/useUrlSearchParams";
import { useMemo } from "react";

export type LinkedAddressManagementEntity = NonNullable<
  WdGetLinkedAddressesManagementQuery["linkedAddresses"]
>[number];

export default (searchParams: TableUrlSearchParams) => {
  const result = useWdGetLinkedAddressesManagementQuery({
    variables: {
      offset: (searchParams.page - 1) * searchParams.itemsPerPage,
      limit: searchParams.itemsPerPage,
      ...(searchParams.search && { search: searchParams.search.trim() }),
    },
    fetchPolicy: "no-cache",
  });

  return useMemo(() => {
    const addresses =
      (result.data ?? result.previousData)?.linkedAddresses ?? [];
    return {
      ...result,
      data: {
        addresses,
        totalAddresses: (addresses[0]?.qty as number) ?? 0,
      },
    };
  }, [result]);
};
