import React, { FC } from "react";
import { Box } from "@periplus/ui-library";
import { Button } from "@periplus/ui-library";
import Add from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

interface ActionBarProps {
  onCreateClick: () => void;
}

const ActionBar: FC<ActionBarProps> = ({ onCreateClick }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", mb: 1.5 }}>
      <Button
        color="primary"
        endIcon={<Add />}
        variant="contained"
        onClick={onCreateClick}
        minimizeForMobiles
        sx={{ ml: "auto" }}
      >
        {t("importListTrigger:createRule")}
      </Button>
    </Box>
  );
};

export default ActionBar;
