import React, { FC, useMemo } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import useUpdateNotificationTrigger from "../../domain/importListTrigger/useUpdateNotificationTrigger";
import useAddNotificationTrigger from "../../domain/importListTrigger/useAddNotificationTrigger";
import {
  FTATrigger,
  HighCustomFeesTrigger,
  ImportListTrigger,
  NOTIFICATION_TRIGGER_CONFIG_TYPES,
  SpecificHsCodesTrigger,
  TriggerTypesEnum,
} from "../../domain/importListTrigger/types";
import {
  ALL_COUNTRIES,
  FILTER_EU_CHF,
  FILTER_EU_FTA,
  FILTER_REST,
} from "../../domain/importListTrigger/countries";

interface ImportListTriggerFormikProps {
  children: any;
  onSubmit?: () => void;
  importListTrigger?: ImportListTrigger | null;
}

const ImportListTriggerFormik: FC<ImportListTriggerFormikProps> = ({
  children,
  onSubmit,
  importListTrigger,
}) => {
  const { t } = useTranslation();

  const updateNotificationTrigger = useUpdateNotificationTrigger();
  const addNotificationTrigger = useAddNotificationTrigger();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      display_name: Yup.string().trim().required().max(50),
      type: Yup.object().nullable().required(),
      recipients: Yup.string().when(
        "notifyBy",
        (notifyBy: any, schema: any) => {
          if (notifyBy === "email") {
            return schema
              .required()
              .matches(
                /^(\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4}\s*?,?\s*?)+$/,
                t("importListTrigger:emailRequiredRule")
              );
          }
        }
      ),
      labels: Yup.array().when("notifyBy", (notifyBy: any, schema: any) => {
        if (notifyBy === "label") {
          return schema
            .min(1, t("error:required"))
            .max(1, t("importListTrigger:Only one label can be selected"));
        }
      }),
      specificHsCodes: Yup.string().when("type", (type: any, schema: any) => {
        if (type?.id === TriggerTypesEnum.SpecificHsCodes) {
          return schema
            .required()
            .matches(
              /^[0-9]{2,8}(?:,[0-9]{8})*$/gm,
              t("importListTrigger:hsRequiredAndFormat")
            );
        } else {
          return schema.nullable();
        }
      }),
      countryOfOrigin: Yup.string()
        .nullable()
        .when("type", (type: any, schema: any) => {
          if (type?.id === TriggerTypesEnum.HighCustomFeesTrigger) {
            return schema.required();
          }

          return schema;
        }),
      feeAmount: Yup.number().when("type", (type: any, schema: any) => {
        if (
          type?.id === TriggerTypesEnum.HighCustomFeesTrigger ||
          type?.id === TriggerTypesEnum.FTA
        ) {
          return schema.required().positive().min(0);
        } else {
          return schema.notRequired();
        }
      }),
      hsCode: Yup.string().when("type", (type: any, schema: any) => {
        if (type?.id === TriggerTypesEnum.FTA) {
          return schema
            .matches(
              /^[0-9]{2,8}$/gm,
              t("importListTrigger:hsOneRequiredAndFormat")
            )
            .notRequired();
        } else {
          return schema.nullable();
        }
      }),
      checkPreference: Yup.boolean().when("type", (type: any, schema: any) => {
        if (
          [
            TriggerTypesEnum.HighCustomFeesTrigger,
            TriggerTypesEnum.SpecificHsCodes,
            TriggerTypesEnum.FTA,
          ].includes(type?.id)
        ) {
          return schema.required();
        } else {
          return schema.notRequired();
        }
      }),
    });
  }, [t]);

  const initialValues = useMemo(() => {
    const defaultValues = {
      display_name: "",
      type: null,
      recipients: "",
      specificHsCodes: "",
      countryOfOrigin: null,
      feeAmount: 0,
      eu_chf_countries: [],
      efta_countries: [],
      other_countries: [],
      hsCode: "",
      notifyBy: "email",
      labels: [],
      checkPreference: false,
    };
    if (!importListTrigger) {
      return defaultValues;
    }

    const { display_name, config, label } = importListTrigger;

    return {
      ...defaultValues,
      display_name,
      type: NOTIFICATION_TRIGGER_CONFIG_TYPES.find(
        (d) => d.id === importListTrigger.type
      ),
      notifyBy: label ? "label" : "email",
      labels: label ? [label] : [],
      ...config,
      recipients: config.recipients?.join(","),
      ...(importListTrigger.type === TriggerTypesEnum.HighCustomFeesTrigger && {
        countryOfOrigin:
          (config as HighCustomFeesTrigger).countryOfOrigin ||
          defaultValues.countryOfOrigin,
        checkPreference: (config as HighCustomFeesTrigger).checkPreference,
      }),
      ...(importListTrigger.type === TriggerTypesEnum.SpecificHsCodes && {
        specificHsCodes: (
          config as SpecificHsCodesTrigger
        ).specificHsCodes.join(","),
        checkPreference: (config as SpecificHsCodesTrigger).checkPreference,
      }),
      ...(importListTrigger.type === TriggerTypesEnum.FTA && {
        eu_chf_countries: FILTER_EU_CHF(
          ALL_COUNTRIES.filter((c) =>
            new Set((config as FTATrigger).countries).has(c.id)
          )
        ),
        efta_countries: FILTER_EU_FTA(
          ALL_COUNTRIES.filter((c) =>
            new Set((config as FTATrigger).countries).has(c.id)
          )
        ),
        other_countries: FILTER_REST(
          ALL_COUNTRIES.filter((c) =>
            new Set((config as FTATrigger).countries).has(c.id)
          )
        ),
        checkPreference: (config as SpecificHsCodesTrigger).checkPreference,
      }),
    };
  }, [importListTrigger]);

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    const onError = (error: any) => {
      enqueueSnackbar(t("common:serverError"), { variant: "error" });
      setSubmitting(false);
    };
    const onSuccess = () => {
      setSubmitting(false);
      onSubmit && onSubmit();
    };
    const castedValues: any = validationSchema.cast(values);

    const {
      display_name,
      recipients,
      type,
      countryOfOrigin,
      feeAmount,
      specificHsCodes,
      eu_chf_countries,
      efta_countries,
      other_countries,
      hsCode,
      notifyBy,
      labels,
      checkPreference,
    } = castedValues;

    const newImportListTrigger = {
      displayName: display_name,
      notificationType: type?.id,
      labelId: notifyBy === "label" ? labels[0].id : null,
      config: (() => {
        const config = {
          recipients: recipients?.split(",").map((d) => d.trim()),
          countryOfOrigin: countryOfOrigin ?? "",
          feeAmount,
          hsCode,
          specificHsCodes: specificHsCodes?.split(",").map((d) => d.trim()),
          countries: [
            ...eu_chf_countries,
            ...efta_countries,
            ...other_countries,
          ].map((c) => c.id),
          checkPreference,
        };

        let mandatoryFields: string[] = [];
        if (notifyBy === "email") {
          mandatoryFields.push("recipients");
        }
        switch (type?.id) {
          case TriggerTypesEnum.RetrievalOfImportList:
            mandatoryFields = [...mandatoryFields];
            break;
          case TriggerTypesEnum.HighCustomFeesTrigger:
            mandatoryFields = [
              ...mandatoryFields,
              "feeAmount",
              "countryOfOrigin",
              "checkPreference",
            ];
            break;
          case TriggerTypesEnum.SpecificHsCodes:
            mandatoryFields = [
              ...mandatoryFields,
              "specificHsCodes",
              "checkPreference",
            ];
            break;
          case TriggerTypesEnum.FTA:
            mandatoryFields = [
              ...mandatoryFields,
              "hsCode",
              "feeAmount",
              "countries",
              "checkPreference",
            ];
            break;
        }
        const l = new Set(mandatoryFields);
        for (let prop of Object.keys(config)) {
          if (!l.has(prop)) {
            delete config[prop];
          }
        }
        return config;
      })(),
      meta: {},
    };

    if (!importListTrigger) {
      addNotificationTrigger({
        variables: {
          data: newImportListTrigger,
        },
      })
        .then(onSuccess)
        .catch(onError);
    } else {
      updateNotificationTrigger({
        variables: {
          id: importListTrigger?.id,
          data: newImportListTrigger,
        },
      })
        .then(onSuccess)
        .catch(onError);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {children}
    </Formik>
  );
};

export default ImportListTriggerFormik;
