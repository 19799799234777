import { FC } from "react";
import { useMediaQuery, Button, Theme } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useCustomDropzone } from "./useCustomDropzone";
import { makeStyles } from 'tss-react/mui';

interface FileUploadProps {
  onFileUpload: (files: File[]) => void;
  isFilesUploads: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    maxWidth: 130,
  },
}));

const FileUpload: FC<FileUploadProps> = ({ onFileUpload, isFilesUploads }) => {
  const { t } = useTranslation("declaration");
  const { classes } = useStyles();
  const isUpTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("tablet")
  );

  const { getRootProps: getButtonRootProps, getInputProps } = useCustomDropzone(
    { onDrop: onFileUpload, noClick: false, noKeyboard: false, noDrag: true }
  );

  return (
    <Button
      className={classes.root}
      variant="outlined"
      endIcon={isUpTablet && <CloudUploadOutlinedIcon />}
      loading={isFilesUploads}
      {...getButtonRootProps()}
      color="secondary"
      sx={{
        backgroundColor: "white",
      }}
    >
      <input {...getInputProps()} />
      {isUpTablet ? t("declaration:upload") : <CloudUploadOutlinedIcon />}
    </Button>
  );
};

export default FileUpload;
