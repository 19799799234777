import { useMemo } from "react";
import gql from "graphql-tag";
import {
  WdGetDeclarationDetailsQuery,
  useWdGetDeclarationDetailsQuery,
} from "graphql/generated";

export type DeclarationDetailsEntity = NonNullable<
  WdGetDeclarationDetailsQuery["pp_declaration_requests"]
>[number];

gql`
  query WdGetDeclarationDetails($file_id: UUID!) {
    pp_declaration_requests(args: { _file_id: $file_id }) {
      fileId
      drNo
      declarationLabels {
        id
        title
        description
        color
        isDeleted
      }
      declarationRequest {
        fileId
        drNo
        trNo
        chExpDecl
        impCustClr
        transitdocType
        destTolldeptCode
        destTolldeptName
        isExpress
        declarationType
        creationUser
        creationDate
        clearanceType
        arrivalTime
        arrivalType
        eta
        waNumber
        meta
      }
      customsOffice {
        name
        number
      }
      declarationStatus
      addressReferences
      addresses {
        id
        addressTypeId
        companyName
        country
        city
        street
        streetNumber
        zipcode
        reference
        drReference
      }
      userPp {
        displayName
        phoneNumber
        email
      }
      file {
        identification
        meta
      }
      declaration {
        acceptanceDate
        customsDeclarationNumber
        customsDeclarationVersion
        customsOfficeNumber
        requestorTraderIdentificationNumber
        release
        status
        statusTime
        statusDate
        materialCheck
        traderDeclarationNumber
        traderReference
        totalGoodsItems
        specialMention {
          id
          text
        }
        vatSuffix
        placeOfUnloading
        totalVatValue
        dutyTotalAmount
        dutyAndOtherTaxesAmount
        transportMode
        transportNumber
        transportType
        transportCountry
        customsAccountName
        customsAccountNumber
        incoterms
        invoiceCurrencyType
        vatAccountName
        vatAccountNumber
        vatTotalAmount
        vatNumber
        dispatchCountry
        packagingQuantity
        grossMass
        evvReceived
        previousDocument {
          id
          previousDocumentType
          previousDocumentReference
        }
      }
      payer {
        address {
          id
        }
      }
    }
  }
`;

const useGetDeclarationDetails = ({ file_id }: { file_id: string }) => {
  const result = useWdGetDeclarationDetailsQuery({
    variables: {
      file_id,
    },
    fetchPolicy: "no-cache",
  });

  return useMemo(
    () => ({
      ...result,
      data: {
        declaration: result.data?.pp_declaration_requests?.[0],
      },
    }),
    [result]
  );
};

export default useGetDeclarationDetails;
