import { useMemo } from "react";
import gql from "graphql-tag";
import { WdGetKpiQuery, useWdGetKpiQuery } from "graphql/generated";

export type KPIEntity = NonNullable<WdGetKpiQuery["kpis"]>;
export type KPICustomAgencyEntity = NonNullable<
  WdGetKpiQuery["kpis"]
>["customsAgencies"][number];

gql`
  query WdGetKPI(
    $date_from: String!
    $date_to: String!
    $interval: String!
    $tenant_id: String
    $trader_identification_number: String
  ) {
    kpis: pp_get_kpis(
      date_from: $date_from
      date_to: $date_to
      interval: $interval
      tenant_id: $tenant_id
      trader_identification_number: $trader_identification_number
    ) {
      customsDeclarations {
        tickInterval
        dateInterval {
          date
          count
          lastYearCount
        }
      }
      avgCommodityCodesPerDeclaration
      commodityCodesChapters {
        chapter
        heading
        subheading
        tariffNumber
        totalNetMass
        totalGrossMass
        declarationCount
        goodsItemCount
      }
      customsAgencies {
        label
        count
        percentage
        traderIdentificationNumber
      }
    }
  }
`;

export default (...args: Parameters<typeof useWdGetKpiQuery>) => {
  const result = useWdGetKpiQuery(...args);

  return useMemo(
    () => ({
      ...result,
      data: {
        kpis: (result.data ?? result.previousData)?.kpis,
      },
    }),
    [result]
  );
};
