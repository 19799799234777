import { useAuth } from "keycloak";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ColumnSizingState,
  MRT_DensityState,
  MRT_SortingState,
  MRT_VisibilityState,
} from "@periplus/ui-library/lib/components/MRTable";

export interface TableLocalStorage<
  T = any,
  DisablePagination extends boolean = false
> {
  itemsPerPage: DisablePagination extends true ? number | undefined : number;
  filters?: T;
  density?: MRT_DensityState;
  columnSizing?: MRT_ColumnSizingState;
  columnPinning?: MRT_ColumnPinningState;
  columnVisibility?: MRT_VisibilityState;
  columnOrder?: MRT_ColumnOrderState;
  sorting?: MRT_SortingState;
  showColumnFilters?: boolean;
  template?: string;
}

//If usePageLocalStorage and useUrlSearchParams called together, then usePageLocalStorage should be called firstly
function usePageLocalStorage<T = any>(
  initialState?: T
): {
  pageLocalStorage: T;
  setPageLocalStorage: (newValues: Partial<T>) => void;
  getPageLocalStorage: () => T;
} {
  const { user } = useAuth();
  const {
    state: { path },
  } = useLocation<{ path: string }>();

  const pageLocalStorage = {
    ...initialState,
    ...(JSON.parse(localStorage.getItem("users") || "{}")[
      user?.userId as string
    ]?.pages?.[path] || {}),
  };

  const setPageLocalStorage = useCallback(
    (newValues: any) => {
      const userId = user?.userId as string;
      const parsedUsersLocalStorage = JSON.parse(
        localStorage.getItem("users") || "{}"
      );
      localStorage.setItem(
        `users`,
        JSON.stringify({
          ...parsedUsersLocalStorage,
          [userId]: {
            ...parsedUsersLocalStorage[userId],
            pages: {
              ...parsedUsersLocalStorage[userId]?.pages,
              [path]: {
                ...parsedUsersLocalStorage[userId]?.pages?.[path],
                ...newValues,
              },
            },
          },
        })
      );
    },
    [user, path]
  );

  const getPageLocalStorage = () => ({
    ...initialState,
    ...(JSON.parse(localStorage.getItem("users") || "{}")[
      user?.userId as string
    ]?.pages?.[path] || {}),
  });

  return { pageLocalStorage, setPageLocalStorage, getPageLocalStorage };
}

export default usePageLocalStorage;
