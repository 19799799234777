import React, { FC, useState } from "react";
import { ButtonProps, Button } from "@periplus/ui-library";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTranslation } from "react-i18next";
import useGetAddressGroupMembers from "domain/addressGroup/useGetAddressGroupMembers";
import EditDialog from "./EditDialog";
import { DeclarationDetailsEntity } from "pages/Declaration/hooks/useGetDeclarationDetails";

interface EditButtonProps extends ButtonProps {
  declaration: DeclarationDetailsEntity;
  onEdit: () => void;
}

const EditButton: FC<EditButtonProps> = ({ declaration, onEdit }) => {
  const { t } = useTranslation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const { addressGroupMembers } = useGetAddressGroupMembers();
  const permissions_js = addressGroupMembers.find(
    (agm) =>
      agm.payer?.id === declaration?.payer.address.id &&
      agm.clearance_types.some(
        (el) => el === declaration?.declarationRequest.clearanceType
      ) &&
      agm.declaration_types.some(
        (el) => el === declaration?.declarationRequest.declarationType
      )
  )?.permissions_js;

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setIsEditDialogOpen(true)}
        startIcon={<EditOutlinedIcon />}
        color="primary"
        disabled={!permissions_js}
        minimizeForMobiles
      >
        {t("edit")}
      </Button>
      {isEditDialogOpen && (
        <EditDialog
          declaration={declaration}
          addressGroupMembers={addressGroupMembers}
          onClose={() => setIsEditDialogOpen(false)}
          onConfirm={() => {
            setIsEditDialogOpen(false);
            onEdit();
          }}
        />
      )}
    </>
  );
};

export default EditButton;
