import gql from "graphql-tag";
import {
  WdGetAddressGroupsQuery,
  useWdGetAddressGroupsQuery,
} from "graphql/generated";

export type AddressGroupListEntity = NonNullable<
  WdGetAddressGroupsQuery["addressGroups"]
>[number];

gql`
  query WdGetAddressGroups {
    addressGroups: pp_address_groups {
      payerAddress {
        id
        company_name
        street
        street_number
        country
        zipcode
        city
        tenant {
          id
        }
      }
      groupMembers {
        address_type
        clearance_types
        declaration_types
        permissions_js
        address {
          id
          companyName: company_name
          country
          city
          street
          streetNumber: street_number
          zipcode
          reference
        }
      }
    }
  }
`;

const useGetAddressGroup = () => {
  const { data, ...rest } = useWdGetAddressGroupsQuery();

  return {
    addressGroups: data?.addressGroups || [],
    ...rest,
  };
};

export default useGetAddressGroup;
