import { useGetDownloadUrl } from "utils/azureBlobStorageUtils";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import { useTranslation } from "react-i18next";
import { ListItemIcon, ListItemText, MenuItem } from "@periplus/ui-library";
import { download } from "utils/files";
import { Bordereau } from "../hooks/useGetBordereaus";
import capitalize from "lodash/capitalize";

interface Props {
  extension: "pdf" | "xml";
  borderau: Bordereau;
  onClick: () => void;
}

export default ({ extension, borderau, onClick }: Props) => {
  const { t } = useTranslation("bordereaux");

  const { url: downloadUrl } = useGetDownloadUrl(
    borderau[`bordereau${capitalize(extension)}Doc`]?.document_blobs,
    `application/${extension}`,
    `attachment; filename="${encodeURI(
      `Bordereau ${borderau.documentNumber}.${extension}`
    )}"`
  );

  return (
    <MenuItem
      onClick={() => {
        download(downloadUrl!);
        onClick();
      }}
    >
      <ListItemIcon>
        <GetAppOutlinedIcon sx={{ color: "text.secondary" }} />
      </ListItemIcon>
      <ListItemText
        primary={t(`Download ${extension.toUpperCase()}`)}
        primaryTypographyProps={{ color: "text.secondary" }}
      />
    </MenuItem>
  );
};
