import React, { FC, useMemo, useState } from "react";
import { Theme } from "@periplus/ui-library";
import { Dialog, Typography } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import CustomDocument from "./CustomDocument";
import { Document } from "domain/document/types";
import { DefaultFlag } from "domain/documentType/types";
import { download } from "utils/files";
import { useAuth } from "keycloak";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  link: {
    textDecoration: "none",
    "&:not(:first-child)": {
      marginTop: 8,
    },
  },
  paper: {
    maxWidth: 516,
  },
}));

const CustomsDocumentsDialog: FC<{
  reference: string;
  documents: Document[];
  onClose(): void;
}> = ({ reference, documents, onClose }) => {
  const { classes } = useStyles();
  const { user } = useAuth();
  const { t } = useTranslation("customsDocDialog");
  const [selected, setSelected] = useState<{ id: string; url: string }[]>([]);

  const filteredCustomsDocuments = useMemo(
    () =>
      documents.reduce((acc, document) => {
        if (
          DefaultFlag.EdecRelevant ===
          (user!.tenant!.tenantDocumentTypes.find(
            (tenantDocType) =>
              tenantDocType.document_type === document.document_type
          )?.default_flags &
            DefaultFlag.EdecRelevant)
        ) {
          const duplicatedCustomDocument = acc.find(
            (accEl) => accEl.document_type === document.document_type
          );
          if (
            duplicatedCustomDocument &&
            duplicatedCustomDocument.creation_date < document.creation_date
          ) {
            acc[acc.indexOf(duplicatedCustomDocument)] = document;
          } else {
            acc.push(document);
          }
        }

        return acc;
      }, [] as Document[]),
    [documents, user]
  );

  return (
    <Dialog
      TitleIcon={GetAppOutlinedIcon}
      mainTitle={t("title")}
      subTitle={t("subTitle")}
      onClose={onClose}
      classes={{
        paper: classes.paper,
      }}
      onConfirm={() => {
        download(selected.map((el) => el.url));
      }}
      ConfirmButtonProps={{
        label:
          (selected.length ? `(${selected.length}) ` : "") + t("Download All"),
        disabled: !selected.length,
        endIcon: <GetAppOutlinedIcon />,
      }}
    >
      {filteredCustomsDocuments.length ? (
        filteredCustomsDocuments.map((customDocument) => (
          <CustomDocument
            key={customDocument.id}
            customDocument={customDocument}
            reference={reference}
            selected={selected.some(
              (selectedEl) => selectedEl.id === customDocument.id
            )}
            onToggleSelect={(url) => {
              setSelected((prev) =>
                prev.some((prevEl) => prevEl.id === customDocument.id)
                  ? prev.filter((prevEl) => prevEl.id !== customDocument.id)
                  : [...prev, { id: customDocument.id, url }]
              );
            }}
          />
        ))
      ) : (
        <Typography color="textSecondary">
          {t("There are no Documents available yet, please check again later")}
        </Typography>
      )}
    </Dialog>
  );
};

export default CustomsDocumentsDialog;
