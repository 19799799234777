import { FC, useMemo } from "react";
import {
  Box,
  MuiTable,
  MuiTableHead,
  MuiTableRow,
  MuiTableCell,
  MuiTableBody,
  Theme,
  Typography,
  IconButton,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { FALL_BACK_LANGUAGE } from "i18n";
import { Link } from "react-router-dom";
import { Launch } from "@mui/icons-material";
import { Bordereau } from "pages/Bordereaux/hooks/useGetBordereaus";
import { makeStyles } from "tss-react/mui";

interface SubTableProps {
  bordereauItems: Bordereau["bordereauItems"];
}

const useStyles = makeStyles()((theme: Theme) => ({
  headerCell: {
    background: "#F3F4FB",
  },
  bodyRow: {
    "&:last-child td": {
      borderBottom: "none",
    },
  },
  cell: {
    boxSizing: "border-box",
    height: 35,
    padding: "5px 12px",
    "&:first-of-type": {
      paddingLeft: 24,
    },
    "&:last-child": {
      paddingRight: 24,
    },
  },
}));

const SubTable: FC<SubTableProps> = ({ bordereauItems }) => {
  const { classes, cx } = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation("bordereaux");

  const columns = useMemo(
    () => ({
      toll_dept: {
        header: t("Toll dept"),
        cell: (row) => row.customOffice?.officeNumber,
      },
      evvs: {
        header: t("EVVS"),
        cell: (row) => (
          <Box
            sx={{
              width: "16px",
              height: "16px",
              borderRadius: "50%",
              background: row.status ? "#7AFFB2" : "#FF5D79",
            }}
          />
        ),
      },
      type: {
        header: t("Type"),
        cell: (row) => row.documentTypeAbbreviation,
      },
      description: {
        header: t("Description"),
        cell: (row) =>
          row.documentTypeNameTranslations?.[language] ??
          row.documentTypeNameTranslations?.[FALL_BACK_LANGUAGE],
      },
      trader_reference: {
        header: t("Trader reference"),
        cell: (row) => row.traderReference,
      },
      declaration_number: {
        header: t("Declaration number"),
        cell: (row) => row.customsReference,
      },
      amount_chf: {
        header: t("Amount (CHF)"),
        cell: (row) => row.amount,
      },
      actions: {
        header: "",
        cell: (row) =>
          !!row.file?.id &&
          !!row.drNo && (
            <Link
              to={`/declaration/${row.file?.id}/${row.drNo}`}
              style={{
                textDecoration: "none",
              }}
            >
              <IconButton color="primary">
                <Launch />
              </IconButton>
            </Link>
          ),
        sx: {
          textAlign: "right",
        },
      },
    }),
    [t, language]
  );

  return (
    <MuiTable sx={{ width: "100%" }}>
      <MuiTableHead>
        <MuiTableRow>
          {Object.entries(columns).map(([columnId, column]) => (
            <MuiTableCell
              className={cx(classes.cell, classes.headerCell)}
              key={columnId}
            >
              <Typography variant="overline" color="textSecondary">
                {column.header}
              </Typography>
            </MuiTableCell>
          ))}
        </MuiTableRow>
      </MuiTableHead>
      <MuiTableBody>
        {[...bordereauItems]
          .sort((a, b) => a.customsReference.localeCompare(b.customsReference))
          .map((bordereauItem) => (
            <MuiTableRow className={cx(classes.bodyRow)} key={bordereauItem.id}>
              {Object.entries(columns).map(([columnId, column]) => (
                <MuiTableCell
                  className={classes.cell}
                  key={columnId}
                  sx={(column as any).sx}
                >
                  {column.cell(bordereauItem)}
                </MuiTableCell>
              ))}
            </MuiTableRow>
          ))}
      </MuiTableBody>
    </MuiTable>
  );
};

export default SubTable;
