export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.min(
    Math.floor(Math.log(bytes) / Math.log(k)),
    sizes.length - 1
  );

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const download = (value: string | string[]) => {
  const urls = typeof value === "string" ? [value] : value;
  urls.forEach((url) => {
    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("download", "download");
    if (Array.isArray(value)) {
      element.setAttribute("target", "_blank");
    }
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  });
};
