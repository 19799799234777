import { Declaration_Labels } from "graphql/generated";

export enum TriggerTypesEnum {
  HighCustomFeesTrigger = "HighCustomFeesTrigger",
  SpecificHsCodes = "SpecificHsCodes",
  RetrievalOfImportList = "RetrievalOfImportList",
  FTA = "FTA",
}

export const NOTIFICATION_TRIGGER_CONFIG_TYPES = [
  {
    id: TriggerTypesEnum.HighCustomFeesTrigger,
    name: "High customs fees",
    shortName: "CF",
  },
  {
    id: TriggerTypesEnum.SpecificHsCodes,
    name: "Specific Hs Codes",
    shortName: "TN",
  },
  {
    id: TriggerTypesEnum.RetrievalOfImportList,
    name: "Import list import",
    shortName: "IL",
  },
  {
    id: TriggerTypesEnum.FTA,
    name: "Free Trade Agreement",
    shortName: "FT",
  },
];

export type HighCustomFeesTrigger = {
  feeAmount: number;
  countryOfOrigin: string;
  recipients: string[];
  checkPreference: boolean;
};

export type SpecificHsCodesTrigger = {
  specificHsCodes: string[];
  recipients: string[];
  checkPreference: boolean;
};

export type RetrievalOfImportListTrigger = {
  recipients: string[];
};

export type FTATrigger = {
  recipients: string[];
  hsCode: string;
  feeAmount: number;
  countries: string[];
  checkPreference: boolean;
};

export type ImportListTrigger = {
  id: number;
  display_name: string;
  type: TriggerTypesEnum;
  config:
    | HighCustomFeesTrigger
    | SpecificHsCodesTrigger
    | RetrievalOfImportListTrigger
    | FTATrigger;
  creation_date: string;
  creation_user: string;
  last_modified_date: string;
  last_modified_user: string;
  creationUser: any;
  lastModifiedUser: any;
  label_id: number;
  label: Declaration_Labels;
  meta: any;
};
