import { DropzoneOptions, useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import config from "config";
import { formatBytes } from "utils/files";

export const ACCEPTED_FILE_EXTENSIONS = [
  ".pdf",
  ".msg",
  ".eml",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".xml",
];

export const useCustomDropzone = ({
  onDrop,
  noClick,
  noKeyboard,
  noDrag,
}: Pick<DropzoneOptions, "onDrop" | "noClick" | "noKeyboard" | "noDrag">) => {
  const { t } = useTranslation("declaration");
  const { enqueueSnackbar } = useSnackbar();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick,
    noKeyboard,
    noDrag,
    accept: ACCEPTED_FILE_EXTENSIONS,
    maxSize: +config.maxFileSize,
    multiple: true,
    onDropRejected: (files) => {
      files.forEach(({ file: { name, size } }) => {
        const fileExtension = `.${name.split(".").pop()}`;
        if (!ACCEPTED_FILE_EXTENSIONS.includes(fileExtension)) {
          enqueueSnackbar(
            t("error:notSupportedFileExtension", {
              fileName: name,
              acceptedFileExtensions: ACCEPTED_FILE_EXTENSIONS.join(", "),
            }),
            { variant: "error" }
          );
          return;
        }

        if (size > +config.maxFileSize) {
          enqueueSnackbar(
            t("error:maxFileSize", {
              fileName: name,
              maxFileSize: formatBytes(+config.maxFileSize),
            }),
            { variant: "error" }
          );
          return;
        }
      });
    },
  });

  return { getRootProps, getInputProps };
};
