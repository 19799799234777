import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const EDIT_ADDRESS_MUTATION = gql`
  mutation EditAddress(
    $id: Int!
    $aoId: Int
    $newData: AddressInputRS!
    $additionalParams: EditAddressInputACS!
  ) {
    editAddressACS(
      id: $id
      aoId: $aoId
      newData: $newData
      additionalParams: $additionalParams
    ) {
      id
      city
      companyName
      contactEmail
      contactPhone
      contactWebsite
      country
      reference
      street
      streetNumber
      zipcode
      meta
    }
  }
`;

const useEditAddress = () => {
  const [editAddressMutation, { data, loading, error }] = useMutation(
    EDIT_ADDRESS_MUTATION
  );

  const editAddress = async (id, aoId, newData, additionalParams) => {
    await editAddressMutation({
      variables: { id, aoId, newData, additionalParams },
    });
  };

  return { editAddress, data, loading, error };
};

export default useEditAddress;
