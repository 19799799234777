import React, { FC, memo } from "react";
import { Paper, Box, IconButton, Badge } from "@periplus/ui-library";
import { Launch } from "@mui/icons-material";
import { AdditionalRef } from "../../";
import ChatOutlined from "@mui/icons-material/ChatOutlined";
import dayjs from "dayjs";
import { Typography, Button } from "@periplus/ui-library";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CustomsIcon, WarehouseIcon } from "components/Icons";
import Field from "./Field";
import { useAuth } from "keycloak";
import { Permissions } from "keycloak/context/AuthContext";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import DeclarationStatusChip from "domain/declaration/components/DeclarationStatusChip";
import DeclarationActions from "pages/Dashboard/DeclarationActions";
import { DeclarationStatus } from "graphql/generated";
import DeclarationLabelsField from "domain/declaration/components/DeclarationLabel/DeclarationLabelsField";
import { DeclarationDashboardEntity } from "pages/Dashboard/hooks/useGetDeclarationsDashboard";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ isDeclarationCanceled: boolean }>()(
  (theme, { isDeclarationCanceled }) => ({
    root: {
      boxShadow: "unset",
      border: `1px solid ${theme.palette.grey3.main}`,
      borderRadius: "4px",
      display: "grid",
      gridTemplate: `
      "statusBlock    controls"
      "divider        divider"
      "fields         fields"
      /1fr            auto
  `,
      [theme.breakpoints.up("md")]: {
        gridTemplate: `
          "statusBlock    divider fields  controls"
          /175px          auto    1fr     min-content 
      `,
        alignItems: "center",
      },
    },
    statusBlock: {
      gridArea: "statusBlock",
      padding: "16px 4px 16px 16px",
      alignSelf: "stretch",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      gap: 8,
      [theme.breakpoints.up("md")]: {
        padding: 16,
      },
    },
    controls: {
      gridArea: "controls",
      padding: "16px 16px 16px 4px",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        padding: 16,
      },
      ...(isDeclarationCanceled && { visibility: "hidden" }),
    },
    detailsLink: {
      textDecoration: "none",
      marginRight: 8,
    },
    detailsButton: {
      backgroundColor: "#FAFAFE",
    },
    divider: {
      gridArea: "divider",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      [theme.breakpoints.up("md")]: {
        height: "100%",
        borderTop: "unset",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      },
    },
    fields: {
      gridArea: "fields",
      padding: 16,
      gap: "10px",
      display: "grid",
      gridTemplateColumns: "repeat(2, auto)",
      [theme.breakpoints.up("md")]: {
        gap: "26px 32px",
        gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
      },
      [theme.breakpoints.up("lg")]: {
        padding: 16,
      },
    },
    fieldValueIcon: {
      display: "flex",
      gap: 10,
    },
    importListLink: {
      textDecoration: "none",
      display: "flex",
    },
    disabled: {
      position: "relative",
      "&:after": {
        content: '""',
        pointerEvents: "none",
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        background: "rgba(204, 204, 204, 0.25)",
      },
    },
    listItemIcon: {
      color: "rgb(115, 122, 150)",
      marginRight: 12,
    },
    searchRef: {
      marginTop: theme.spacing(0.5),
    },
  })
);

const DeclarationCard: FC<{
  declaration: DeclarationDashboardEntity;
  onLabelsAssigned: () => void;
}> = ({ declaration, onLabelsAssigned }) => {
  const isDeclarationCanceled =
    declaration.declarationStatus === DeclarationStatus.DeclarationCanceled;

  const { classes, cx } = useStyles({ isDeclarationCanceled });
  const { t } = useTranslation();
  const { user } = useAuth();
  const history = useHistory();

  const showDetailsButton = user?.hasAllowedPermissions([
    Permissions.DASH_VIA_CUST,
    Permissions.DASH_EXC_CUST,
  ]);
  const isSupplierAvailable = user?.hasAllowedPermissions([
    Permissions.SUPPLIER_ADDRESS,
  ]);

  const mrtNumber = declaration.file.identification || "...";

  const importerCompanyName = declaration.addresses?.find(
    (address) => address.addressTypeId === 4
  )?.companyName;
  const consignorCompanyName = declaration.addresses?.find(
    (address) => address.addressTypeId === 1
  )?.companyName;
  const supplierCompanyName = declaration.addresses?.find(
    (address) => address.addressTypeId === 3
  )?.companyName;

  return (
    <>
      <Paper
        className={cx(classes.root, {
          [classes.disabled]: isDeclarationCanceled,
        })}
        square
      >
        <div className={classes.statusBlock}>
          <Box>
            <Typography
              variant="h6"
              sx={{
                wordBreak: "break-all",
              }}
            >
              {declaration.addressReferences}
            </Typography>
            {declaration.searchReferences && (
              <AdditionalRef className={classes.searchRef}>
                {declaration.searchReferences}
              </AdditionalRef>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: "column",
              alignItems: "flex-start",
              maxWidth: "100%",
            }}
          >
            {!!declaration.declarationDiscussion?.unreadMessagesCount && (
              <IconButton
                size="large"
                disabled={isDeclarationCanceled}
                onClick={() => {
                  history.push(
                    `/declaration/${declaration.fileId}/${declaration.drNo}`
                  );
                }}
              >
                <Badge
                  badgeContent={
                    declaration.declarationDiscussion?.unreadMessagesCount
                  }
                  color={"primary"}
                  invisible={isDeclarationCanceled}
                >
                  <ChatOutlined />
                </Badge>
              </IconButton>
            )}
            <DeclarationStatusChip
              declarationStatus={declaration?.declarationStatus}
            />
          </Box>
        </div>
        <div className={classes.controls}>
          <Link
            className={classes.detailsLink}
            to={`/declaration/${declaration.fileId}/${declaration.drNo}`}
          >
            {showDetailsButton && (
              <Button
                className={classes.detailsButton}
                variant="outlined"
                color="primary"
              >
                <Launch />
              </Button>
            )}
          </Link>
          <DeclarationActions declaration={declaration} />
        </div>
        <div className={classes.divider} />
        <div className={classes.fields}>
          <Field
            label={t("declaration:Arrival")}
            value={
              <div className={classes.fieldValueIcon}>
                {(() => {
                  const type = declaration?.declarationRequest.waNumber
                    ? "allowed_consignee"
                    : "border";

                  const arrivalType = {
                    allowed_consignee: {
                      Icon: WarehouseIcon,
                      label: "AC-Place",
                    },
                    border: { Icon: CustomsIcon, label: "Border" },
                  };

                  const { Icon, label } = arrivalType[type];
                  return (
                    <>
                      <Icon
                        style={{
                          color: "#4F4F4F",
                          marginTop: 2,
                          width: 18,
                          height: 18,
                        }}
                      />
                      <Typography style={{ color: "#4F4F4F", marginTop: 4 }}>
                        {t(`declaration:${label}`)}
                      </Typography>
                    </>
                  );
                })()}
              </div>
            }
          />
          <Field
            label={t("declaration:Client")}
            value={declaration.payer.address.companyName}
          />
          <Field
            label={t("declaration:Consignor")}
            value={consignorCompanyName}
          />
          <Field
            label={t("declaration:Importer")}
            value={importerCompanyName}
          />
          {isSupplierAvailable && (
            <Field
              label={t("declaration:Supplier")}
              value={supplierCompanyName}
            />
          )}
          <Field
            label={t("declaration:tolldept")}
            value={
              declaration.declaration?.customsOfficeNumber &&
              `${declaration.declaration?.customsOfficeNumber} ${declaration.customsOffice?.name}`
            }
          />
          <Field label={t("declaration:Jobreference")} value={mrtNumber} />
          {declaration.declarationRequest.creationDate && (
            <Field
              label={t("declaration:Recieved")}
              value={
                declaration.declarationRequest.creationDate &&
                dayjs(declaration.declarationRequest.creationDate).format(
                  "DD.MM.YYYY HH:mm"
                )
              }
            />
          )}
          <Field
            label={t(`declaration:ETA`)}
            value={
              declaration.declarationRequest.eta &&
              dayjs(declaration.declarationRequest.eta).format(
                "DD.MM.YYYY HH:mm"
              )
            }
          />
          <Field
            label={t(`declaration:ATA`)}
            value={
              declaration.declarationRequest.arrivalTime &&
              dayjs(declaration.declarationRequest.arrivalTime).format(
                "DD.MM.YYYY HH:mm"
              )
            }
          />
          <Field
            label={t(`declaration:Acceptance date`)}
            value={
              declaration.declaration?.acceptanceDate &&
              dayjs(declaration.declaration?.acceptanceDate).format(
                "DD.MM.YYYY HH:mm"
              )
            }
          />
          {declaration.declaration?.customsDeclarationNumber && (
            <Field
              label={t(`declaration:Declaration Number`)}
              value={[
                declaration.declaration?.customsDeclarationNumber,
                declaration.declaration?.customsDeclarationVersion,
              ]
                .filter((el) => el)
                .join(" . ")}
            />
          )}
          <Field
            label={t(`declaration:EVV`)}
            value={
              <div className={classes.fieldValueIcon}>
                {(() => {
                  const isEVVReceived = declaration.declaration.evvReceived;
                  if (!isEVVReceived) return null;

                  const isEVVConfirmed =
                    declaration.declarationRequest.meta.evvConfirmed;

                  const { Icon, label } = isEVVConfirmed
                    ? {
                        Icon: DraftsOutlinedIcon,
                        label: "Confirmed",
                      }
                    : { Icon: MailOutlineOutlinedIcon, label: "Received" };

                  return (
                    <>
                      <div style={{ position: "relative" }}>
                        <Icon
                          style={{
                            color: "#4F4F4F",
                            marginTop: 2,
                            width: 24,
                            height: 24,
                          }}
                        />
                        {!isEVVConfirmed && (
                          <div
                            style={{
                              width: 10,
                              height: 10,
                              position: "absolute",
                              left: 0,
                              top: 0,
                              backgroundColor: "#EC008C",
                              borderRadius: "100%",
                            }}
                          />
                        )}
                      </div>
                      <Typography style={{ color: "#4F4F4F", marginTop: 4 }}>
                        {t(`declaration:${label}`)}
                      </Typography>
                    </>
                  );
                })()}
              </div>
            }
          />
          <Field
            label={t(`declaration:Wa Number`)}
            value={declaration.declarationRequest.waNumber}
          />
          <Field
            label={t(`declaration:Contact Person`)}
            value={declaration.userPp?.displayName}
          />
          <Field
            label={t(`declaration:Email`)}
            value={declaration.userPp?.email}
          />
          {!!user?.tenant?.declaration_labels.length && (
            <DeclarationLabelsField
              view="card"
              file_id={declaration.fileId}
              dr_no={declaration.drNo}
              declaration_labels={declaration.declarationLabels}
              onLabelsAssigned={onLabelsAssigned}
            />
          )}
        </div>
      </Paper>
    </>
  );
};

export default memo(DeclarationCard) as typeof DeclarationCard;
