import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  WdDeleteNotificationTriggerMutation,
  WdDeleteNotificationTriggerMutationVariables,
} from "graphql/generated";

const useDeleteNotificationTrigger = () => {
  const [deleteNotificationTrigger] = useMutation<
    WdDeleteNotificationTriggerMutation,
    WdDeleteNotificationTriggerMutationVariables
  >(
    gql`
      mutation WdDeleteNotificationTrigger($id: Int!) {
        deleteNotificationTrigger(id: $id) {
          id
        }
      }
    `
  );

  return deleteNotificationTrigger;
};

export default useDeleteNotificationTrigger;
