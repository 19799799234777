import React, { FC } from "react";
import { styled, Theme, Box, Popper, FormCheckbox } from "@periplus/ui-library";
import { AutocompleteCloseReason } from "@periplus/ui-library";
import { Form } from "formik";
import {
  FormTextField,
  Dialog,
  FormAutocomplete,
  Typography,
  Checkbox,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { useAuth } from "keycloak";
import ImportListTriggerFormik from "../ImportListTriggerFormik";
import {
  ImportListTrigger,
  NOTIFICATION_TRIGGER_CONFIG_TYPES,
  TriggerTypesEnum,
} from "../../../domain/importListTrigger/types";
import { FTA_COUNTRIES_DATA } from "../../../domain/importListTrigger/countries";
import { FALL_BACK_LANGUAGE } from "i18n";
import DeclarationLabelChip from "domain/declaration/components/DeclarationLabel/DeclarationLabelChip";
import { Declaration_Labels } from "graphql/generated";
import { makeStyles } from "tss-react/mui";
import { useAppState } from "App/AppContext";

const useStyles = makeStyles()((theme: Theme) => ({
  filterLabel: {
    marginTop: theme.spacing(1.5),
  },
  stepContentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  selectAll: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  selectField: {
    display: "flex",
    gap: theme.spacing(1),
    flexDirection: "row-reverse",
  },
  selectInput: {
    flex: 1,
  },
}));

const PopperStyledComponent = styled(Popper)(() => ({
  border: "1px solid rgba(149, 157, 165, 0.3)",
  background: "white",
  borderRadius: "4px",
}));

const CreateImportListTriggerDialog: FC<{
  onClose: () => void;
  onConfirm: () => void;
  importListTrigger?: ImportListTrigger | null;
}> = ({ onClose, onConfirm, importListTrigger }) => {
  const { classes } = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation("importListTrigger");
  const { user } = useAuth();
  const {
    edecData: { edec_countries },
  } = useAppState();

  const [euOpen, setEUOpen] = React.useState(false);

  const [ftaOpen, setFTAOpen] = React.useState(false);

  const [allOpen, setAllOpen] = React.useState(false);

  return (
    <ImportListTriggerFormik
      onSubmit={onConfirm}
      importListTrigger={importListTrigger}
    >
      {({
        submitForm,
        isSubmitting,
        isValid,
        dirty,
        values,
        setFieldValue,
      }: any) => {
        return (
          <Dialog
            onClose={onClose}
            mainTitle={t("title")}
            subTitle={t("subTitle")}
            onConfirm={submitForm}
            ConfirmButtonProps={{
              disabled: isSubmitting || !dirty || !isValid,
            }}
          >
            <Form className={classes.stepContentContainer}>
              <Typography variant="overline" color="textSecondary">
                {t("displayName")} *
              </Typography>
              <FormTextField name="display_name" variant="outlined" required />
              <Typography
                className={classes.filterLabel}
                variant="overline"
                color="textSecondary"
              >
                {t("Notify by")} *
              </Typography>
              <FormAutocomplete
                name="notifyBy"
                options={["email", "label"]}
                getOptionLabel={(option) => t(`${option}`)}
                disableClearable
              />
              {(() => {
                switch (values.notifyBy) {
                  case "email":
                    return (
                      <>
                        <Typography
                          className={classes.filterLabel}
                          variant="overline"
                          color="textSecondary"
                        >
                          {t("recipients")} *
                        </Typography>
                        <FormTextField
                          name="recipients"
                          variant="outlined"
                          required
                        />
                      </>
                    );
                  case "label":
                    return (
                      <>
                        <Box>
                          <Typography
                            className={classes.filterLabel}
                            variant="overline"
                            color="textSecondary"
                          >
                            {t("label")} *
                          </Typography>
                          {importListTrigger && (
                            <Typography
                              className={classes.filterLabel}
                              variant="caption"
                              color="textSecondary"
                            >
                              {` (${t(
                                "When editing, historical data will not be changed"
                              )})`}
                            </Typography>
                          )}
                        </Box>
                        <FormAutocomplete<Declaration_Labels, true>
                          name="labels"
                          options={user!.tenant!.declaration_labels}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          multiple
                          getOptionLabel={(option) =>
                            option.title[language] ||
                            option.title[FALL_BACK_LANGUAGE]
                          }
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                <DeclarationLabelChip
                                  declaration_label={option}
                                />
                              </li>
                            );
                          }}
                          renderTags={(values, getTagProps) => {
                            return values.map((value, index) => (
                              <DeclarationLabelChip
                                declaration_label={value}
                                {...getTagProps({ index })}
                              />
                            ));
                          }}
                          sx={{
                            "& .MuiAutocomplete-inputRoot": {
                              gap: 1,
                            },
                          }}
                        />
                      </>
                    );
                }
              })()}
              <Typography
                className={classes.filterLabel}
                variant="overline"
                color="textSecondary"
              >
                {t("trigger_type")} *
              </Typography>
              <FormAutocomplete
                name="type"
                options={NOTIFICATION_TRIGGER_CONFIG_TYPES}
                getOptionLabel={(option) =>
                  t(`importListTrigger:${option.name}`)
                }
              />

              {[
                TriggerTypesEnum.HighCustomFeesTrigger,
                TriggerTypesEnum.SpecificHsCodes,
                TriggerTypesEnum.FTA,
              ].includes(values.type?.id) && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mt: 1.5,
                  }}
                >
                  <FormCheckbox name="checkPreference" />
                  <Typography variant="overline" color="textSecondary">
                    {t("Non-preferential customs clearance")}
                  </Typography>
                </Box>
              )}
              {values.type?.id === TriggerTypesEnum.SpecificHsCodes && (
                <>
                  <Typography
                    className={classes.filterLabel}
                    variant="overline"
                    color="textSecondary"
                  >
                    {t("importListTrigger:hsCodes")} *
                  </Typography>
                  <FormTextField name="specificHsCodes" variant="outlined" />
                </>
              )}
              {values.type?.id === TriggerTypesEnum.HighCustomFeesTrigger && (
                <>
                  <Typography
                    className={classes.filterLabel}
                    variant="overline"
                    color="textSecondary"
                  >
                    {t("importListTrigger:countryOfOrigin")} *
                  </Typography>
                  <FormAutocomplete
                    name="countryOfOrigin"
                    getOptionLabel={(option) => {
                      const edec_country = edec_countries.find(
                        (el) => el.iso_code === option
                      )!;
                      return (
                        edec_country[`name_${language}`] ||
                        edec_country[`name_${FALL_BACK_LANGUAGE}`]
                      );
                    }}
                    options={edec_countries
                      .sort((el1, el2) =>
                        el1[`name_${language}`].localeCompare(
                          el2[`name_${language}`]
                        )
                      )
                      .map((el) => el.iso_code)}
                  />
                  <Typography
                    className={classes.filterLabel}
                    variant="overline"
                    color="textSecondary"
                  >
                    {t("importListTrigger:feeAmount")} *
                  </Typography>
                  <FormTextField name="feeAmount" variant="outlined" />
                </>
              )}
              {values.type?.id === TriggerTypesEnum.FTA && (
                <>
                  <Typography
                    className={classes.filterLabel}
                    variant="overline"
                    color="textSecondary"
                  >
                    {t("importListTrigger:feeAmount")} *
                  </Typography>
                  <FormTextField required name="feeAmount" variant="outlined" />
                  <Typography
                    className={classes.filterLabel}
                    variant="overline"
                    color="textSecondary"
                  >
                    {t("importListTrigger:Commodity Code")}
                  </Typography>
                  <FormTextField required name="hsCode" variant="outlined" />
                  <Typography
                    className={classes.filterLabel}
                    variant="overline"
                    color="textSecondary"
                  >
                    {t("eu_chf_countries")}
                  </Typography>
                  <Box className={classes.selectField}>
                    <Box className={classes.selectAll}>
                      <Checkbox
                        checked={FTA_COUNTRIES_DATA.EU_CHF.every(
                          (eu_chf_country) =>
                            values.eu_chf_countries.some(
                              (formik_eu_chf_country) =>
                                formik_eu_chf_country.id === eu_chf_country.id
                            )
                        )}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (event.target.checked) {
                            setFieldValue(
                              "eu_chf_countries",
                              FTA_COUNTRIES_DATA.EU_CHF
                            );
                            setEUOpen(false);
                          } else {
                            setFieldValue("eu_chf_countries", []);
                          }
                        }}
                        id="check-all-eu-chf"
                      />
                      <Typography color="textSecondary">{t("All")}</Typography>
                    </Box>
                    <FormAutocomplete
                      multiple
                      name="eu_chf_countries"
                      options={FTA_COUNTRIES_DATA.EU_CHF}
                      disableCloseOnSelect
                      limitTags={3}
                      onChange={(event, newValue, reason) => {
                        if (reason === "selectOption") {
                          setEUOpen(false);
                          setFieldValue("eu_chf_countries", newValue);
                        } else if (reason === "removeOption") {
                          setFieldValue("eu_chf_countries", newValue);
                        } else if (reason === "clear") {
                          setFieldValue("eu_chf_countries", []);
                        }
                      }}
                      onClose={(e: any, reason: AutocompleteCloseReason) => {
                        if (reason === "escape") {
                          setEUOpen(false);
                        } else if (reason === "toggleInput") {
                          setEUOpen(false);
                        }
                      }}
                      onOpen={() => {
                        setEUOpen(true);
                      }}
                      open={euOpen}
                      PopperComponent={(param) => (
                        <PopperStyledComponent {...param}>
                          <Box
                            role={param.role}
                            children={param.children as React.ReactNode}
                          />
                        </PopperStyledComponent>
                      )}
                      getOptionLabel={(option) => t(`${option.name}`)}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      InputProps={{
                        required: false,
                        variant: "outlined",
                      }}
                      className={classes.selectInput}
                    />
                  </Box>

                  <Typography
                    className={classes.filterLabel}
                    variant="overline"
                    color="textSecondary"
                  >
                    {t("efta_countries")}
                  </Typography>
                  <Box className={classes.selectField}>
                    <Box className={classes.selectAll}>
                      <Checkbox
                        checked={FTA_COUNTRIES_DATA.EU_FTA.every(
                          (efta_country) =>
                            values.efta_countries.some(
                              (formik_efta_country) =>
                                formik_efta_country.id === efta_country.id
                            )
                        )}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (event.target.checked) {
                            setFieldValue(
                              "efta_countries",
                              FTA_COUNTRIES_DATA.EU_FTA
                            );
                            setFTAOpen(false);
                          } else {
                            setFieldValue("efta_countries", []);
                          }
                        }}
                        id="check-all-fta"
                      />
                      <Typography color="textSecondary">{t("All")}</Typography>
                    </Box>
                    <FormAutocomplete
                      multiple
                      name="efta_countries"
                      options={FTA_COUNTRIES_DATA.EU_FTA}
                      disableCloseOnSelect
                      limitTags={3}
                      onChange={(event, newValue, reason) => {
                        if (reason === "selectOption") {
                          setFTAOpen(false);
                          setFieldValue("efta_countries", newValue);
                        } else if (reason === "removeOption") {
                          setFieldValue("efta_countries", newValue);
                        } else if (reason === "clear") {
                          setFieldValue("efta_countries", []);
                        }
                      }}
                      onClose={(e: any, reason: AutocompleteCloseReason) => {
                        if (reason === "escape") {
                          setFTAOpen(false);
                        } else if (reason === "toggleInput") {
                          setFTAOpen(false);
                        }
                      }}
                      onOpen={() => {
                        setFTAOpen(true);
                      }}
                      open={ftaOpen}
                      PopperComponent={(param) => (
                        <PopperStyledComponent {...param}>
                          <Box
                            role={param.role}
                            children={param.children as React.ReactNode}
                          />
                        </PopperStyledComponent>
                      )}
                      getOptionLabel={(option) => t(`${option.name}`)}
                      InputProps={{
                        required: false,
                        variant: "outlined",
                      }}
                      className={classes.selectInput}
                    />
                  </Box>

                  <Typography
                    className={classes.filterLabel}
                    variant="overline"
                    color="textSecondary"
                  >
                    {t("other_countries")}
                  </Typography>
                  <Box className={classes.selectField}>
                    <Box className={classes.selectAll}>
                      <Checkbox
                        checked={FTA_COUNTRIES_DATA.rest.every(
                          (other_country) =>
                            values.other_countries.some(
                              (formik_other_country) =>
                                formik_other_country.id === other_country.id
                            )
                        )}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (event.target.checked) {
                            setFieldValue(
                              "other_countries",
                              FTA_COUNTRIES_DATA.rest
                            );
                            setAllOpen(false);
                          } else {
                            setFieldValue("other_countries", []);
                          }
                        }}
                        id="check-all-all"
                      />
                      <Typography color="textSecondary">{t("All")}</Typography>
                    </Box>
                    <FormAutocomplete
                      multiple
                      name="other_countries"
                      options={FTA_COUNTRIES_DATA.rest}
                      disableCloseOnSelect
                      limitTags={3}
                      onChange={(event, newValue, reason) => {
                        if (reason === "selectOption") {
                          setAllOpen(false);
                          setFieldValue("other_countries", newValue);
                        } else if (reason === "removeOption") {
                          setFieldValue("other_countries", newValue);
                        } else if (reason === "clear") {
                          setFieldValue("other_countries", []);
                        }
                      }}
                      onClose={(e: any, reason: AutocompleteCloseReason) => {
                        if (reason === "escape") {
                          setAllOpen(false);
                        } else if (reason === "toggleInput") {
                          setAllOpen(false);
                        }
                      }}
                      onOpen={() => {
                        setAllOpen(true);
                      }}
                      open={allOpen}
                      PopperComponent={(param) => (
                        <PopperStyledComponent {...param}>
                          <Box
                            role={param.role}
                            children={param.children as React.ReactNode}
                          />
                        </PopperStyledComponent>
                      )}
                      getOptionLabel={(option) => t(`${option.name}`)}
                      InputProps={{
                        required: false,
                        variant: "outlined",
                      }}
                      className={classes.selectInput}
                    />
                  </Box>
                </>
              )}
            </Form>
          </Dialog>
        );
      }}
    </ImportListTriggerFormik>
  );
};

export default CreateImportListTriggerDialog;
