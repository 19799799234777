import React, { FC, useState } from "react";
import { Box } from "@periplus/ui-library";
import DeclarationField from "../DeclarationField/DeclarationField";
import { useTranslation } from "react-i18next";
import { DeclarationLabel } from "graphql/generated";
import DeclarationLabelChip from "domain/declaration/components/DeclarationLabel/DeclarationLabelChip";
import DeclarationLabelsAssignmentDialog from "domain/declaration/components/DeclarationLabel/DeclarationLabelsAssignmentDialog";
import { useAuth } from "keycloak";

export interface DeclarationLabelsFieldProps {
  view: "card" | "table";
  file_id: string;
  dr_no: number;
  declaration_labels: Pick<DeclarationLabel, "id">[];
  onLabelsAssigned: () => void;
}

const DeclarationLabelsField: FC<DeclarationLabelsFieldProps> = ({
  view,
  file_id,
  dr_no,
  declaration_labels,
  onLabelsAssigned,
}) => {
  const { t } = useTranslation("declaration");
  const { user } = useAuth();
  const [isAssignmentDialogOpen, setIsAssignmentDialogOpen] = useState(false);

  return (
    <>
      <DeclarationField
        view={view}
        label={t(`declaration:Labels`)}
        value={
          <Box
            onClick={() => setIsAssignmentDialogOpen(true)}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: { table: "no-wrap", card: "wrap" }[view],
              gap: 0.5,
              alignItems: "flex-start",
            }}
          >
            {user?.tenant?.declaration_labels
              .filter((tenantDeclarationLabel) =>
                declaration_labels.some(
                  (declaration_label) =>
                    declaration_label.id === tenantDeclarationLabel.id
                )
              )
              .map((declaration_label) => (
                <DeclarationLabelChip
                  declaration_label={declaration_label}
                  key={declaration_label.id}
                  sx={{ cursor: "pointer" }}
                />
              ))}
          </Box>
        }
        onEdit={() => setIsAssignmentDialogOpen(true)}
      />
      {isAssignmentDialogOpen && (
        <DeclarationLabelsAssignmentDialog
          file_id={file_id}
          dr_no={dr_no}
          declaration_labels={declaration_labels}
          onClose={() => setIsAssignmentDialogOpen(false)}
          onAssigned={() => {
            setIsAssignmentDialogOpen(false);
            onLabelsAssigned();
          }}
        />
      )}
    </>
  );
};

export default DeclarationLabelsField;
