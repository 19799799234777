import React, { FC } from "react";

import ClassIcon from "@mui/icons-material/Class";
import { useTranslation } from "react-i18next";
import { MenuItem, ListItemIcon, ListItemText } from "@periplus/ui-library";
import { useGetDocumentTypes } from "domain/documentType";
import ClassificationPopover from "./Popover";
import { useAuth } from "keycloak";
import { TransformedDocument } from "../../DocumentsTab";
import { DeclarationStatus } from "graphql/generated";

interface ClassificationButtonProps {
  document: TransformedDocument;
  declarationStatus: DeclarationStatus;
  onCancel: () => void;
  onClassificated: () => Promise<any>;
}

const ClassificationButton: FC<ClassificationButtonProps> = ({
  document,
  declarationStatus,
  onCancel,
  onClassificated,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { documentTypes } = useGetDocumentTypes({
    tenantId: user?.tenant_id,
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = async () => {
    setAnchorEl(null);
    onCancel();
  };

  return (
    <>
      {!!documentTypes.length && (
        <MenuItem
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          disabled={
            !document ||
            declarationStatus !== DeclarationStatus.DeclarationExported
          }
        >
          <ListItemIcon>
            <ClassIcon sx={{ color: "text.secondary" }} />
          </ListItemIcon>
          <ListItemText
            primary={t("tooltips:classify")}
            primaryTypographyProps={{ color: "text.secondary" }}
          />
        </MenuItem>
      )}
      {anchorEl && (
        <ClassificationPopover
          anchorEl={anchorEl}
          document={document}
          documentTypes={documentTypes}
          onClose={handleClose}
          onClassificated={onClassificated}
        />
      )}
    </>
  );
};

export default ClassificationButton;
