import React, { FC } from "react";
import { Box, BoxProps } from "@periplus/ui-library";
import DetailsPanel from "./DetailsPanel";
import GoodsItemsPanel from "./GoodsItemsPanel";
import CommunicationPanel from "./CommunicationPanel";
import { DeclarationDetailsEntity } from "pages/Declaration/hooks/useGetDeclarationDetails";

interface GeneralTabProps extends BoxProps {
  declaration: DeclarationDetailsEntity;
  onLabelsAssigned: () => void;
}

const GeneralTab: FC<GeneralTabProps> = ({ declaration, onLabelsAssigned }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          overflow: "hidden",
        }}
      >
        <DetailsPanel
          declaration={declaration}
          onLabelsAssigned={onLabelsAssigned}
        />
        <GoodsItemsPanel declaration={declaration} />
      </Box>
      <CommunicationPanel file_id={declaration.fileId} />
    </Box>
  );
};

export default GeneralTab;
