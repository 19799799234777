import {
  Box,
  BoxProps,
  Button,
  Dialog,
  FormTextField,
  IconButton,
  MRTable,
} from "@periplus/ui-library";
import dayjs, { Dayjs } from "dayjs";
import { DeclarationFilters } from "domain/declaration/components/DeclarationFiltersSidebar";
import usePageSettings from "domain/user/usePageSettings";
import usePageSettingsTemplates from "pages/Statistics/components/TemplatesManager/usePageSettingsTemplates";
import { Formik } from "formik";
import { TableLocalStorage } from "hooks/usePageLocalStorage";
import { StatisticPageSettings } from "pages/Statistics/Statistics";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useSnackbar } from "notistack";
import { UiSettingsTemplate } from "keycloak/context/AuthContext";

interface Props extends BoxProps {
  onTemplateApply: (settings: Partial<StatisticPageSettings>) => void;
  onClose: () => void;
}

export type SettingsTemplateEntity = {
  name: string;
  settings: TableLocalStorage<DeclarationFilters>;
  lastAppliedDate: Dayjs | null;
};

export default ({ onClose, onTemplateApply }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { pageSettings } = usePageSettings<StatisticPageSettings>();
  const { pageSettingsTemplates, setPageSettingsTemplates } =
    usePageSettingsTemplates();

  const [columns, tableData] = useMemo<
    [MRTable.MRT_ColumnDef<UiSettingsTemplate>[], UiSettingsTemplate[]]
  >(
    () => [
      [
        {
          header: t("Name"),
          id: "name",
          accessorFn: (originalRow) => originalRow.name,
        },
        {
          header: "",
          id: "actions",
          columnDefType: "display",
          Cell: ({ row: { original: originalRow } }) => (
            <Box
              sx={{
                p: 0.5,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  enqueueSnackbar(t("Successfully applied"), {
                    variant: "success",
                  });
                  setPageSettingsTemplates((prev) =>
                    prev.map((el) => ({
                      ...el,
                      ...(el.name === originalRow.name && {
                        lastAppliedDate: dayjs().format(),
                      }),
                    }))
                  );
                  onTemplateApply({
                    ...originalRow.settings,
                    template: originalRow.name,
                  });
                }}
              >
                {t("Apply")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  enqueueSnackbar(t("Successfully updated"), {
                    variant: "success",
                  });
                  setPageSettingsTemplates((prev) =>
                    prev.reduce((acc, el) => {
                      if (el.name === originalRow.name) {
                        el.settings = pageSettings;
                      }

                      acc.push(el);
                      return acc;
                    }, [] as typeof prev)
                  );
                }}
                sx={{
                  ml: 1,
                }}
              >
                {t("Update")}
              </Button>
              <IconButton
                color="error"
                tooltip={t("Delete")}
                onClick={() => {
                  enqueueSnackbar(t("Successfully deleted"), {
                    variant: "success",
                  });
                  setPageSettingsTemplates((prev) =>
                    prev.reduce((acc, el) => {
                      if (el.name === originalRow.name) {
                        return acc;
                      }

                      acc.push(el);
                      return acc;
                    }, [] as typeof prev)
                  );
                }}
                sx={{
                  ml: 1,
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          ),
          muiTableBodyCellProps: {
            sx: {
              justifyContent: "right",
            },
          },
        },
      ],
      [...pageSettingsTemplates].sort((a, b) =>
        dayjs(a.lastAppliedDate).isAfter(dayjs(b.lastAppliedDate)) ? -1 : 1
      ),
    ],
    [
      t,
      pageSettingsTemplates,
      enqueueSnackbar,
      onTemplateApply,
      pageSettings,
      setPageSettingsTemplates,
    ]
  );

  return (
    <Dialog
      mainTitle={t("Templates")}
      onClose={onClose}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: 800,
        },
      }}
    >
      <MRTable.Table
        columns={columns}
        data={tableData}
        getRowId={(originalRow) => originalRow.name}
        initialState={{
          pagination: {
            pageSize: 5,
            pageIndex: 0,
          },
        }}
        globalFilterFn="contains"
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableColumnResizing={false}
        enableColumnPinning={false}
        enableColumnActions={false}
        enableColumnOrdering={false}
        renderTopToolbarCustomActions={() => (
          <Formik
            onSubmit={(values, formikHelpers) => {
              enqueueSnackbar(t("Successfully added"), {
                variant: "success",
              });
              setPageSettingsTemplates((prev) => [
                ...prev,
                {
                  name: values.name,
                  settings: pageSettings,
                  lastAppliedDate: null,
                },
              ]);
              formikHelpers.resetForm();
            }}
            initialValues={{
              name: "",
            }}
            validationSchema={yup.object().shape({
              name: yup
                .string()
                .trim()
                .required()
                .test("Duplicated", t("validation:Duplicated"), (value) => {
                  return pageSettingsTemplates.every((el) => el.name !== value);
                }),
            })}
          >
            {({ submitForm }) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <FormTextField
                  name="name"
                  placeholder={t("Enter template name")}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  sx={{
                    ml: 1,
                    mr: 1,
                  }}
                >
                  {t("Add")}
                </Button>
              </Box>
            )}
          </Formik>
        )}
        renderToolbarInternalActions={() => <></>}
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            cursor: "pointer",
            ...(pageSettings.template === row.original.name && {
              backgroundColor: "#604dff14",
            }),
          },
        })}
        muiSearchTextFieldProps={{
          sx: {
            width: 220,
            maxWidth: 220,
          },
        }}
      />
    </Dialog>
  );
};
