import { Autocomplete } from "@periplus/ui-library";
import useAddressAutocomplete, {
  AddressAutocompleteBaseProps,
} from "./useAddressAutocomplete";

interface AddressAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends AddressAutocompleteBaseProps<Multiple, DisableClearable> {}

export default function <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
>({ ...rest }: AddressAutocompleteProps<Multiple, DisableClearable>) {
  const props = useAddressAutocomplete<Multiple, DisableClearable>(rest);

  return <Autocomplete {...props} />;
}
