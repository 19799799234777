import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "@periplus/ui-library";

interface WarehouseIconProps extends SvgIconProps {}

const WarehouseIcon: FC<WarehouseIconProps> = ({ style, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 485 485" style={{ fill: "none", ...style }} {...rest}>
      <path
        d="M366.56 470H454.682C458.171 470 461 467.165 461 463.668V143.235C461 140.945 459.766 138.834 457.774 137.713L239.246 14.81C237.275 13.7018 234.864 13.7326 232.923 14.891L27.0865 137.686C25.1727 138.828 24 140.895 24 143.128V463.668C24 467.165 26.8287 470 30.3181 470H119.299M366.56 470H119.299M366.56 470V273.782M119.299 470V273.782M119.299 273.782V195.467H366.56V273.782M119.299 273.782H366.56M117.718 263.317V187.157C117.718 183.66 120.547 180.825 124.036 180.825H359.911C363.4 180.825 366.229 183.66 366.229 187.157V263.317M117.718 263.317V338.605M117.718 263.317H366.229M117.718 338.605V463.668C117.718 467.165 120.547 470 124.036 470H359.911C363.4 470 366.229 467.165 366.229 463.668V338.605M117.718 338.605H366.229M366.229 338.605V263.317"
        stroke="currentColor"
        strokeWidth="25"
      />
    </SvgIcon>
  );
};

export default WarehouseIcon;
