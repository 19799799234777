import { useMutation, MutationHookOptions } from "@apollo/client";
import gql from "graphql-tag";
import useErrorHandling from "hooks/useErrorHandling";

const UPDATE_DECLARATION_REQUEST = gql`
  mutation UPDATE_DECLARATION_REQUEST(
    $file_id: uuid!
    $arrival_time: timestamptz!
    $declaration_status: String
    $arrival_type: String
  ) {
    update_declaration_request_by_pk(
      pk_columns: { file_id: $file_id, dr_no: 1 }
      _set: {
        arrival_time: $arrival_time
        declaration_status: $declaration_status
        arrival_type: $arrival_type
      }
    ) {
      file_id
    }
  }
`;

const sendDeclarationArrivalNotification = gql`
  mutation sendDeclarationArrivalNotification(
    $file_id: uuid!
    $arrival_time: timestamptz!
  ) {
    sendDeclarationArrivalNotification(
      data: { file_id: $file_id, dr_no: 1, arrival_time: $arrival_time }
    )
  }
`;

const useUpdateDeclarationRequest = () => {
  const withErrorHandling = useErrorHandling();

  const [DeclarationRequest] = useMutation(UPDATE_DECLARATION_REQUEST);
  const [sendDeclarationArrivalNotificationMutation] = useMutation(
    sendDeclarationArrivalNotification
  );

  return (options?: MutationHookOptions) =>
    withErrorHandling(async (options) => {
      await DeclarationRequest(options);
      await sendDeclarationArrivalNotificationMutation({
        ...options,
        variables: {
          file_id: options.variables.file_id,
          arrival_time: options.variables.arrival_time,
        },
      });
    }, options);
};

export default useUpdateDeclarationRequest;
