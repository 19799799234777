import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { DefaultFlag, DocumentType } from "./types";
import { useAuth } from "keycloak";

const GET_DOCUMENT_TYPES = gql`
  query GET_DOCUMENT_TYPES {
    document_types: document_type(order_by: { creation_date: asc }) {
      lastModifiedDate: last_modified_date
      name
      display_name
      translations
    }
  }
`;

const useGetDocumentTypes = ({ tenantId }) => {
  const { user } = useAuth();
  const { data, ...rest } = useQuery<{
    document_types: DocumentType[];
  }>(GET_DOCUMENT_TYPES, {
    fetchPolicy: "cache-first",
  });

  return {
    documentTypes:
      data?.document_types.filter((document_type) => {
        const default_flags = user!.tenant!.tenantDocumentTypes.find(
          (tenantDocType) => tenantDocType.document_type === document_type.name
        )?.default_flags;
        return (
          (default_flags & DefaultFlag.wedeclare_relevant) ===
          DefaultFlag.wedeclare_relevant
        );
      }) || [],
    ...rest,
  };
};

export default useGetDocumentTypes;
