import gql from "graphql-tag";
import { useWdCreateDeclarationMutation } from "graphql/generated";

gql`
  mutation WdCreateDeclaration(
    $documents: [CreateDeclarationDocumentInput!]!
    $declaration: DeclarationInput
    $fileId: ID = null
  ) {
    createDeclarationReq(
      data: {
        documents: $documents
        fileId: $fileId
        flags: 8
        orgId: 651
        ignoreChecksum: true
        declaration: $declaration
      }
    ) {
      success
      error
      documentIds
      fileId
    }
  }
`;

const useCreateDeclaration = () => {
  const [createDeclaration] = useWdCreateDeclarationMutation();

  return createDeclaration;
};

export default useCreateDeclaration;
