import { useMemo } from "react";
import gql from "graphql-tag";
import {
  WdGetTenantsQuery,
  useWdGetTenantsLazyQuery,
  useWdGetTenantsQuery,
} from "graphql/generated";

export type TenantListEntity = NonNullable<
  WdGetTenantsQuery["tenants"]
>[number];

gql`
  query WdGetTenants {
    tenants: tenant {
      id
      tenant_name
      address {
        company_name
      }
    }
  }
`;

const useGetTenants = () => {
  const result = useWdGetTenantsQuery();

  return useMemo(
    () => ({
      ...result,
      data: {
        tenants: result.data?.tenants ?? [],
      },
    }),
    [result]
  );
};

export default useGetTenants;

export const useGetTenantsLazy = () => {
  return useWdGetTenantsLazyQuery();
};
