import React, {
  FC,
  HtmlHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  PageContainer,
  Autocomplete,
  IconButton,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  //Box,
  MRTable,
} from "@periplus/ui-library";
import { Launch } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import useUrlSearchParams, {
  TableUrlSearchParams,
} from "hooks/useUrlSearchParams";
import { TableLocalStorage } from "hooks/usePageLocalStorage";
import DeclarationStatusChip from "domain/declaration/components/DeclarationStatusChip";
import DeclarationArrivalTypeBlock from "domain/declaration/components/DeclarationArrivalTypeBlock";
import { DeclarationFilters } from "domain/declaration/components/DeclarationFiltersSidebar";
import useDownloadDeclarationGoodItemsStatistics from "domain/declaration/useDownloadDeclarationGoodItemsStatistics";
import ExportToExcelButton from "./ExportToExcelButton";
import useGetDeclarationsGoodsItemsStatistics, {
  DeclarationGoodsItemStatisticEntity,
} from "pages/Statistics/hooks/useGetDeclarationsGoodsItemsStatistics";
import useGetDeclarationsGoodsItemsStatisticsCount from "./hooks/useGetDeclarationsGoodsItemsStatisticsCount";
import DeclarationFiltersSidebar from "domain/declaration/components/DeclarationFiltersSidebar";
import { Link } from "react-router-dom";
import { useAuth } from "keycloak";
//import DeclarationLabelsField from "domain/declaration/components/DeclarationLabel/DeclarationLabelsField";
//import { DeclarationLabel } from "graphql/generated";
import { Permissions } from "keycloak/context/AuthContext";
import usePageSettings from "domain/user/usePageSettings";
import { convertPrimitive } from "utils/common";
import { insertIf } from "utils/array";
import { useAppState } from "App/AppContext";
import TemplatesManagerButton from "./components/TemplatesManager/TemplatesManagerButton";

interface StatisticsProps extends HtmlHTMLAttributes<HTMLElement> {}

export type StatisticPageSettings = TableLocalStorage<DeclarationFilters>;

const defaultFilters = {
  relative_date: "last3month",
} as DeclarationFilters;

const insertColumntIf = insertIf<
  MRTable.MRT_ColumnDef<DeclarationGoodsItemStatisticEntity>
>;

const Statistics: FC<StatisticsProps> = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("declaration");
  const { user } = useAuth();
  const { edecData } = useAppState();

  const isSupplierAvailable = user?.hasAllowedPermissions([
    Permissions.SUPPLIER_ADDRESS,
  ]);

  const { pageSettings, setPageSettings, setPageSettingsForMRTable } =
    usePageSettings<StatisticPageSettings>({
      itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
    });

  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<
    TableUrlSearchParams<DeclarationFilters>
  >({
    page: 1,
    itemsPerPage: pageSettings.itemsPerPage,
    ...(pageSettings.filters && {
      filters: {
        ...pageSettings.filters,
      },
    }),
  });

  const filters = useMemo(
    () => ({
      ...defaultFilters,
      ...urlSearchParams.filters,
    }),
    [urlSearchParams.filters]
  );

  const {
    data: { acs_goods_items_for_drs_count } = {
      acs_goods_items_for_drs_count: undefined,
    },
  } = useGetDeclarationsGoodsItemsStatisticsCount({
    ...urlSearchParams,
    filters,
  });

  const {
    data: { declarations_goods_items_statistics },
    loading: goodsItemsStatisticsLoading,
    //refetch,
  } = useGetDeclarationsGoodsItemsStatistics({
    ...urlSearchParams,
    sorting: pageSettings.sorting,
    filters,
  });

  const downloadStatistic = useDownloadDeclarationGoodItemsStatistics();

  const [columns, tableData] = React.useMemo<
    [
      MRTable.MRT_ColumnDef<DeclarationGoodsItemStatisticEntity>[],
      DeclarationGoodsItemStatisticEntity[]
    ]
  >(
    () => [
      [
        {
          header: t("Jobreference"), //x
          id: "identification",
          accessorFn: (originalRow) => originalRow.identification || "...",
        },
        {
          header: t("Creation date"), //x
          id: "creationDate",
          accessorFn: (originalRow) =>
            originalRow.declarationRequest?.creationDate &&
            dayjs(originalRow.declarationRequest.creationDate).format(
              "DD.MM.YYYY HH:mm"
            ),
        },
        {
          header: t("Acceptance date"), //x
          id: "acceptanceDate",
          accessorFn: (originalRow) =>
            originalRow.declarationRequest!.acceptanceDate &&
            dayjs(originalRow.declarationRequest!.acceptanceDate).format(
              "DD.MM.YYYY HH:mm"
            ),
        },
        {
          header: t("Status"), //x
          id: "declarationStatus",
          accessorFn: (originalRow) => originalRow.declarationStatus,
          Cell: ({ row: { original: originalRow } }) => (
            <DeclarationStatusChip
              declarationStatus={originalRow.declarationStatus}
            />
          ),
        },
        // {
        //   header: t("Service type"),
        //   id: "serviceType",
        //   accessorFn: (originalRow) =>
        //     edecData.edec_domains.find(
        //       (el) =>
        //         el.domain_name === "serviceType" &&
        //         el.value === originalRow.declaration.serviceType
        //     )?.[`meaning_${language}`],
        // },
        {
          header: t("Declaration Nr."), //x
          id: "customsDeclarationNumber",
          accessorFn: (originalRow) =>
            originalRow.declaration?.customsDeclarationNumber,
        },
        // {
        //   header: t("Declaration version"),
        //   id: "customsDeclarationVersion",
        //   accessorFn: (originalRow) =>
        //     originalRow.declaration.customsDeclarationVersion,
        // },
        // ...insertColumntIf(!!user?.tenant?.declaration_labels.length, {
        //   header: t("Labels"),
        //   id: "declarationLabels",
        //   Cell: ({ row: { original: originalRow } }) => (
        //     <DeclarationLabelsField
        //       view="table"
        //       file_id={originalRow.fileId}
        //       dr_no={originalRow.drNo!}
        //       declaration_labels={
        //         originalRow.declarationLabels as DeclarationLabel[]
        //       }
        //       onLabelsAssigned={() => refetch()}
        //     />
        //   ),
        //   enableSorting: false,
        // }),
        {
          header: t("Reference"), //x
          id: "reference",
          accessorFn: (originalRow) => originalRow.refs,
        },
        {
          header: t("Declaration type"), //x
          id: "declarationType",
          accessorFn: (originalRow) => {
            return edecData.edec_domains.find(
              (el) =>
                el.domain_name === "declarationType" &&
                el.value === originalRow.declaration?.declarationType
            )?.[`meaning_${language}`];
          },
        },
        {
          header: t("Toll Dept"), //x
          id: "tollDep",
          accessorFn: (originalRow) =>
            [
              originalRow.customsOffice?.number,
              originalRow.customsOffice?.name ?? "",
            ].join(" "),
        },
        {
          header: t("Requestor TIN"), //x
          id: "requestorTraderIdentificationNumber",
          accessorFn: (originalRow) =>
            originalRow.declaration?.requestorTraderIdentificationNumber,
        },
        {
          header: t("Consignor"), //x
          id: "consignor",
          accessorFn: (originalRow) =>
            originalRow.addresses!.find(
              (address) => address!.addressTypeId === 1
            )?.companyName,
          enableSorting: false,
        },
        {
          header: t("Consignor country"), //x
          id: "consignorCountry",
          accessorFn: (originalRow) => {
            return originalRow.addresses!.find(
              (address) => address!.addressTypeId === 1
            )?.country;
          },
          enableSorting: false,
        },
        {
          header: t("Consignor city"), //x
          id: "consignorCity",
          accessorFn: (originalRow) =>
            originalRow.addresses!.find(
              (address) => address!.addressTypeId === 1
            )?.city,
          enableSorting: false,
        },
        {
          header: t("Importer"), //x
          id: "importer",
          accessorFn: (originalRow) =>
            originalRow.addresses!.find(
              (address) => address!.addressTypeId === 4
            )?.companyName,
          enableSorting: false,
        },
        {
          header: t("Consignee"), //x
          id: "consignee",
          accessorFn: (originalRow) =>
            originalRow.addresses!.find(
              (address) => address!.addressTypeId === 2
            )?.companyName,
          enableSorting: false,
        },
        {
          header: t("Freight forwarder"), //x
          id: "declarant",
          accessorFn: (originalRow) =>
            originalRow.addresses!.find(
              (address) => address!.addressTypeId === 5
            )?.companyName,
          enableSorting: false,
        },
        ...insertColumntIf(isSupplierAvailable, {
          header: t("Supplier"), //x
          id: "supplier",
          accessorFn: (originalRow) =>
            originalRow.addresses!.find(
              (address) => address!.addressTypeId === 3
            )?.companyName,
          enableSorting: false,
        }),
        // {
        //   header: t("Total VAT"),
        //   id: "vatTotalAmount",
        //   accessorFn: (originalRow) => originalRow.declaration?.vatTotalAmount,
        // },
        // {
        //   header: t("Total Duty"),
        //   id: "dutyTotalAmount",
        //   accessorFn: (originalRow) => originalRow.declaration?.dutyTotalAmount,
        // },
        {
          header: t("Total Duties (incl. additional taxes)"), //x
          id: "dutyAndOtherTaxesAmount",
          accessorFn: (originalRow) =>
            originalRow.declaration?.dutyAndOtherTaxesAmount,
        },
        // {
        //   header: t("Trader declaration number"),
        //   id: "traderDeclarationNumber",
        //   accessorFn: (originalRow) =>
        //     originalRow.declaration?.traderDeclarationNumber,
        // },
        // {
        //   header: t("Trader reference"),
        //   id: "traderReference",
        //   accessorFn: (originalRow) => originalRow.declaration?.traderReference,
        // },
        {
          header: t("Dispatch country"), //x
          id: "dispatchCountry",
          accessorFn: (originalRow) => originalRow.declaration?.dispatchCountry,
        },
        // {
        //   header: t("Positions"),
        //   id: "totalGoodsItems",
        //   accessorFn: (originalRow) => originalRow.declaration?.totalGoodsItems,
        // },
        // {
        //   header: t("Packages"),
        //   id: "packagingQuantity",
        //   accessorFn: (originalRow) =>
        //     originalRow.declaration?.packagingQuantity,
        // },
        {
          header: t("Total gross weight"), //x
          id: "grossMass",
          accessorFn: (originalRow) => originalRow.declaration?.grossMass,
        },
        // {
        //   header: t("Total VAT-Value"),
        //   id: "totalVatValue",
        //   accessorFn: (originalRow) => originalRow.declaration?.totalVatValue,
        // },
        {
          header: t("VAT Nr."), //x
          id: "vatNumber",
          accessorFn: (originalRow) =>
            [
              originalRow.declaration?.vatNumber,
              originalRow.declaration?.vatSuffix && "MWST",
            ]
              .filter((el) => el)
              .join(" "),
        },
        // {
        //   header: t("Duty account"),
        //   id: "customsAccount",
        //   accessorFn: (originalRow) =>
        //     [
        //       originalRow.declaration?.customsAccountNumber,
        //       originalRow.declaration?.customsAccountName,
        //     ]
        //       .filter((el) => el)
        //       .join(", "),
        // },
        // {
        //   header: t("VAT account"),
        //   id: "vatAccount",
        //   accessorFn: (originalRow) =>
        //     [
        //       originalRow.declaration?.vatAccountNumber,
        //       originalRow.declaration?.vatAccountName,
        //     ]
        //       .filter((el) => el)
        //       .join(" "),
        // },
        {
          header: t("Currency"), //x
          id: "invoiceCurrencyType",
          accessorFn: (originalRow) => {
            return edecData.edec_domains.find(
              (el) =>
                el.domain_name === "invoiceCurrencyType" &&
                el.value === originalRow.declaration?.invoiceCurrencyType
            )?.[`meaning_${language}`];
          },
        },
        {
          header: t("Incoterms"), //x
          id: "incoterms",
          accessorFn: (originalRow) => originalRow.declaration?.incoterms,
        },
        // {
        //   header: t("Toll Dept code"),
        //   id: "placeOfUnloading",
        //   accessorFn: (originalRow) =>
        //     originalRow.declaration?.placeOfUnloading,
        // },
        {
          header: t("Transport mode"), //x
          id: "transportMode",
          accessorFn: (originalRow) => {
            return edecData.edec_domains.find(
              (el) =>
                el.domain_name === "transportMode" &&
                el.value === originalRow.declaration?.transportMode?.toString()
            )?.[`meaning_${language}`];
          },
        },
        // {
        //   header: t("Transport type"),
        //   id: "transportType",
        //   accessorFn: (originalRow) => {
        //     return edecData.edec_domains.find(
        //       (el) =>
        //         el.domain_name === "transportationType" &&
        //         el.value === originalRow.declaration?.transportType
        //     )?.[`meaning_${language}`];
        //   },
        // },
        // {
        //   header: t("Transport country"),
        //   id: "transportCountry",
        //   accessorFn: (originalRow) =>
        //     originalRow.declaration?.transportCountry,
        // },
        // {
        //   header: t("Transport number"),
        //   id: "transportNumber",
        //   accessorFn: (originalRow) => originalRow.declaration?.transportNumber,
        // },
        // {
        //   header: t(
        //     "Previous documents (type, number, additional information)"
        //   ),
        //   id: "previousDocument",
        //   accessorFn: (originalRow) =>
        //     originalRow.previousDocuments &&
        //     (Array.isArray(originalRow.previousDocuments)
        //       ? originalRow.previousDocuments
        //       : [originalRow.previousDocuments]
        //     )
        //       ?.map(
        //         (pd) =>
        //           `${
        //             edecData.edec_domains.find(
        //               (el) =>
        //                 el.domain_name === "previousDocumentType" &&
        //                 el.value === pd.previousDocumentType
        //             )?.[`meaning_${language}`]
        //           } ${pd.previousDocumentReference}`
        //       )
        //       .join(", "),
        //   enableSorting: false,
        // },
        // {
        //   header: t("Declaration special mention"),
        //   id: "specialMentionCrs",
        //   accessorFn: (originalRow) =>
        //     originalRow.declarationSpecialMentions
        //       ?.map((el) => el.text)
        //       .join(", "),
        //   enableSorting: false,
        // },
        {
          header: t("Arrival"), //x
          id: "arrivalType",
          Cell: ({ row: { original: originalRow } }) => (
            <DeclarationArrivalTypeBlock
              arrival_type={originalRow.declarationRequest!.arrivalType}
            />
          ),
          enableSorting: false,
        },
        {
          header: t("ETA"), //x
          id: "eta",
          accessorFn: (originalRow) =>
            originalRow.declarationRequest!.eta &&
            dayjs(originalRow.declarationRequest!.eta).format(
              "DD.MM.YYYY HH:mm"
            ),
        },
        {
          header: t("ATA"), //x
          id: "arrivalTime",
          accessorFn: (originalRow) =>
            originalRow.declarationRequest!.arrivalTime &&
            dayjs(originalRow.declarationRequest!.arrivalTime).format(
              "DD.MM.YYYY HH:mm"
            ),
        },
        {
          header: t("Position Nr."), //x
          id: "customsItemNumber",
          accessorFn: (originalRow) => originalRow.customsItemNumber,
        },
        {
          header: t("Tariff Nr."), //x
          id: "commodityCode",
          accessorFn: (originalRow) => originalRow.goodsItems?.commodityCode,
        },
        // {
        //   header: t("Statistical code"),
        //   id: "statisticalCode",
        //   accessorFn: (originalRow) => originalRow.goodsItems?.statisticalCode,
        // },
        {
          header: t("Description"), //x
          id: "description",
          accessorFn: (originalRow) => originalRow.goodsItems!.description,
        },
        // {
        //   header: t("Commercial good"),
        //   id: "commercialGood",
        //   accessorFn: (originalRow) =>
        //     edecData.edec_domains.find(
        //       (el) =>
        //         el.domain_name === "commercialGood" &&
        //         el.value ===
        //           { true: "1", false: "2" }[
        //             String(originalRow.goodsItems?.commercialGood)
        //           ]
        //     )?.[`meaning_${language}`],
        //   enableSorting: false,
        // },
        {
          header: t("Preference"), //x
          id: "preference",
          accessorFn: (originalRow) =>
            edecData.edec_domains.find(
              (el) =>
                el.domain_name === "yesNoCode" &&
                el.value ===
                  convertPrimitive(
                    originalRow.goodsItems?.originPreference,
                    "number"
                  )?.toString()
            )?.[`meaning_${language}`],
        },
        {
          header: t("Country of origin"), //x
          id: "originCountry",
          accessorFn: (originalRow) => originalRow.goodsItems!.originCountry,
        },
        // {
        //   header: t("Clearance type"),
        //   id: "clearanceType",
        //   accessorFn: (originalRow) =>
        //     edecData.edec_domains.find(
        //       (el) =>
        //         el.domain_name === "customsClearanceType" &&
        //         el.value === originalRow.goodsItems?.clearanceType?.toString()
        //     )?.[`meaning_${language}`],
        // },
        // {
        //   header: t("Repair"),
        //   id: "repair",
        //   accessorFn: (originalRow) =>
        //     edecData.edec_domains.find(
        //       (el) =>
        //         el.domain_name === "yesNoCode" &&
        //         el.value ===
        //           convertPrimitive(
        //             originalRow.goodsItems?.repair,
        //             "number"
        //           )?.toString()
        //     )?.[`meaning_${language}`],
        // },
        // {
        //   header: t("Repair type"),
        //   id: "repairReason",
        //   accessorFn: (originalRow) => originalRow.goodsItems?.repairReason,
        //   enableSorting: false,
        // },
        {
          header: t("Net weight"), //x
          id: "netMass",
          accessorFn: (originalRow) => originalRow.goodsItems!.netMass,
        },
        {
          header: t("Gross weight"), //x
          id: "grossMassItem",
          accessorFn: (originalRow) => originalRow.goodsItems!.grossMass,
          enableSorting: false,
        },
        // {
        //   header: t("Statistical value"),
        //   id: "statisticalValue",
        //   accessorFn: (originalRow) => originalRow.goodsItems?.statisticalValue,
        // },
        {
          header: t("VAT-Value"), //x
          id: "valuationVatValue",
          accessorFn: (originalRow) => originalRow.goodsItems!.vatValue,
        },
        // {
        //   header: t("VAT %"),
        //   id: "vatRate",
        //   accessorFn: (originalRow) => originalRow.goodsItems?.vatRate,
        // },
        // {
        //   header: t("VAT"),
        //   id: "vatTotal",
        //   accessorFn: (originalRow) => originalRow.goodsItems?.vatTotal,
        // },
        // {
        //   header: t("Duty rate"),
        //   id: "dutyRate",
        //   accessorFn: (originalRow) => originalRow.goodsItems?.dutyRate,
        // },
        // {
        //   header: t("Duty"),
        //   id: "dutyTotal",
        //   accessorFn: (originalRow) => originalRow.goodsItems!.dutyTotal,
        // },
        {
          header: t("My Duties"), //x
          id: "myDuties",
          accessorFn: (originalRow) => originalRow.myDuties,
        },
        {
          header: t("Duties (incl. additional taxes)"), //x
          id: "dutyAndOtherTaxesTotal",
          accessorFn: (originalRow) =>
            originalRow.goodsItems?.dutyAndOtherTaxesTotal,
        },
        // {
        //   header: t("Additional unit"),
        //   id: "additionalUnit",
        //   accessorFn: (originalRow) => originalRow.goodsItems?.additionalUnit,
        //   enableSorting: false,
        // },
        // {
        //   header: t("Packages (type, quantity, number)"),
        //   id: "packagings",
        //   Cell: ({ row: { original: originalRow } }) =>
        //     originalRow.packagings?.map(
        //       (
        //         { packagingType, packagingQuantity, packagingReferenceNumber },
        //         i
        //       ) => (
        //         <Box key={i}>
        //           {[
        //             edecData.edec_domains.find(
        //               (el) =>
        //                 el.domain_name === "packagingType" &&
        //                 el.value === packagingType?.toString()
        //             )?.[`meaning_${language}`],
        //             packagingQuantity,
        //             packagingReferenceNumber,
        //           ]
        //             .filter((el) => el)
        //             .join(", ")}
        //         </Box>
        //       )
        //     ),
        //   enableSorting: false,
        // },
        // {
        //   header: t("Produced documents (type, number, date)"),
        //   id: "producedDocuments",
        //   Cell: ({ row: { original: originalRow } }) =>
        //     originalRow.producedDocuments?.map(
        //       (
        //         {
        //           producedDocumentType,
        //           producedDocumentReferenceNumber,
        //           producedDocumentIssueDate,
        //         },
        //         i
        //       ) => (
        //         <Box key={i}>
        //           {[
        //             edecData.edec_domains.find(
        //               (el) =>
        //                 el.domain_name === "ProducedDocuments" &&
        //                 el.value === producedDocumentType
        //             )?.[`meaning_${language}`],
        //             producedDocumentReferenceNumber,
        //             producedDocumentIssueDate,
        //           ]
        //             .filter((el) => el)
        //             .join(", ")}
        //         </Box>
        //       )
        //     ),
        //   enableSorting: false,
        // },
        // {
        //   header: t("Authorization code"),
        //   id: "permitObligationCode",
        //   accessorFn: (originalRow) => {
        //     return edecData.edec_domains.find(
        //       (el) =>
        //         el.domain_name === "permitObligation" &&
        //         el.value ===
        //           originalRow.goodsItems?.permitObligationCode?.toString()
        //     )?.[`meaning_${language}`];
        //   },
        //   enableSorting: false,
        // },
        // {
        //   header: t("Authorization (type, authority, number)"),
        //   id: "permits",
        //   Cell: ({ row: { original: originalRow } }) =>
        //     originalRow.permits?.map(
        //       ({ permitType, permitAuthority, permitNumber }, i) => (
        //         <Box key={i}>
        //           {[
        //             edecData.edec_domains.find(
        //               (el) =>
        //                 el.domain_name === "permitType" &&
        //                 el.value === permitType?.toString()
        //             )?.[`meaning_${language}`],
        //             edecData.edec_domains.find(
        //               (el) =>
        //                 el.domain_name === "permitAuthority" &&
        //                 el.value === permitAuthority?.toString()
        //             )?.[`meaning_${language}`],
        //             permitNumber,
        //           ]
        //             .filter((el) => el)
        //             .join(", ")}
        //         </Box>
        //       )
        //     ),
        //   enableSorting: false,
        // },
        // {
        //   header: t("Selection result"),
        //   id: "selectionResult",
        //   accessorFn: (originalRow) =>
        //     edecData.edec_domains.find(
        //       (el) =>
        //         el.domain_name === "selectionResult" &&
        //         el.value === originalRow.goodsItems?.selectionResult?.toString()
        //     )?.[`meaning_${language}`],
        //   enableSorting: false,
        // },
        // {
        //   header: t("Position special mention"),
        //   id: "goodsItemSpecialMentions",
        //   accessorFn: (originalRow) =>
        //     originalRow.goodsItemSpecialMentions
        //       ?.map((el) => el.text)
        //       .join(", "),
        //   enableSorting: false,
        // },
        // {
        //   header: t("E-dec status"),
        //   id: "status",
        //   accessorFn: (originalRow) => originalRow.declaration?.status,
        //   enableSorting: false,
        // },
        // {
        //   header: t("E-dec release"),
        //   id: "release",
        //   accessorFn: (originalRow) =>
        //     edecData.edec_domains.find(
        //       (el) =>
        //         el.domain_name === "yesNoCode" &&
        //         el.value ===
        //           convertPrimitive(
        //             originalRow.declaration?.release,
        //             "number"
        //           )?.toString()
        //     )?.[`meaning_${language}`],
        //   enableSorting: false,
        // },
        // {
        //   header: t("E-dec status date"),
        //   id: "statusDateTime",
        //   accessorFn: (originalRow) =>
        //     [
        //       originalRow.declaration?.statusDate,
        //       originalRow.declaration?.statusTime,
        //     ]
        //       .filter((el) => el)
        //       .join(" "),
        //   enableSorting: false,
        // },
        // {
        //   header: t("E-dec material check"),
        //   id: "materialCheck",
        //   accessorFn: (originalRow) =>
        //     edecData.edec_domains.find(
        //       (el) =>
        //         el.domain_name === "yesNoCode" &&
        //         el.value ===
        //           convertPrimitive(
        //             originalRow.declaration?.materialCheck,
        //             "number"
        //           )?.toString()
        //     )?.[`meaning_${language}`],
        //   enableSorting: false,
        // },
      ],
      [...declarations_goods_items_statistics],
    ],
    [
      t,
      language,
      edecData,
      //refetch,
      isSupplierAvailable,
      //user?.tenant?.declaration_labels.length,
      declarations_goods_items_statistics,
    ]
  );

  const pagination = useMemo<MRTable.MRT_PaginationState>(
    () => ({
      pageIndex: urlSearchParams.page - 1,
      pageSize: urlSearchParams.itemsPerPage,
    }),
    [urlSearchParams.page, urlSearchParams.itemsPerPage]
  );
  const tableDefaultState = useMemo<{
    density: MRTable.MRT_DensityState;
    columnSizing: MRTable.MRT_ColumnSizingState;
    columnPinning: MRTable.MRT_ColumnPinningState;
    columnVisibility: MRTable.MRT_VisibilityState;
    columnOrder: MRTable.MRT_ColumnOrderState;
    sorting: MRTable.MRT_SortingState;
  }>(
    () => ({
      density: "compact",
      columnSizing: {},
      columnPinning: {},
      columnVisibility: {
        serviceType: false,
        customsDeclarationVersion: false,
        declarationLabels: false,
        vatTotalAmount: false,
        dutyTotalAmount: false,
        traderDeclarationNumber: false,
        traderReference: false,
        totalGoodsItems: false,
        packagingQuantity: false,
        totalVatValue: false,
        customsAccount: false,
        vatAccount: false,
        placeOfUnloading: false,
        transportType: false,
        transportCountry: false,
        transportNumber: false,
        previousDocument: false,
        specialMentionCrs: false,
        statisticalCode: false,
        commercialGood: false,
        clearanceType: false,
        repair: false,
        repairReason: false,
        statisticalValue: false,
        vatRate: false,
        vatTotal: false,
        dutyRate: false,
        dutyTotal: false,
        additionalUnit: false,
        packagings: false,
        producedDocuments: false,
        permitObligationCode: false,
        permits: false,
        selectionResult: false,
        goodsItemSpecialMentions: false,
        status: false,
        release: false,
        statusDateTime: false,
        materialCheck: false,
      },
      columnOrder: columns.map((c) => c.id!),
      sorting: [],
    }),
    [columns]
  );
  const [density, setDensity] = useState<MRTable.MRT_DensityState>(
    pageSettings.density ?? tableDefaultState.density
  );
  const [columnSizing, setColumnSizing] =
    useState<MRTable.MRT_ColumnSizingState>(
      pageSettings.columnSizing ?? tableDefaultState.columnSizing
    );
  const [columnPinning, setColumnPinning] =
    useState<MRTable.MRT_ColumnPinningState>(
      pageSettings.columnPinning ?? tableDefaultState.columnPinning
    );
  const [columnVisibility, setColumnVisibility] =
    useState<MRTable.MRT_VisibilityState>(
      pageSettings.columnVisibility ?? tableDefaultState.columnVisibility
    );
  const [columnOrder, setColumnOrder] = useState<MRTable.MRT_ColumnOrderState>(
    pageSettings.columnOrder ?? tableDefaultState.columnOrder
  );
  const [sorting, setSorting] = useState<MRTable.MRT_SortingState>(
    pageSettings.sorting ?? tableDefaultState.sorting
  );

  useEffect(
    () => {
      setPageSettingsForMRTable((prev) => ({
        template: undefined,
        itemsPerPage: urlSearchParams.itemsPerPage,
        density,
        columnSizing,
        columnPinning,
        columnVisibility,
        columnOrder,
        sorting,
      }));
    },
    // eslint-disable-next-line
    [
      urlSearchParams.itemsPerPage,
      density,
      columnSizing,
      columnPinning,
      columnVisibility,
      columnOrder,
      sorting,
    ]
  );

  const handleTemplateApply = useCallback(
    (settings: Partial<StatisticPageSettings>) => {
      setDensity(settings.density ?? tableDefaultState.density);
      setColumnSizing(settings.columnSizing ?? tableDefaultState.columnSizing);
      setColumnPinning(
        settings.columnPinning ?? tableDefaultState.columnPinning
      );
      setColumnVisibility(
        settings.columnVisibility ?? tableDefaultState.columnVisibility
      );
      setColumnOrder(settings.columnOrder ?? tableDefaultState.columnOrder);
      setSorting(settings.sorting ?? tableDefaultState.sorting);
      setTimeout(() => {
        setPageSettings(settings);
        setUrlSearchParams({ filters: settings.filters, page: 1 });
      }); //this code should be executed after the MRTable useEffect is triggered
    },
    [setPageSettings, setUrlSearchParams, tableDefaultState]
  );

  return (
    <PageContainer
      title={t("navigation:statistics")}
      controls={
        <>
          <TemplatesManagerButton onTemplateApply={handleTemplateApply} />
          <ExportToExcelButton
            onClick={async () =>
              await downloadStatistic({
                filters,
                search: urlSearchParams.search,
                search_field: urlSearchParams.search_field,
                columns: columnOrder.filter(
                  (column) =>
                    columnVisibility[column] !== false &&
                    column !== "mrt-row-actions"
                ),
                total_items:
                  Number(acs_goods_items_for_drs_count?.resultSetCount) || 0,
              })
            }
          />
        </>
      }
      sx={{
        "& .LuiPageContainer-appBar-controls": {
          display: "flex",
          alignItems: "center",
          gap: 1,
        },
        "& .LuiPageContainer-content": {
          display: "flex",
          flexDirection: "row",
          gap: 0,
          p: 0,
        },
      }}
    >
      <DeclarationFiltersSidebar
        filters={urlSearchParams.filters}
        onChange={(newFilters) => {
          setPageSettings((prev) => ({
            filters: newFilters,
            template: undefined,
          }));
          setUrlSearchParams({ filters: newFilters, page: 1 });
        }}
        defaultFilters={defaultFilters}
      />
      <MRTable.Table
        columns={columns}
        data={tableData}
        state={{
          globalFilter: urlSearchParams.search,
          pagination,
          density,
          columnSizing,
          columnPinning,
          columnVisibility,
          columnOrder,
          sorting,
          isLoading: goodsItemsStatisticsLoading,
        }}
        enableRowActions
        onGlobalFilterChange={(updater) => {
          const newState =
            updater instanceof Function
              ? updater(urlSearchParams.search)
              : updater;
          setUrlSearchParams({ search: newState || undefined, page: 1 });
        }}
        manualPagination
        manualFiltering
        manualSorting
        rowCount={acs_goods_items_for_drs_count?.resultSetCount || 0}
        onPaginationChange={(updater) => {
          const newState =
            updater instanceof Function ? updater(pagination) : updater;
          const newPage = newState.pageIndex + 1;
          if (urlSearchParams.page !== newPage)
            setUrlSearchParams({ page: newPage });

          const newItemsPerPage = newState.pageSize;
          if (urlSearchParams.itemsPerPage !== newState.pageSize)
            setUrlSearchParams({ itemsPerPage: newItemsPerPage, page: 1 });
        }}
        onDensityChange={setDensity}
        onColumnSizingChange={setColumnSizing}
        onColumnPinningChange={setColumnPinning}
        onColumnVisibilityChange={setColumnVisibility}
        onColumnOrderChange={setColumnOrder}
        onSortingChange={setSorting}
        renderRowActions={({ row: { original } }) => (
          <Link
            to={`/declaration/${original.fileId}/${original.drNo}`}
            style={{
              textDecoration: "none",
            }}
          >
            <IconButton color="primary">
              <Launch />
            </IconButton>
          </Link>
        )}
        muiTableContainerProps={{
          sx: {
            maxHeight: "calc(100vh - var(--appbar-height) - 145px)",
          },
        }}
        muiTablePaperProps={({ table }) => ({
          sx: (theme) => ({
            minWidth: 0,
            width: "100%",
            padding: `${theme.spacing(
              table.getState().isFullScreen ? 1 : 2
            )}!important`,
          }),
        })}
        muiTableHeadCellProps={{
          sx: {
            "& .Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
            },
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableBodyCellProps: {
              sx: {
                justifyContent: "center",
              },
            },
          },
        }}
        renderToolbarInternalActions={({ table }) => {
          return (
            <>
              <Autocomplete
                getOptionLabel={(option) => t(`${option}`)}
                InputProps={{
                  placeholder: t("Fields"),
                }}
                value={urlSearchParams.search_field || null}
                onChange={(event, newValue) => {
                  setUrlSearchParams({
                    search_field: newValue || undefined,
                    page: 1,
                  });
                }}
                options={["hs_code", "file_identification"]}
                sx={{
                  width: 120,
                  ml: 1,
                }}
              />
              <MRTable.MRT_ShowHideColumnsButton table={table} />
              <MRTable.MRT_ToggleFullScreenButton table={table} />
            </>
          );
        }}
      />
    </PageContainer>
  );
};

export default Statistics;
