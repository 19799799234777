import React from "react";
import {
  Autocomplete,
  Box,
  BoxProps,
  Paper,
  Typography,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import ReactEcharts from "echarts-for-react";
import Summary from "../components/Summary";
import { KPIEntity } from "../hooks/useGetKPI";
import dayjs, { Dayjs } from "dayjs";
import usePageSettings from "domain/user/usePageSettings";

interface Props extends BoxProps {
  kpis: KPIEntity;
  dateFrom: Dayjs;
  dateTo: Dayjs;
}

const KPI_INTERVALS = ["day", "week", "month", "year"] as const;
export type KpiIntervalType = (typeof KPI_INTERVALS)[number];

export default ({ kpis, dateFrom, dateTo }: Props) => {
  const { t } = useTranslation();
  const { pageSettings, setPageSettings } = usePageSettings<{
    interval: KpiIntervalType;
  }>({
    interval: "day",
  });

  const chartData = Object.entries(
    [...kpis.customsDeclarations.dateInterval]
      .sort((a, b) => Number(a.date) - Number(b.date))
      .reduce(
        (aggregatedData, el) => {
          const formattedDate = dayjs(Number(el.date))
            .startOf(pageSettings.interval)
            .format("YYYY-MM-DD");
          aggregatedData[formattedDate] = {
            count: (aggregatedData[formattedDate]?.count || 0) + el.count,
            lastYearCount:
              (aggregatedData[formattedDate]?.lastYearCount || 0) +
              el.lastYearCount,
          };
          return aggregatedData;
        },
        {} as {
          [key: string]: {
            count: number;
            lastYearCount: number;
          };
        }
      )
  ).map(([date, { count, lastYearCount }]) => ({
    date: dayjs(date),
    count,
    lastYearCount,
  }));

  const chartOption = {
    // toolbox: {
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: false,
    //     },
    //   },
    // },
    dataZoom: [
      {
        type: "inside",
      },
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    grid: {
      left: "27.5px",
      right: "57.5px",
      bottom: "35px",
      top: "35px",
    },
    legend: {
      data: [t("Last year"), t("Current")],
    },
    xAxis: [
      {
        type: "category",
        axisTick: { show: false },
        data: chartData.map(({ date }) => {
          switch (pageSettings.interval) {
            case "day":
              return date.format("DD/MM\nYYYY");
            case "week":
              return `${t("Week")} ${date.week()}\n${date.year()}`;
            case "month":
              return date.format("MMM YYYY");
            case "year":
            default:
              return date.format("YYYY");
          }
        }),
        axisPointer: {
          label: {
            padding: [5, 9, 5, 7],
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        position: "right",
        axisLabel: {
          formatter: `{value} ${t("kpi:pcs")}`,
        },
        minInterval: 1,
      },
    ],
    series: [
      {
        name: t("Last year"),
        type: "bar",
        data: chartData.map((el) => el.lastYearCount),
      },
      {
        name: t("Current"),
        type: "bar",
        data: chartData.map((el) => el.count),
      },
    ],
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
      }}
    >
      <Box sx={{ flexGrow: 1, flexBasis: "50%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h6">{t("Customs Clearances")}</Typography>
          <Autocomplete
            value={pageSettings.interval}
            onChange={(e, value) =>
              setPageSettings((prev) => ({ ...prev, interval: value }))
            }
            options={KPI_INTERVALS}
            getOptionLabel={(option) => t(option)}
            disableClearable
            InputProps={{
              label: t("Interval"),
            }}
            sx={{
              minWidth: 120,
            }}
          />
        </Box>
        <Paper
          variant="outlined"
          sx={{
            p: 1,
            height: 312.8,
          }}
        >
          <ReactEcharts option={chartOption} />
        </Paper>
      </Box>
      <Box sx={{ flexGrow: 1, flexBasis: "50%" }}>
        <Box
          sx={{
            height: 40,
            display: "flex",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h6">{t("Clearances in Numbers")}</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Summary
            title={t("Total Clearances")}
            value={kpis.customsDeclarations.dateInterval.reduce(
              (acc, el) => acc + el.count,
              0
            )}
            primary
          />
          <Summary
            title={t("Clearances per day")}
            value={
              kpis.customsDeclarations.dateInterval.reduce(
                (acc, el) => acc + el.count,
                0
              ) /
              (dateTo.diff(dateFrom, "day") + 1)
            }
            valuePrefix="⌀"
          />
          <Summary
            title={t("Tariff positions per Clearance")}
            value={kpis.avgCommodityCodesPerDeclaration}
            valuePrefix="⌀"
          />
        </Box>
      </Box>
    </Box>
  );
};
