import gql from "graphql-tag";
import { useSubscribeDeclarationDiscussionsSubscription } from "graphql/generated";

gql`
  subscription subscribeDeclarationDiscussions($file_id: uuid) {
    declaration_discussion(where: { file_id: { _eq: $file_id } }) {
      discussion_id
      discussion_theme
      declaration_messages(order_by: { msg_date: asc }) {
        creation_user
        message
        msg_date
        read_date
        type
        user {
          tenant_id
          display_name
          first_name
          last_name
          email
          tenant {
            tenant_name
          }
        }
      }
    }
  }
`;

const useSubscribeDeclarationDiscussions = (file_id?: string) => {
  const result = useSubscribeDeclarationDiscussionsSubscription({
    variables: {
      file_id,
    },
    shouldResubscribe: true,
  });

  return result;
};

export default useSubscribeDeclarationDiscussions;
