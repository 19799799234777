import { useState, useMemo, useEffect } from "react";
import {
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  ThreeDotsMenu,
  ListItemIcon,
  ListItemText,
  MRTable,
} from "@periplus/ui-library";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAuth } from "keycloak";

import { TableLocalStorage } from "hooks/usePageLocalStorage";
import useUrlSearchParams, {
  TableUrlSearchParams,
} from "hooks/useUrlSearchParams";
import { AddressType } from "domain/declaration/types";
import AddressDialog from "../components/AddressDialog";
import usePageSettings from "domain/user/usePageSettings";
import LinkAddressDialog from "./components/LinkAddressDialog";
import useGetAddressesManagement, {
  AddressManagementEntity,
} from "./hooks/useGetAddressesManagement";
import FiltersSidebar, {
  AddressesManagementFilters,
} from "../components/FiltersSidebar";
import dayjs from "dayjs";
import PageContainer from "../components/PageContainer";

export default () => {
  const { user } = useAuth();

  const { t } = useTranslation();
  const [addressDialogState, setAddressDialogState] = useState<{
    dialog?: "edit" | "link";
    address?: AddressManagementEntity;
  }>({});

  const { pageSettings, setPageSettings, setPageSettingsForMRTable } =
    usePageSettings<TableLocalStorage<AddressesManagementFilters>>({
      itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
    });

  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<
    TableUrlSearchParams<AddressesManagementFilters>
  >({
    page: 1,
    itemsPerPage: pageSettings.itemsPerPage,
    filters: pageSettings.filters,
  });

  const {
    data: { addresses, totalAddresses },
    loading,
    refetch,
  } = useGetAddressesManagement(urlSearchParams);

  const [columns, tableData] = useMemo<
    [
      MRTable.MRT_ColumnDef<AddressManagementEntity>[],
      AddressManagementEntity[]
    ]
  >(
    () => [
      [
        {
          header: t("company"),
          id: "companyName",
          accessorFn: (originalRow) => originalRow.companyName,
        },
        {
          header: t("Creation date", { ns: "declaration" }),
          id: "creationDate",
          accessorFn: (originalRow) =>
            dayjs(originalRow.creationDate).format("DD.MM.YYYY HH:mm"),
        },
        {
          header: t("Type"),
          id: "addressTypes",
          accessorFn: (originalRow) =>
            originalRow.address_types
              .map((type) => t(`addressTypes:${AddressType[type]}`))
              .join(", "),
          size: 250,
        },
        {
          header: t("address"),
          accessorFn: (originalRow) =>
            [originalRow.street, originalRow.street_number]
              .filter((el) => el)
              .join(" "),
          id: "street",
        },
        {
          header: t("zip"),
          id: "zipcode",
          accessorFn: (originalRow) => originalRow.zipcode,
        },
        {
          header: t("city"),
          id: "city",
          accessorFn: (originalRow) => originalRow.city,
        },
        {
          header: t("country"),
          id: "country",
          accessorFn: (originalRow) => originalRow.country,
        },
        {
          header: t("Edited by user"),
          id: "source",
          accessorFn: (originalRow) =>
            t(`${originalRow.source === "ao" ? "Yes" : "No"}`),
        },
      ],
      [...addresses],
    ],
    [t, addresses]
  );

  const pagination = useMemo<MRTable.MRT_PaginationState>(
    () => ({
      pageIndex: urlSearchParams.page - 1,
      pageSize: urlSearchParams.itemsPerPage,
    }),
    [urlSearchParams.page, urlSearchParams.itemsPerPage]
  );
  const [density, setDensity] = useState<MRTable.MRT_DensityState>(
    pageSettings.density ?? "compact"
  );
  const [columnSizing, setColumnSizing] =
    useState<MRTable.MRT_ColumnSizingState>(pageSettings.columnSizing ?? {});
  const [columnPinning, setColumnPinning] =
    useState<MRTable.MRT_ColumnPinningState>(pageSettings.columnPinning ?? {});
  const [columnVisibility, setColumnVisibility] =
    useState<MRTable.MRT_VisibilityState>(pageSettings.columnVisibility ?? {});
  const [columnOrder, setColumnOrder] = useState<MRTable.MRT_ColumnOrderState>(
    pageSettings.columnOrder ?? columns.map((c) => c.id!)
  );

  useEffect(
    () => {
      setPageSettingsForMRTable({
        itemsPerPage: urlSearchParams.itemsPerPage,
        density,
        columnSizing,
        columnPinning,
        columnVisibility,
        columnOrder,
      });
    },
    // eslint-disable-next-line
    [
      urlSearchParams.itemsPerPage,
      density,
      columnSizing,
      columnPinning,
      columnVisibility,
      columnOrder,
    ]
  );

  return (
    <PageContainer
    // onAddressCreated={() => {
    //   refetch();
    // }}
    >
      <FiltersSidebar
        filters={urlSearchParams.filters}
        onChange={(newFilters) => {
          setPageSettings({
            filters: newFilters,
          });
          setUrlSearchParams({ filters: newFilters, page: 1 });
        }}
      />
      <MRTable.Table
        columns={columns}
        data={tableData}
        state={{
          globalFilter: urlSearchParams.search,
          pagination,
          density,
          columnSizing,
          columnPinning,
          columnVisibility,
          columnOrder,
          isLoading: loading,
        }}
        enableRowActions
        enableSorting={false}
        onGlobalFilterChange={(updater) => {
          const newState =
            updater instanceof Function
              ? updater(urlSearchParams.search)
              : updater;
          setUrlSearchParams({ search: newState || undefined, page: 1 });
        }}
        manualPagination
        manualFiltering
        rowCount={totalAddresses}
        onPaginationChange={(updater) => {
          const newState =
            updater instanceof Function ? updater(pagination) : updater;
          const newPage = newState.pageIndex + 1;
          if (urlSearchParams.page !== newPage)
            setUrlSearchParams({ page: newPage });

          const newItemsPerPage = newState.pageSize;
          if (urlSearchParams.itemsPerPage !== newState.pageSize)
            setUrlSearchParams({ itemsPerPage: newItemsPerPage, page: 1 });
        }}
        onDensityChange={setDensity}
        onColumnSizingChange={setColumnSizing}
        onColumnPinningChange={setColumnPinning}
        onColumnVisibilityChange={setColumnVisibility}
        onColumnOrderChange={setColumnOrder}
        renderRowActions={({ row: { original } }) => (
          <ThreeDotsMenu
            options={[
              {
                content: (
                  <>
                    <ListItemIcon>
                      <LinkOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("Link", { ns: "addresses" })} />
                  </>
                ),
                action: (close) => {
                  setAddressDialogState({
                    dialog: "link",
                    address: original,
                  });
                  close();
                },
                disabled: original.source === "ao",
                tooltip:
                  original.source === "ao" &&
                  t("This address is already linked or edited"),
              },
              {
                content: (
                  <>
                    <ListItemIcon>
                      <EditOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("Edit")} />
                  </>
                ),
                action: (close) => {
                  setAddressDialogState({
                    dialog: "edit",
                    address: original,
                  });
                  close();
                },
              },
            ]}
          />
        )}
        muiTableContainerProps={{
          sx: {
            maxHeight: "calc(100vh - var(--appbar-height) - 145px)",
          },
        }}
        muiTablePaperProps={({ table }) => ({
          sx: (theme) => ({
            minWidth: 0,
            width: "100%",
            padding: `${theme.spacing(
              table.getState().isFullScreen ? 1 : 2
            )}!important`,
          }),
        })}
        muiTableHeadCellProps={{
          sx: {
            "& .Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
            },
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableBodyCellProps: {
              sx: {
                justifyContent: "center",
              },
            },
          },
        }}
      />
      {addressDialogState?.dialog === "edit" && (
        <AddressDialog
          tenantId={user?.tenant_id}
          address={addressDialogState.address}
          onClose={() => setAddressDialogState({})}
          onConfirm={() => {
            setAddressDialogState({});
            refetch();
          }}
        />
      )}
      {addressDialogState?.dialog === "link" && (
        <LinkAddressDialog
          address={addressDialogState.address!}
          tenantId={user?.tenant_id}
          onClose={() => setAddressDialogState({})}
          onLinkSuccess={() => {
            refetch();
          }}
        />
      )}
    </PageContainer>
  );
};
