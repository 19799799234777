import { FC, useMemo, useState } from "react";
import {
  PageContainer,
  Autocomplete,
  Loader,
  DatePickerRange,
  Box,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import GeneralTab from "./GeneralTab";
import TariffTab from "./TariffTab";
import useGetKPI from "./hooks/useGetKPI";
import dayjs, { Dayjs } from "dayjs";
import usePageSettings from "domain/user/usePageSettings";
import useGetTenants, { TenantListEntity } from "domain/tenant/useGetTenants";
import { useAuth } from "keycloak";
import { MNR_TENANT_ID } from "domain/tenant/types";

export const RELATIVE_DATES = {
  today: {
    label: "Today",
    dateFrom: dayjs().startOf("day"),
    dateTo: dayjs().endOf("day"),
  },
  last2weeks: {
    label: "Last 2 weeks",
    dateFrom: dayjs().subtract(2, "weeks").startOf("day"),
    dateTo: dayjs().endOf("day"),
  },
  last1month: {
    label: "Previous Month",
    dateFrom: dayjs().subtract(1, "month").startOf("month").startOf("day"),
    dateTo: dayjs().subtract(1, "month").endOf("month").endOf("day"),
  },
  last3month: {
    label: "Last 3 Month",
    dateFrom: dayjs().subtract(3, "month").startOf("month").startOf("day"),
    dateTo: dayjs().subtract(1, "month").endOf("month").endOf("day"),
  },
  last6month: {
    label: "Last 6 Month",
    dateFrom: dayjs().subtract(6, "month").startOf("month").startOf("day"),
    dateTo: dayjs().subtract(1, "month").endOf("month").endOf("day"),
  },
  other: {
    label: "Other",
  },
} as const;
type RelativeDateType = keyof typeof RELATIVE_DATES;

const KPI: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { pageSettings, setPageSettings } = usePageSettings<{
    payer: TenantListEntity;
    relativeDate: RelativeDateType;
    dateFrom?: string;
    dateTo?: string;
    customsAgency?: { label: string; traderIdentificationNumber: string };
  }>({
    payer: user?.tenant!,
    relativeDate: "last2weeks",
  });

  const {
    data: { tenants },
    loading: tenantsLoading,
  } = useGetTenants();

  const { dateFrom, dateTo } = useMemo(
    () =>
      pageSettings.relativeDate === "other"
        ? {
            dateFrom: dayjs(pageSettings.dateFrom),
            dateTo: dayjs(pageSettings.dateTo),
          }
        : {
            dateFrom: RELATIVE_DATES[pageSettings.relativeDate].dateFrom,
            dateTo: RELATIVE_DATES[pageSettings.relativeDate].dateTo,
          },
    [pageSettings.relativeDate, pageSettings.dateFrom, pageSettings.dateTo]
  );

  const [selectedTab, setSelectedTab] = useState(0);
  const [dateRange, setDateRange] = useState<{
    dateFrom: Dayjs;
    dateTo: Dayjs;
  }>({
    dateFrom,
    dateTo,
  });

  const {
    data: { kpis },
    loading: kpiLoading,
  } = useGetKPI({
    variables: {
      date_from: dateFrom.format(),
      date_to: dateTo.format(),
      interval: "day",
      tenant_id: pageSettings.payer.id,
      trader_identification_number:
        pageSettings.customsAgency?.traderIdentificationNumber,
    },
    skip: !pageSettings.payer,
  });

  return (
    <PageContainer
      title={t("navigation:kpi")}
      tabs={[t("General"), t("Tariff")]}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
      controls={
        <Box sx={{ display: "flex", gap: 1 }}>
          {user?.tenant_id === MNR_TENANT_ID && (
            <Autocomplete
              value={pageSettings.payer}
              getOptionLabel={(option) => option.address.company_name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={tenants}
              onChange={(event, newPayer) => {
                setPageSettings({ payer: newPayer });
              }}
              loading={tenantsLoading}
              disableClearable
              InputProps={{
                label: t("Client"),
              }}
              sx={{
                width: 180,
              }}
            />
          )}
          <Autocomplete
            value={pageSettings.relativeDate}
            options={Object.keys(RELATIVE_DATES) as RelativeDateType[]}
            onChange={(e, newValue) => {
              if (newValue === "other") {
                setPageSettings({
                  relativeDate: newValue,
                  dateFrom: dateRange.dateFrom.toISOString(),
                  dateTo: dateRange.dateTo.toISOString(),
                });
              } else {
                setPageSettings({
                  relativeDate: newValue,
                  dateFrom: undefined,
                  dateTo: undefined,
                });
                setDateRange({
                  dateFrom: RELATIVE_DATES[newValue].dateFrom,
                  dateTo: RELATIVE_DATES[newValue].dateTo,
                });
              }
            }}
            getOptionLabel={(option) => t(RELATIVE_DATES[option].label)}
            InputProps={{
              label: t("Period"),
            }}
            disableClearable
            sx={{
              width: 160,
            }}
          />
          <DatePickerRange
            startDate={dateRange.dateFrom}
            endDate={dateRange.dateTo}
            onChange={(newValue) => {
              setDateRange({
                dateFrom: newValue.startDate!,
                dateTo: newValue.endDate!,
              });
              if (
                newValue.startDate?.isValid() &&
                newValue.endDate?.isValid()
              ) {
                setPageSettings({
                  relativeDate: "other",
                  dateFrom: newValue.startDate!.toISOString(),
                  dateTo: newValue.endDate!.toISOString(),
                });
              }
            }}
            startDatePickerProps={{
              label: t("From"),
            }}
            endDatePickerProps={{
              label: t("To"),
            }}
          />
          {user?.tenant_id !== MNR_TENANT_ID && (
            <Autocomplete
              value={pageSettings.customsAgency ?? null}
              getOptionLabel={(option) => option.label}
              options={(() => {
                const defaultOptions = pageSettings.customsAgency
                  ? [pageSettings.customsAgency]
                  : [];
                return (
                  kpis?.customsAgencies.reduce((acc, customsAgency) => {
                    if (
                      !acc.some(
                        (el) =>
                          el?.traderIdentificationNumber ===
                          customsAgency.traderIdentificationNumber
                      )
                    ) {
                      acc.push(customsAgency);
                    }

                    return acc;
                  }, defaultOptions) ?? defaultOptions
                );
              })()}
              onChange={(event, value) => {
                setPageSettings({
                  customsAgency: value ?? undefined,
                });
              }}
              loading={kpiLoading}
              InputProps={{
                label: t("Customs Agencies"),
              }}
              sx={{
                width: 180,
              }}
            />
          )}
        </Box>
      }
    >
      {!kpis ? (
        <Loader />
      ) : (
        (() => {
          switch (selectedTab) {
            case 0:
              return (
                <GeneralTab kpis={kpis} dateFrom={dateFrom} dateTo={dateTo} />
              );
            case 1:
              return (
                <TariffTab
                  kpis={kpis}
                  payer={pageSettings.payer}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  traderIdentificationNumber={
                    pageSettings.customsAgency?.traderIdentificationNumber
                  }
                />
              );
          }
        })()
      )}
    </PageContainer>
  );
};

export default KPI;
