import { FormikHelpers, useFormik } from "formik";
import * as yup from "yup";
import { AddressType } from "domain/declaration/types";
import { uploadFile } from "utils/azureBlobStorageUtils";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import useCreateDeclaration from "domain/declaration/useCreateDeclaration";
import {
  Address,
  DeclarationAddressInput,
  Declaration_Request,
  Group_Members,
} from "graphql/generated";
import { Paths } from "utils/utilityTypes";
import { EdecCustomsOffice } from "domain/edec/useGetEdecMasterData";

export const DECLARATION_REQUEST_FORM_ADDRESSES_TYPES = [
  "carrier",
  "supplier",
  "importer",
  "consignor",
  "consignee",
  "freight_forwarder",
  "consignee_transit",
  "consignor_transit",
] as const;
export type DeclarationRequestFormAddressesTypes =
  (typeof DECLARATION_REQUEST_FORM_ADDRESSES_TYPES)[number];
export type DeclarationRequestFormAddress = Pick<
  Address,
  "id" | "street" | "country" | "zipcode" | "city" | "reference"
> & {
  companyName: Address["company_name"];
  streetNumber: Address["street_number"];
};
export type DeclarationRequestFormAddresses = {
  [key in DeclarationRequestFormAddressesTypes]: {
    address: DeclarationRequestFormAddress | null;
    ref: string;
  };
};
export type DeclarationRequestFormPayer = Pick<
  Address,
  "id" | "company_name" | "address_type"
> & {
  tenant: Pick<NonNullable<Address["tenant"]>, "id"> | null;
};
export type DeclarationRequestFormCustomsOffice = Pick<
  EdecCustomsOffice,
  "name" | "number"
>;
export type DeclarationRequestForm = Pick<
  Declaration_Request,
  | "declaration_type"
  | "clearance_type"
  | "tr_no"
  | "ch_exp_decl"
  | "imp_cust_clr"
  | "transitdoc_type"
  | "dest_tolldept_code"
  | "dest_tolldept_name"
  | "eta"
  | "is_express"
  | "arrival_time"
  | "declaration_status"
> &
  DeclarationRequestFormAddresses & {
    file_id?: Declaration_Request["file_id"];
    payer: DeclarationRequestFormPayer;
    customs_place: {
      is_allowed_consignee: boolean;
      wa_number: Declaration_Request["wa_number"];
    };
    toll_dep: DeclarationRequestFormCustomsOffice | null;
    special_remarks?: string;
    docs?: any[];
  };

export type DeclarationRequestFormPaths = Paths<DeclarationRequestForm>;

export interface DeclarationFormikProps {
  addressGroupMembers: Group_Members[];
  initialValues: DeclarationRequestForm;
  onSuccess: (
    values: DeclarationRequestForm,
    formikHelpers: FormikHelpers<DeclarationRequestForm>
  ) => void;
}

const useDeclarationFormik = ({
  addressGroupMembers,
  initialValues,
  onSuccess,
}: DeclarationFormikProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("declaration");
  const createDeclaration = useCreateDeclaration();

  const validationSchema = yup.lazy((values) => {
    const permissions_js = addressGroupMembers.find(
      (agm) =>
        agm.payer?.id === values.payer.id &&
        agm.clearance_types.some((el) => el === values.clearance_type) &&
        agm.declaration_types.some((el) => el === values.declaration_type)
    )?.permissions_js as Group_Members["permissions_js"];
    if (!permissions_js) return yup.object();
    const constractSimpleFieldSchema = (field, baseSchema) => {
      if (!permissions_js[field].visible) return;
      return {
        [field]: permissions_js[field].mandatory
          ? baseSchema.required()
          : baseSchema,
      };
    };
    return yup.object().shape({
      ...DECLARATION_REQUEST_FORM_ADDRESSES_TYPES.reduce((acc, addressType) => {
        const addressPermissions = permissions_js[`${addressType}_addr`];
        const baseAddressSchema = yup.object().nullable();
        const refPermissions = permissions_js[`${addressType}_ref`];
        const baseRefSchema = yup.string().trim();

        return {
          ...acc,
          [addressType]: yup.object().shape({
            address:
              addressPermissions?.visible &&
              (addressPermissions?.mandatory
                ? baseAddressSchema.required()
                : baseAddressSchema),
            ref:
              refPermissions?.visible &&
              (refPermissions?.mandatory
                ? baseRefSchema.required()
                : baseRefSchema),
          }),
        };
      }, {}),
      ...(values.declaration_status !== "declaration_exported" &&
        values.declaration_status !==
          "declaration_exported_reference_missing" && {
          payer: yup.object().required(),
          declaration_type: yup.string().required(),
          clearance_type: yup.string().required(),
          docs: yup
            .array()
            .when("file_id", (file_id, schema) =>
              !file_id && permissions_js.docs.mandatory
                ? schema.required().min(1).max(30)
                : schema.notRequired()
            ),
          ...(permissions_js.customsplace.visible && {
            customs_place: yup.object().shape({
              is_allowed_consignee: yup.boolean(),
              wa_number: values.customs_place.is_allowed_consignee
                ? yup.string().required()
                : yup.string().nullable(),
            }),
          }),
          ...constractSimpleFieldSchema("toll_dep", yup.object().nullable()),
          ...constractSimpleFieldSchema("eta", yup.date().nullable()),
          ...constractSimpleFieldSchema("tr_no", yup.string().trim()),
          ...constractSimpleFieldSchema(
            "ch_exp_decl",
            yup.boolean().nullable()
          ),
          ...constractSimpleFieldSchema("is_express", yup.boolean().nullable()),
          ...constractSimpleFieldSchema(
            "imp_cust_clr",
            yup.boolean().nullable()
          ),
          ...constractSimpleFieldSchema("special_remarks", yup.string().trim()),
          ...constractSimpleFieldSchema(
            "transitdoc_type",
            yup.string().nullable()
          ),
          ...constractSimpleFieldSchema(
            "dest_tolldept_code",
            yup.string().trim()
          ),
          ...constractSimpleFieldSchema(
            "dest_tolldept_name",
            yup.string().trim()
          ),
        }),
    });
  });

  const handleSubmit = (
    values: DeclarationRequestForm,
    formikHelpers: FormikHelpers<DeclarationRequestForm>
  ): void | Promise<any> => {
    const castedValues = validationSchema.cast(
      values
    ) as any as DeclarationRequestForm;
    const {
      file_id,
      declaration_status,
      docs = [],
      payer,
      declaration_type,
      clearance_type,
      tr_no,
      ch_exp_decl,
      imp_cust_clr,
      transitdoc_type,
      eta,
      customs_place,
      toll_dep,
      dest_tolldept_code,
      dest_tolldept_name,
      is_express,
      special_remarks,
      arrival_time,
    } = castedValues;

    const permissions_js = addressGroupMembers.find(
      (agm) =>
        agm.payer?.id === castedValues.payer.id &&
        agm.clearance_types.some((el) => el === castedValues.clearance_type) &&
        agm.declaration_types.some((el) => el === castedValues.declaration_type)
    )?.permissions_js as Group_Members["permissions_js"];

    Promise.all(docs.map(uploadFile))
      .then((uploadedDocs: any[]) => {
        createDeclaration({
          variables: {
            fileId: file_id,
            documents: uploadedDocs.map(({ name, blobUri }) => ({
              fileName: name,
              fileUrl: blobUri,
            })),
            declaration: {
              declaration_status,
              payer_id: payer.tenant?.id,
              declaration_type,
              clearance_type,
              ...(permissions_js.tr_no.visible && { tr_no }),
              ...(permissions_js.ch_exp_decl.visible && { ch_exp_decl }),
              ...(permissions_js.imp_cust_clr.visible && { imp_cust_clr }),
              ...(permissions_js.transitdoc_type.visible && {
                transitdoc_type,
              }),
              ...(permissions_js.dest_tolldept_code.visible && {
                dest_tolldept_code,
              }),
              ...(permissions_js.dest_tolldept_name.visible && {
                dest_tolldept_name,
              }),
              ...(permissions_js.is_express.visible && { is_express }),
              ...(permissions_js.eta.visible && { eta }),
              addresses: DECLARATION_REQUEST_FORM_ADDRESSES_TYPES.reduce(
                (acc, addressType) => {
                  const address = {} as DeclarationAddressInput;
                  if (payer?.address_type === AddressType[addressType]) {
                    address.id = payer.id;
                  }
                  if (
                    permissions_js[`${addressType}_ref`]?.visible &&
                    castedValues[addressType].ref
                  ) {
                    address.refs = castedValues[addressType].ref;
                  }
                  if (permissions_js[`${addressType}_addr`]?.visible) {
                    if (castedValues[addressType].address) {
                      address.id = castedValues[addressType].address!.id;
                    }
                  }
                  if (Object.keys(address).length) {
                    address.address_type = AddressType[addressType];
                    acc.push(address);
                  }

                  return acc;
                },
                [
                  {
                    id: payer.id,
                    address_type: AddressType.payer,
                  },
                ] as DeclarationAddressInput[]
              ),
              arrival_type: customs_place.is_allowed_consignee
                ? "allowed_consignee"
                : "border",
              ...(permissions_js.customsplace.visible && {
                wa_number: customs_place.wa_number,
              }),
              ...(permissions_js.toll_dep.visible && {
                toll_dep: toll_dep?.number ?? null,
              }),
              ...(file_id
                ? {
                    arrival_time,
                  }
                : {
                    meta: {
                      ...(permissions_js.special_remarks.visible && {
                        message: special_remarks,
                      }),
                    },
                  }),
            },
          },
        }).then((result) => {
          formikHelpers.setSubmitting(false);
          if (!result?.data?.createDeclarationReq?.success) {
            enqueueSnackbar(t("error:serverError"), { variant: "error" });
            return;
          }
          onSuccess(castedValues, formikHelpers);
        });
      })
      .catch(() => {
        formikHelpers.setSubmitting(false);
        enqueueSnackbar(t("error:serverError"), { variant: "error" });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return formik;
};

export default useDeclarationFormik;
