import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AlertDialog,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@periplus/ui-library";
import { TransformedDocument } from "../DocumentsTab";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import useDeleteDocument from "domain/document/useDeleteDocument";

interface DeleteButtonProps {
  file_id: string;
  document: TransformedDocument;
  onCancel: () => void;
  onDeleted: () => Promise<any>;
}

const DeleteButton: FC<DeleteButtonProps> = ({
  file_id,
  document,
  onCancel,
  onDeleted,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteDocument = useDeleteDocument();

  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen(true);
        }}
      >
        <ListItemIcon>
          <DeleteOutlineIcon color="error" />
        </ListItemIcon>
        <ListItemText
          primary={t(`Delete`)}
          primaryTypographyProps={{ color: "error" }}
        />
      </MenuItem>
      {open && (
        <AlertDialog
          variant="warning"
          CancelButtonProps={{ label: t("abort") }}
          ConfirmButtonProps={{ label: t("confirm"), loading: isLoading }}
          onClose={() => {
            setOpen(false);
            onCancel();
          }}
          onConfirm={async () => {
            setIsLoading(true);
            await deleteDocument(document.id, file_id);
            await onDeleted();
            setIsLoading(false);
            setOpen(false);
          }}
        >
          {`${t("newDeclaration:deleteDocumentRequest")} "${
            document.document_name
          }"?`}
        </AlertDialog>
      )}
    </>
  );
};

export default DeleteButton;
