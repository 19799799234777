import React, { FC, HtmlHTMLAttributes } from "react";
import { Box, Panel, Typography } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import Group from "./Group";
import DeclarationStatusChip from "domain/declaration/components/DeclarationStatusChip";
import { CustomsIcon, WarehouseIcon } from "components/Icons";
import AddressesGroup from "./AddressesGroup";
import dayjs from "dayjs";
import { useAuth } from "keycloak";
import Field from "./Field";
import DeclarationLabelsField from "domain/declaration/components/DeclarationLabel/DeclarationLabelsField";
import { convertPrimitive } from "utils/common";
import { AddressType } from "domain/declaration/types";
import { DeclarationDetailsEntity } from "pages/Declaration/hooks/useGetDeclarationDetails";
import { useAppState } from "App/AppContext";

interface DetailsPanelProps extends HtmlHTMLAttributes<HTMLElement> {
  declaration: DeclarationDetailsEntity;
  onLabelsAssigned: () => void;
}

const DetailsPanel: FC<DetailsPanelProps> = ({
  declaration,
  onLabelsAssigned,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("declaration");
  const { user } = useAuth();
  const { edecData } = useAppState();

  return (
    <Panel
      title={t("Details")}
      sx={{
        "& .LuiPanel-content": {
          display: "flex",
          flexDirection: "column",
          gap: 2,
          overflow: "auto",
        },
      }}
    >
      <Group title={t("Reference & State")}>
        <Field
          label={t("Status")}
          value={
            <DeclarationStatusChip
              declarationStatus={declaration.declarationStatus}
            />
          }
        />
        <Field
          label={t("Jobreference")}
          value={declaration.file?.identification}
        />
        <Field
          label={t("Importer Reference")}
          value={
            declaration.addresses?.find(
              (el) => el.addressTypeId === AddressType.importer
            )?.drReference
          }
        />
        <Field
          label={t("Trader reference")}
          value={declaration.declaration?.traderReference}
        />
        {!!user?.tenant?.declaration_labels.length && (
          <DeclarationLabelsField
            view="card"
            file_id={declaration.fileId}
            dr_no={declaration.drNo!}
            declaration_labels={declaration.declarationLabels}
            onLabelsAssigned={onLabelsAssigned}
          />
        )}
      </Group>
      <Group title={t("General")}>
        <Field
          label={t("Arrival")}
          value={
            <Box
              sx={(theme) => ({
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                [theme.breakpoints.up("sm")]: {
                  justifyContent: "unset",
                },
              })}
            >
              {(() => {
                const type = declaration.declarationRequest.waNumber
                  ? "allowed_consignee"
                  : "border";

                const arrivalType = {
                  allowed_consignee: {
                    Icon: WarehouseIcon,
                    label: "AC-Place",
                  },
                  border: { Icon: CustomsIcon, label: "Border" },
                };

                const { Icon, label } = arrivalType[type];
                return (
                  <>
                    <Icon />
                    <Typography sx={{ ml: 1 }}>
                      {t(`declaration:${label}`)}
                    </Typography>
                  </>
                );
              })()}
            </Box>
          }
        />
        <Field
          label={t("Declaration type")}
          value={
            declaration.declarationRequest.declarationType !== "unknown" &&
            t(
              `declaration_type:${declaration.declarationRequest.declarationType}`
            )
          }
        />
        <Field
          label={t("Clearance")}
          value={
            declaration.declarationRequest.clearanceType !== "unknown" &&
            t(`clearance_type:${declaration.declarationRequest.clearanceType}`)
          }
        />
        <Field
          label={t("Wa-Number")}
          value={declaration.declarationRequest.waNumber}
        />
        <Field
          label={t("Contact Person")}
          value={declaration.userPp?.displayName}
        />
        <Field label={t("Email")} value={declaration.userPp?.email} />
        <Field
          label={t("Declaration Nr.")}
          value={declaration.declaration?.customsDeclarationNumber}
        />
        <Field
          label={t("Declaration version")}
          value={declaration.declaration?.customsDeclarationVersion}
        />
        <Field
          label={t("Requestor TIN")}
          value={declaration.declaration?.requestorTraderIdentificationNumber}
        />
        <Field
          label={t("Trader declaration Nr.")}
          value={declaration.declaration?.traderDeclarationNumber}
        />
        <Field
          label={t("VAT Nr.")}
          value={[
            declaration.declaration?.vatNumber,
            declaration.declaration?.vatSuffix && "MWST",
          ]
            .filter((el) => el)
            .join(" ")}
        />

        <Field
          label={t("Total gross weight")}
          value={declaration.declaration?.grossMass}
        />
        <Field
          label={t("Total VAT-Value")}
          value={declaration.declaration?.totalVatValue}
        />
      </Group>
      <AddressesGroup declaration={declaration} />
      <Group title={t("Timeline")}>
        <Field
          label={t("Creation date")}
          value={
            declaration.declarationRequest.creationDate &&
            dayjs(declaration.declarationRequest.creationDate).format(
              "DD.MM.YYYY HH:mm"
            )
          }
        />
        <Field
          label={t("ETA")}
          value={
            declaration.declarationRequest.eta &&
            dayjs(declaration.declarationRequest.eta).format("DD.MM.YYYY HH:mm")
          }
        />
        <Field
          label={t("ATA")}
          value={
            declaration.declarationRequest.arrivalTime &&
            dayjs(declaration.declarationRequest.arrivalTime).format(
              "DD.MM.YYYY HH:mm"
            )
          }
        />
        <Field
          label={t("Acceptance Date")}
          value={
            declaration.declaration.acceptanceDate &&
            dayjs(declaration.declaration.acceptanceDate).format(
              "DD.MM.YYYY HH:mm"
            )
          }
        />
      </Group>
      <Group title={t("Accounts")}>
        <Field
          label={t("Duty account")}
          value={[
            declaration.declaration?.customsAccountNumber,
            declaration.declaration?.customsAccountName,
          ]
            .filter((el) => !!el)
            .join(" ")}
        />
        <Field
          label={t("Account VAT")}
          value={[
            declaration.declaration?.vatAccountNumber,
            declaration.declaration?.vatAccountName,
          ]
            .filter((el) => !!el)
            .join(" ")}
        />
      </Group>
      <Group title={t("Terms & Expenses")}>
        <Field
          label={t("IncoTerm")}
          value={declaration.declaration?.incoterms}
        />
        <Field
          label={t("Currency")}
          value={
            edecData.edec_domains.find(
              (el) =>
                el.domain_name === "invoiceCurrencyType" &&
                el.value === declaration.declaration?.invoiceCurrencyType
            )?.[`meaning_${language}`]
          }
        />
        <Field
          label={t("Total VAT")}
          value={declaration.declaration?.vatTotalAmount}
        />
        <Field
          label={t("Total Duty")}
          value={declaration.declaration?.dutyTotalAmount}
        />
        <Field
          label={t("Total Duties (incl. additional taxes)")}
          value={declaration.declaration?.dutyAndOtherTaxesAmount}
        />
      </Group>
      <Group title={t("Cargo Details")}>
        <Field
          label={t("Transport (mode of transport, type, country, number)")}
          value={(() => {
            const transportModeType = edecData.edec_domains.find(
              (el) =>
                el.domain_name === "transportMode" &&
                el.value === declaration.declaration?.transportMode?.toString()
            )?.[`meaning_${language}`];

            const transportType = edecData.edec_domains.find(
              (el) =>
                el.domain_name === "transportationType" &&
                el.value === declaration.declaration?.transportType
            )?.[`meaning_${language}`];

            return [
              transportModeType,
              transportType,
              declaration.declaration?.transportCountry,
              declaration.declaration?.transportNumber,
            ]
              .filter((el) => !!el)
              .join(", ");
          })()}
        />
        <Field
          label={t("Previous documents (type, number, additional information)")}
          value={
            declaration.declaration.previousDocument &&
            (Array.isArray(declaration.declaration.previousDocument)
              ? declaration.declaration.previousDocument
              : [declaration.declaration.previousDocument]
            )
              ?.map((pd) =>
                [
                  edecData.edec_domains.find(
                    (el) =>
                      el.domain_name === "previousDocumentType" &&
                      el.value === pd.previousDocumentType
                  )?.[`meaning_${language}`],
                  pd.previousDocumentReference,
                ]
                  .filter((el) => el)
                  .join(" ")
              )
              .join(", ")
          }
        />
        <Field
          label={t("Toll Dept")}
          value={
            declaration.declaration.customsOfficeNumber &&
            `${declaration.declaration.customsOfficeNumber} ${declaration.customsOffice?.name}`
          }
        />
        <Field
          label={t("Toll Dept code")}
          value={declaration.declaration?.placeOfUnloading}
        />
        <Field
          label={t("Positions")}
          value={declaration.declaration?.totalGoodsItems}
        />
        <Field
          label={t("Special mention")}
          value={declaration.declaration?.specialMention?.map((el, i) => (
            <Typography key={i}>{el.text}</Typography>
          ))}
        />
        <Field
          label={t("Packages")}
          value={declaration.declaration?.packagingQuantity}
        />
        <Field
          label={t("Dispatch country")}
          value={declaration.declaration?.dispatchCountry}
        />
      </Group>
      <Group title={t("E-dec Details")}>
        <Field
          label={t("E-dec status")}
          value={declaration.declaration?.status}
        />
        <Field
          label={t("E-dec release")}
          value={
            edecData.edec_domains.find(
              (el) =>
                el.domain_name === "yesNoCode" &&
                el.value ===
                  convertPrimitive(
                    declaration.declaration?.release,
                    "number"
                  )?.toString()
            )?.[`meaning_${language}`]
          }
        />
        <Field
          label={t("E-dec status date")}
          value={(() => {
            if (
              !declaration.declaration?.statusDate ||
              !declaration.declaration?.statusTime
            )
              return;

            const statusTimeArray =
              declaration.declaration.statusTime.split(":");
            const hours = parseInt(statusTimeArray[0]);
            const minutes = parseInt(statusTimeArray[1]);
            const seconds = parseInt(statusTimeArray[2]);

            return dayjs(declaration.declaration.statusDate)
              .add(hours, "h")
              .add(minutes, "m")
              .add(seconds, "s")
              .format("DD.MM.YYYY hh:mm");
          })()}
        />
        <Field
          label={t("E-dec material check")}
          value={
            edecData.edec_domains.find(
              (el) =>
                el.domain_name === "yesNoCode" &&
                el.value ===
                  convertPrimitive(
                    declaration.declaration?.materialCheck,
                    "number"
                  )?.toString()
            )?.[`meaning_${language}`]
          }
        />
      </Group>
    </Panel>
  );
};

export default DetailsPanel;
