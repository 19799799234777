import gql from "graphql-tag";
import {
  WdGetDeclarationDetailsDocumentsQuery,
  WdGetDeclarationDetailsDocumentsQueryVariables,
  useWdGetDeclarationDetailsDocumentsQuery,
  useWdGetDocumentUpdatesSubscription,
} from "graphql/generated";
import { useMemo, useState } from "react";
import { NotNullable } from "utils/utilityTypes";

type DeclarationDetailsDocumentOriginalEntity = NonNullable<
  WdGetDeclarationDetailsDocumentsQuery["documents"]
>[number];
export type DeclarationDetailsDocumentEntity = NotNullable<
  Omit<DeclarationDetailsDocumentOriginalEntity, "pages_m2m">,
  "document_type" | "document_version" | "documentType"
> & {
  pages_m2m: NonNullable<
    NonNullable<DeclarationDetailsDocumentOriginalEntity["pages_m2m"]>[number]
  >[];
};

gql`
  query WdGetDeclarationDetailsDocuments($file: UUID!) {
    documents(
      file: $file
      filters: {
        operator: "AND"
        filters: [
          { field: "DocumentEntity.is_deleted", op: "EQ", values: ["false"] }
          {
            field: "document_type"
            op: "NOT_IN"
            values: [
              "edec_response_xml"
              "customs_overview"
              "customs_declaration"
            ]
          }
        ]
      }
    ) {
      id
      document_name
      document_type
      document_blobs(path: "blobs")
      document_extension
      is_deleted
      number_of_pages
      document_flags
      last_modified_date
      documentType {
        name
        translations
      }
      sequence
      document_version
      pages_m2m {
        pageNumber
        page {
          id
          rotate
          thumbnailBigUrl
          thumbnailSmallUrl
          pageStatus
        }
      }
      customs_response_data {
        document_version
      }
    }
  }
`;

gql`
  subscription WdGetDocumentUpdates {
    pp_doc_max_date_hs: pp_doc_max_date_v2(args: { _my: false }) {
      max_change_date
    }
  }
`;

const useGetDeclarationDetailsDocuments = ({
  file_id,
}: {
  file_id: WdGetDeclarationDetailsDocumentsQueryVariables["file"];
}) => {
  const result = useWdGetDeclarationDetailsDocumentsQuery({
    variables: {
      file: file_id,
    },
  });

  const [, setChangeDate] = useState(null);
  useWdGetDocumentUpdatesSubscription({
    onData: ({ data: { data } }) => {
      const newChangeDate =
        data?.pp_doc_max_date_hs?.[0]?.max_change_date || null;
      setChangeDate((prev) => {
        if (prev !== null) {
          result.refetch();
        }
        return newChangeDate;
      });
    },
  });

  return useMemo(
    () => ({
      ...result,
      data: {
        documents: (result.data?.documents ??
          []) as DeclarationDetailsDocumentEntity[],
      },
    }),
    [result]
  );
};

export default useGetDeclarationDetailsDocuments;
