import { FC, HtmlHTMLAttributes, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@periplus/ui-library";
import { DeclarationForm } from "domain/declaration/components/DeclarationForm";
import { AddressType } from "domain/declaration/types";
import useDeclarationFormik, {
  DECLARATION_REQUEST_FORM_ADDRESSES_TYPES,
  DeclarationRequestFormAddresses,
  DeclarationRequestForm,
} from "domain/declaration/components/DeclarationForm/useDeclarationFormik";
import { FormikProvider } from "formik";
import { DeclarationDetailsEntity } from "pages/Declaration/hooks/useGetDeclarationDetails";
import { Group_Members } from "graphql/generated";
import { useAppState } from "App/AppContext";
import dayjs from "dayjs";

interface EditDialogProps extends HtmlHTMLAttributes<HTMLElement> {
  declaration: DeclarationDetailsEntity;
  addressGroupMembers: Group_Members[];
  onClose: () => void;
  onConfirm: () => void;
}

const EditDialog: FC<EditDialogProps> = ({
  declaration,
  addressGroupMembers,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation("declaration");
  const { edecData } = useAppState();

  const initialValues: DeclarationRequestForm = useMemo(
    () => ({
      file_id: declaration.fileId,
      payer: addressGroupMembers.find(
        (agm) => agm.payer?.id === declaration.payer.address.id
      )!.payer!,
      declaration_type: declaration.declarationRequest.declarationType,
      clearance_type: declaration.declarationRequest.clearanceType,
      ...DECLARATION_REQUEST_FORM_ADDRESSES_TYPES.reduce((acc, addressType) => {
        const declarationAddress = declaration?.addresses?.find(
          (declarationAddress) =>
            declarationAddress.addressTypeId === AddressType[addressType]
        );
        acc[addressType] = {
          address: declarationAddress?.id
            ? {
                id: declarationAddress.id,
                companyName: declarationAddress.companyName!,
                country: declarationAddress.country,
                city: declarationAddress.city,
                street: declarationAddress.street,
                streetNumber: declarationAddress.streetNumber,
                zipcode: declarationAddress.zipcode,
                reference: declarationAddress.reference,
              }
            : null,
          ref: declarationAddress?.drReference ?? "",
        };
        return acc;
      }, {} as DeclarationRequestFormAddresses),
      tr_no: declaration.declarationRequest.trNo,
      ch_exp_decl: declaration.declarationRequest.chExpDecl,
      imp_cust_clr: declaration.declarationRequest.impCustClr,
      transitdoc_type: declaration.declarationRequest.transitdocType,
      dest_tolldept_code: declaration.declarationRequest.destTolldeptCode,
      dest_tolldept_name: declaration.declarationRequest.destTolldeptName,
      customs_place: {
        is_allowed_consignee:
          declaration.declarationRequest.arrivalType === "allowed_consignee",
        wa_number: declaration.declarationRequest.waNumber,
      },
      toll_dep: edecData.edec_customs_offices.find(
        (el) => el.number === declaration.declaration.customsOfficeNumber
      )!,
      eta:
        declaration.declarationRequest.eta &&
        dayjs(declaration.declarationRequest.eta),
      is_express: declaration.declarationRequest.isExpress,
      arrival_time:
        declaration.declarationRequest.arrivalTime &&
        dayjs(declaration.declarationRequest.arrivalTime),
      declaration_status: declaration.declarationStatus,
    }),
    [declaration, addressGroupMembers, edecData]
  );

  const handleSubmit = () => {
    onConfirm();
  };

  const formik = useDeclarationFormik({
    initialValues,
    addressGroupMembers: addressGroupMembers,
    onSuccess: handleSubmit,
  });
  const { submitForm, isSubmitting, dirty, isValid } = formik;

  return (
    <FormikProvider value={formik}>
      <Dialog
        maxWidth="md"
        mainTitle={t("declaration:editRequest")}
        subTitle={t("declaration:editRequestSubTitle")}
        onClose={onClose}
        onConfirm={submitForm}
        CancelButtonProps={{ label: t("common:abort") }}
        ConfirmButtonProps={{
          label: t("common:save"),
          disabled: isSubmitting || !dirty || !isValid,
          loading: isSubmitting,
        }}
      >
        <DeclarationForm
          formType="edit"
          addressGroupMembers={addressGroupMembers}
        />
      </Dialog>
    </FormikProvider>
  );
};

export default EditDialog;
