import React, { FC } from "react";

import { useAuth } from "keycloak/context/AuthContext";

import AuthRoutes from "./Auth";
import NonAuthRoutes from "./NonAuth";
import ErrorBoundary from "../ErrorBoundary";
import { Loader } from "@periplus/ui-library";

const Routes: FC = () => {
  const { loading, isAuthenticated, user } = useAuth();

  if (loading) {
    return <Loader />;
  }

  const UserRoutes =
    isAuthenticated && user?.userPermissions?.length
      ? AuthRoutes
      : NonAuthRoutes;

  return (
    <ErrorBoundary>
      <UserRoutes />
    </ErrorBoundary>
  );
};

export default Routes;
