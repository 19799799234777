import { useCallback } from "react";
import { AlertDialog } from "@periplus/ui-library";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import useUnlinkAddresses from "../../hooks/useUnlinkAddresses";
import { LinkedAddressManagementEntity } from "../../hooks/useGetLinkedAddressesManagement";

interface Props {
  addressId: LinkedAddressManagementEntity["sourceAddress"]["id"];
  onClose: () => void;
  onConfirm: () => void;
}

export default ({ addressId, onClose, onConfirm }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [unlinkAddresses, { loading: unlinkAddressesLoading }] =
    useUnlinkAddresses();

  const handleConfirm = useCallback(() => {
    unlinkAddresses({
      variables: {
        sourceAddressId: addressId,
      },
    })
      .then(() => {
        enqueueSnackbar(t("Address successfully unlinked"), {
          variant: "success",
        });
        onConfirm();
      })
      .catch(() => {
        enqueueSnackbar(t("Address unlinking unsuccessful"), {
          variant: "error",
        });
        onClose();
      });
  }, [addressId, onConfirm, onClose, enqueueSnackbar, t, unlinkAddresses]);

  return (
    <AlertDialog
      onClose={onClose}
      fullWidth
      onConfirm={handleConfirm}
      variant="warning"
      ConfirmButtonProps={{
        label: t("Unlink"),
        loading: unlinkAddressesLoading,
      }}
    >
      {t("Are you sure you want to remove this address link?")}
    </AlertDialog>
  );
};
