import React, { useEffect } from "react";

import { useAuth } from "keycloak/context/AuthContext";
import { Loader, Typography, Button } from "@periplus/ui-library";
import { Theme } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    container: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    logout: {
      marginTop: theme.spacing(1),
    }
  }));

const Login = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { loginWithRedirect, isAuthenticated, user, logout, loading } =
    useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: { targetUrl: "/" },
      });
    }
  }, [loginWithRedirect, isAuthenticated]);

  return (
    <div className={classes.container}>
      {loading && <Loader />}
      {isAuthenticated && !user?.userPermissions?.length && (
        <div>
          <Typography variant="h3">{t("error:unauthorizedTitle")}</Typography>
          <Typography>{t("error:unauthorizedDescription1")}</Typography>
          <Typography>{t("error:unauthorizedDescription2")}</Typography>
          <Button
            color="primary"
            variant="contained"
            className={classes.logout}
            onClick={() => logout()}
          >
            {t("common:logout")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Login;
