import { FormAutocomplete, FormAutocompleteProps } from "@periplus/ui-library";
import { DeclarationRequestFormPaths } from "../useDeclarationFormik";

interface DeclarationFormAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  K extends DeclarationRequestFormPaths
> extends FormAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: K;
}

export default function <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
  K extends DeclarationRequestFormPaths = DeclarationRequestFormPaths
>(
  props: DeclarationFormAutocomplete<T, Multiple, DisableClearable, FreeSolo, K>
) {
  return <FormAutocomplete {...props} />;
}
