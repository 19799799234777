import React, { FC, HtmlHTMLAttributes } from "react";
import { Box, Typography } from "@periplus/ui-library";
import DeclarationFieldEditControls from "./DeclarationFieldEditControls";

interface DeclarationFieldLabelProps extends HtmlHTMLAttributes<HTMLElement> {
  onEdit?: () => void;
  hovered: boolean;
}

const DeclarationFieldLabel: FC<DeclarationFieldLabelProps> = ({
  children,
  onEdit,
  hovered,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Typography
        variant="overline"
        color="textSecondary"
        sx={{ position: "relative" }}
      >
        {children}
        {onEdit && (
          <Box sx={{ position: "absolute", left: "100%", bottom: -2 }}>
            <DeclarationFieldEditControls
              onEdit={onEdit}
              sx={{ ml: 1, visibility: hovered ? "visible" : "hidden" }}
            />
          </Box>
        )}
      </Typography>
    </Box>
  );
};

export default DeclarationFieldLabel;
