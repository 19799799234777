import React, { FC, useState } from "react";
import {
  Box,
  combineSX,
  SxProps,
  Theme,
  TypographyProps,
} from "@periplus/ui-library";
import { Typography } from "@periplus/ui-library";
import DeclarationFieldEditControls from "./DeclarationFieldEditControls";

export interface DeclarationFieldValueProps extends TypographyProps {
  onEdit?: () => void;
}

const DeclarationFieldValue: FC<DeclarationFieldValueProps> = ({
  onEdit,
  noWrap,
  children,
  sx,
}) => {
  const sharedSX: SxProps<Theme> = (theme) => ({
    minHeight: 20,
    color: "#4F4F4F",
    justifySelf: "end",
    [theme.breakpoints.up("sm")]: {
      justifySelf: "unset",
    },
  });
  const [hovered, setHovered] = useState(false);

  return (
    <Box
      {...(onEdit && {
        onMouseEnter: () => setHovered(true),
        onMouseLeave: () => setHovered(false),
      })}
      sx={combineSX(
        {
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
        },
        sx
      )}
    >
      {children && typeof children === "object" ? (
        <Box sx={sharedSX}>{children}</Box>
      ) : (
        <Typography
          sx={combineSX(
            {
              overflow: "hidden",
            },
            sharedSX
          )}
          noWrap={noWrap}
          tooltip={noWrap}
        >
          {children}
        </Typography>
      )}
      {onEdit && (
        <DeclarationFieldEditControls
          onEdit={onEdit}
          sx={{
            visibility: hovered ? "visible" : "hidden",
          }}
        />
      )}
    </Box>
  );
};

export default DeclarationFieldValue;
