import client, { keycloak } from "graphql/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import "./i18n";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import packageJson from "../package.json";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { KeycloakInitOptions } from "keycloak-js";
import Cookies from "js-cookie";
import { AuthProvider } from "keycloak/context/AuthContext";
import { ApolloProvider } from "@apollo/client";
import { SnackbarProvider } from "notistack";
import "typeface-rubik";
import "typeface-open-sans";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

//localStorage version
if (localStorage.getItem("version") !== "13") {
  localStorage.clear();
  localStorage.setItem("version", "13");
}

const onEvent = (event: any, error: any) => {
  if (typeof error !== undefined) {
    switch (event) {
      case "onAuthSuccess":
        localStorage.setItem(
          "kc_idToken",
          //@ts-ignore
          keycloak.idToken
        );
        Cookies.set(
          "refreshToken",
          //@ts-ignore
          keycloak.refreshToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "idToken",
          //@ts-ignore
          keycloak.idToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "token",
          //@ts-ignore
          keycloak.token,
          { expires: 7, sameSite: "strict", secure: true }
        );
        break;
      case "onAuthRefreshSuccess":
        localStorage.setItem(
          "kc_idToken",
          //@ts-ignore
          keycloak.idToken
        );
        Cookies.set(
          "refreshToken",
          //@ts-ignore
          keycloak.refreshToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "idToken",
          //@ts-ignore
          keycloak.idToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "token",
          //@ts-ignore
          keycloak.token,
          { expires: 7, sameSite: "strict", secure: true }
        );
        break;
      case "onAuthRefreshError":
        console.log("onAuthRefreshError");
        break;
      case "onAuthError":
        console.log("onAuthError");
        break;
      default:
        break;
    }
  }
};

const refreshToken = Cookies.get("refreshToken");
const token = Cookies.get("token");
const idToken = Cookies.get("idToken");

const keycloakProviderInitConfig: KeycloakInitOptions = {
  onLoad: "check-sso",
  checkLoginIframe: false,
  //@ts-ignore
  promiseType: "legacy",
  // @ts-ignore
  token: token,
  // @ts-ignore
  refreshToken: refreshToken,
  // @ts-ignore
  idToken: idToken,
};

if ("production" === process.env.REACT_APP_ENVIRONMENT) {
  Sentry.init({
    dsn: "https://b8c86d76c8ad40e8b190586c9727c664@o249208.ingest.sentry.io/5753237",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: `${packageJson.name}@${packageJson.version}`,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
      onEvent={onEvent}
    >
      <ApolloProvider client={client}>
        <AuthProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <App />
          </SnackbarProvider>
        </AuthProvider>
      </ApolloProvider>
    </ReactKeycloakProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
